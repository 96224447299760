import { Input } from "reactstrap";
import { key } from "../utils/consts";

const TextInput = ({ placeholder, onChange, inputType, defaultValue, disabled, pattern, onKeyPress, backgroundColor }) => {
    return (
        <Input
            className="w-full h-12 rounded placeholder-gray-400 border-0"
            pattern={pattern ? pattern : null}
            placeholder={placeholder}
            onChange={onChange}
            type={inputType}
            defaultValue={defaultValue}
            disabled={disabled}
            onKeyPress={onKeyPress}
            style={{ letterSpacing: 1, fontFamily: key.fontFamily, backgroundColor: backgroundColor  }}
        />
    )
}
export default TextInput;