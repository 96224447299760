import { Input } from "reactstrap";
import Text from "../../atom/Text";
import Header from "../../components/Header/Header";
import { key } from "../../utils/consts";
import { priceFormat } from "../../utils/priceFunctions";
import moment from "moment";
import Footer from "../../components/Footer";
import Button from "../../atom/Button";
import { useHistory } from "react-router-dom";
import { CONTACT, ORDER } from "../../utils/pathDetails";
import { useLocation } from "react-router-dom";

const BankDetails = () => {
    const location = useLocation();
    const Details = ({ header, text }) => {
        return (
            <div className={`flex items-center justify-center gap-2 py-2 ${header === "UPI ID" ? "" : "border-b"}`}>
                <Text content={header} className={"font-bold w-1/2"} style={{ fontFamily: key.fontFamily, color: key.blue }} />
                <Text content={text} className={"text-sm w-1/2"} style={{ fontFamily: key.fontFamily, color: key.blue }} />
            </div>

        )
    }
    const history = useHistory();
    return (
        <>
            <Header />
            <div className="flex gap-2 m-4 max-[1036px]:flex-col">
                <div className="w-2/3 max-[1036px]:w-full">
                    <div className="pb-2 rounded" style={{ backgroundColor: key.secondaryColor }}>
                        <Text content={"Thank you for your order!"} className={"text-xl pt-3 pb-2 px-3 rounded-t"} style={{ fontFamily: key.fontFamily, color: key.secondaryColor, backgroundColor: key.blue, fontWeight: "bold" }} />
                        <div className="flex gap-3 px-3 border max-[700px]:flex-col">
                            <div className="flex flex-col items-center justify-center gap-2 px-2 py-3 border-r w-1/3 rounded my-3 border drop-shadow-lg max-[700px]:w-full" style={{ backgroundColor: key.secondaryColor }}>
                                <Text content={"Order No."} className={"font-bold"} style={{ fontFamily: key.fontFamily, color: key.blue }} />
                                <Text content={"65d85689f022e41021d16e05"} className={"text-sm"} style={{ fontFamily: key.fontFamily, color: key.blue }} />
                            </div>
                            <div className="flex flex-col items-center justify-center gap-2 px-2 py-3 border-r w-1/3 rounded my-3 border drop-shadow-lg max-[700px]:w-full" style={{ backgroundColor: key.secondaryColor}}>
                                <Text content={"Order Date"} className={"font-bold"} style={{ fontFamily: key.fontFamily, color: key.blue }} />
                                <Text content={moment(location?.state?.orderDate).format("DD/MM/YYYY")} className={"text-sm"} style={{ fontFamily: key.fontFamily, color: key.blue }} />
                            </div>
                            <div className="flex flex-col items-center justify-center gap-2 px-2 py-3 border-r w-1/3 rounded my-3 border drop-shadow-lg max-[700px]:w-full" style={{ backgroundColor: key.secondaryColor }}>
                                <Text content={"Payable Amount"} className={"font-bold"} style={{ fontFamily: key.fontFamily, color: key.blue }} />
                                <Text content={priceFormat(location?.state?.advancePayment)} className={"text-sm"} style={{ fontFamily: key.fontFamily, color: key.blue }} />
                            </div>
                        </div>
                    </div>
                    <div className="pb-2 rounded my-3 border-x border-b" style={{ backgroundColor: key.secondaryColor }}>
                        <Text content={"Bank Details"} className={"text-xl pt-3 pb-2 px-3 rounded-t"} style={{ fontFamily: key.fontFamily, color: key.secondaryColor, fontWeight: "bold", backgroundColor: key.blue }} />
                        <div className="p-2">
                            <Details header={"Bank Name"} text={"ICICI Bank Ltd."} />
                            <Details header={"Account Name"} text={"Reciclar Technologies Pvt Ltd."} />
                            <Details header={"Account Number"} text={"2312 0500 0193"} />
                            <Details header={"IFSC Code"} text={"ICIC0002312"} />
                            <Details header={"Branch Name"} text={"44A to 47 Pushkar Industrial Estate, Opp. Rifle Club, Phase-1, GIDC Vatva, Ahmedabad - 382445"} />
                            <Details header={"UPI ID"} text={"accelplix@icici"} />
                        </div>
                    </div>
                </div>
                <div className="w-1/3 mb-3 rounded max-[1036px]:w-full">
                    <Text content={"Important Links"} className={"text-lg pl-2 py-2.5 rounded-t"} style={{ backgroundColor: key.blue, fontFamily: key.fontFamily, color: key.secondaryColor, fontWeight: "bold" }} />
                    <div className="flex flex-col justify-center py-3 border-x border-b gap-2 px-2">
                        <Text
                            content={"We also offer transporters to assist your logistics in different states. Please note that this service can be beneficial to you once your order is confirmed."}
                            className={"text-sm"}
                            style={{ fontFamily: key.fontFamily, opacity: "0.6" }}
                        />
                        <Text
                            content={"Path - Resources > Transporters"}
                            className={"text-sm border-b pb-2.5 font-bold"}
                            style={{ fontFamily: key.fontFamily, opacity: "0.6" }}
                        />
                        <Text
                            content={"Kindly furnish the particulars of your transporter on this page, enabling us to allocate your order to them once it is prepared for pickup."}
                            className={"text-sm"}
                            style={{ fontFamily: key.fontFamily, opacity: "0.6" }}
                        />
                        <Text
                            content={"Path - Profile > My Orders > Order"}
                            className={"text-sm font-bold"}
                            style={{ fontFamily: key.fontFamily, opacity: "0.6" }}
                        />
                        <Button buttonText={"Add Transporters"} className={"py-2 px-3 rounded my-2 w-fit"} onClick={() => {
                            history.push(ORDER + location?.state?.orderNumber)
                        }} />
                        <Text
                            content={"If you have any query regarding your order, Please find our contact details here. "}
                            className={"text-sm pt-2 border-t"}
                            style={{ fontFamily: key.fontFamily, opacity: "0.6" }}
                        />
                        <Button buttonText={"Contact Us"} className={"py-2 px-3 rounded my-2 w-fit"} onClick={() => {
                            history.push(CONTACT);
                        }} />
                    </div>
                </div>
            </div >
            <Footer />
        </>
    )
}
export default BankDetails;