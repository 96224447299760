import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { key } from "../../utils/consts";
import Text from "../../atom/Text";
import { FaTimes } from "react-icons/fa";
import ReactPlayer from "react-player";
import { ORDER_VIDEO_URL } from "../../Services/config";
import Button from "../../atom/Button";

const PlayVideoModal = ({ openModal, setOpenModal, videoId }) => {
    console.log(`${ORDER_VIDEO_URL}${videoId}.mp4`)
    return (
        <Modal isOpen={openModal} toggle={() => { setOpenModal(false) }} size="lg">
            <ModalHeader style={{ fontFamily: key.fontFamily, width: "100%", color: key.blue, }}>
                Play Video
            </ModalHeader>
            <ModalBody>
                <ReactPlayer
                    style={{ backgroundColor: "#000" }}
                    width={"100%"}
                    volume={1}
                    playing
                    url={`${ORDER_VIDEO_URL}${videoId}.mp4`}
                    controls
                />
            </ModalBody>
            <ModalFooter>
                <Button buttonText={"Close"} className={"px-3 py-2 rounded"} style={{backgroundColor: key.blue, color: key.secondaryColor, fontFamily: key.fontFamily }} onClick={()=>{
                    setOpenModal(false)
                }}/>
            </ModalFooter>
        </Modal>
    )
}
export default PlayVideoModal;