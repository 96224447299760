import { RxOpenInNewWindow } from "react-icons/rx"
import Text from "../../atom/Text"
import { IMAGE_URL } from "../../Services/config"
import { PRODUCT } from "../../utils/pathDetails"
import { Button } from "reactstrap"
import { useContext, useState } from "react"
import { key } from "../../utils/consts"
import { CartContext } from "../ShoppingCart/CartContext"

const Accessories = ({ accessoriesData, plantData }) => {
    const { updateCart, cartData } = useContext(CartContext);
    const [selectedVariantId, setSelectedVariantId] = useState("");
    const [showQuantity, setShowQuantity] = useState(true);
    const [selectedVariant, setSelectedVariant] = useState([]);
    return (
        <>
            <div className="flex border">
                <div className={"w-2/6 px-2 gap-2 rounded-tl flex items-center justify-center border-r"} style={{ fontFamily: key.fontFamily }}>
                    <Text content={accessoriesData?.name} className={'text-base'} style={{ fontFamily: key.fontFamily, color: key.blue }} />
                    <RxOpenInNewWindow color={key.blue} size={16} onClick={() => { window.open(PRODUCT + "accessories/" + accessoriesData?._id) }} />
                </div>
                <div className={"w-2/6 px-2 flex items-center justify-center border-r"} style={{ fontFamily: key.fontFamily }}>
                    <img src={IMAGE_URL + `/${accessoriesData?.images[0]}`} className="w-2/3" />
                </div>
                <div className={"w-1/6 px-2 flex flex-col items-center justify-center border-r"} style={{ fontFamily: key.fontFamily }}>
                    {accessoriesData?.variants ? accessoriesData?.variants.map((variant, variantIndex) => {
                        return (
                            <Button
                                key={variantIndex}
                                className={"text-sm my-2 border-1 rounded"}
                                style={{ fontFamily: key.fontFamily, color: selectedVariantId == variant?._id ? key.secondaryColor : key.blue, borderColor: selectedVariantId == variant?._id ? "green" : key.blue, backgroundColor: selectedVariantId == variant?._id ? "green" : 'transparent' }}
                                onClick={() => {
                                    setSelectedVariantId(variant?._id)
                                    setSelectedVariant(variant)
                                }}>{variant?.modelNumber}</Button>
                        )
                    }) : <></>}
                </div>
                <div className={"w-1/6 px-2 flex rounded-tr items-center justify-center"} style={{ fontFamily: key.fontFamily }}>
                    {showQuantity && plantData?.bom?.filter((item) => item?.variantId == selectedVariantId)?.length < 1 ? <Button color="success" outline onClick={async () => {
                        setShowQuantity(false);
                        await plantData['bom'].push({
                            variantId: selectedVariantId, quantity: 1, componentName: accessoriesData?.name, make: accessoriesData?.brand, specification: accessoriesData?.variants?.filter((value) => value?._id == selectedVariantId)[0]?.technicalDetails, unit: accessoriesData?.modelNumber,
                            name: accessoriesData?.name,
                            productsId: accessoriesData?._id,
                            b2bPrice: selectedVariant?.b2bPrice,
                            b2cPrice: selectedVariant?.b2cPrice,
                            capacity: selectedVariant?.capacity,
                            unit: selectedVariant?.unit,
                            modelNumber: accessoriesData?.modelNumber,
                            brand: accessoriesData?.brand,
                            type: "accessories",
                            images: accessoriesData?.images,
                            gst: selectedVariant?.gstPercentage,
                            productId: accessoriesData?._id,
                            variantId: selectedVariant?._id
                        })
                        console.log("PLANT QUANTITY ===>", cartData?.products?.plants?.filter((item) => item?.productId?._id == plantData?._id)[0]?.quantity)
                        if (cartData?.products?.plants?.filter((item) => item?.productId?._id == plantData?._id)[0]?.quantity && cartData?.products?.plants?.filter((item) => item?.productId?._id == plantData?._id)[0]?.quantity > 0) {
                            await updateCart("accessories", 1, accessoriesData?._id, selectedVariant?._id);
                        }
                        setShowQuantity(true);
                    }}>Add</Button> :
                        <div className="w-3/4 flex" style={{ backgroundColor: key.blue }}>
                            <Button style={{ color: key.secondaryColor, fontFamily: key.fontFamily, border: "none", backgroundColor: key.blue }} className="w-1/4" onClick={async () => {
                                const filteredData = await plantData?.bom?.filter((item) => item?.variantId == selectedVariantId)[0];
                                const unFilteredData = await plantData?.bom?.filter((item) => item?.variantId != selectedVariantId);
                                if (filteredData?.quantity - 1 == 0) {
                                    setShowQuantity(false);
                                    plantData['bom'] = await unFilteredData;
                                    if (cartData?.products?.plants?.filter((item) => item?.productId?._id == plantData?._id)[0]?.quantity && cartData?.products?.plants?.filter((item) => item?.productId?._id == plantData?._id)[0]?.quantity > 0) {
                                        await updateCart("accessories", 0, accessoriesData?._id, selectedVariant?._id);
                                    }
                                    setShowQuantity(true);
                                } else {
                                    if (cartData?.products?.plants?.filter((item) => item?.productId?._id == plantData?._id)[0]?.quantity && cartData?.products?.plants?.filter((item) => item?.productId?._id == plantData?._id)[0]?.quantity > 0) {
                                        await updateCart("accessories", filteredData['quantity'] - 1, accessoriesData?._id, selectedVariant?._id);
                                    }
                                    filteredData['quantity'] = await filteredData['quantity'] - 1;
                                    await unFilteredData?.push(filteredData);
                                    setShowQuantity(false);
                                    plantData['bom'] = await unFilteredData;
                                    setShowQuantity(true);
                                }
                            }}>-</Button>
                            <div style={{ backgroundColor: key.secondaryColor }} className="flex items-center justify-center w-1/2 border-y">
                                <Text content={plantData?.bom?.filter((item) => item?.variantId == selectedVariantId)[0]?.quantity} style={{ fontFamily: key.fontFamily, color: key.blue, fontWeight: "bold" }} className={""} />
                            </div>
                            <Button style={{ color: key.secondaryColor, fontFamily: key.fontFamily, border: "none", backgroundColor: key.blue }} className="w-1/4" onClick={async () => {
                                const filteredData = await plantData?.bom?.filter((item) => item?.variantId == selectedVariantId)[0];
                                const unFilteredData = await plantData?.bom?.filter((item) => item?.variantId != selectedVariantId);
                                if (cartData?.products?.plants?.filter((item) => item?.productId?._id == plantData?._id)[0]?.quantity && cartData?.products?.plants?.filter((item) => item?.productId?._id == plantData?._id)[0]?.quantity > 0) {
                                    await updateCart("accessories", filteredData['quantity'] + 1, accessoriesData?._id, selectedVariant?._id);
                                }
                                filteredData['quantity'] = await filteredData['quantity'] + 1;
                                await unFilteredData?.push(filteredData);
                                setShowQuantity(false);
                                plantData['bom'] = await unFilteredData;
                                setShowQuantity(true);
                            }}>+</Button>
                        </div>
                    }
                </div>
            </div>
        </>
    )
}
export default Accessories;