import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import OTPInput from "react-otp-input";
import { key } from "../../utils/consts";
import Text from "../../atom/Text";
import { useState } from "react";
import { VerifyOtpProfile, callPostServiceData } from "../../Services/DataServices";
import { CHECKOTP } from "../../utils/urlDetails";
import { ToastContainer, toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import { EDIT_PROFILE } from "../../utils/pathDetails";

const OtpVerificationModal = ({ openModal, setOpenModal, mobile, forMobileVerification, setMobileEditable }) => {
    const [otp, setOtp] = useState();
    const history = useHistory();
    const onClickButton = async () => {
        const data = { otp, mobile: mobile }
        // const { result } = await VerifyOtpProfile(data);
        const { result } = await callPostServiceData(CHECKOTP, data)
        console.log("123456result.type", result)
        if (result.error == false) {
            setOpenModal(false);
            if (forMobileVerification == true) {
                console.log("forMobileVerification", forMobileVerification)
                setMobileEditable(false);
            } else {
                history.push(EDIT_PROFILE, { otpValid: true })
            }
        } else {
            toast.error("Invalid OTP!")
        }
    }
    return (
        <>
            <ToastContainer />
            <Modal isOpen={openModal} toggle={() => { setOpenModal(!false) }} centered>
                <ModalHeader style={{ fontFamily: key.fontFamily, fontWeight: "bold" }}>
                    Verify OTP
                </ModalHeader>
                <ModalBody>
                    <Text className={"text-base"} content={"Please enter the One Time Password (OTP) sent to your mobile number."} style={{ fontFamily: key.fontFamily, }} />
                    <div className="my-7 flex items-center justify-center">
                        <OTPInput
                            value={otp}
                            placeholder={"000000"}
                            numInputs={6}
                            onChange={(value) => setOtp(value)}
                            inputStyle={{
                                borderRadius: 9,
                                borderColor: "lightgray",
                                backgroundColor: "#F0F5FA",
                                color: "gray",
                                fontWeight: 'bold',
                                fontFamily: key.fontFamily,
                                height: 54,
                                width: 54,
                                marginRight: 15,
                            }}
                            renderInput={(props) => <input {...props} />}
                        />
                    </div>
                </ModalBody>
                <ModalFooter>
                    <Button outline onClick={() => { setOpenModal(false) }} style={{ fontFamily: key.fontFamily, paddingInline: 22 }}>
                        Close
                    </Button>
                    <Button style={{ fontFamily: key.fontFamily, background: key.primaryColor, color: "#fff", border: "none", paddingInline: 22 }} onClick={() => {
                        onClickButton()
                    }}>
                        Submit
                    </Button>
                </ModalFooter>
            </Modal>
        </>
    )
}
export default OtpVerificationModal;