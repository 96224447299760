import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap"
import OTPInput from "react-otp-input";
import Text from "../../atom/Text"
import { useState } from "react";
import { key } from "../../utils/consts";
import { ToastContainer, toast } from "react-toastify";
import { editMobileNumberService } from "../../Services/ProfileServices";

const NewMobileVerifyOtpModal = ({ openModal, setOpenModal, newMobileNumber, refreshData }) => {
    const [otp, setOtp] = useState();
    const onSubmit = async () => {
        if (otp) {
            const apiBody = {
                otp: otp,
                newMobileNumber: newMobileNumber
            }
            const response = await editMobileNumberService(apiBody);
            console.log("response?.result?.data?.type", response?.result)
            if (response?.result?.data?.type == "error") {
                toast.error(response?.result?.data?.response)
            } else {
                toast.success("Mobile Number Updated!")
                const user = localStorage.getItem("user");
                console.log(user)
                let user1=JSON.parse(user)
                user1.mobile=newMobileNumber
                localStorage.setItem("user", JSON.stringify(user1));
                setTimeout(() => {
                    setOpenModal(false);
                    refreshData()
                }, 2000)
            }
        } else {
            toast.error("Please Enter the OTP!")
        }
    }
    return (
        <>
            <ToastContainer />
            <Modal isOpen={openModal} toggle={() => { setOpenModal(!false) }} centered>
                <ModalHeader style={{ fontFamily: key.fontFamily, fontWeight: "bold" }}>
                    Verify OTP
                </ModalHeader>
                <ModalBody>
                    <Text className={"text-base"} content={"Please enter the One Time Password (OTP) sent to your mobile number."} style={{ fontFamily: key.fontFamily, }} />
                    <div className="my-7 flex items-center justify-center">
                        <OTPInput
                            value={otp}
                            placeholder={"000000"}
                            numInputs={6}
                            onChange={(value) => setOtp(value)}
                            inputStyle={{
                                borderRadius: 9,
                                borderColor: "lightgray",
                                backgroundColor: "#F0F5FA",
                                color: "gray",
                                fontWeight: 'bold',
                                fontFamily: key.fontFamily,
                                height: 54,
                                width: 54,
                                marginRight: 15,
                            }}
                            renderInput={(props) => <input {...props} />}
                        />
                    </div>
                </ModalBody>
                <ModalFooter>
                    <Button outline onClick={() => {
                        setOpenModal(false)
                        setOtp()
                    }} style={{ fontFamily: key.fontFamily, paddingInline: 22 }}>
                        Close
                    </Button>
                    <Button style={{ fontFamily: key.fontFamily, background: key.primaryColor, color: "#fff", border: "none", paddingInline: 22 }} onClick={() => {
                        onSubmit()
                        setOtp()
                    }}>
                        Submit
                    </Button>
                </ModalFooter>
            </Modal>
        </>
    )
}
export default NewMobileVerifyOtpModal;