import { CardBody, Collapse, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Input, InputGroup, InputGroupText, Modal, ModalBody, ModalHeader } from "reactstrap";
import Image from "../../atom/Image";
import Text from "../../atom/Text";
import { key } from "../../utils/consts";
import { FaBars, FaTimes } from "react-icons/fa";
import { useContext, useState } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import { useHistory } from "react-router-dom";
import Logo from '../../utils/images/logo-light.png'
import { FiSearch } from "react-icons/fi";
import { ABOUT, CATALOGUE, CONTACT, FAQs, GENERATE_NEW_QUOTATION, HOME, LOGIN, MANUALS, ORDER, ORDER_HISTORY, PROFILE, SEARCH, SERVICES, SHOPPING_CART, TRANSPORTERS, USE_QUOTATION_NUMBER, VIDEOS } from "../../utils/pathDetails";
import Order from "./Order/Order";
import Resource from "./Resource";
import Profile from "./Profile";
import ShoppingCart from "./ShoppingCart";
import { CiSearch } from "react-icons/ci";
import { CartContext } from "../../pages/ShoppingCart/CartContext";


const Header = ({ theme }) => {
    const { cartCount } = useContext(CartContext)
    const history = useHistory();
    const [responsiveHeader, setResponsiveHeader] = useState(false);
    const [orderSelected, setOrderSelected] = useState(false);
    const [resourceSelected, setResourceSelected] = useState(false);
    const [profileSelected, setProfileSelected] = useState(false);
    const [responsiveProfileSelected, setResponsiveProfileSelected] = useState(false);
    const [responsiveOrderSelected, setResponsiveOrderSelected] = useState(false);
    const [responsiveSearch, setResponsiveSearch] = useState(false);
    const [responsiveResourceSelected, setResponsiveResourceSelected] = useState(false);
    const [searchText, setSearchText] = useState("");
    return (
        <>
            <header className="flex pl-4 py-3 mb-2" style={{ background: "#fff", position: "sticky", top: 0, zIndex: 100, borderColor: "#000", filter: "drop-shadow(-5px 4px 4px rgba(0, 0, 0, 0.25))" }}>
                <button className="w-2/12" onClick={() => { history.push(HOME) }}><Image className={"w-2/3"} src={Logo} /></button>
                <div className="flex w-10/12 justify-start pl-3 items-center max-[1100px]:hidden">
                    <Text HtmlTag="button" content={"Home"} className={`text-base py-2 hover:border hover:rounded`} style={{ fontFamily: key.fontFamily, fontWeight: "700", color: "#000", width: "13%", borderColor: key.blue }} onClick={() => { history.push(HOME) }} />
                    <Order orderSelected={orderSelected} setOrderSelected={setOrderSelected} theme={"light"} />
                    <Resource resourceSelected={resourceSelected} setResourceSelected={setResourceSelected} theme={"light"} />
                    <Text HtmlTag="button" content={"About Us"} className="text-base py-2 hover:border hover:rounded" style={{ fontFamily: key.fontFamily, fontWeight: "700", color: "#000", width: "13%", borderColor: key.blue }} onClick={() => { history.push(ABOUT) }} />
                    <Text HtmlTag="button" content={"FAQ"} className="text-base py-2 hover:border hover:rounded" style={{ fontFamily: key.fontFamily, fontWeight: "700", color: "#000", width: "13%", borderColor: key.blue }} onClick={() => { history.push(FAQs) }} />
                    <Text HtmlTag="button" content={"Contact Us"} className="text-base py-2 hover:border hover:rounded" style={{ fontFamily: key.fontFamily, fontWeight: "700", color: "#000", width: "13%", borderColor: key.blue }} onClick={() => { history.push(CONTACT) }} />
                </div>
                <div className="flex w-2/5 justify-end items-center max-[1100px]:hidden">
                    <InputGroup style={{ borderColor: key.primaryColorOne, right: 20 }} className="border rounded">
                        <Input placeholder="Search" style={{ fontFamily: key.fontFamily }} onChange={(e) => {
                            setSearchText(e);
                        }}
                            onKeyPress={(target) => {
                                if (target.charCode == 13) {
                                    window.location.replace(SEARCH + searchText.target.value)
                                }
                            }} />
                        <InputGroupText style={{ backgroundColor: key.blue }} onClick={() => {
                            window.location.replace(SEARCH + searchText.target.value)
                        }}>
                            <CiSearch size={20} color={key.secondaryColor} />
                        </InputGroupText>
                    </InputGroup>
                    <ShoppingCart theme={"light"} />
                    <Profile profileSelected={profileSelected} setProfileSelected={setProfileSelected} theme={"light"} />
                </div>
                <div className="flex w-10/12 justify-end items-center min-[1100px]:hidden">
                    <Dropdown isOpen={responsiveSearch} toggle={() => { setResponsiveSearch(!responsiveSearch) }} direction={"down"}>
                        <DropdownToggle style={{ backgroundColor: "transparent", border: "none" }} toggle={() => { setResponsiveSearch(true) }}>
                            <FiSearch color="#000" className="mr-4" style={{ width: "1.5em", height: "1.5em", color: "#000" }} />
                        </DropdownToggle>
                        <DropdownMenu toggle={() => { setResponsiveSearch(true) }} style={{ width: "19.4rem" }}>
                            <DropdownItem text style={{ display: "flex" }}>
                                <Input className="w-60 border-1" style={{ borderColor: key.blue, fontFamily: key.fontFamily }} placeholder="Search" onChange={(e) => {
                                    setSearchText(e);
                                }}
                                    onKeyPress={(target) => {
                                        if (target.charCode == 13) {
                                            window.location.replace(SEARCH + searchText.target.value)
                                        }
                                    }} />
                                <button
                                    className="w-fit flex items-center px-3 rounded" style={{ background: key.primaryColor }}
                                    onClick={() => {
                                        window.location.replace(SEARCH + searchText.target.value)
                                    }}>
                                    <CiSearch size={20} color="#fff" />
                                </button>
                            </DropdownItem>
                        </DropdownMenu>
                    </Dropdown>
                    <FaBars className="mr-7" style={{ width: "1.5em", height: "1.5em", color: "#000" }} onClick={() => { setResponsiveHeader(true) }} />
                </div>
            </header>

            <Modal
                isOpen={responsiveHeader}
                toggle={() => setResponsiveHeader(!responsiveHeader)}
                style={customStyles.content}>
                <ModalHeader style={{
                    border: "none",
                    background: key.primaryColor,
                    color: "#fff",
                    opacity: 0.9,
                    display: "flex",
                    justifyContent: "flex-end"
                }}>
                    <FaTimes style={{ color: "#fff", alignSelf: "flex-end" }} onClick={() => { setResponsiveHeader(false) }} />
                </ModalHeader>
                <ModalBody style={customStyles.content}>
                    <Text content={"Home"} className="text-base	mt-8 text-center" style={{ fontFamily: key.fontFamily, fontWeight: "700" }} onClick={() => { history.push(HOME) }} />
                    <Text content={"Order"} className="text-base mt-8 text-center" style={{ fontFamily: key.fontFamily, fontWeight: "700" }} onClick={() => {
                        setResponsiveOrderSelected(!responsiveOrderSelected)
                    }} />
                    <Collapse isOpen={responsiveOrderSelected} style={{ visibility: "visible" }}>
                        <CardBody style={{
                            background: key.primaryColor,
                            color: "#fff",
                            border: "none",
                            fontFamily: key.fontFamily,
                            textAlign: "center",
                            fontWeight: "bold",
                        }}
                            className="text-xs mt-3"
                            onClick={() => { history.push(GENERATE_NEW_QUOTATION) }}>
                            Generate New Quotation
                        </CardBody>
                        <CardBody style={{
                            background: key.primaryColor,
                            color: "#fff",
                            border: "none",
                            fontFamily: key.fontFamily,
                            textAlign: "center",
                            fontWeight: "bold",
                        }}
                            className="text-xs mt-3"
                            onClick={() => { history.push(USE_QUOTATION_NUMBER) }}>
                            Order With Quotation No.
                        </CardBody>
                    </Collapse>
                    <Text content={"Resources"} className="text-base mt-8 text-center" style={{ fontFamily: key.fontFamily, fontWeight: "700" }} onClick={() => { setResponsiveResourceSelected(!responsiveResourceSelected) }} />
                    <Collapse isOpen={responsiveResourceSelected} style={{ visibility: "visible" }}>
                        <CardBody style={{
                            background: key.primaryColor,
                            color: "#fff",
                            border: "none",
                            fontFamily: key.fontFamily,
                            textAlign: "center",
                            fontWeight: "bold",
                        }}
                            className="text-xs mt-3"
                            onClick={() => { history.push(VIDEOS) }}>
                            Videos
                        </CardBody>
                        <CardBody style={{
                            background: key.primaryColor,
                            color: "#fff",
                            border: "none",
                            fontFamily: key.fontFamily,
                            textAlign: "center",
                            fontWeight: "bold",
                        }}
                            className="text-xs mt-3"
                            onClick={() => { history.push(MANUALS) }}>
                            Manuals
                        </CardBody>
                        <CardBody style={{
                            background: key.primaryColor,
                            color: "#fff",
                            border: "none",
                            fontFamily: key.fontFamily,
                            textAlign: "center",
                            fontWeight: "bold",
                        }}
                            className="text-xs mt-3"
                            onClick={() => { history.push(CATALOGUE) }}>
                            Spare Parts Catalogue
                        </CardBody>
                        <CardBody style={{
                            background: key.primaryColor,
                            color: "#fff",
                            border: "none",
                            fontFamily: key.fontFamily,
                            textAlign: "center",
                            fontWeight: "bold",
                        }}
                            className="text-xs mt-3"
                            onClick={() => { history.push(TRANSPORTERS) }}>
                            Transporters
                        </CardBody>
                    </Collapse>
                    <Text content={"About Us"} className="text-base	mt-8 text-center" style={{ fontFamily: key.fontFamily, fontWeight: "700" }} onClick={() => { history.push(ABOUT) }} />
                    <Text content={"FAQ"} className="text-base mt-8 text-center" style={{ fontFamily: key.fontFamily, fontWeight: "700" }} onClick={() => { history.push(FAQs) }} />
                    <Text content={"Contact Us"} className="text-base mt-8 text-center" style={{ fontFamily: key.fontFamily, fontWeight: "700" }} onClick={() => { history.push(CONTACT) }} />
                    <button className="flex mt-8 items-center justify-center ml-6 w-full" onClick={() => {
                        history.push(SHOPPING_CART)
                    }}><Text content={"Shopping Cart"} className="text-base text-center" style={{ fontFamily: key.fontFamily, fontWeight: "700" }} /><sup className={"py-2 px-1 mr-3 rounded"} style={{ backgroundColor: "red", fontFamily: key.fontFamily, color: "#fff", fontWeight: "bold" }}>{cartCount}</sup></button>
                    <Text content={"Profile"} className="text-base mt-8 text-center" style={{ fontFamily: key.fontFamily, fontWeight: "700" }} onClick={() => { setResponsiveProfileSelected(!responsiveProfileSelected) }} />
                    <Collapse isOpen={responsiveProfileSelected} style={{ visibility: "visible" }}>
                        <CardBody style={{
                            background: key.primaryColor,
                            color: "#fff",
                            border: "none",
                            fontFamily: key.fontFamily,
                            textAlign: "center",
                            fontWeight: "bold",
                        }}
                            className="text-xs mt-3"
                            onClick={() => { history.push(PROFILE) }}>
                            Profile
                        </CardBody>
                        <CardBody style={{
                            background: key.primaryColor,
                            color: "#fff",
                            border: "none",
                            fontFamily: key.fontFamily,
                            textAlign: "center",
                            fontWeight: "bold",
                        }}
                            className="text-xs mt-3"
                            onClick={() => { history.push(ORDER_HISTORY) }}>
                            My Orders
                        </CardBody>
                        <CardBody style={{
                            background: key.primaryColor,
                            color: "#fff",
                            border: "none",
                            fontFamily: key.fontFamily,
                            textAlign: "center",
                            fontWeight: "bold",
                        }}
                            className="text-xs mt-3">
                            Quotations
                        </CardBody>
                    </Collapse>
                    <Text content={"Logout"} className="text-base mt-8 text-center" style={{ fontFamily: key.fontFamily, fontWeight: "700" }} onClick={async () => {
                        await window.localStorage.clear();
                        history.push(LOGIN)
                    }} />
                </ModalBody>
            </Modal>
        </>
    )
}
const customStyles = {
    content: {
        background: key.primaryColor,
        color: "#fff",
        opacity: 0.9
    },
};
export default Header;