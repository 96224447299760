import { useEffect } from "react";
import Text from "../../atom/Text";
import { key } from "../../utils/consts";
import { useHistory, useLocation } from "react-router-dom";
import { HOME } from "../../utils/pathDetails";
import successImage from './success.png'

const OrderSuccess = () => {
    const history = useHistory()
    const location = useLocation();
    useEffect(() => {
        // setTimeout(()=>{
        //     history.push(HOME)
        // },6000)
    }, [])
    return (
        <>
            <div className="h-screen w-full flex items-center justify-center" style={{ backgroundColor: key.blue, opacity: "0.9" }}>
                <div className="w-2/3 rounded flex items-center justify-start flex-col" style={{ boxShadow: key.formShadow, backgroundColor: key.secondaryColor }}>
                    <div className="flex items-center justify-center max-[802px]:flex-col">
                        <div className="w-1/3 flex items-center justify-center">
                            <img src={successImage} className="w-4/5" />
                        </div>
                        <div className="w-2/3 flex items-start justify-center flex-col pr-3 max-[802px]:w-full max-[802px]:px-3">
                            <Text content={"Thank you for your purchase!"} style={{ fontFamily: key.fontFamily, color: key.blue, fontWeight: "bold" }} className={"text-4xl mt-3 max-[802px]:w-full max-[802px]:text-3xl"} />
                            <Text content={`Order No : ${location?.state?.orderNumber}`} style={{ fontFamily: key.fontFamily, color: key.blue, fontWeight: "bold" }} className={"text-lg mt-4 max-[802px]:w-full"} />
                            <Text content={"You'll recieve the confirmation mail for your order shortly."} style={{ fontFamily: key.fontFamily, color: key.blue }} className={"mt-3 max-[802px]:w-full"}/>
                            <Text content={"Redirecting to the website...."} style={{ fontFamily: key.fontFamily, color: key.blue }} className={"my-2 max-[802px]:w-full"}/>
                        </div>
                    </div>
                    <div className="border-t w-full p-3">
                        <Text content={"We'll keep you updated regarding the status of your order via mails. You'll see the order in the profile section of the website. "} style={{ fontFamily: key.fontFamily, color: key.blue }} className={"mt-2 text-sm"}/>
                    </div>
                </div>
            </div>
        </>
    )
}
export default OrderSuccess;