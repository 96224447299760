import { Button, ModalBody, ModalFooter, ModalHeader, Modal } from "reactstrap"
import { key } from "../../utils/consts"
import Text from "../../atom/Text"
import { MdDiscount } from "react-icons/md"
import { priceFormat } from "../../utils/priceFunctions"
import moment from "moment"

const CouponKnowMoreModal = ({ openModal, setOpenModal, couponData }) => {
    const couponText = () => {
        if (couponData?.type === "both") {
            return `* Use code ${couponData?.code?.toUpperCase()} and get ${couponData?.discountPercentage}% Offer on orders above ${priceFormat(couponData?.lowerLimit)}. Maximum discount ${priceFormat(couponData?.discountAmount)}.`
        } else if (couponData?.type === "flat") {
            return `* Use code ${couponData?.code?.toUpperCase()} and get flat discount of ${priceFormat(couponData?.discountAmount)} on orders above ${priceFormat(couponData?.lowerLimit)}.`
        } else if (couponData?.type === "percentage") {
            return `* Use code ${couponData?.code?.toUpperCase()} and get ${couponData?.discountPercentage}% discount on orders above ${priceFormat(couponData?.lowerLimit)}.`
        }
    }
    return (
        <Modal isOpen={openModal}>
            <ModalHeader>
                <div className='flex items-center gap-1'>
                    <Text content={"Offers & Coupons"} className={"text-sm"} style={{ fontFamily: key.fontFamily, color: key.blue, fontWeight: "bold" }} />
                    <MdDiscount size={15} style={{ color: key.blue }} />
                </div>
            </ModalHeader>
            <ModalBody>
                <Text content={`USE CODE - ${couponData?.code}`} style={{ fontFamily: key.fontFamily, fontWeight: "bold", color: key.blue }} className={"text-base"} />
                <Text content={couponText()} className={"text-xs w-full mt-6 pr-2"} style={{ fontFamily: key.fontFamily, opacity: "0.7" }} />
                <Text content={`* Offer is available only on ${couponData?.productCategories?.join(", ")}.`} className={"my-2 text-xs"} style={{ fontFamily: key.fontFamily, opacity: "0.7" }} />
                <Text content={`* Minimum order amount should be ${priceFormat(couponData?.lowerLimit)} of a particular product type.`} className={"my-2 text-xs"} style={{ fontFamily: key.fontFamily, opacity: "0.7" }} />
                <Text content={`* Maximum order amount should be ${priceFormat(couponData?.upperLimit)} of a particular product type.`} className={"my-2 text-xs"} style={{ fontFamily: key.fontFamily, opacity: "0.7" }} />
                <Text content={`* This coupon will be expiring on ${moment(couponData?.validTillDate).format("DD/MM/YYYY")}.`} className={"my-2 text-xs"} style={{ fontFamily: key.fontFamily, opacity: "0.7" }} />
                <Text content={`* You can use this coupon ${couponData?.maximumUsage} times.`} className={"my-2 text-xs"} style={{ fontFamily: key.fontFamily, opacity: "0.7" }} />
                {couponData?.purchaseTargetAdded ? <>
                    <Text content={`* This coupon will be applicable if your ${couponData?.purchaseTarget?.invoiceValueCategory == "singleInvoiceValue" ? "last order" : "orders"} amount in past ${couponData?.purchaseTarget?.inLastDays} days is above ${priceFormat(couponData?.purchaseTarget?.invoiceValue)}`} className={"my-2 text-xs"} style={{ fontFamily: key.fontFamily, opacity: "0.7" }} />
                </> : <></>}
            </ModalBody>
            <ModalFooter>
                <Button style={{ backgroundColor: key.blue, color: key.secondaryColor, fontFamily: key.fontFamily, border: "none" }}
                    onClick={() => { setOpenModal(false) }}>Close</Button>
            </ModalFooter>
        </Modal>
    )
}
export default CouponKnowMoreModal;