import { Button, Modal, ModalBody, ModalFooter } from "reactstrap";
import Text from "../../atom/Text";
import { key } from "../../utils/consts";
import Field from "../../molecules/Field";
import { ToastContainer, toast } from "react-toastify";
import { callPostServiceData } from "../../Services/DataServices";
import { BASE_URL } from "../../Services/config";

const NewMobileEnterModal = ({ 
    openModal, 
    setOpenModal, 
    newMobileNumber, 
    setNewMobileNumber, 
    validateMobileNumber,
    setVerifyOtpModal
}) => {
    const onSubmit = async () => {
        const validNewMobileNumber = validateMobileNumber(newMobileNumber);
        if (validNewMobileNumber) {
            const user = localStorage.getItem("user");
            console.log(user)
            let user1=JSON.parse(user)
            console.log(user1)
            console.log(user1.mobile)   
            const apiBody = { mobile:user1.mobile, newMobileNumber:newMobileNumber };
            const response = await callPostServiceData(`${BASE_URL}auth/forgot-password-new`, apiBody);
            if (response.result.data.type == "success") {
            setOpenModal(false);
            setVerifyOtpModal(true);
            } else {
                toast.error("Invalid Mobile Number!")
            }
        } else {
            toast.error("Invalid Mobile Number!")
        }
    }
    return (
        <>
            <ToastContainer />
            <Modal isOpen={openModal} toggle={() => { setOpenModal(!openModal) }} centered>
                <ModalBody style={{background: key.formBackground}}>
                    <Text
                        content={"Please enter your new mobile number. The One Time Password (OTP) will be sent to this new mobile number."}
                        className={"text-base"}
                        style={{ fontFamily: key.fontFamily }}
                    />
                    <div className="my-4">
                        <Field text={"New Mobile No."} placeholder={"New Mobile No."} onChange={(e) => { setNewMobileNumber(e.target.value) }} />
                    </div>
                </ModalBody>
                <ModalFooter style={{background: key.formBackground}}>
                    <Button outline onClick={() => {
                        setOpenModal(false)
                        setNewMobileNumber("")
                    }} style={{ fontFamily: key.fontFamily, paddingInline: 22 }}>
                        Close
                    </Button>
                    <Button style={{ fontFamily: key.fontFamily, background: key.primaryColor, color: "#fff", border: "none", paddingInline: 22 }}
                        onClick={() => { onSubmit() }}>
                        Submit
                    </Button>
                </ModalFooter>
            </Modal>
        </>
    )
}
export default NewMobileEnterModal;