import OTPInput from "react-otp-input";

import Button from "../atom/Button";
import Text from "../atom/Text";
import { key } from "../utils/consts";

const OtpForm = ({ mobileNumber, otp, setOtp, isMobile,onClick }) => (
  <div
    className="h-full w-full mt-16 pr-12 pl-12 rounded-2xl"
    style={{
      backgroundColor: key.formBackground,
      boxShadow: key.formShadow,
    }}
  >
    <Text
      {...{
        content: `Verify the One Time Password sent to your mobile number ${mobileNumber}. The OTP will be valid for 5 minutes`,
        className: "text-center w-full pt-10 text-lg text-gray-600",
        style: { fontFamily: key.fontFamily },
      }}
    />
    <div className="flex flex-col gap-4 items-center">
      <OTPInput
        value={otp}
        onChange={(value) => setOtp(value)}
        numInputs={6}
        inputStyle={{
          borderRadius: 9,
          borderColor: "lightgray",
          backgroundColor: "#fff",
          color: "black",
          fontWeight:'bold',
          height: isMobile ? 30 : 54,
          width: isMobile ? 30 : 54,
          marginRight: isMobile ? 5 : 15,
        }}
        renderInput={(props) => <input {...props} />}
        containerStyle={{ marginTop: "8%", marginBottom: "4%" }}
      />
      <Button
        {...{
          buttonText: "Submit",
          className:
            "p-2.5 text-center text-white phone:w-36 tablet:w-60 rounded-lg",
          style: { background: key.primaryColor },
          onClick:onClick
        }}
      />
      <div className="flex pt-10 gap-3">
        <Text
          {...{
            content: "Didn't get the OTP ?",
            className: "text-center text-sm text-slate-500",
            style: { fontFamily: key.fontFamily },
          }}
        />
        <Text
          {...{
            content: "RESEND",
            className: "text-center text-sm text-blue-500 font-bold",
          }}
        />
        <div className="h-20" />
      </div>
    </div>
  </div>
);

export default OtpForm;
