import { useState } from "react";
import Button from "../atom/Button";
import Text from "../atom/Text";
import ServiceModal from "../components/Services/ServiceModal";
import { key } from "../utils/consts";
import { priceFormat } from "../utils/priceFunctions";
import DisplayInput from "../components/DisplayInput";
import { useHistory } from "react-router-dom";
import { SERVICE } from "../utils/pathDetails";

const Service = ({ serviceData }) => {
    const [serviceModal, setServiceModal] = useState(false);
    const history = useHistory();
    return (
        <>
            <ServiceModal openModal={serviceModal} setOpenModal={setServiceModal} serviceData={serviceData} />
            <button className="mt-8" onClick={() => { history.push(SERVICE + serviceData?._id) }}>
                <div className="w-full rounded pt-2 flex flex-col items-start justify-center border-x border-t" style={{ backgroundColor: key.secondaryColor, borderColor: "#000" }}>
                    <Text content={serviceData.name} className="text-base ml-3 mt-3" style={{ fontFamily: key.fontFamily, fontWeight: "bold" }} />
                    <div className="ml-3 mr-2 my-2 line-clamp-1 h-3/5">
                        <DisplayInput inputText={serviceData?.details} />
                    </div>
                    <Button
                        buttonText={"Get Quote"}
                        className="w-full mt-2 py-2 rounded-b"
                        style={{ fontFamily: key.fontFamily, background: key.blue, color: "#fff" }} />
                </div>
            </button>
        </>
    )
}
export default Service;