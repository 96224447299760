import { Input } from "reactstrap";
import Button from "../../../../atom/Button";
import Text from "../../../../atom/Text";
import Header from "../../../../components/Header/Header";
import { key } from "../../../../utils/consts";
import { CiSearch } from "react-icons/ci";
import { useEffect, useState } from "react";
import { getPlantDataService } from "../../../../Services/OrderServices";
import Plant from "../../../../molecules/Plant";
import Footer from "../../../../components/Footer";
import { useHistory } from "react-router-dom";
import { ACCESSORIES, WATER_TREATMENT_PLANT } from "../../../../utils/pathDetails";
import { IMAGE_URL } from "../../../../Services/config";
import backgroundImage from './20kld_biozip_cyan_color_2-removebg.png';

const GenerateNewQuotation = () => {
    const history = useHistory()
    const [plantData, setPlantData] = useState([]);
    const [unfilteredPlantData, setUnfilteredPlantData] = useState([]);
    const [hover, setHover] = useState(false)
    const [hoverAccessories, setHoverAccessories] = useState(false)
    const [searchData, setSearchData] = useState("");
    const getPlantData = async () => {
        const { result } = await getPlantDataService();
        setPlantData(result);
        setUnfilteredPlantData(result);
    }
    const filterdata = async (text) => {
        text && text.length > 1 ?
            setPlantData(unfilteredPlantData.filter((item) => String(item.name).toLowerCase().includes(String(text).toLowerCase()))) : setPlantData(unfilteredPlantData)
    }
    useEffect(() => {
        getPlantData();
    }, [])
    return (
        <>
            <Header />
            <Text content={"Generate New Quotation"} className="text-4xl m-4" style={{ fontFamily: key.fontFamily, fontWeight: "bold", background: key.primaryColor, WebkitBackgroundClip: "text", WebkitTextFillColor: "transparent" }} />
            <div className="flex">
                <div className="m-4 border-1 w-1/2 flex items-center justify-center rounded bg-right-bottom	hover:bg-left-bottom" style={{ borderColor: key.blue, background: hover ? key.blue : key.secondaryColor, transition: "all .5s ease-out", }} onMouseEnter={() => { setHover(true) }} onMouseLeave={() => { setHover(false) }}>
                    <Text
                        content={"Water Treatment Plant"}
                        className={"text-3xl py-32"}
                        style={{ fontFamily: key.fontFamily, color: hover ? key.secondaryColor : key.blue , fontWeight: "bold" }}
                    />
                </div>
                <div className="m-4 border-1 w-1/2 flex items-center justify-center rounded" style={{ borderColor: key.blue, background : hoverAccessories ? key.blue : key.secondaryColor, transition: "all .5s ease-out" }} onMouseEnter={() => { setHoverAccessories(true) }} onMouseLeave={() => { setHoverAccessories(false) }}>
                    <Text
                        content={"Accessories"}
                        className={"text-3xl py-32"}
                        style={{ fontFamily: key.fontFamily, color: hoverAccessories ? key.secondaryColor : key.blue , fontWeight: "bold" }}
                    />
                </div>
            </div>
            {/* <div className="flex m-4 max-[563px]:flex-col">
                <Button
                    buttonText={"Water Treatment Plant"}
                    className={"w-60 h-12 rounded"}
                    onClick={() => { history.push(WATER_TREATMENT_PLANT) }}
                    style={{ fontFamily: key.fontFamily, color: "#fff", background: key.primaryColor }}
                />
                <Button
                    buttonText={"Accessories"}
                    onClick={() => { history.push(ACCESSORIES) }}
                    className={"w-60 h-12 rounded ml-4 max-[563px]:ml-0 max-[563px]:mt-4"}
                    style={{ fontFamily: key.fontFamily, color: "#fff", background: key.primaryColor }}
                />
            </div>
            <div className="w-1/2 m-4 flex max-[634px]:w-4/5">
                <Input placeholder="Search a Plant" style={{ borderImage: key.primaryColor + 1, fontFamily: key.fontFamily }}
                    onChange={setSearchData}
                    onKeyPress={(target) => {
                        if (target.charCode == 13) {
                            filterdata(searchData.target.value)
                        }
                    }}
                />
                <button
                    className="w-fit flex items-center px-3" style={{ background: key.primaryColor }}
                    onClick={() => {
                        filterdata(searchData.target.value)
                    }}>
                    <CiSearch size={20} color="#fff" />
                </button>
            </div>
            {plantData.length > 0 ?
                plantData.map((item, index) => {
                    return (
                        <Plant key={index} plantData={item} />
                    )
                })
                : <></>} */}
            <Footer />
        </>
    )
}
export default GenerateNewQuotation;