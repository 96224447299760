import React from 'react';
import { Spinner } from 'reactstrap';

const Loader = () => {
    return (
        <div style={{ alignItems: "center", justifyContent: "center", display: "flex" }}><Spinner className="m-5" /></div>
    );
};

export default Loader;
