import Text from "../../atom/Text";
import { key } from "../../utils/consts";

const Specifications = ({ productData }) => {
    return (
        <div className="mx-6 mt-3" >
            <Text
                className={"text-4xl"}
                content={productData?.name}
                style={{ fontFamily: key.fontFamily, fontWeight: "bold" }} />
            <div className="border-b pb-8 h-full" style={{ overflowY: "auto", borderColor: "#d3d3d3" }} >
                {productData?.additionalInformation != undefined ? <Text
                    className={"text-base mt-10"}
                    content={productData?.additionalInformation}
                    style={{ fontFamily: key.fontFamily, fontWeight: "500", opacity: "0.5" }}
                /> : <></>}
                {productData?.information != undefined && productData?.modelNumber != undefined ?
                    <>
                        <Text
                            className={"text-lg mt-10"}
                            content={"Specifications :"}
                            style={{ fontFamily: key.fontFamily, fontWeight: "bold" }}
                        />
                        <Text
                            className={"text-sm mt-3 ml-6"}
                            content={`Model - ${productData?.modelNumber}`}
                            style={{ display: "list-item", fontFamily: key.fontFamily, fontWeight: "300", opacity: "0.5" }}
                        />
                        <Text
                            className={"text-sm mt-1 ml-6"}
                            content={`Information - ${productData?.information}`}
                            style={{ display: "list-item", fontFamily: key.fontFamily, fontWeight: "300", opacity: "0.5" }}
                        />
                    </> : <></>}
                {productData?.details != undefined ?
                    <>
                        <Text
                            className={"text-lg mt-10"}
                            content={"Technical Details :"}
                            style={{ fontFamily: key.fontFamily, fontWeight: "bold" }}
                        />
                        <Text
                            className={"text-sm mt-1 ml-6"}
                            content={`${productData?.details}`}
                            style={{ display: "list-item", fontFamily: key.fontFamily, fontWeight: "300", opacity: "0.5" }}
                        />
                    </>
                    : <></>}
            </div>
        </div>
    )
}
export default Specifications;