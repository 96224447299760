import { useParams } from "react-router-dom";
import Footer from "../../components/Footer";
import Header from "../../components/Header/Header";
import { useContext, useEffect, useState } from "react";
import { callGetPlantsById, getCouponsByType, sendQuotationToUser } from "../../Services/OrderServices";
import ImageDisplay from "../Product/ImageDisplay";
import { key } from "../../utils/consts";
import PlantDescription from "./PlantDescription";
import { CiShoppingCart } from "react-icons/ci";
import { IoDocumentTextOutline } from "react-icons/io5";
import { priceFormat } from "../../utils/priceFunctions";
import Text from "../../atom/Text";
import Button from "../../atom/Button";
import { CartContext } from "../ShoppingCart/CartContext";
import { ToastContainer, toast } from "react-toastify";
import { sendQuotationFromCart } from "../../Services/CartServices";
import { Spinner } from "reactstrap";
import { BiSolidOffer } from "react-icons/bi";
import Coupons from "../../components/Coupons/Coupons";

const Plant = () => {
    const { plantId } = useParams();
    const { cartData, updateCart } = useContext(CartContext);
    const [plantData, setPlantData] = useState({});
    const [productType, setProductType] = useState("plants")
    const [showQuantity, setShowQuantity] = useState(true);
    const [showPrice, setShowPrice] = useState(true);
    const [loading, setLoading] = useState(false);
    const [couponData, setCouponData] = useState([]);
    const [extraAccessoriesPrice, setExtraAccssoriesPrice] = useState({
        totalPrice: 0,
        priceWithoutGst: 0,
        gstPrice: 0
    })
    const getPlantDataById = async () => {
        const response = await callGetPlantsById(plantId);
        console.log("PLANT DATA ==>", response?.result)
        setPlantData(response?.result);
    }
    const sendQuotation = async () => {
        setLoading(true);
        const products = await plantData?.bom?.filter((item) => item?.variantId != undefined);
        console.log(">productId",plantData)
        products.push({
            name: plantData?.name,
            b2bPrice: plantData?.b2bPrice,
            b2cPrice: plantData?.b2cPrice,
            unit: "NOS",
            brand: plantData?.brand,
            modelNumber: plantData?.modelNumber,
            capacity: plantData?.capacity + ` KLD`,
            type: "plants",
            quantity: 1,
            images: plantData?.images,
            gst: plantData?.gstPercentage,
            category: plantData?.category,
            parameters: plantData?.parameters,
            bom: plantData?.bom,
            productId:plantData?._id
        })
        const apiBody = {
            products: products,
            type: "plantQuotation"
        }
        const response = await sendQuotationFromCart(apiBody)
        if (response?.result?.type == "success") {
            setLoading(false);
            toast.success(response?.result?.message)
        } else {
            setLoading(false);
            toast.error(response?.result?.message)
        }
    }
    const addBomPriceInPlant = async () => {
        setShowPrice(false)
        await plantData['bom']?.map(async (item) => {
            if (item?.b2bPrice != undefined && item?.b2cPrice != undefined) {
                plantData['b2bPrice'] = await plantData['b2bPrice'] + item?.b2bPrice;
                plantData['b2cPrice'] = await plantData['b2cPrice'] + item?.b2cPrice;
            }
        })
        setShowPrice(true)
    }
    const getCoupons = async () => {
        const response = await getCouponsByType("plants");
        if (response?.result) {
            setCouponData(response?.result)
        }
    }
    useEffect(() => {
        getPlantDataById()
        getCoupons()
    }, [])
    return (
        <>
            <ToastContainer />
            <Header />
            <div className="flex max-[848px]:flex-col" style={{ height: window.innerWidth > 993 ? window.innerHeight / 1.09 : "100%" }}>
                <div className="w-3/4 flex rounded max-[848px]:flex-col max-[848px]:w-full" style={{ backgroundColor: key.secondaryColor }}>
                    <ImageDisplay productData={plantData} />
                    <PlantDescription plantData={plantData} addPrice={addBomPriceInPlant} setExtraAccssoriesPrice={setExtraAccssoriesPrice} extraAccessoriesPrice={extraAccessoriesPrice} />
                </div>
                <div className="w-1/4 px-3 max-[848px]:w-full" style={{ backgroundColor: key.secondaryColor, overflowY:"scroll" }}>
                    {showPrice ? <>
                        <Text content={`${window.localStorage.getItem("userRole") == "b2c" ? priceFormat(plantData?.b2cPrice + plantData?.b2cPrice * plantData?.gstPercentage / 100) : priceFormat(plantData?.b2bPrice + plantData?.b2bPrice * plantData?.gstPercentage / 100 + extraAccessoriesPrice?.totalPrice)} (Incl. of all taxes)`} className={"text-sm mt-8 max-[848px]:hidden"} style={{ fontFamily: key.fontFamily, color: "gray" }} />
                        <div className="my-3 flex flex-col items-start max-[848px]:hidden">
                            <Text content={window.localStorage.getItem("userRole") == "b2c" ? priceFormat(plantData?.b2cPrice + extraAccessoriesPrice?.priceWithoutGst) : priceFormat(plantData?.b2bPrice + extraAccessoriesPrice?.priceWithoutGst)} className={"text-3xl"} style={{ fontFamily: key.fontFamily, fontWeight: "bolder" }} />
                            <Text content={`+ ${priceFormat(window.localStorage.getItem("userRole") == "b2c" ? plantData?.b2cPrice * plantData?.gstPercentage / 100 : plantData?.b2bPrice * plantData?.gstPercentage / 100 + extraAccessoriesPrice?.gstPrice)} GST`} className={"text-sm mt-2"} style={{ fontFamily: key.fontFamily, color: "gray" }} />
                        </div></> :
                        <></>}
                    <div className="mt-14 flex items-center flex-col justify-center gap-6 max-[848px]:mt-0 max-[848px]:my-7">
                        {showQuantity && cartData?.products && cartData?.products?.plants.filter((item) => item?.productId?._id == plantData?._id)?.length < 1 ? <button
                            className="flex w-52 h-12 rounded items-center justify-center"
                            style={{ background: key.blue }}
                            onClick={async () => {
                                await setShowQuantity(false)
                                plantData?.bom?.map(async (item) => {
                                    if (item?.variantId != undefined) {
                                        await updateCart("accessories", item?.quantity, item?.productId, item?.variantId)
                                    }
                                })
                                await updateCart(productType, 1, plantData?._id)
                                await setShowQuantity(true)
                            }}
                        >
                            <CiShoppingCart
                                className={"text-sm"}
                                size={30}
                                style={{ fontFamily: key.fontFamily, fontWeight: "700", color: "#fff" }}
                            />
                            <Button
                                className={"text-sm ml-4"}
                                buttonText={"Add to Cart"}
                                style={{ fontFamily: key.fontFamily, fontWeight: "700", color: "#fff" }}
                            />
                        </button> :
                            <div className="w-52 h-12 flex rounded items-center justify-center" style={{ background: key.blue }}>
                                <Button buttonText={"-"} className={"text-base w-1/4 h-12 rounded-l flex items-center justify-center"} style={{ fontFamily: key.fontFamily, backgroundColor: key.blue, color: key.secondaryColor }} onClick={async () => {
                                    await setShowQuantity(false)
                                    await updateCart(productType, cartData?.products?.plants?.filter((item) => item?.productId?._id == plantData?._id)[0]?.quantity - 1, plantData?._id)
                                    await setShowQuantity(true)
                                }} />
                                <Text content={cartData?.products ? cartData?.products?.plants?.filter((item) => item?.productId?._id == plantData?._id)[0]?.quantity : 0} className={"text-base w-2/4 h-12 flex items-center justify-center border-y"} style={{ fontFamily: key.fontFamily, backgroundColor: key.secondaryColor, color: key.blue, fontWeight: 'bold' }} />
                                <Button buttonText={"+"} className={"text-base w-1/4 h-12 flex items-center justify-center rounded-r"} style={{ fontFamily: key.fontFamily, backgroundColor: key.blue, color: key.secondaryColor }} onClick={async () => {
                                    await setShowQuantity(false)
                                    await updateCart(productType, cartData?.products?.plants?.filter((item) => item?.productId?._id == plantData?._id)[0]?.quantity + 1, plantData?._id)
                                    await setShowQuantity(true)
                                }} />
                            </div>
                        }
                        <button
                            className="flex w-52 h-12 rounded items-center justify-center"
                            style={{ background: key.blue }}
                            disabled={loading ? true : false}
                            onClick={() => { sendQuotation() }}
                        >
                            {!loading ?
                                <>
                                    <IoDocumentTextOutline
                                        className={"text-sm"}
                                        size={23}
                                        style={{ fontFamily: key.fontFamily, fontWeight: "700", color: "#fff" }}
                                    />
                                    <Button
                                        className={"text-sm ml-4"}
                                        buttonText={"Get Quote"}
                                        style={{ fontFamily: key.fontFamily, fontWeight: "700", color: "#fff" }}
                                    />
                                </> : <Spinner size={"sm"} style={{ color: key.secondaryColor }} />}
                        </button>
                        <Text content={"(Quotation will be sent to your registered email ID.)"}
                            className={"text-xs italic"} style={{ color: "gray", fontFamily: key.fontFamily }} />
                    </div>
                    <Coupons couponData={couponData} />
                </div>
            </div>
            <Footer />
        </>
    )
}
export default Plant;