import { useEffect, useState } from "react";
import Video from "../../../molecules/Video";
import Text from "../../../atom/Text";
import Header from "../../../components/Header/Header";
import Footer from "../../../components/Footer";
import { key } from "../../../utils/consts";
import PlayVideoModal from "./PlayVideoModal";
import { getVideoByCategoriesServices, getVideoCategoriesServices, getVideoDataService } from "../../../Services/ResourcesServices";
import { FormGroup, Input, Label, Spinner } from "reactstrap";
import { CiSearch } from "react-icons/ci";
import Loader from "../../../atom/Loader";

const Videos = () => {
    const [videoData, setVideoData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [unfilteredVideoData, setUnfilteredVideoData] = useState([]);
    const [searchData, setSearchData] = useState("");
    const [categoriesData, setCategoriesData] = useState([])
    const [videoStart, setVideoStart] = useState(false);
    const [selectedVideoData, setSelectedVideoData] = useState({});
    const getVideoData = async () => {
        setLoading(true)
        const response = await getVideoDataService();
        if (response.result?.length > 0) {
            setVideoData(response.result);
            setUnfilteredVideoData(response.result);
        }
        setLoading(false)
    }
    const getVideoCategories = async () => {
        setLoading(true)
        const response = await getVideoCategoriesServices();
        if (response.result?.length > 0) {
            setCategoriesData(response.result);
        }
        setLoading(false)
    }
    const videoByCategory = async (category) => {
        console.log("category ,",category.target.value)
        if(category.target.value == "all"){
            setVideoData(unfilteredVideoData)
        }else{
            const response = await getVideoByCategoriesServices(category.target.value);
            console.log("RES -->", response.result)
            setVideoData(response.result);
        }
    }
    const filterdata = async (text) => {
        setLoading(true)
        text && text.length > 1 ?
            setVideoData(unfilteredVideoData.filter((item) => String(item.title).toLowerCase().includes(String(text).toLowerCase()) || String(item.description).toLowerCase().includes(String(text).toLowerCase()))) : setVideoData(unfilteredVideoData)
        setLoading(false)
    }
    useEffect(() => {
        getVideoData();
        getVideoCategories()
    }, [])
    return (
        <>
            <PlayVideoModal openModal={videoStart} setOpenModal={setVideoStart} videoData={selectedVideoData} />
            <Header />
            {/* {videoData.length > 0 ? <> */}
            {!loading > 0 ?
                <>
                    <Text content={"Videos"} className="text-4xl m-4" style={{ fontFamily: key.fontFamily, fontWeight: "bold", background: key.primaryColor, WebkitBackgroundClip: "text", WebkitTextFillColor: "transparent" }} />
                    <hr className="w-3/4 m-4" />
                    <div className="flex items-center m-4 gap-6 max-[747px]:flex-col max-[747px]:items-start">
                        <div className="w-1/3 flex max-[634px]:w-4/5">
                            <Input placeholder="Search a Video" style={{ borderImage: key.primaryColor + 1, fontFamily: key.fontFamily }}
                                onChange={setSearchData}
                                onKeyPress={(target) => {
                                    if (target.charCode == 13) {
                                        filterdata(searchData.target.value)
                                    }
                                }}
                            />
                            <button
                                className="w-fit flex items-center px-3" style={{ background: key.primaryColor }}
                                onClick={() => {
                                    filterdata(searchData.target.value)
                                }}>
                                <CiSearch size={20} color="#fff" />
                            </button>
                        </div>
                        <div className="w-1/3 flex max-[634px]:w-4/5">
                            <Input
                                id="exampleSelect"
                                name="select"
                                type="select"
                                className="border-1 italic w-32"
                                style={{ borderColor: "#3A7AB7", fontFamily: key.fontFamily, color: "#3A7AB7" }}
                                onChange={videoByCategory}
                            >
                                <option value={"all"}>
                                    All Categories
                                </option>
                                {categoriesData?.length > 0 ?
                                    categoriesData.map((item, index) => {
                                        return (
                                            <option value={item?._id} >{item?.category[0].toUpperCase() + item?.category.slice(1)}</option>
                                        )
                                    }) : <></>
                                }
                            </Input>
                        </div>
                    </div >

                    {videoData.length > 0 ?
                        <div className="w-full flex justify-center my-14">
                            <div className="w-full mx-4 grid grid-cols-4 gap-6 max-[1000px]:grid-cols-3 max-[800px]:grid-cols-2 max-[450px]:grid-cols-1">
                                {videoData.map((item, key) => {
                                    return (
                                        <Video videoData={item} key={item?._id} onClick={() => {
                                            setSelectedVideoData(item);
                                            setVideoStart(true);
                                        }} />
                                    )
                                })}
                            </div>
                        </div> :
                        <div className="w-full flex justify-center my-14" >
                        <div className="w-50 flex justify-center">
                            
                            <Text content={" No Videos found"} className="text-2xl m-4" style={{ fontFamily: key.fontFamily, fontWeight: "bold", background: 'grey', WebkitBackgroundClip: "text", WebkitTextFillColor: "transparent" }} />
                        </div>
                        </div>
                    }

                </>
                : 
                   <Loader></Loader>
              
            }
            <Footer />
        </>
    )
}
export default Videos;