import { BASE_URL } from "../Services/config"

export const GETALLUSERS = BASE_URL + "users";
export const REGISTERUSERS = BASE_URL + "auth/register";
export const VERIFICATIONPENDING = BASE_URL + "users/request/pending-b2b";
export const REJECTIONEMAIL = GETALLUSERS + "/reject-profile";
export const GETALLFAQ = BASE_URL + "faqs";
export const GETALLTRANSPORTERS = BASE_URL + "transporters";
export const UPLOADASSETS = BASE_URL + "uploads/assets";
export const RESOURCEVIDEOS = BASE_URL + "resource-videos"
export const PRODUCTS = BASE_URL + "products"
export const PLANTS = BASE_URL + "plants"
export const ABOUTUS = BASE_URL + "abouts"
export const CHECKOTP = BASE_URL + "auth/login-with-otp"
export const CHANGEPASSWORD = BASE_URL + "auth/change-password"