import { useState } from "react";
import Button from "../../../../atom/Button";
import Description from "../../../../atom/Description";
import Text from "../../../../atom/Text";
import Footer from "../../../../components/Footer";
import Header from "../../../../components/Header/Header";
import Field from "../../../../molecules/Field";
import { key } from "../../../../utils/consts";
import { checkQuotationByNumber } from "../../../../Services/OrderServices";
import { ToastContainer, toast } from "react-toastify";
import { addToCartService } from "../../../../Services/CartServices";
import Label from "../../../../atom/Label";
import TextInput from "../../../../atom/TextInput";
import { useHistory } from "react-router-dom";
import { QUOTATION_SUMMARY } from "../../../../utils/pathDetails";

const OrderQuotationNumber = () => {
    const [quotationNumber, setQuotationNumber] = useState("");
    const history = useHistory();
    const onSubmit = async () => {
        if (quotationNumber != "") {
            const apiBody = { quotationNumber: quotationNumber }
            const response = await checkQuotationByNumber(apiBody);
            if (response?.result?.message) {
                toast.error(response?.result?.message)
            } else {
                toast.success("Quotation Found!")
                history.push(QUOTATION_SUMMARY + response?.result?._id)
            }
        } else {
            toast.error("Enter Quotation Number!")
        }
    }
    return (
        <>
            <ToastContainer />
            <Header />
            <Text content={"Order With Quotation Number"} className="text-4xl m-4" style={{ fontFamily: key.fontFamily, fontWeight: "bold", background: key.primaryColor, WebkitBackgroundClip: "text", WebkitTextFillColor: "transparent" }} />
            <Text
                content={"The quotation number is mentioned in the quotation which has been sent to your registered mail account."}
                className="text-xl mx-4 w-2/3 mt-8"
                style={{ fontFamily: key.fontFamily, fontWeight: "500", opacity: "0.5" }}
            />
            <div className="mx-4 w-96 mt-9">
                <Label text={"Quotation Number"} required={true} />
                <TextInput placeholder={"Quotation Number"} onChange={(e) => { setQuotationNumber(e.target.value) }} backgroundColor="#F0F5FA" onKeyPress={(target) => {
                    if (target.charCode == 13) {
                        onSubmit()
                    }
                }} />
            </div>
            <Button
                buttonText={"Submit"}
                onClick={() => { onSubmit() }}
                className={"mx-4 my-10 w-52 h-10 rounded"}
                style={{ fontFamily: key.fontFamily, color: "#fff", background: key.blue, fontWeight: "bold" }}
            />
            <Footer />
        </>
    )
}
export default OrderQuotationNumber;