import { createContext, useEffect, useState } from "react";
import { addToCartService, calculateBillAmountService, calculatePriceDiscount, getCartDataService, getCouponService } from "../../Services/CartServices";
import { callCalculateDiscountData, getProductTypeTotal } from "../../Services/CouponServices";
import { ToastContainer, toast } from "react-toastify";

export const CartContext = createContext()

export const CartProvider = ({ children }) => {
    const [cartCount, setCartCount] = useState(0);
    const [showCart, setShowCart] = useState(true);
    const [cartData, setCartData] = useState([]);
    const [cartDataWithoutCoupon, setCartDataWithoutCoupon] = useState([]);
    const [gstPrice, setGstPrice] = useState(0);
    const [productPrice, setProductPrice] = useState(0);
    const [totalPrice, setTotalPrice] = useState(0);
    const [totalDiscountPrice, setTotalDiscountPrice] = useState();
    const [gstDiscountPrice, setGstDiscountPrice] = useState();
    const [productDiscountPrice, setProductDiscountPrice] = useState();
    const [discountPrice, setDiscountPrice] = useState();
    const [couponData, setCouponsData] = useState([]);
    const [selectedCouponIndex, setSelectedCouponIndex] = useState();

    const getCartCount = async () => {
        const response = await getCartDataService();
        setCartData(response?.result)
        setCartCount(response?.result?.count);
        setCartDataWithoutCoupon(response?.result);
        await getCouponsData(response?.result)
        await calculateCartAmount();
    }
    const updateCart = async (productType, quantity, productId, variantId) => {
        setSelectedCouponIndex();
        setTotalDiscountPrice();
        setGstDiscountPrice();
        setProductDiscountPrice();
        const apiBody = { productType: productType, quantity: quantity, productId: productId };
        if (productType == "spareParts" || productType == "accessories") {
            apiBody['variantId'] = variantId
        }
        const response = await addToCartService(apiBody);
        if (response?.result) {
            getCartCount();
        }
    }
    const getCouponsData = async (apiBody) => {
        const response = await getCouponService(apiBody);
        if (response?.result) {
            response?.result?.sort((a, b) => b.isApplicable - a.isApplicable)
            setCouponsData(response?.result)
        }
    }
    const calculateCartAmount = async () => {
        const response = await calculateBillAmountService();
        if (response?.result) {
            setGstPrice(response?.result?.gstAmount);
            setProductPrice(response?.result?.productPrice);
            setTotalPrice(response?.result?.totalPrice)
        }
    }
    const applyCoupon = async (couponId, productType) => {
        const productTypeTotalResponse = await getProductTypeTotal({ productType: productType });
        const apiBody = {
            couponId: couponId,
            appliedFrom: "cart",
            applyTo: productType,
            products: cartDataWithoutCoupon?.products,
            totalProductsPrice: productTypeTotalResponse?.result?.totalPrice
        }
        const response = await callCalculateDiscountData(apiBody);
        if (response?.result) {
            if (response?.result?.message) {
                toast.error(response?.result?.message);
                setSelectedCouponIndex();
            } else {
                setShowCart(false)
                const calculatePricing = await calculatePriceDiscount({ cartData: response?.result })
                if (calculatePricing?.result) {
                    setTotalDiscountPrice(calculatePricing?.result?.totalPrice)
                    setGstDiscountPrice(calculatePricing?.result?.gstPrice)
                    setProductDiscountPrice(calculatePricing?.result?.productPrice);
                }
                setCartData(response?.result);
                setDiscountPrice(response?.result?.discount);
                setShowCart(true);
            }
        }
    }
    useEffect(() => {
        getCartCount();
    }, [])

    return (
        <>
            <ToastContainer />
            <CartContext.Provider
                value={{
                    cartCount,
                    cartData,
                    getCartCount,
                    updateCart,
                    gstPrice,
                    totalPrice,
                    productPrice,
                    discountPrice,
                    couponData,
                    setCartData,
                    applyCoupon,
                    showCart,
                    setShowCart,
                    selectedCouponIndex,
                    setSelectedCouponIndex,
                    totalDiscountPrice,
                    gstDiscountPrice,
                    productDiscountPrice,
                    setTotalDiscountPrice,
                    setGstDiscountPrice,
                    setProductDiscountPrice,
                    setDiscountPrice,
                    cartDataWithoutCoupon
                }}>
                {children}
            </CartContext.Provider>
        </>
    );
};
