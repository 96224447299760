import { useState } from "react";
import { key } from "../utils/consts";

const Button = ({ buttonText, className,style,  ...rest }) => {
  const [mouseEnter, setMouseEnter] = useState(false);
  return (
    <button {...{ className, ...rest }} onMouseEnter={() => { setMouseEnter(true) }} onMouseLeave={() => { setMouseEnter(false) }} style={{ backgroundColor: mouseEnter ? key.hoverColor : key.blue, fontFamily: key.fontFamily, color: key.secondaryColor, ...style }}>{buttonText}</button>
  );
}
export default Button;
