import { useLocation } from "react-router-dom";
import Image from "../../atom/Image";
import ResetPasswordForm from "../../molecules/ResetPasswordForm";
import { key } from "../../utils/consts";
import { useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import { callPostServiceData } from "../../Services/DataServices";
import { CHANGEPASSWORD } from "../../utils/urlDetails";
import { useHistory } from "react-router-dom";
import BackgroundImage from "../../atom/BackgroundImage";
import Title from "../../atom/Title";
import Description from "../../atom/Description";
import { LOGIN } from "../../utils/pathDetails";

const ResetPassword = ({ }) => {
  const location = useLocation()
  const [userData, setUserData] = useState({})
  const [resetPassword, setResetPassword] = useState("");
  const [resetPasswordValid, setResetPasswordValid] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [confirmPasswordValid, setConfirmPasswordValid] = useState("");
  const history = useHistory();

  const onClickButton = async () => {
    userData['password'] = resetPassword;
    userData['confirmPassword'] = confirmPassword;
    const data = {
      _id: location?.state?.user?._id,
      mail: location?.state?.user?.email,
      password: userData?.password
    }
    if (userData.password) {
      if (userData.password === userData.confirmPassword) {
        if (resetPasswordValid) {
          const { result } = await callPostServiceData(CHANGEPASSWORD, data);
          if ((result.data.passwordChanged) && (data?._id !== undefined) && (data?.mail !== undefined)) {
            toast.success("Password set successfully")
            setTimeout(() => {
              history.push(LOGIN);
            }, 2000);
          } else {
            toast.error("Something went wrong")
          }
        } else {
          toast.error("Invalid Password Format")
        }
      } else {
        toast.error("Both password are different")
      }
    } else {
      toast.error("Please enter password")
    }
  }
  const checkResetPassword = async () => {
    const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
    const result = regex.test(resetPassword);
    if (result) {
      setResetPasswordValid(true);
    } else {
      setResetPasswordValid(false);
    }
  }
  const checkConfirmPassword = async () => {
    const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
    const result = regex.test(confirmPassword);
    if (result) {
      return true
    } else {
      return false
    }
  }
  return (
    <>
      <ToastContainer />
      <div className="flex" style={{ backgroundColor: "#fff" }}>
          <BackgroundImage src={"./images/Login Photo.png"} />
        <div className="w-10/12 pl-10 pr-10 flex flex-col items-center justify-center max-[952px]:w-full pt-20 " style={{ background: key.secondaryColor }}>
          <div className="flex items-center justify-center self-center w-fit max-[1032px]:flex-col">
            <Image src={'./images/logo-light.png'} className={"w-1/3"}/>
            <div className="flex flex-col ml-6 max-[1032px]:mt-6 ml-0">
              <Title text={"Purifying Water, Power Communities"} color={key.blue} />
              {/* <Description text={"Enter Password"} color={key.blue} /> */}
            </div>
          </div>
          <ResetPasswordForm
            resetPassword={resetPassword}
            setResetPassword={setResetPassword}
            confirmPassword={confirmPassword}
            setConfirmPassword={setConfirmPassword}
            resetPasswordValid={resetPasswordValid}
            checkResetPasswordValid={checkResetPassword}
            confirmPasswordValid={confirmPasswordValid}
            setConfirmPasswordValid={setConfirmPasswordValid}
            checkConfirmPasswordValid={checkConfirmPassword}
            onClick={onClickButton}
          />
        </div>
      </div>
    </>
  );
};

export default ResetPassword;
