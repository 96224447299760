export const LOGIN = "/login";
export const SIGNUP = "/signup";
export const BUSINESS_SIGNUP = "/signup/business";
export const INDIVIDUAL_SIGNUP = "/signup/individual";
export const VERIFICATION_PENDING = "/verification-pending";
export const OTP_VERIFICATION = "/otp-verification";
export const FORGOT_PASSWORD = "/forgot-password";
export const RESET_PASSWORD = "/reset-password";
export const HOME = "/home";
export const SEARCH = "/search/";
export const SEARCH_NAVIGATION = "/search/:searchText"
export const ABOUT = "/about";
export const FAQs = "/faq";
export const CONTACT = "/contact";
export const PROFILE = "/profile";
export const EDIT_PROFILE = "/profile/edit";
export const GENERATE_NEW_QUOTATION = "/order/generate-quotation";
export const WATER_TREATMENT_PLANT = "/order/generate-quotation/water-treatment-plant";
export const ACCESSORIES = "/order/generate-quotation/accessories";
export const USE_QUOTATION_NUMBER = "/order/use-quotation-number";
export const SERVICES = '/order/services';
export const ORDER_HISTORY = "/history/orders";
export const QUOTATION_HISTORY = "/history/quotations";
export const VIDEOS = '/videos';
export const CATALOGUE = '/catalogue';
export const TRANSPORTERS = '/transporters';
export const SHOPPING_CART = "/shopping-cart";
export const PRODUCT = "/product/";
export const PRODUCT_NAVIGATION = "/product/:productType/:productId";
export const CATEGORY = "/category/"
export const CATEGORY_NAVIGATION = "/category/:productType/:categoryId";
export const PLANT = "/plants/";
export const PLANT_NAVIGATION = "/plants/:plantId";
export const MANUALS = '/manuals';
export const QUOTATION_SUMMARY_NAVIGATION = "/quotation/summary/:quotationNumber";
export const QUOTATION_SUMMARY = "/quotation/summary/";
export const ORDER_CONFIRM_NAVIGATION = "/order/confirm/:quotationNumber";
export const ORDER_CONFIRM = "/order/confirm/";
export const ORDER_SUMMARY = "/order/summary";
export const ORDER_SUCCESS = "/order/success";
export const TERMS_AND_CONDITIONS = "/termsandconditions";
export const REFUND_POLICY = "/refundpolicy";
export const SERVICE_NAVIGATION = "/service/:serviceId";
export const SERVICE = "/service/";
export const ORDER_NAVIGATION = "/order/:orderId";
export const ORDER = "/order/";
export const BANK_DETAILS = "/bank-details";