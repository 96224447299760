import { useEffect, useState } from "react";
import Text from "../../../atom/Text";
import Footer from "../../../components/Footer";
import Header from "../../../components/Header/Header";
import { key } from "../../../utils/consts";
import { callGetServicesData } from "../../../Services/OrderServices";
import Service from "../../../molecules/Service";
import { Input } from "reactstrap";
import { CiSearch } from "react-icons/ci";
import Button from "../../../atom/Button";

const Services = () => {
    const [serviceData, setServiceData] = useState([]);
    const [unfilteredServiceData, setUnfilteredServiceData] = useState([]);
    const [searchData, setSearchData] = useState("");
    const servicesPerRow = 12;
    const [next, setNext] = useState(servicesPerRow);

    const handleMoreServices = () => {
        setNext(next + servicesPerRow);
    };

    const getServiceData = async () => {
        const response = await callGetServicesData();
        setServiceData(response?.result);
        setUnfilteredServiceData(response?.result);
    }
    const filterdata = async (text) => {
        text && text.length > 1 ?
            setServiceData(unfilteredServiceData.filter((item) => String(item.name).toLowerCase().includes(String(text).toLowerCase()))) : setServiceData(unfilteredServiceData)
    }
    useEffect(() => {
        getServiceData();
    }, [])
    return (
        <>
            <Header />
            <Text content={"Services"} className="text-4xl mt-6" style={{ background: key.primaryColor, WebkitBackgroundClip: "text", WebkitTextFillColor: "transparent", fontFamily: key.fontFamily, fontWeight: "bold", textAlign: "center" }} />
            <div className="w-full flex justify-center ">
                <hr className="w-1/12" />
            </div>
            <div className="w-full flex justify-center mt-8">
                <div className="w-4/5">
                    <div className="w-1/2 flex max-[634px]:w-4/5 max-[491px]:w-full">
                        <Input placeholder="Search a Service" style={{ borderImage: key.primaryColor + 1, fontFamily: key.fontFamily }}
                            onChange={setSearchData}
                            onKeyPress={(target) => {
                                if (target.charCode == 13) {
                                    filterdata(searchData.target.value)
                                }
                            }}
                        />
                        <button
                            className="w-fit flex items-center px-3" style={{ background: key.primaryColor }}
                            onClick={() => {
                                filterdata(searchData.target.value)
                            }}>
                            <CiSearch size={20} color="#fff" />
                        </button>
                    </div>
                </div>
            </div>
            <div className="w-full flex justify-center mt-2 mb-10">
                <div className="w-4/5 grid grid-cols-4 gap-x-10 max-[1000px]:grid-cols-3 max-[735px]:grid-cols-2 max-[425px]:grid-cols-1">

                    {serviceData.length > 0 ? serviceData.slice(0, next).map((item) => {
                        return (
                            <Service serviceData={item} />
                        )
                    }) : <></>}
                </div>
            </div>
            {next < serviceData?.length ? <div className="w-full flex justify-center">
                <Button
                    className={"w-44 h-12 text-base rounded mb-6"}
                    buttonText={"Load More"}
                    style={{ fontFamily: key.fontFamily, color: "#fff", background: key.homeBackgroundColor, fontWeight: "bold" }}
                    onClick={() => { handleMoreServices() }}
                />
            </div> : <></>}
            <Footer />
        </>
    )
}
export default Services;