import { ProgressBar, Step } from "react-step-progress-bar";
import Text from "../../../../atom/Text";
import { key } from "../../../../utils/consts";
import { IoIosArrowDroprightCircle } from "react-icons/io";
import { priceFormat } from "../../../../utils/priceFunctions";
import { useHistory } from "react-router-dom";
import { ORDER } from "../../../../utils/pathDetails";
import { getOrderStatus } from "../../../../utils/orderStatus";
import moment from "moment";

const OrderTrack = ({ orderData, index, isOpen, setIsOpen, setSelectedIndex, selectedIndex }) => {
    const history = useHistory();
    return (
        <div className="p-2 mx-4 rounded flex mt-12" style={{ boxShadow: key.formShadow, }} onClick={() => {
            if (selectedIndex == index) {
                setSelectedIndex()
            } else {
                setSelectedIndex(index)
            }
        }}>
            <div className="flex flex-col justify-center border-r-2 pr-2 w-3/12" >
                <div className="flex items-center my-2">
                    <Text content={`Order :`} className={"ml-2"} style={{ fontFamily: key.fontFamily, fontWeight: "bold" }} />
                    <Text content={`${orderData?._id}`} className={"ml-2 text-sm"} style={{ fontFamily: key.fontFamily, }} />
                </div>
                <div className="flex items-center mt-2 mb-1">
                    <Text content={`Status :`} className={"ml-2"} style={{ fontFamily: key.fontFamily, fontWeight: "bold" }} />
                    <Text content={getOrderStatus(orderData?.status)} className={"ml-2"} style={{ fontFamily: key.fontFamily, color: orderData?.status == "cancelled" ? "red" : "green" }} />
                </div>
                <div className="flex items-center mt-2 mb-1">
                    <Text content={`Payment :`} className={"ml-2"} style={{ fontFamily: key.fontFamily, fontWeight: "bold" }} />
                    <Text content={priceFormat(orderData?.totalPrice)} className={"ml-2 text-sm"} style={{ fontFamily: key.fontFamily, color: moment(orderData?.paymentBreakup?.advancePayment?.date).isBefore(moment(Date.now())) ? "red" : ""  }} />
                </div>
                {moment(orderData?.paymentBreakup?.advancePayment?.date).isBefore(moment(Date.now())) && orderData?.paymentBreakup?.advancePayment?.status == "unpaid" ? <Text content={`(${moment(Date.now()).diff(moment(orderData?.paymentBreakup?.advancePayment?.date), "days") + 1} day(s) delayed)`} className={"mt-1 ml-2 text-sm"} style={{ fontFamily: key.fontFamily, color:"red" }} /> : <></>}
                <div className="flex items-center mt-2 mb-1">
                    <Text content={`2nd Payment Date :`} className={"ml-2"} style={{ fontFamily: key.fontFamily, fontWeight: "bold" }} />
                    <Text content={moment(orderData?.paymentBreakup?.firstPayment?.date).format("DD/MM/YYYY")} className={"ml-2 text-sm"} style={{ fontFamily: key.fontFamily, color: moment(orderData?.paymentBreakup?.secondPayment?.date).isBefore(moment(Date.now())) ? "red" : "" }} />
                </div>
                {moment(orderData?.paymentBreakup?.firstPayment?.date).isBefore(moment(Date.now())) ? <Text content={`(${moment(Date.now()).diff(moment(orderData?.paymentBreakup?.firstPayment?.date), "days") + 1} day(s) delayed)`} className={"mt-1 ml-2 text-sm"} style={{ fontFamily: key.fontFamily, color:"red" }} /> : <></>}
                <div className="flex items-center mt-2 mb-1">
                    <Text content={`3rd Payment Date :`} className={"ml-2"} style={{ fontFamily: key.fontFamily, fontWeight: "bold" }} />
                    <Text content={moment(orderData?.paymentBreakup?.secondPayment?.date).format("DD/MM/YYYY")} className={"ml-2 text-sm"} style={{ fontFamily: key.fontFamily, color: moment(orderData?.paymentBreakup?.secondPayment?.date).isBefore(moment(Date.now())) ? "red" : "" }} />
                </div>
                {moment(orderData?.paymentBreakup?.secondPayment?.date).isBefore(moment(Date.now())) ? <Text content={`(${moment(Date.now()).diff(moment(orderData?.paymentBreakup?.secondPayment?.date), "days") + 1} day(s) delayed)`} className={"mt-1 ml-2 text-sm"} style={{ fontFamily: key.fontFamily, color:"red" }} /> : <></>}
            </div>
            <div className="w-8/12 border-r px-16 py-32" style={{ opacity: orderData?.status == "hold" ? "0.6" : 1 }}>
                {orderData?.status == "ordered" ?
                    <>
                        <ProgressBar
                            percent={1}
                            filledBackground={key.blue}
                        >
                            <Step transition="scale">
                                {({ accomplished }) => (
                                    <>
                                        <div style={{}}>
                                            <Text content={"Current Status"} className={"mb-8 text-sm"} style={{ fontFamily: key.fontFamily, color: accomplished ? key.blue : "gray", fontWeight: accomplished ? "bold" : "400", textAlign: "center" }} />
                                            <Text content={getOrderStatus(orderData?.status)} className={"mt-8 text-sm"} style={{ fontFamily: key.fontFamily, color: accomplished ? key.blue : "gray", fontWeight: accomplished ? "bold" : "400", textAlign: "center" }} />
                                        </div>
                                    </>
                                )}
                            </Step>
                            {orderData?.status != "cancelled" ?
                                <Step transition="scale">
                                    {({ accomplished }) => (
                                        <>
                                            <div style={{}}>
                                                <Text content={"Next Status"} className={"text-sm mb-8"} style={{ fontFamily: key.fontFamily, color: accomplished ? key.blue : "gray", fontWeight: accomplished ? "bold" : "400", textAlign: "center" }} />
                                                <Text content={getOrderStatus(orderData?.nextStatus)} className={"mt-8 text-sm"} style={{ fontFamily: key.fontFamily, color: accomplished ? key.blue : "gray", fontWeight: accomplished ? "bold" : "400", textAlign: "center" }} />
                                            </div>
                                        </>
                                    )}
                                </Step> : <></>}
                        </ProgressBar>
                    </> :
                    <ProgressBar
                        percent={51}
                        filledBackground={key.blue}
                    >
                        <Step transition="scale">
                            {({ accomplished }) => (
                                <>
                                    <div style={{width:"20%"}}>
                                        <Text content={"Previous Status"} className={"mb-8 text-sm"} style={{ fontFamily: key.fontFamily, color: accomplished ? key.blue : "gray", fontWeight: accomplished ? "bold" : "400", textAlign: "center" }} />
                                        <Text content={getOrderStatus(orderData?.previousStatus)} className={"mt-8 text-sm w-fit"} style={{ fontFamily: key.fontFamily, color: accomplished ? key.blue : "gray", fontWeight: accomplished ? "bold" : "400", textAlign: "center" }} />
                                    </div>
                                </>
                            )}
                        </Step>
                        <Step transition="scale">
                            {({ accomplished }) => (
                                <>
                                    <div style={{width:"10%"}}>
                                        <Text content={"Current Status"} className={"mb-8 text-sm"} style={{ fontFamily: key.fontFamily, color: accomplished ? key.blue : "gray", fontWeight: accomplished ? "bold" : "400", textAlign: "center" }} />
                                        <Text content={getOrderStatus(orderData?.status)} className={"mt-8 text-sm"} style={{ fontFamily: key.fontFamily, color: accomplished ? key.blue : "gray", fontWeight: accomplished ? "bold" : "400", textAlign: "center" }} />
                                    </div>
                                </>
                            )}
                        </Step>
                        {orderData?.status != "cancelled" ?
                            <Step transition="scale">
                                {({ accomplished }) => (
                                    <>
                                        <div style={{width:"80%"}} className="flex flex-col">
                                            <Text content={"Next Status"} className={"mb-8 text-sm"} style={{ fontFamily: key.fontFamily, color: accomplished ? key.blue : "gray", fontWeight: accomplished ? "bold" : "400", textAlign: "left" }} />
                                            <Text content={getOrderStatus(orderData?.nextStatus)} className={"mt-8 text-sm w-fit"} style={{ fontFamily: key.fontFamily, color: accomplished ? key.blue : "gray", fontWeight: accomplished ? "bold" : "400", textAlign: "left" }} />
                                        </div>
                                    </>
                                )}
                            </Step> : <></>}
                    </ProgressBar>
                }
            </div>
            <div className="w-1/12 flex items-center justify-center">
                <IoIosArrowDroprightCircle size={35} style={{ color: key.blue }} onClick={() => {
                    history.push(ORDER + orderData?._id)
                }} />
            </div>
        </div>
    )
}
export default OrderTrack;