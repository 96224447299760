import { Table } from "reactstrap";
import { key } from "../../../utils/consts";
import TableContainer from "../../../components/TableContainer";
import { useMemo } from "react";

const TransportersTable = ({ transportersData }) => {
    const columns = useMemo(
        () => [
            {
                Header: "No.",
                accessor: "id",
                Cell: (cellProps) => (
                    <>
                        <div className="d-flex align-items-center">
                            <div className="flex-grow-1 ms-2 name text-center m-1">
                                {cellProps.row.index + 1}
                            </div>
                        </div>
                    </>
                ),
            },
            {
                Header: "Name",
                accessor: "name",
                Cell: (cellProps) => (
                    <>
                        <div className="d-flex align-items-center">
                            <div className="flex-grow-1 ms-2 name text-center m-1">
                                {cellProps.row.original.name}
                            </div>
                        </div>
                    </>
                ),
            },
            {
                Header: "GST Number",
                accessor: "gst",
                Cell: (cellProps) => (
                    <>
                        <div className="d-flex align-items-center">
                            <div className="flex-grow-1 ms-2 name text-center m-1">
                                {cellProps.row.original.gst}
                            </div>
                        </div>
                    </>
                ),
            },
            {
                Header: "Destination Region",
                accessor: "region",
                Cell: (cellProps) => (
                    <>
                        <div className="d-flex align-items-center">
                            <div className="flex-grow-1 ms-2 name text-center m-1">
                                {cellProps.row.original.destinationRegion}
                            </div>
                        </div>
                    </>
                ),
            },
            {
                Header: "Contact No.",
                accessor: "phone",
                Cell: (cellProps) => (
                    <>
                        <div className="d-flex align-items-center">
                            <div className="flex-grow-1 ms-2 name text-center m-1">
                                {cellProps.row.original.contactNo}
                            </div>
                        </div>
                    </>
                ),
            },
        ],
        []
    );
    return (
        <>
            <div className="m-4" style={{ overflowX: "auto" }}>
            <TableContainer
                columns={columns}
                data={transportersData || []}
                customPageSize={10}
                className="custom-header-css"
                trClass={"h-12 rounded-tr text-md"}
                paginationDisplay={false}
            />
            </div>
        </>
    )
}
export default TransportersTable;