import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { FaCartShopping } from "react-icons/fa6";
import { IoDocumentTextOutline } from "react-icons/io5";
import { IMAGE_URL } from "../../Services/config";
import Button from "../../atom/Button";
import Text from "../../atom/Text";
import Footer from "../../components/Footer";
import Header from "../../components/Header/Header";
import ImageSlider from "../../components/ImageSlider";
import { key } from "../../utils/consts";
import { callGetAccessoriesById, callGetSparePartsById, getCouponsByType, sendQuotationToUser } from "../../Services/OrderServices";
import Loader from "../../components/Loader";
import ProductDescription from "./ProductDescription";
import ImageDisplay from "./ImageDisplay";
import { CiShoppingCart } from "react-icons/ci";
import { priceFormat } from "../../utils/priceFunctions";
import { CartContext } from "../ShoppingCart/CartContext";
import { sendQuotationFromCart } from "../../Services/CartServices";
import { ToastContainer, toast } from "react-toastify";
import { Spinner } from "reactstrap";
import Coupons from "../../components/Coupons/Coupons";

const Product = () => {
    const { productId, productType } = useParams();
    const { cartData, updateCart } = useContext(CartContext);
    const [activeVariant, setActiveVariant] = useState({});
    const [productData, setProductData] = useState({});
    const [loading, setLoading] = useState(false);
    const [quotationLoader, setQuotationLoader] = useState(false);
    const [showQuantity, setShowQuantity] = useState(true);
    const [couponData, setCouponData] = useState([]);
    const getProductData = async () => {
        if (productType === 'spare-parts') {
            setLoading(true);
            const response = await callGetSparePartsById(productId);
            setProductData(response?.result)
            setActiveVariant(response?.result?.variants[0]);
            setLoading(false);
        } else if (productType === 'accessories') {
            setLoading(true);
            const response = await callGetAccessoriesById(productId);
            setProductData(response?.result)
            setActiveVariant(response?.result?.variants[0]);
            setLoading(false);
        }
    }
    const sendQuotation = async () => {
        setQuotationLoader(true);
        const apiBody = {
            products: [{
                name: productData?.name,
                b2bPrice: activeVariant?.b2bPrice,
                b2cPrice: activeVariant?.b2cPrice,
                capacity: activeVariant?.capacity,
                unit: activeVariant?.unit,
                images: productData?.images,
                modelNumber: productData?.modelNumber,
                type: productType == "spare-parts" ? "spareParts" : productType,
                quantity: 1,
                brand: productData?.brand,
                gst: activeVariant?.gstPercentage,
                category: productData?.category,
                parameters: activeVariant?.parameters,
                productId:productData?._id
            }],
            type: productType == "spare-parts" ? "sparePartsQuotation" : "accessoriesQuotation"
        }
        console.log(">activeVariant",productData)
        const response = await sendQuotationFromCart(apiBody)
        if (response?.result?.type == "success") {
            setQuotationLoader(false)
            toast.success(response?.result?.message)
        } else {
            setQuotationLoader(false)
            toast.error(response?.result?.message)
        }
    }
    const getCoupons = async () => {
        const response = await getCouponsByType(productType == "spare-parts" ? "spareParts" : productType);
        if (response?.result) {
            setCouponData(response?.result)
        }
    }
    useEffect(() => {
        getProductData()
        getCoupons();
    }, [])
    return (
        <>
            <ToastContainer />
            <Header />
            {!loading ? <>
                <div className="flex max-[848px]:flex-col" style={{ height: window.innerWidth > 993 ? window.innerHeight / 1.05 : "100%" }}>
                    <div className="w-3/4 flex rounded max-[848px]:flex-col max-[848px]:w-full" style={{ backgroundColor: key.secondaryColor }}>
                        <ImageDisplay productData={productData} />
                        <ProductDescription activeVariant={activeVariant} productData={productData} setActiveVariant={setActiveVariant} productType={productType} />
                    </div>
                    <div className="w-1/4 px-3 max-[848px]:w-full" style={{ backgroundColor: key.secondaryColor, overflowY:"scroll" }}>
                        <Text content={`${window.localStorage.getItem("userRole") == "b2c" ? priceFormat(activeVariant?.b2cPrice + activeVariant?.b2cPrice * activeVariant?.gstPercentage / 100) : priceFormat(activeVariant?.b2bPrice + activeVariant?.b2bPrice * activeVariant?.gstPercentage / 100)} (Incl. of all taxes)`} className={"text-sm mt-8 max-[848px]:hidden"} style={{ fontFamily: key.fontFamily, color: "gray" }} />
                        <div className="my-3 flex flex-col items-start max-[848px]:hidden">
                            <Text content={window.localStorage.getItem("userRole") == "b2c" ? priceFormat(activeVariant?.b2cPrice) : priceFormat(activeVariant?.b2bPrice)} className={"text-3xl"} style={{ fontFamily: key.fontFamily, fontWeight: "bolder" }} />
                            <Text content={`+ ${priceFormat(window.localStorage.getItem("userRole") == "b2c" ? activeVariant?.b2cPrice * activeVariant?.gstPercentage / 100 : activeVariant?.b2bPrice * activeVariant?.gstPercentage / 100)} GST`} className={"text-sm mt-2"} style={{ fontFamily: key.fontFamily, color: "gray" }} />
                        </div>
                        <div className="mt-16 flex items-center flex-col justify-center gap-6 max-[848px]:mt-0 max-[848px]:my-7">
                            {showQuantity && cartData?.products && cartData?.products[productType == "spare-parts" ? "spareParts" : productType].filter((item) => item?.productId?._id == productData?._id && item?.variantId == activeVariant?._id)?.length < 1 ? <button
                                className="flex w-52 h-12 rounded items-center justify-center"
                                style={{ background: key.blue }}
                                onClick={async () => {
                                    await setShowQuantity(false)
                                    let productTypes = productType;
                                    if (productTypes == "spare-parts") {
                                        productTypes = "spareParts"
                                    }
                                    await updateCart(productTypes, 1, productData?._id, activeVariant?._id)
                                    await setShowQuantity(true)
                                }}
                            >
                                <CiShoppingCart
                                    className={"text-sm"}
                                    size={30}
                                    style={{ fontFamily: key.fontFamily, fontWeight: "700", color: "#fff" }}
                                />
                                <Button
                                    className={"text-sm ml-4"}
                                    buttonText={"Add to Cart"}
                                    style={{ fontFamily: key.fontFamily, fontWeight: "700", color: "#fff" }}
                                />
                            </button> :
                                <div className="w-52 h-12 rounded flex items-center justify-center" style={{ background: key.blue }}>
                                    <Button buttonText={"-"} className={"w-1/4 rounded-l h-12 flex items-center justify-center"} style={{ fontFamily: key.fontFamily, color: key.secondaryColor, fontWeight: "bold" }} onClick={async () => {
                                        await setShowQuantity(false)
                                        let productTypes = productType;
                                        if (productTypes == "spare-parts") {
                                            productTypes = "spareParts"
                                        }
                                        await updateCart(productTypes, cartData?.products[productType == "spare-parts" ? "spareParts" : productType].filter((item) => item?.productId?._id == productData?._id && item?.variantId == activeVariant?._id)[0]?.quantity - 1, productData?._id, activeVariant?._id)
                                        await setShowQuantity(true)
                                    }} />
                                    <Text className={"w-2/4 h-12 border-y flex items-center justify-center"} content={cartData?.products ? cartData?.products[productType == "spare-parts" ? "spareParts" : productType].filter((item) => item?.productId?._id == productData?._id && item?.variantId == activeVariant?._id)[0]?.quantity : 0} style={{ backgroundColor: key.secondaryColor, color: key.blue, fontWeight: "bold", fontFamily: key.fontFamily }} />
                                    <Button buttonText={"+"} className={"w-1/4 rounded-r h-12 flex items-center justify-center"} style={{ fontFamily: key.fontFamily, color: key.secondaryColor, fontWeight: "bold" }} onClick={async () => {
                                        await setShowQuantity(false)
                                        let productTypes = productType;
                                        if (productTypes == "spare-parts") {
                                            productTypes = "spareParts"
                                        }
                                        await updateCart(productTypes, cartData?.products[productType == "spare-parts" ? "spareParts" : productType].filter((item) => item?.productId?._id == productData?._id && item?.variantId == activeVariant?._id)[0]?.quantity + 1, productData?._id, activeVariant?._id)
                                        await setShowQuantity(true)
                                    }} />
                                </div>
                            }
                            <button
                                className="flex w-52 h-12 rounded items-center justify-center"
                                style={{ background: key.blue }}
                                onClick={() => { sendQuotation() }}
                            >
                                {!quotationLoader ? <>
                                    <IoDocumentTextOutline
                                        className={"text-sm"}
                                        size={23}
                                        style={{ fontFamily: key.fontFamily, fontWeight: "700", color: "#fff" }}
                                    />
                                    <Button
                                        className={"text-sm ml-4"}
                                        buttonText={"Get Quote"}
                                        style={{ fontFamily: key.fontFamily, fontWeight: "700", color: "#fff" }}
                                    />
                                </> : <Spinner size={"sm"} style={{ color: key.secondaryColor }} />}
                            </button>
                            <Text content={"(The quotation will be sent to your registered mail account with quotation number.)"}
                                className={"text-xs italic"} style={{ color: "gray", fontFamily: key.fontFamily }} />
                        </div>
                        <Coupons couponData={couponData}/>
                    </div>
                </div> </> : <Loader />}
            <Footer />
        </>
    )
}
export default Product;