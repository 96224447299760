import { ButtonBack, ButtonNext, CarouselProvider, Slide, Slider } from "pure-react-carousel";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { key as utilsKey } from "../../utils/consts";
import { ORDER_IMAGE_URL, ORDER_MATRIAL_IMAGE_URL, ORDER_PACKING_IMAGE_URL } from "../../Services/config";
import { FaGreaterThan, FaLessThan } from "react-icons/fa6";
import { LiaTimesSolid } from "react-icons/lia";


const ImageOpenModal = ({ openModal, setOpenModal, selectedImageIndex, imageData, typeOfImage }) => {
    return (
        <Modal isOpen={openModal} toggle={() => { setOpenModal(!openModal) }} size="lg">
            <ModalHeader style={{ display: "flex", justifyContent: "flex-end", border: "none" }}>
                <LiaTimesSolid size={15} onClick={() => { setOpenModal(false) }} />
            </ModalHeader>
            <ModalBody>
                <CarouselProvider
                    currentSlide={selectedImageIndex}
                    naturalSlideWidth={"100%"}
                    isIntrinsicHeight={"100"}
                    totalSlides={imageData?.length}
                    infinite={true}
                >
                    <Slider>
                        {imageData?.map((item, key) => {
                            return (
                                <Slide key={item?._id}>
                                    <div className="w-full flex items-center justify-center h-full" style={{ background: utilsKey.secondaryColor, borderColor: "#000" }}>
                                        <div style={{ width: "10%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                                            <ButtonBack><FaLessThan className="text-3xl max-[768px]:text-base" style={{ color: "gray", opacity: 0.6 }} /></ButtonBack>
                                        </div>
                                        <div style={{ width: "80%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                                            <img src={typeOfImage === "orderImage" ? ORDER_IMAGE_URL + item : typeOfImage === "packingImage" ? ORDER_PACKING_IMAGE_URL + item : ORDER_MATRIAL_IMAGE_URL + item} className="w-5/6" />
                                        </div>
                                        <div style={{ width: "10%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                                            <ButtonNext><FaGreaterThan className="text-3xl max-[768px]:text-base" style={{ color: "gray", opacity: 0.6 }} /></ButtonNext>
                                        </div>
                                    </div>
                                </Slide>
                            )
                        })}
                    </Slider>
                </CarouselProvider>
            </ModalBody>
        </Modal>
    )
}
export default ImageOpenModal;