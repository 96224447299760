import { useParams } from "react-router-dom";
import Footer from "../../components/Footer";
import Header from "../../components/Header/Header";
import { callGetPaymentBreakupData, callPostConfirmData, checkQuotationByNumber, getPaymentValuesData, getQuotationById } from "../../Services/OrderServices";
import { useEffect, useState } from "react";
import { key } from "../../utils/consts";
import Text from "../../atom/Text";
import ShippingAddress from "./ShippingAddress";
import ApplicableCoupons from "./ApplicableCoupons";
import BillAmount from "./BillAmount";
import { ToastContainer, toast } from "react-toastify";
import { useHistory, useLocation } from "react-router-dom";
import { ORDER_SUMMARY } from "../../utils/pathDetails";
import { validateEmail, validateGst, validatePhone, validatePincode } from "../../utils/validationRegex";
import { addDays, priceFormat } from "../../utils/priceFunctions";
import moment from "moment";
import Button from "../../atom/Button";
import BillingAddress from "./BillingAddress";
import { getUserDataService } from "../../Services/ProfileServices";
import { callGetNoteStatusData } from "../../Services/DataServices";

const OrderConfirm = () => {
    const { quotationNumber } = useParams();
    const location = useLocation();
    const history = useHistory();
    const [quotationData, setQuotationData] = useState([]);
    const [addressData, setAddressData] = useState({});
    const [billingAddressData, setBillingAddressData] = useState({});
    const [advancePaymentData, setAdvancePaymentData] = useState({});
    const [secondPaymentData, setSecondPaymentData] = useState({});
    const [thirdPaymentData, setThirdPaymentData] = useState({});
    const [delayChargesData, setDelayChargesData] = useState({});
    const [totalDaysData, setTotalDaysData] = useState({})
    const [quotationPaymentData, setQuotationPaymentData] = useState([]);
    const [companyName, setCompanyName] = useState("");
    const [gstNumber, setGstNumber] = useState("");
    const [showNote, setShowNote] = useState();
    const [noteData, setNoteData] = useState("");
    const getQuotationData = async () => {
        const response = await getQuotationById(quotationNumber, { data: location.state.data });
        if (response?.result) {
            setQuotationData(response?.result)
            const data = response?.result;
            data['price'] = data?.price - data?.totalSpareAndServicePrice;
            data['priceWithoutGst'] = data?.priceWithoutGst - data?.spareAndServicePriceWithoutGst;
            data['totalGstPrice'] = data?.totalGstPrice - data?.totalSpareAndServiceGst
            setQuotationPaymentData(data);
        }
    }
    const getPaymentBreakupData = async () => {
        const response = await callGetPaymentBreakupData();
        console.log(">>>>", response?.result)
        if (response?.result) {
            setAdvancePaymentData(response?.result?.filter((item) => item?.paymentType === "advance")[0])
            setSecondPaymentData(response?.result?.filter((item) => item?.paymentType === "second")[0])
            setThirdPaymentData(response?.result?.filter((item) => item?.paymentType === "third")[0])
            setDelayChargesData(response?.result?.filter((item) => item?.paymentType === "delayCharges")[0])
            setTotalDaysData(response?.result?.filter((item) => item?.paymentType === "totalDays")[0])
        }
    }
    const getDisplayNoteStatus = async () => {
        const response = await callGetNoteStatusData();
        if (response?.result) {
            setShowNote(response?.result?.filter((item) => item?.field == "showNote")[0])
            setNoteData(response?.result?.filter((item) => item?.field == "note")[0])
        }
    }
    const getPaymentValueData = async () => {
        const apiBody = { quotationId: quotationNumber }
        const response = await getPaymentValuesData(apiBody)
    }
    const getOrderData = async () => {
        const response = await callPostConfirmData(location.state.data);
        console.log("RESPONSE ===>", response?.result)
        if (response?.result) {
            setQuotationData(response?.result);
            const data = response?.result;
            data['price'] = data?.price - data?.totalSpareAndServicePrice;
            data['priceWithoutGst'] = data?.priceWithoutGst - data?.spareAndServicePriceWithoutGst;
            data['totalGstPrice'] = data?.totalGstPrice - data?.totalSpareAndServiceGst
            setQuotationPaymentData(data);
        }
    }
    const getUserGstNumber = async () => {
        const response = await getUserDataService();
        console.log("RESPONSE --->", response?.result)
        if (response?.result) {
            setCompanyName(response?.result?.companyName);
            setGstNumber(response?.result?.GSTNumber);
            billingAddressData['companyName'] = response?.result?.companyName;
            billingAddressData['gstNumber'] = response?.result?.GSTNumber;
        }
    }
    const onSubmit = () => {
        if (addressData?.houseNo && addressData?.address && addressData?.state && addressData?.city && addressData?.city != "none" && addressData?.pin && addressData?.mobile && billingAddressData?.houseNo && billingAddressData?.address && billingAddressData?.state && billingAddressData?.city && billingAddressData?.city != "none" && billingAddressData?.pin && billingAddressData?.mobile && billingAddressData?.email) {
            const validatePincodeValue = validatePincode(addressData?.pin);
            if (validatePincodeValue) {
                const validateMobile = validatePhone(addressData?.mobile);
                if (validateMobile) {
                    const validatePinBill = validatePincode(billingAddressData?.pin)
                    if (validatePinBill) {
                        const validateMobileBill = validatePhone(billingAddressData?.mobile);
                        if (validateMobileBill) {
                            const validateEmails = validateEmail(billingAddressData?.email);
                            if (validateEmails) {
                                // const validateGsts = validateGst(addressData?.gstNumber);
                                // if (validateGsts) {
                                history.push(ORDER_SUMMARY, { quotationData: quotationData, addressData: addressData, billingAddressData: billingAddressData, advancePaymentData: advancePaymentData, secondPaymentData: secondPaymentData, thirdPaymentData: thirdPaymentData, quotationPaymentData: quotationPaymentData })
                                // } else {
                                //     toast.error("Invalid GST Number!")
                                // }
                            } else {
                                toast.error("Invalid Email in Billing Address!")
                            }
                        } else {
                            toast.error("Invalid Mobile Number in Billing Address!")
                        }
                    } else {
                        toast.error("Invalid PIN in Billing Address!")
                    }
                } else {
                    toast.error("Invalid Mobile Number in Shipping Address!")
                }
            } else {
                toast.error("Invalid PIN Code in Shipping Address!")
            }
        } else {
            toast.error("Please Enter Address!")
        }
    }
    useEffect(() => {
        getOrderData();
        getDisplayNoteStatus();
        getPaymentBreakupData();
        getUserGstNumber();
    }, [])

    const data = [1, 2, 3, 4, 5, 2, 2]
    return (
        <>
            <ToastContainer />
            <Header />
            <Text content={"Confirm Order"} className="text-4xl m-4" style={{ fontFamily: key.fontFamily, fontWeight: "bold", background: key.primaryColor, WebkitBackgroundClip: "text", WebkitTextFillColor: "transparent" }} />
            <div className="flex p-4 gap-6 max-[837px]:flex-col">
                <div className="w-3/5 max-[883px]:w-3/5 max-[837px]:w-full">
                    <ShippingAddress addressData={addressData} />
                    <BillingAddress addressData={billingAddressData} shippingAddress={addressData} setAddressData={setBillingAddressData} companyName={companyName} gstNumber={gstNumber} />
                </div>
                <div className="w-2/5 flex flex-col max-[883px]:w-2/5 max-[837px]:w-full">
                    <BillAmount quotationData={quotationData} />
                    <div className="rounded-t my-6" style={{ backgroundColor: key.formBackground }}>
                        <div className="">
                            <Text className={"text-lg pl-3 py-2 rounded-t"} content={"Payment"} style={{ fontFamily: key.fontFamily, color: key.secondaryColor, backgroundColor: key.blue, fontWeight: "bold" }} />
                        </div>
                        <div className="px-2 pt-3">
                            <div className="flex w-full">
                                <Text content={"Payment Type"} className={"w-1/4 border-x-2 border-y-2 text-center text-sm py-2 rounded-tl"} style={{ fontFamily: key.fontFamily, fontWeight: "bold" }} />
                                <Text content={"Payment Date"} className={"w-1/4 border-r-2 border-y-2 text-center text-sm py-2"} style={{ fontFamily: key.fontFamily, fontWeight: "bold" }} />
                                <Text content={"Percentage"} className={"w-1/4 border-r-2 border-y-2 text-center text-sm py-2"} style={{ fontFamily: key.fontFamily, fontWeight: "bold" }} />
                                <Text content={"Value"} className={"w-1/4 border-r-2 border-y-2 text-center text-sm py-2 rounded-tr"} style={{ fontFamily: key.fontFamily, fontWeight: "bold" }} />
                            </div>
                            <div className="flex w-full">
                                <Text content={location.state.data?.products?.accessories?.length > 0 || location.state.data?.products?.plants?.length > 0 ? "Advance Payment" : "Total Payment"} className={"w-1/4 border-x-2 border-b-2 text-center text-xs py-2 "} style={{ fontFamily: key.fontFamily, fontWeight: 300 }} />
                                <Text content={`${moment(addDays(Date.now(), advancePaymentData?.days)).format("DD/MM/YYYY")}`} className={"w-1/4 border-r-2 border-b-2 text-center text-xs py-2"} style={{ fontFamily: key.fontFamily, fontWeight: 300 }} />
                                <Text content={location.state.data?.products?.accessories?.length > 0 || location.state.data?.products?.plants?.length > 0 ? advancePaymentData?.percentage + "%" : "100%"} className={"w-1/4 border-r-2 border-b-2 text-center text-xs py-2"} style={{ fontFamily: key.fontFamily, fontWeight: 300 }} />
                                <Text content={priceFormat(quotationPaymentData?.price * advancePaymentData?.percentage / 100 + quotationPaymentData?.totalSpareAndServicePrice)} className={"w-1/4 border-r-2 border-b-2 text-center text-xs py-2"} style={{ fontFamily: key.fontFamily, fontWeight: 300 }} />
                            </div>
                            {location.state.data?.products?.accessories?.length > 0 || location.state.data?.products?.plants?.length > 0 ?
                                <>
                                    <div className="flex w-full">
                                        <Text content={"Second Payment"} className={"w-1/4 border-x-2 border-b-2 text-center text-xs py-2 "} style={{ fontFamily: key.fontFamily, fontWeight: 300 }} />
                                        <Text content={`${moment(addDays(Date.now(), secondPaymentData?.days)).format("DD/MM/YYYY")}`} className={"w-1/4 border-r-2 border-b-2 text-center text-xs py-2"} style={{ fontFamily: key.fontFamily, fontWeight: 300 }} />
                                        <Text content={secondPaymentData?.percentage + "%"} className={"w-1/4 border-r-2 border-b-2 text-center text-xs py-2"} style={{ fontFamily: key.fontFamily, fontWeight: 300 }} />
                                        <Text content={priceFormat(quotationPaymentData?.price * secondPaymentData?.percentage / 100)} className={"w-1/4 border-r-2 border-b-2 text-center text-xs py-2"} style={{ fontFamily: key.fontFamily, fontWeight: 300 }} />
                                    </div>
                                    <div className="flex w-full">
                                        <Text content={"Third Payment"} className={"w-1/4 border-x-2 border-b-2 text-center text-xs py-2 "} style={{ fontFamily: key.fontFamily, fontWeight: 300 }} />
                                        <Text content={`${moment(addDays(Date.now(), thirdPaymentData?.days)).format("DD/MM/YYYY")}`} className={"w-1/4 border-r-2 border-b-2 text-center text-xs py-2"} style={{ fontFamily: key.fontFamily, fontWeight: 300 }} />
                                        <Text content={thirdPaymentData?.percentage + "%"} className={"w-1/4 border-r-2 border-b-2 text-center text-xs py-2"} style={{ fontFamily: key.fontFamily, fontWeight: 300 }} />
                                        <Text content={priceFormat(quotationPaymentData?.price * thirdPaymentData?.percentage / 100)} className={"w-1/4 border-r-2 border-b-2 text-center text-xs py-2"} style={{ fontFamily: key.fontFamily, fontWeight: 300 }} />
                                    </div>
                                    <div className="flex mt-4 w-full">
                                        <Text content={`Note : Orders placed and paid today will have a material pickup date between ${moment(addDays(Date.now(), totalDaysData?.days + 1)).format("DD/MM/YYYY")} and ${moment(addDays(Date.now(), delayChargesData?.startingDay - 1)).format("DD/MM/YYYY")}`} className={" text-xs"} style={{ fontFamily: key.fontFamily, color: "red" }} />
                                    </div>
                                    <div className="flex mt-2 w-full">
                                        <Text content={`Note : In the case of spare parts and services, the entirety of their prices shall be factored into the advance payment.`} className={" text-xs"} style={{ fontFamily: key.fontFamily, color: "red" }} />
                                    </div>
                                </> : <></>}
                        </div>
                        <Button buttonText={"Continue"} className={"w-full h-10 rounded-b mt-4"} style={{ fontFamily: key.fontFamily, color: key.secondaryColor, backgroundColor: key.blue }} onClick={() => {
                                onSubmit()
                        }} />
                        {showNote?.content == "true" ? <div className="w-full flex justify-end" style={{backgroundColor:"#fff"}}>
                            <Text content={noteData?.content} className={"w-full text-sm mt-2"} style={{ fontFamily: key.fontFamily, color: "#f44336" }} />
                        </div> : <></>}
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}
export default OrderConfirm;