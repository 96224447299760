import Image from "../atom/Image";
import Text from "../atom/Text";
import { key } from "../utils/consts";

const TitleWithLogo = ({ title, description }) => (
  <div className="flex items-center justify-center self-center w-fit max-[1032px]:flex-col">
    <Image src={"./images/logo-light.png"} className={"w-1/3"}/>
    <div className="flex flex-col ml-6 max-[1032px]:mt-6 ml-0">
      <Text
        {...{
          content: title,
          className: "text-3xl font-bold",
          style: { fontFamily: key.fontFamily, color: key.blue },
        }}
      />
      <Text
        {...{
          content: description,
          className: "text-xl",
          style: { fontFamily: key.fontFamily, color: key.blue },
        }}
      />
    </div>
  </div>
);

export default TitleWithLogo;
