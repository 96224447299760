import { BASE_URL, getTokenData } from "./config";

export const getProductTypeTotal = async (apiBody) => {
    try {
        const response = await fetch(BASE_URL + "carts/get/total", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: getTokenData(),
            },
            body: JSON.stringify(apiBody)
        });
        const result = await response.json();
        return { result };
    } catch (error) {
        return { status: false, message: "Something went wrong" };
    }
};

export const callCalculateDiscountData = async (apiBody) => {
    try {
        const response = await fetch(BASE_URL + "coupons/apply", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: getTokenData(),
            },
            body: JSON.stringify(apiBody)
        });
        const result = await response.json();
        return { result };
    } catch (error) {
        return { status: false, message: "Something went wrong" };
    }
}