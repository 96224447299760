import { useContext, useEffect, useState } from "react";
import { IMAGE_URL } from "../Services/config";
import Button from "../atom/Button";
import Text from "../atom/Text";
import ProductModal from "../components/Product/ProductModal";
import { key } from "../utils/consts";
import { PRODUCT } from "../utils/pathDetails"
import { getCartDataService } from "../Services/CartServices";
import { CartContext } from "../pages/ShoppingCart/CartContext";


let hi = "productData.name text-base ml-3 mt-3 productData.name text-base ml-3 mt-3 productData.name text-base ml-3 mt-3"
let hi2 = "productData.name text-base ml-3 mt-3 productData.name text-base ml-3 mt-3 productData.name text-base ml-3 mt-3"

const Product = ({ productData, setCartCount, productType }) => {
    const { updateCart, cartData } = useContext(CartContext);
    const [productModal, setProductModal] = useState(false);
    const [showQuantity, setShowQuantity] = useState(true);
    const [title, setTitle] = useState('');
    const [mouseEnter, setMouseEnter] = useState(false);
    const [subTitle, setSubTitle] = useState('');

    useEffect(() => {
        setTitle(productData.name)
        if (productData?.name?.length > 25) {
            console.log(">Large string")
            setTitle(productData.name.slice(0, 25) + "...")
        }
        setSubTitle(productData?.brand)
        if (productData?.brand?.length > 25) {
            setTitle(productData.brand.slice(0, 25) + "...")
        }
    }, [])
    return (
        <>
            <ProductModal openModal={productModal} setOpenModal={setProductModal} productData={productData} />
            <div className="border-x border-t rounded mt-8" onMouseEnter={() => { setMouseEnter(true) }} onMouseLeave={() => { setMouseEnter(false) }} style={{ borderColor: "#000", boxShadow: mouseEnter ? key.formShadow : null, transition: "0.5s" }}>
                <div className="w-full rounded pt-2 flex flex-col items-start justify-center h-9/12" style={{ backgroundColor: key.secondaryColor }} onClick={async () => {
                    window.open(PRODUCT + `${productType == "spareParts" ? "spare-parts" : "accessories"}/` + productData?._id)
                }}>
                    <div className="w-full flex justify-center px-3" style={{height:120}}>
                        <img src={`${IMAGE_URL}/${productData.images[0]}`} style={{ userDrag: "none",height:'100%' }} draggable={false} onMouseDown={() => { return false }} className="" />
                    </div>
                    <div style={{height:"45%"}}>
                        <Text content={title} className="text-base ml-3 mt-3" style={{ fontFamily: key.fontFamily, fontWeight: "bold" }} />
                        <Text content={`Brand : ${subTitle}`} className="text-base ml-3 my-2 text-sm" style={{ fontFamily: key.fontFamily }} />
                        {productType === 'spareParts' ? <Text content={`Model No. : ${productData?.modelNumber}`} className="text-base ml-3 my-1 text-sm" style={{ fontFamily: key.fontFamily }} /> : <></>}
                    </div>
                </div>
                <div className="w-full rounded-b flex items-center justify-center h-3/12" style={{ background: key.blue }}>
                    {showQuantity && cartData?.products && cartData?.products[productType].filter((item) => item?.productId?._id == productData?._id)?.length < 1 ? <Button
                        buttonText={"ADD TO CART"}
                        onClick={async () => {
                            await setShowQuantity(false)
                            await updateCart(productType, 1, productData?._id, productData?.variants[0]?._id)
                            await setShowQuantity(true)
                        }}
                        className="w-1/2 h-10 rounded-l text-xs flex justify-center items-center border-r"
                        style={{ fontFamily: key.fontFamily, color: "#fff", fontWeight: "bold", borderColor: "#fff" }}
                    /> :
                        <div className="w-1/2 h-10 rounded-l border-r flex justify-center items-center">
                            <Button buttonText={"-"} className={'w-1/4 rounded h-10 flex items-center justify-center'} onClick={async () => {
                                await setShowQuantity(false)
                                await updateCart(productType, cartData?.products[productType].filter((item) => item?.productId?._id == productData?._id)[0]?.quantity - 1, productData?._id, productData?.variants[0]?._id)
                                await setShowQuantity(true)
                            }} style={{ backgroundColor: key.blue, color: key.secondaryColor, fontFamily: key.fontFamily }} />
                            <Text content={cartData?.products ? cartData?.products[productType].filter((item) => item?.productId?._id == productData?._id)[0]?.quantity : 0} className={"w-2/4 text-center text-xs h-10 flex items-center justify-center border-y"} style={{ fontFamily: key.fontFamily, color: key.blue, backgroundColor: key.secondaryColor, fontWeight: 'bold' }} />
                            <Button buttonText={"+"} className={'w-1/4 rounded h-10 flex items-center justify-center'} onClick={async () => {
                                await setShowQuantity(false)
                                await updateCart(productType, cartData?.products[productType].filter((item) => item?.productId?._id == productData?._id)[0]?.quantity + 1, productData?._id, productData?.variants[0]?._id)
                                await setShowQuantity(true)
                            }} style={{ backgroundColor: key.blue, color: key.secondaryColor, fontFamily: key.fontFamily }} />
                        </div>}
                    <Button
                        onClick={async () => {
                            window.open(PRODUCT + `${productType == "spareParts" ? "spare-parts" : "accessories"}/` + productData?._id)
                        }}
                        buttonText={"GET QUOTE"}
                        className="w-1/2 h-10 rounded-r text-xs flex justify-center items-center"
                        style={{ fontFamily: key.fontFamily, color: "#fff", fontWeight: "bold" }}
                    />
                </div>
            </div>
        </>
    )
}
export default Product;