import Header from '../../components/Header/Header';
import Footer from '../../components/Footer';
import { useLocation, useHistory } from 'react-router-dom';
import { useContext, useEffect, useMemo, useState } from 'react';
import { BANK_DETAILS, HOME, ORDER_SUCCESS } from '../../utils/pathDetails';
import { key } from '../../utils/consts';
import Text from '../../atom/Text';
import { IoIosArrowDroprightCircle } from 'react-icons/io';
import { IMAGE_URL } from '../../Services/config';
import { priceFormat } from '../../utils/priceFunctions';
import { Card, CardBody, Collapse, Spinner } from 'reactstrap';
import TableContainer from '../../components/TableContainer';
import Products from './Products';
import { Button } from 'reactstrap';
import { callPostPlaceOrder } from '../../Services/OrderServices';
import { CartContext } from '../ShoppingCart/CartContext';
import UploadPurchaseOrder from './UploadPurchaseOrder';
import { UploadPurchaseOrderPdf, callGetNoteStatusData } from '../../Services/DataServices';


const OrderSummary = () => {
    const { getCartCount } = useContext(CartContext);
    const location = useLocation();
    const history = useHistory();
    const [isOpen, setIsOpen] = useState(true);
    const [loading, setLoading] = useState(false);
    const [showShippingAddress, setShowShippingAddress] = useState(true);
    const [showBillingAddress, setShowBillingAddress] = useState(true);
    const [fileData, setFileData] = useState({});
    const [fileChoose, setFileChoose] = useState(false);

    const onUpload = async (id) => {
        const formDataUpload = new FormData();
        await formDataUpload.append('assets', fileData, `${id}.pdf`);
        const uploadImageResponse = await UploadPurchaseOrderPdf(formDataUpload);
        return uploadImageResponse
    }
    const onPlaceOrder = async () => {
        setLoading(true)
        const apiBody = await {
            products: location?.state?.quotationData?.products,
            addressData: location?.state?.addressData,
            billingAddress: location?.state?.billingAddressData,
            purchaseOrderUpload: fileChoose,
            discountPrice: location?.state?.quotationData?.discountPrice
        }
        const response = await callPostPlaceOrder(apiBody);
        if (response?.result) {
            if (fileChoose === true) {
                const uploadImageResponse = await onUpload(response?.result?._id)
                setLoading(false)
                await getCartCount();
                if (uploadImageResponse?.result?.status === true) {
                    // history.push(ORDER_SUCCESS, { orderNumber: response?.result?._id })
                    history.push(BANK_DETAILS, { orderNumber: response?.result?._id, advancePayment: response?.result?.paymentBreakup?.advancePayment?.totalPrice, orderDate: response?.result?.createdAt })
                }
            } else {
                setLoading(false)
                await getCartCount();
                // history.push(ORDER_SUCCESS, { orderNumber: response?.result?._id })
                history.push(BANK_DETAILS, { orderNumber: response?.result?._id, advancePayment: response?.result?.paymentBreakup?.advancePayment?.totalPrice, orderDate: response?.result?.createdAt })
            }
        } else {
            setLoading(false)
        }
    }

    useEffect(() => {
        if (location?.state?.addressData && location?.state?.quotationData) {
            setTimeout(() => {
                setShowBillingAddress(false)
                setShowShippingAddress(false);
            }, 5000)
        } else {
            history.push(HOME)
        }
    }, [])

    return (
        <>
            <Header />
            <Text content={"Order Summary"} className="text-4xl m-4" style={{ fontFamily: key.fontFamily, fontWeight: "bold", background: key.primaryColor, WebkitBackgroundClip: "text", WebkitTextFillColor: "transparent" }} />
            <div className='flex m-4 gap-6 max-[800px]:flex-col max-[800px]:items-center max-[800px]:justify-center'>
                <Products quotationData={location?.state?.quotationData} />
                <div className='w-1/3 h-fit rounded-t max-[800px]:w-3/5 max-[544px]:w-4/5 max-[419px]:w-full' style={{ backgroundColor: key.formBackground }}>
                    <Text content={"Bill Summary"} className={"py-3 pl-2 border-b text-xl rounded-t"} style={{ fontFamily: key.fontFamily, color: key.secondaryColor, backgroundColor: key.blue, fontWeight: "bold" }} />
                    <div className='flex items-center border-b-2 mb-3 pb-2 pt-3 pl-2' onClick={() => { setShowShippingAddress(!showShippingAddress) }}>
                        <Text content={"Shipping Details"} className={"w-full"} style={{ fontFamily: key.fontFamily, color: key.blue, fontWeight: "bold" }} />
                        <IoIosArrowDroprightCircle size={23} color={key.blue} className='mr-3' style={{ rotate: `${showShippingAddress ? "90deg" : ""}` }} />
                    </div>
                    <Collapse isOpen={showShippingAddress}>
                        <CardBody>
                            <div className='pl-2'>
                                <Text content={"Address"} className={"text-sm"} style={{ fontFamily: key.fontFamily, color: key.blue, fontWeight: "bold" }} />
                                <Text content={location?.state?.addressData?.houseNo + ", " + location?.state?.addressData?.address + ", " + location?.state?.addressData?.city + ", " + location?.state?.addressData?.state + ", " + "India - " + location?.state?.addressData?.pin}
                                    className={"my-2 text-sm italic"}
                                    style={{ fontFamily: key.fontFamily, color: "#000", opacity: "0.7" }}
                                />
                            </div>
                            <div className='flex gap-4 border-b-2'>
                                <div className='pt-3 pl-2'>
                                    <Text content={"Mobile No."} className={"text-sm"} style={{ fontFamily: key.fontFamily, color: key.blue, fontWeight: "bold" }} />
                                    <Text content={location?.state?.addressData?.mobile}
                                        className={"my-2 text-sm italic"}
                                        style={{ fontFamily: key.fontFamily, color: "#000", opacity: "0.7" }}
                                    />
                                </div>
                                {location?.state?.addressData?.alternateMobile ? <div className='pt-3 pl-2'>
                                    <Text content={"Alternative Mobile No."} className={"text-sm"} style={{ fontFamily: key.fontFamily, color: key.blue, fontWeight: "bold" }} />
                                    <Text content={location?.state?.addressData?.alternateMobile}
                                        className={"my-2 text-sm italic"}
                                        style={{ fontFamily: key.fontFamily, color: "#000", opacity: "0.7" }}
                                    />
                                </div> : <></>}
                            </div>
                        </CardBody>
                    </Collapse>
                    <div className='flex items-center border-b-2 mb-3 pb-2 pt-3 pl-2' onClick={() => { setShowBillingAddress(!showBillingAddress) }}>
                        <Text content={"Billing Details"} className={"w-full"} style={{ fontFamily: key.fontFamily, color: key.blue, fontWeight: "bold" }} />
                        <IoIosArrowDroprightCircle size={23} color={key.blue} className='mr-3' style={{ rotate: `${showBillingAddress ? "90deg" : ""}` }} />
                    </div>
                    <Collapse isOpen={showBillingAddress}>
                        <CardBody>
                            <div className='pl-2'>
                                {/* <Text content={"Billing Details"} className={"mb-3 border-b-2 pb-2"} style={{ fontFamily: key.fontFamily, color: key.blue, fontWeight: "bold" }} /> */}
                                <Text content={"Address"} className={"text-sm"} style={{ fontFamily: key.fontFamily, color: key.blue, fontWeight: "bold" }} />
                                <Text content={location?.state?.billingAddressData?.houseNo + ", " + location?.state?.billingAddressData?.address + ", " + location?.state?.billingAddressData?.city + ", " + location?.state?.billingAddressData?.state + ", " + "India - " + location?.state?.billingAddressData?.pin}
                                    className={"my-2 text-sm italic"}
                                    style={{ fontFamily: key.fontFamily, color: "#000", opacity: "0.7" }}
                                />
                            </div>
                            <div className='flex gap-4'>
                                <div className='pt-3 pl-2'>
                                    <Text content={"Mobile No."} className={"text-sm"} style={{ fontFamily: key.fontFamily, color: key.blue, fontWeight: "bold" }} />
                                    <Text content={location?.state?.billingAddressData?.mobile}
                                        className={"my-2 text-sm italic"}
                                        style={{ fontFamily: key.fontFamily, color: "#000", opacity: "0.7" }}
                                    />
                                </div>
                                {location?.state?.billingAddressData?.alternateMobile ? <div className='pt-3 pl-2'>
                                    <Text content={"Alternative Mobile No."} className={"text-sm"} style={{ fontFamily: key.fontFamily, color: key.blue, fontWeight: "bold" }} />
                                    <Text content={location?.state?.billingAddressData?.alternateMobile}
                                        className={"my-2 text-sm italic"}
                                        style={{ fontFamily: key.fontFamily, color: "#000", opacity: "0.7" }}
                                    />
                                </div> : <></>}
                            </div>
                            <div className='flex gap-4 border-b-2'>
                                <div className='pt-3 pl-2'>
                                    <Text content={"Email"} className={"text-sm"} style={{ fontFamily: key.fontFamily, color: key.blue, fontWeight: "bold" }} />
                                    <Text content={location?.state?.billingAddressData?.email}
                                        className={"my-2 text-sm italic"}
                                        style={{ fontFamily: key.fontFamily, color: "#000", opacity: "0.7" }}
                                    />
                                </div>
                                <div className='pt-3 pl-2'>
                                    <Text content={"GST Number"} className={"text-sm"} style={{ fontFamily: key.fontFamily, color: key.blue, fontWeight: "bold" }} />
                                    <Text content={location?.state?.billingAddressData?.gstNumber}
                                        className={"my-2 text-sm italic"}
                                        style={{ fontFamily: key.fontFamily, color: "#000", opacity: "0.7" }}
                                    />
                                </div>
                            </div>
                        </CardBody>
                    </Collapse>
                    <div className='mt-4 pl-2'>
                        <Text content={"Amount"} className={""} style={{ fontFamily: key.fontFamily, color: key.blue, fontWeight: "bold" }} />
                        <Text content={`${priceFormat(location?.state?.quotationPaymentData?.price * location?.state?.advancePaymentData?.percentage / 100 + location?.state?.quotationPaymentData?.totalSpareAndServicePrice)} (Incl. of all taxes)`} className={"text-sm mt-3 max-[848px]:hidden"} style={{ fontFamily: key.fontFamily, color: "#000", opacity: 0.7 }} />
                        <div className="mt-2 mb-3 flex flex-col items-start max-[848px]:hidden">
                            <Text content={priceFormat(location?.state?.quotationPaymentData?.priceWithoutGst * location?.state?.advancePaymentData?.percentage / 100 + location?.state?.quotationPaymentData?.spareAndServicePriceWithoutGst)} className={"text-lg"} style={{ fontFamily: key.fontFamily, fontWeight: "bolder" }} />
                            <Text content={`+ ${priceFormat(location?.state?.quotationPaymentData?.totalGstPrice * location?.state?.advancePaymentData?.percentage / 100 + location?.state?.quotationPaymentData?.totalSpareAndServiceGst)} GST`} className={"text-sm mt-2"} style={{ fontFamily: key.fontFamily, color: "#000", opacity: 0.7 }} />
                        </div>
                    </div>
                    <Button className={"w-full h-10 mt-3"} disabled={loading ? true : false} style={{ fontFamily: key.fontFamily, backgroundColor: key.blue, color: key.secondaryColor, border: "none", borderTopLeftRadius: 0, borderTopRightRadius: 0, fontWeight: "bold" }} onClick={async () => {
                        await onPlaceOrder()
                    }}>
                        {!loading ? "Proceed to Payment" :
                            <Spinner size={"sm"} />}
                    </Button>
                    <div className='h-4' style={{ backgroundColor: key.secondaryColor }} />
                    <UploadPurchaseOrder fileData={fileData} setFileData={setFileData} fileChoose={fileChoose} setFileChoose={setFileChoose} />
                </div>
            </div >
            <Footer />
        </>
    )
}
export default OrderSummary;