import { FaShoppingCart } from "react-icons/fa";
import { key } from "../../utils/consts";
import { useHistory } from "react-router-dom";
import { SHOPPING_CART } from "../../utils/pathDetails";
import { useContext, useEffect, useState } from "react";
import { CartContext } from "../../pages/ShoppingCart/CartContext";

const ShoppingCart = ({ theme }) => {
    const history = useHistory();
    const { cartCount } = useContext(CartContext)
    const [mouseEntered, setMouseEntered] = useState(false);
    return (
        <button className={`flex justify-center py-2 px-2 ${mouseEntered ? "border-1 rounded" : ""}`} onMouseEnter={()=>{setMouseEntered(true)}} onMouseLeave={()=>{setMouseEntered(false)}} style={{ borderColor: key.blue }} onClick={() => {
            history.push(SHOPPING_CART)
        }}>
            <FaShoppingCart
                style={{
                    width: "1.5em",
                    height: "1.5em",
                    color: theme == "light" ? "#000" : "#fff"
                }} />
            <sup className={"text-xs px-1 rounded"} style={{ backgroundColor: "red", fontFamily: key.fontFamily, color: "#fff", fontWeight: "bold" }}>{cartCount || 0}</sup>
        </button>
    )
}
export default ShoppingCart;