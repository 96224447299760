import { LiaTimesSolid } from "react-icons/lia";
import Text from "../../atom/Text";
import { key } from "../../utils/consts";
import Button from "../../atom/Button";
import { CiShoppingCart } from "react-icons/ci";
import { PiBagThin } from "react-icons/pi";
import Specifications from "./Specifications";
import { addToCartService } from "../../Services/CartServices";
import { useContext, useState } from "react";
import { CartContext } from "../../pages/ShoppingCart/CartContext";

const ProductDescription = ({ productData, setOpenModal }) => {
    const { getCartCount, cartData } = useContext(CartContext);
    const onAddToCart = async (type) => {
        let selectedProduct = []
        let apiBody
        if (cartData?.products.length == 0) {
            apiBody = { productId: productData?._id, quantity: 1 }
        } else {
            selectedProduct = cartData?.products.filter((item) => item?.productId?._id == productData?._id);
            if (selectedProduct.length > 0) {
                apiBody = { productId: productData?._id, quantity: type == 'plus' ? selectedProduct[0]?.quantity + 1 : selectedProduct[0]?.quantity - 1 };
            } else {
                apiBody = { productId: productData?._id, quantity: 1 }
            }
        }
        const response = await addToCartService(apiBody);
        await getCartCount()
    }
    return (
        <div className="w-1/2 pb-10 max-[992px]:w-full max-[992px]:pt-6 rounded-r-xl" style={{ backgroundColor: "#E6E6E6", height: window.innerWidth > 993 ? window.innerHeight / 1.1 : "100%",overflowY:"auto" }}>
            <div className="flex justify-end w-full mt-3 max-[992px]:hidden">
                <LiaTimesSolid size={18} className={"mr-3"} style={{ border: "none", opacity: "0.7" }} onClick={() => { (setOpenModal(false)) }} />
            </div>
            <Specifications productData={productData} />
            <div className="flex items-start justify-center gap-3 mx-6 mt-10 max-[486px]:flex-col max-[486px]:items-center">
                <button className="flex w-52 h-12 rounded items-center justify-center" style={{ background: key.primaryColor }}>
                    <PiBagThin
                        className={"text-sm"}
                        size={28}
                        style={{ fontFamily: key.fontFamily, fontWeight: "700", color: "#fff" }}
                    />
                    <Button
                        className={"text-sm ml-4"}
                        buttonText={"Order Now"}
                        style={{ fontFamily: key.fontFamily, fontWeight: "700", color: "#fff" }}
                    />
                </button>
                {/* {cartData?.products && cartData?.products.filter((item) => item?.productId?._id === productData?._id).length < 1 ? */}
                    <button
                        className="flex w-52 h-12 rounded items-center justify-center"
                        style={{ background: key.primaryColor }}
                        onClick={() => { onAddToCart("plus") }}
                    >
                        <CiShoppingCart
                            className={"text-sm"}
                            size={30}
                            style={{ fontFamily: key.fontFamily, fontWeight: "700", color: "#fff" }}
                        />
                        <Button
                            className={"text-sm ml-4"}
                            buttonText={"Add to Cart"}
                            style={{ fontFamily: key.fontFamily, fontWeight: "700", color: "#fff" }}
                        />
                    </button> 
                    {/* :
                    <div className="flex w-52 h-12">
                        <Button buttonText={"-"} className={"w-1/5 rounded text-center text-xl"} style={{ background: key.primaryColor, fontFamily: key.fontFamily, fontWeight: "bold", color: "#fff" }} onClick={() => { onAddToCart("minus") }} />
                        <Text content=
                            {
                                cartData?.products.filter((item) => item?.productId?._id === productData?._id).length > 0 ?
                                    parseInt(cartData?.products.filter((item) => item?.productId?._id == productData?._id)[0]?.quantity) :
                                    0
                            }
                            className={"w-3/5 text-center text-xl border-y flex items-center justify-center"}
                            style={{ background: "#fff", color: "#184295", fontFamily: key.fontFamily, borderColor: "#d3d3d3" }} />
                        <Button buttonText={"+"} className={"w-1/5 rounded text-center text-xl"} style={{ background: key.primaryColor, fontFamily: key.fontFamily, fontWeight: "bold", color: "#fff" }} onClick={() => { onAddToCart("plus") }} />
                    </div>} */}

            </div>
        </div>
    )
}
export default ProductDescription;