import { BrowserRouter } from 'react-router-dom';
import Route from './Routes';
import { CartProvider } from './pages/ShoppingCart/CartContext';
import ScrollToTop from './components/ScrollToTop/ScrollToTop';


function App() {
  return (
    <BrowserRouter>
      <ScrollToTop />
      <CartProvider>
        <Route />
      </CartProvider>
    </BrowserRouter>
  );
}

export default App;