import { useState } from "react"
import CouponKnowMoreModal from "../ShoppingCart/CouponKnowMoreModal"
import SelectProductTypeModal from "./SelectProductTypeModal"
import { MdDiscount } from "react-icons/md";
import Text from "../../atom/Text";
import { key } from "../../utils/consts";
import { BiSolidOffer } from "react-icons/bi";
import { priceFormat } from "../../utils/priceFunctions";
import { getProductTypeTotal } from "../../Services/QuotationServices";
import { callCalculateDiscountData } from "../../Services/CouponServices";
import { toast } from "react-toastify";
import { calculatePriceDiscount } from "../../Services/CartServices";

const Coupons = ({ couponData, setShowData, unChangedData, setData, data, setTotalDiscountPrice, setGstDiscountPrice, setProductDiscountPrice, setDiscount }) => {
    const [selectTypeModal, setSelectTypeModal] = useState(false);
    const [knowMoreModal, setKnowMoreModal] = useState(false);
    const [selectedData, setSelectedData] = useState({});
    const [selectedCouponIndex, setSelectedCouponIndex] = useState();
    const [productType, setProductType] = useState();
    const [couponProductTypes, setCouponProductTypes] = useState([]);
    const [modalCouponIndex, setModalCouponIndex] = useState();

    const couponText = (coupon) => {
        if (coupon?.type === "both") {
            return `Use code ${coupon?.code?.toUpperCase()} and get ${coupon?.discountPercentage}% Offer on orders above ${priceFormat(coupon?.lowerLimit)}. Maximum discount ${priceFormat(coupon?.discountAmount)}.`
        } else if (coupon?.type === "flat") {
            return `Use code ${coupon?.code?.toUpperCase()} and get flat discount of ${priceFormat(coupon?.discountAmount)} on orders above ${priceFormat(coupon?.lowerLimit)}.`
        } else if (coupon?.type === "percentage") {
            return `Use code ${coupon?.code?.toUpperCase()} and get ${coupon?.discountPercentage}% discount on orders above ${priceFormat(coupon?.lowerLimit)}.`
        }
    }
    const selectProductType = (coupon, couponIndex) => {
        if (coupon?.productCategories?.length < 2) {
            setProductType(coupon?.productCategories[0]);
            getDiscountData(coupon?._id, coupon?.productCategories[0])
            setSelectedCouponIndex(couponIndex);
        } else {
            let productTypeData = [];
            coupon?.productCategories?.map((item) => {
                if (data?.products[item]?.length > 0) {
                    productTypeData = [...productTypeData, item];
                }
            })
            if (productTypeData?.length < 2) {
                setProductType(productTypeData[0])
                getDiscountData(coupon?._id, productTypeData[0])
                setSelectedCouponIndex(couponIndex);
            } else {
                setCouponProductTypes(productTypeData);
                setModalCouponIndex(couponIndex);
                setSelectTypeModal(true)
            }
        }
    }
    const getDiscountData = async (couponId, productType) => {
        const productTypeTotalResponse = await getProductTypeTotal({ data: data, productType: productType });
        console.log("productTypeTotalResponse ===>", productTypeTotalResponse?.result)
        const apiBody = {
            couponId: couponId,
            appliedFrom: "cart",
            applyTo: productType,
            products: unChangedData?.products,
            totalProductsPrice: productTypeTotalResponse?.result?.totalPrice
        }
        const response = await callCalculateDiscountData(apiBody);
        console.log("discout res--->", response?.result)
        if (response?.result) {
            if (response?.result?.message) {
                toast.error(response?.result?.message);
                setSelectedCouponIndex();
            } else {
                setShowData(false)
                setDiscount(response?.result?.discount)
                const calculatePricing = await calculatePriceDiscount({ cartData: response?.result })
                if (calculatePricing?.result) {
                    setTotalDiscountPrice(calculatePricing?.result?.totalPrice)
                    setGstDiscountPrice(calculatePricing?.result?.gstPrice)
                    setProductDiscountPrice(calculatePricing?.result?.productPrice);
                }
                setData(response?.result);
                setShowData(true);
            }
        }
    }
    return (
        <>
            <SelectProductTypeModal openModal={selectTypeModal} setOpenModal={setSelectTypeModal} couponData={selectedData} couponProductTypes={couponProductTypes} setSelectedProductType={setProductType} couponIndex={modalCouponIndex} setSelectedCouponIndex={setSelectedCouponIndex} getDiscountData={getDiscountData} />
            <CouponKnowMoreModal openModal={knowMoreModal} setOpenModal={setKnowMoreModal} couponData={selectedData} />
            <div className='border-2 rounded-b' style={{ backgroundColor: key.formBackground }}>
                <div className='pl-3 py-2 rounded-t flex items-center gap-1' style={{ backgroundColor: key.blue, }}>
                    <Text content={"Offers & Coupons"} className={"text-sm"} style={{ fontFamily: key.fontFamily, color: key.secondaryColor, fontWeight: "bold" }} />
                    <MdDiscount size={15} style={{ color: key.secondaryColor }} />
                </div>
                <div className='' style={{ overflowY: "auto", height: "270px" }}>
                    {couponData ? couponData?.map((item, index) => {
                        return (
                            selectedCouponIndex != index ?
                                <>
                                    <div key={index} className='pl-3 mx-3 my-3 shadow-md rounded' style={{ backgroundColor: key.secondaryColor, opacity: !item?.isApplicable ? "0.6" : 1 }}>
                                        <div className='py-1.5 flex w-full items-center'>
                                            <BiSolidOffer size={26} style={{ color: key.blue }} />
                                            <Text content={item?.code?.toUpperCase()} className={"ml-1 text-sm w-full"} style={{ fontFamily: key.fontFamily, fontWeight: "bold" }} />
                                            <button disabled={!item?.isApplicable ? true : false} onClick={async () => {
                                                setSelectedData(item)
                                                selectProductType(item, index)
                                            }}>
                                                <Text content={"Apply"} className={"text-xs mr-3"} style={{ fontFamily: key.fontFamily, fontWeight: "bold", color: key.blue }} />
                                            </button>
                                        </div>
                                        <Text content={couponText(item)} className={"text-xs w-full mt-2 pr-2"} style={{ fontFamily: key.fontFamily, opacity: "0.7" }} />
                                        <button onClick={() => {
                                            setSelectedData(item);
                                            setKnowMoreModal(true);
                                        }}>
                                            <Text content={"Know More"} className={"text-xs pb-2.5"} style={{ fontFamily: key.fontFamily, color: key.blue }} />
                                        </button>
                                    </div>
                                </> :

                                <>
                                    <div key={index} className='mx-3 my-3 shadow-md rounded' style={{ backgroundColor: key.blue, opacity: !item?.isApplicable ? "0.6" : 1 }}>
                                        <div className='py-1.5 flex w-full items-center pl-3 rounded-t' style={{ backgroundColor: key.secondaryColor }}>
                                            <BiSolidOffer size={26} style={{ color: key.blue }} />
                                            <Text content={item?.code?.toUpperCase()} className={"ml-1 text-sm w-full"} style={{ fontFamily: key.fontFamily, fontWeight: "bold" }} />
                                            <button disabled={!item?.isApplicable ? true : false} onClick={async () => {
                                                setSelectedCouponIndex();
                                                setData(unChangedData);
                                                setTotalDiscountPrice()
                                                setGstDiscountPrice()
                                                setDiscount();
                                                setProductDiscountPrice();
                                            }}>
                                                <Text content={"Remove"} className={"text-xs mr-3"} style={{ fontFamily: key.fontFamily, fontWeight: "bold", color: "red" }} />
                                            </button>
                                        </div>
                                        <Text content={couponText(item)} className={"text-xs w-full mt-2 pr-2 pl-3"} style={{ fontFamily: key.fontFamily, opacity: "0.7", color: key.secondaryColor }} />
                                        <button className="pl-3" onClick={() => {
                                            setSelectedData(item);
                                            setKnowMoreModal(true);
                                        }}>
                                            <Text content={"Know More"} className={"text-xs pb-2.5"} style={{ fontFamily: key.fontFamily, color: key.secondaryColor }} />
                                        </button>
                                    </div>
                                </>
                        )
                    }) : <></>}
                </div>
            </div>
        </>
    )
}
export default Coupons;