import { BASE_URL, getTokenData } from "./config";

export const getVideoDataService = async () => {
    try {
        const response = await fetch(BASE_URL + "resource-videos/public", {
            method: "GET",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: getTokenData(),
            },
        });
        const result = await response.json();
        return { result };
    } catch (error) {
        return { status: false, message: "Something went wrong" };
    }
};
export const getTransportersDataService = async () => {
    try {
        const response = await fetch(BASE_URL + "transporters", {
            method: "GET",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: getTokenData(),
            },
        });
        const result = await response.json();
        return { result };
    } catch (error) {
        return { status: false, message: "Something went wrong" };
    }
};
export const getSparePartsDataService = async () => {
    try {
        const response = await fetch(BASE_URL + `products/type/spareParts`, {
            method: "GET",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: getTokenData(),
            },
        });
        const result = await response.json();
        return { result };
    } catch (error) {
        return { status: false, message: "Something went wrong" };
    }
}
export const getVideoCategoriesServices = async () => {
    try {
        const response = await fetch(BASE_URL + "/video-categories", {
            method: "GET",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: getTokenData(),
            },
        });
        const result = await response.json();
        return { result };
    } catch (error) {
        return { status: false, message: "Something went wrong" };
    }
}
export const getVideoByCategoriesServices = async (categoryId) => {
    try {
        const response = await fetch(BASE_URL + `resource-videos/category/get/${categoryId}`, {
            method: "GET",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: getTokenData(),
            },
        });
        const result = await response.json();
        return { result };
    } catch (error) {
        return { status: false, message: "Something went wrong" };
    }
}
export const getManualServices = async () =>{ 
    try {
        const response = await fetch(BASE_URL + `manuals`, {
            method: "GET",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: getTokenData(),
            },
        });
        const result = await response.json();
        return { result };
    } catch (error) {
        return { status: false, message: "Something went wrong" };
    }
}