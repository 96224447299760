import { useEffect, useState } from "react";
import Text from "../../atom/Text";
import Header from "../../components/Header/Header";
import { key } from "../../utils/consts";
import { getAboutDataService } from "../../Services/AboutServices";
import { IMAGE_URL } from "../../Services/config";
import Footer from "../../components/Footer";
import Loader from "../../components/Loader";

const About = ({ }) => {
    const [loading, setLoading] = useState(false);
    const [mainContent, setMainContent] = useState([]);
    const [subContent, setSubContent] = useState([]);

    const getAboutContent = async () => {
        const response = await getAboutDataService();
        setMainContent(response.result.filter((item) => item.type == "Main"));
        setSubContent(response.result.filter((item) => item.type == "Sub"));
    }
    useEffect(() => {
        setLoading(true)
        getAboutContent()
        setLoading(false)
    }, [])
    return (
        <>
            <Header />
            {!loading ?
                <>
                    <Text content={"About Us"} className="text-4xl m-4" style={{ fontFamily: key.fontFamily, fontWeight: "bold", background: key.primaryColor, WebkitBackgroundClip: "text", WebkitTextFillColor: "transparent" }} />
                    <div className="py-10" style={{ background: key.blue }}>
                        {mainContent.map((item, index) => {
                            return (
                                <>
                                    <div key={index} className="m-4 flex max-[810px]:flex-col-reverse max-[810px]:items-center">
                                        <div className="w-2/3 flex flex-col max-[810px]:w-full">
                                            {item.content.map((value) => {
                                                return (
                                                    <Text
                                                        key={value.content}
                                                        content={value.content}
                                                        className="mb-8 text-lg mr-16 text-justify max-[810px]:mr-0"
                                                        style={{ fontFamily: key.fontFamily, fontWeight: "bold", color:key.secondaryColor }}
                                                    />
                                                )
                                            })}
                                        </div>
                                        <div className="w-1/3 flex justify-center h-1/3 max-h-1/2 max-[810px]:w-2/3 mb-10 max-[510px]:w-full">
                                            <img src={`${IMAGE_URL}/${item.thumbnail[0]}`} draggable={false} onMouseDown={() => { return false }} style={{ userDrag: "none" }} />
                                        </div>
                                    </div>
                                </>
                            )
                        })}
                    </div>

                    <div className="w-full" style={{ background: key.secondaryColor }}>
                        {subContent.map((item, index) => {
                            return (
                                <>
                                    <div key={index} className={`px-40 py-14 flex max-[810px]:flex-col-reverse max-[810px]:w-full max-[810px]:py-6 max-[567px]:px-20 max-[414px]:px-10`} dir={index % 2 == 0 ? "rtl" : "ltr"}>
                                        {item.content.map((value) => {
                                            return (
                                                <>
                                                    <div key={value.content} className={`flex flex-col`}>
                                                        <Text
                                                            content={value.content.split(":")[0]}
                                                            className={`text-2xl text-left mb-6 ${index % 2 == 0 ? "ml-14" : "mr-14"} max-[810px]:mx-0 max-[810px]:my-4`}
                                                            style={{ fontFamily: key.fontFamily, fontWeight: "500", color: key.blue }}
                                                        />
                                                        <Text
                                                            content={value.content.split(":")[1]}
                                                            className={`text-lg text-left ${index % 2 == 0 ? "ml-14" : "mr-14"} max-[810px]:mx-0 max-[810px]:mt-0`}
                                                            style={{ fontFamily: key.fontFamily, fontWeight: "500", color: key.blue }}
                                                        />
                                                    </div>
                                                </>
                                            )
                                        })
                                        }
                                        <img className="max-w-64 max-h-64 rounded" src={`${IMAGE_URL}/${item.thumbnail[0]}`} draggable={false} onMouseDown={() => { return false }} style={{ userDrag: "none" }} />
                                    </div>
                                </>
                            )
                        })}
                    </div> </> :
                <Loader />}
            <Footer />
        </>
    )
}
export default About;