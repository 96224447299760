import { CardBody, Collapse, FormGroup, Input, Label } from "reactstrap"
import Text from "../../atom/Text"
import { key } from "../../utils/consts"
import { useState } from "react"
import { IoIosArrowDroprightCircle } from "react-icons/io";
import { City, State } from "country-state-city";

const BillingAddress = ({ addressData, shippingAddress, setAddressData, companyName, gstNumber }) => {
    const [citySelected, setCitySelected] = useState(false);
    const [stateSelected, setStateSelected] = useState(false);
    const [cityData, setCityData] = useState([]);
    const [stateData, setStateData] = useState(State.getStatesOfCountry("IN"));
    const [isOpen, setIsOpen] = useState(true);
    const [otherField, setOtherField] = useState(false);
    const [showData, setShowData] = useState(true);
    const [sameAsSelected, setSameAsSelected] = useState(false);
    return (
        <div className="rounded my-4">
            <div className="flex" onClick={() => { setIsOpen(!isOpen) }}>
                <Text className={"text-lg pl-3 py-2 rounded-tl w-3/4"} content={"Billing Address"} style={{ fontFamily: key.fontFamily, color: key.secondaryColor, backgroundColor: key.blue, fontWeight: "bold" }} onClick={() => {
                    setIsOpen(!isOpen)
                }} />
                <div className="w-1/4 flex justify-end items-center pr-4 rounded-tr" style={{ backgroundColor: key.blue }}>
                    <IoIosArrowDroprightCircle size={25} color={key.secondaryColor} style={{ rotate: isOpen ? "90deg" : null }} />
                </div>
            </div>
            <Collapse isOpen={isOpen}>
                {showData ? <CardBody>
                    <div className="p-3 rounded-b" style={{ backgroundColor: key.formBackground }}>
                        <FormGroup check className="mt-1 border-b pb-3">
                            <Input type="checkbox" onChange={(e) => {
                                if (e.target.checked) {
                                    setShowData(false);
                                    setSameAsSelected(true);
                                    setAddressData(shippingAddress);
                                    setAddressData(prevState => ({ ...prevState, companyName: companyName, gstNumber: gstNumber }))
                                    setShowData(true);
                                } else {
                                    setSameAsSelected(false);
                                    setAddressData((prevState) => ({ ...prevState, houseNo: "", address: "", state: "", city: "", mobile: "", alternativeMobile: "" }));
                                }
                            }} />
                            {' '}
                            <Text content={"Same as Shipping Address"} className={""} style={{ fontFamily: key.fontFamily, fontWeight: "bold", opacity: "0.7" }} />
                        </FormGroup>
                        <div className="flex items-center mt-6 mb-2">
                            <Text content={"Company Name"} className={"text-sm"} style={{ fontFamily: key.fontFamily, fontWeight: "bold", opacity: "0.7" }} />
                            <div style={{ color: "red" }} className="ml-1">*</div>
                        </div>
                        <Input
                            type="text"
                            disabled
                            defaultValue={addressData?.companyName}
                            style={{ fontFamily: key.fontFamily }}
                        />
                        <div className="flex items-center mt-6 mb-2">
                            <Text content={"House No."} className={"text-sm"} style={{ fontFamily: key.fontFamily, fontWeight: "bold", opacity: "0.7" }} />
                            <div style={{ color: "red" }} className="ml-1">*</div>
                        </div>
                        <Input
                            placeholder="House No."
                            type="text"
                            disabled={sameAsSelected ? true : false}
                            defaultValue={sameAsSelected ? shippingAddress?.houseNo : ""}
                            onChange={(e) => { addressData['houseNo'] = e.target.value }}
                            style={{ fontFamily: key.fontFamily }}
                        />
                        <div className="flex items-center mt-4 mb-2">
                            <Text content={"Address"} className={"text-sm"} style={{ fontFamily: key.fontFamily, fontWeight: "bold", opacity: "0.7" }} />
                            <div style={{ color: "red" }} className="ml-1">*</div>
                        </div>
                        <Input
                            placeholder={"Address"}
                            type="textarea"
                            disabled={sameAsSelected ? true : false}
                            defaultValue={sameAsSelected ? shippingAddress?.address : ""}
                            onChange={(e) => { addressData['address'] = e.target.value }}
                            style={{ fontFamily: key.fontFamily }}
                        />
                        <div className="flex gap-2 my-2 max-[485px]:flex-col">
                            <div className="w-1/3 max-[485px]:w-full">
                                <div className="flex items-center mt-3 mb-2">
                                    <Text content={"State"} className={"text-sm"} style={{ fontFamily: key.fontFamily, fontWeight: "bold", opacity: "0.7" }} />
                                    <div style={{ color: "red" }} className="ml-1">*</div>
                                </div>
                                <Input
                                    placeholder="State"
                                    type="select"
                                    disabled={sameAsSelected ? true : false}
                                    defaultValue={sameAsSelected ? shippingAddress?.state : ""}
                                    onChange={(e) => {
                                        if (e.target.value != "none") {
                                            const selectedData = stateData.filter((item) => item?.name == e.target.value)
                                            addressData['state'] = e.target.value
                                            setCityData(City.getCitiesOfState("IN", selectedData[0]?.isoCode))
                                            setStateSelected(true);
                                        } else {
                                            setOtherField(false)
                                            setStateSelected(false);
                                            setCityData([])
                                        }
                                    }}
                                    style={{ fontFamily: key.fontFamily }}
                                >
                                    <option value={"none"}>Select State</option>
                                    {stateData.map((item) => {
                                        return (
                                            <option selected={sameAsSelected ? shippingAddress?.state === item?.name ? "selected" : "" : ""}>{item?.name}</option>
                                        )
                                    })}
                                </Input>
                            </div>
                            <div className="w-1/3 max-[485px]:w-full">
                                <div className="flex items-center mt-3 mb-2">
                                    <Text content={"City"} className={"text-sm"} style={{ fontFamily: key.fontFamily, fontWeight: "bold", opacity: "0.7" }} />
                                    <div style={{ color: "red" }} className="ml-1">*</div>
                                </div>
                                <Input
                                    placeholder="City"
                                    type={sameAsSelected ? "text" : "select"}
                                    defaultValue={sameAsSelected ? shippingAddress?.city : ""}
                                    onChange={(e) => {
                                        if (e.target.value == "none") {
                                            setOtherField(false)
                                            setCitySelected(false)
                                        } else if (e.target.value == "other") {
                                            setOtherField(true)
                                        } else {
                                            setOtherField(false)
                                            addressData['city'] = e.target.value
                                            setCitySelected(true)
                                        }
                                    }}
                                    style={{ fontFamily: key.fontFamily }}
                                    disabled={sameAsSelected ? true : stateSelected ? false : true}
                                >
                                    <option value={"none"}>Select City</option>
                                    {cityData.map((item) => {
                                        return (
                                            <option selected={sameAsSelected ? shippingAddress?.city === item?.name ? "selected" : "" : ""}>{item?.name}</option>
                                        )
                                    })}
                                    <option value={"other"}>Other</option>

                                </Input>
                            </div>
                            <div className="w-1/3 max-[485px]:w-full">
                                <div className="flex items-center mt-3 mb-2">
                                    <Text content={"PIN Code"} className={"text-sm"} style={{ fontFamily: key.fontFamily, fontWeight: "bold", opacity: "0.7" }} />
                                    <div style={{ color: "red" }} className="ml-1">*</div>
                                </div>
                                <Input
                                    placeholder="PIN Code"
                                    type="text"
                                    disabled={sameAsSelected ? true : false}
                                    defaultValue={sameAsSelected ? shippingAddress?.pin : ""}
                                    onChange={(e) => { addressData['pin'] = e.target.value }}
                                    style={{ fontFamily: key.fontFamily }}
                                />
                            </div>
                        </div>
                        {otherField ?
                            <>
                                <Label text={"Other City"} required={true} />
                                <div className="mb-2 w-1/2">
                                    <Input
                                        placeholder={"Other City"}
                                        style={{ fontFamily: key.fontFamily }}
                                        onChange={(e) => { addressData['city'] = e.target.value }}
                                    />
                                </div>
                            </> : <></>}
                        <div className="flex gap-2 my-2 max-[485px]:flex-col">
                            <div className="w-1/2 max-[485px]:w-full">
                                <div className="flex items-center mt-3 mb-2">
                                    <Text content={"Phone No."} className={"text-sm"} style={{ fontFamily: key.fontFamily, fontWeight: "bold", opacity: "0.7" }} />
                                    <div style={{ color: "red" }} className="ml-1">*</div>
                                </div>
                                <Input
                                    placeholder="Phone No."
                                    type="text"
                                    disabled={sameAsSelected ? true : false}
                                    defaultValue={sameAsSelected ? shippingAddress?.mobile : ""}
                                    onChange={(e) => { addressData['mobile'] = e.target.value }}
                                    style={{ fontFamily: key.fontFamily }}
                                />
                            </div>
                            <div className="w-1/2 max-[485px]:w-full">
                                <div className="flex items-center mt-3 mb-2">
                                    <Text content={"Alternate Phone No."} className={"text-sm"} style={{ fontFamily: key.fontFamily, fontWeight: "bold", opacity: "0.7" }} />
                                </div>
                                <Input
                                    placeholder="Alternate Phone No."
                                    type="text"
                                    disabled={sameAsSelected ? true : false}
                                    defaultValue={sameAsSelected ? shippingAddress?.alternateMobile : ""}
                                    onChange={(e) => { addressData['alternateMobile'] = e.target.value }}
                                    style={{ fontFamily: key.fontFamily }}
                                />
                            </div>
                        </div>
                        <div className="flex gap-2 my-2 max-[485px]:flex-col">
                            <div className="w-1/2 max-[485px]:w-full">
                                <div className="flex items-center mt-3 mb-2">
                                    <Text content={"Email"} className={"text-sm"} style={{ fontFamily: key.fontFamily, fontWeight: "bold", opacity: "0.7" }} />
                                    <div style={{ color: "red" }} className="ml-1">*</div>
                                </div>
                                <Input
                                    placeholder="Email"
                                    type="text"
                                    onChange={(e) => { addressData['email'] = e.target.value }}
                                    style={{ fontFamily: key.fontFamily }}
                                />
                            </div>
                            <div className="w-1/2 max-[485px]:w-full">
                                <div className="flex items-center mt-3 mb-2">
                                    <Text content={"GST Number"} className={"text-sm"} style={{ fontFamily: key.fontFamily, fontWeight: "bold", opacity: "0.7" }} />
                                    <div style={{ color: "red" }} className="ml-1">*</div>
                                </div>
                                <Input
                                    // placeholder="GST Number"
                                    type="text"
                                    defaultValue={addressData?.gstNumber}
                                    disabled
                                    style={{ fontFamily: key.fontFamily }}
                                />
                            </div>
                        </div>
                    </div>
                </CardBody> : <></>}
            </Collapse>
        </div>
    )
}
export default BillingAddress;