import { useState } from "react";
import { key } from "../utils/consts";

const Text = ({ HtmlTag = 'div', className, isHeaderText, content, ...rest }) => {
  return (
    <HtmlTag {...{ className, ...rest }}>{content}</HtmlTag>
  )
}


export default Text;
