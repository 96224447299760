import Text from "../../atom/Text";
import DisplayInput from "../../components/DisplayInput";
import { key } from "../../utils/consts";
import { priceFormat } from "../../utils/priceFunctions";

const ProductDescription = ({ productData, activeVariant, setActiveVariant, productType }) => {
    return (
        <div className="w-3/5 px-7 pt-4 pb-12 border-r max-[848px]:w-full" style={{ overflowY: "auto" }}>
            <Text content={productData?.name ? productData?.name : null} className={"text-3xl"} style={{ fontFamily: key.fontFamily, fontWeight: "bolder" }} />
            {productType != 'accessories' ? <DisplayInput inputText={activeVariant?.additionalInformation} /> : <></>}
            <Text
                content={"Variants"}
                className={"text-lg mt-6"}
                style={{ fontFamily: key.fontFamily }}
            />
            <div className="flex mt-2 gap-2">
                {productData?.variants ? productData?.variants.map((item, index) => {
                    if (productType == 'accessories') {
                        return (
                            <button onClick={() => { setActiveVariant(productData?.variants[index]) }}>
                                <div className="py-2 px-4 border-1 rounded" style={{ borderColor: activeVariant?.modelNumber === item.modelNumber ? "green" : null }}>
                                    <Text content={item?.modelNumber} className={"text-sm"} style={{ fontFamily: key.fontFamily, color: activeVariant?.modelNumber === item.modelNumber ? "green" : "#000" }} />
                                </div>
                            </button>
                        )
                    } else if (productType == 'spare-parts') {
                        return (
                            <button onClick={() => { setActiveVariant(productData?.variants[index]) }}>
                                <div className="py-2 px-4 border-1 rounded" style={{ borderColor: activeVariant?.capacity === item.capacity ? "green" : null }}>
                                    <Text content={item?.capacity} className={"text-sm"} style={{ fontFamily: key.fontFamily, color: activeVariant?.capacity === item.capacity ? "green" : "#000" }} />
                                </div>
                            </button>
                        )
                    }
                })
                    : <></>}
            </div>
            {productType == 'accessories' ? <div className="mt-4 flex">
                <Text content={"Capacity : "} style={{ fontFamily: key.fontFamily, fontWeight: "bold" }} />
                <Text content={activeVariant?.capacity} style={{ fontFamily: key.fontFamily, fontWeight: 300 }} className={"ml-1"} />
            </div> : <>
                <div className="mt-4 flex">
                    <Text content={"Model No. : "} style={{ fontFamily: key.fontFamily, fontWeight: "bold" }} />
                    <Text content={productData?.modelNumber} style={{ fontFamily: key.fontFamily, fontWeight: 300 }} className={"ml-1"} />
                </div>
            </>}
            <Text content={`${window.localStorage.getItem("userRole") == "b2c" ? priceFormat(activeVariant?.b2cPrice + activeVariant?.b2cPrice * activeVariant?.gstPercentage / 100) : priceFormat(activeVariant?.b2bPrice + activeVariant?.b2bPrice * activeVariant?.gstPercentage / 100)} (Incl. of all taxes)`} className={"text-sm mt-8"} style={{ fontFamily: key.fontFamily, color: "gray" }} />
            <div className="my-2.5 flex items-center max-[848px]:flex-col max-[848px]:items-start">
                <Text content={window.localStorage.getItem("userRole") == "b2c" ? priceFormat(activeVariant?.b2cPrice) : priceFormat(activeVariant?.b2bPrice)} className={"text-3xl"} style={{ fontFamily: key.fontFamily, fontWeight: "bolder" }} />
                <Text content={`+ ${priceFormat(window.localStorage.getItem("userRole") == "b2c" ? activeVariant?.b2cPrice * activeVariant?.gstPercentage / 100 : activeVariant?.b2bPrice * activeVariant?.gstPercentage / 100)} GST`} className={"text-sm ml-1 max-[848px]:mt-1"} style={{ fontFamily: key.fontFamily, color: "gray" }} />
            </div>
            <div className="py-4 px-3 border mt-12">
                <Text content={"About This Product"} className={"text-lg"} style={{ fontFamily: key.fontFamily, fontWeight: "bolder" }} />
                <hr className="w-5/6" />
                <Text content={"Paramters"} className={"text-base mt-4"} style={{ fontFamily: key.fontFamily, fontWeight: "bold" }} />
                <DisplayInput inputText={activeVariant?.parameters} />
                <Text content={"Technical Details"} className={"text-base mt-7"} style={{ fontFamily: key.fontFamily, fontWeight: "bold" }} />
                <DisplayInput inputText={activeVariant?.technicalDetails} />
                <Text content={"Additional Information"} className={"text-base mt-7"} style={{ fontFamily: key.fontFamily, fontWeight: "bold" }} />
                <DisplayInput inputText={activeVariant?.additionalInformation} />
            </div>
        </div>
    )
}
export default ProductDescription;