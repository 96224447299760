import { useEffect, useState } from "react"
import Text from "../../atom/Text"
import Header from "../../components/Header/Header"
import { key } from "../../utils/consts"
import { getOrderById } from "../../Services/OrderServices"
import { useParams } from "react-router-dom";
import Loader from "../../atom/Loader"
import { Button, CardBody, Collapse, Input } from "reactstrap";
import { FaDownload } from "react-icons/fa6";
import { ORDER_IMAGE_URL, ORDER_MATRIAL_IMAGE_URL, ORDER_PDF_URL } from "../../Services/config"
import OrderDetail from "./OrderDetail"
import { IoIosArrowDroprightCircle } from "react-icons/io";
import ImageOpenModal from "./ImageOpenModal"
import Footer from "../../components/Footer"
import AddTransportersDataModal from "./AddTransportersDataModal"
import PlayVideoModal from "./PlayVideoModal"
import EditTransportersDataModal from "./EditTransportersDataModal"
import EditTransporterConfirmModal from "./EditTransporterConfirmModal"
import ConfirmCancelOrderModal from "./ConfirmCancelOrderModal"
import AddBankDetailsModal from "./AddBankDetailsModal"

const Order = () => {
    const { orderId } = useParams();
    const [orderData, setOrderData] = useState({});
    const [loading, setLoading] = useState(false);
    const [showImages, setShowImages] = useState(true);
    const [showMaterialImages, setShowMaterialImages] = useState(true);
    const [openImageModal, setOpenImageModal] = useState(false);
    const [showPackingImages, setShowPackingImages] = useState(true);
    const [selectedImage, setSelectedImage] = useState(0);
    const [typeOfImage, setTypeOfImage] = useState("");
    const [transportersModal, setTransportersModal] = useState(false);
    const [videoModal, setVideoModal] = useState(false);
    const [editTransportersDataModal, setEditTransportersDataModal] = useState(false);
    const [confirmModal, setConfirmModal] = useState(false);
    const [confirmCancelModal, setConfirmCancelModal] = useState(false);
    const [bankDetailsModal, setBankDetailsModal] = useState(false);

    const getOrderData = async () => {
        setLoading(true);
        const response = await getOrderById(orderId);
        if (response?.result) {
            setLoading(false);
            setOrderData(response?.result);
        }
    }
    useEffect(() => {
        getOrderData();
    }, [])
    return (
        <>
            <EditTransporterConfirmModal openModal={confirmModal} setOpenModal={setConfirmModal} setEditTransportersModal={setEditTransportersDataModal} />
            <EditTransportersDataModal openModal={editTransportersDataModal} setOpenModal={setEditTransportersDataModal} orderData={orderData} setOrderData={setOrderData} refreshData={getOrderData} />
            <PlayVideoModal openModal={videoModal} setOpenModal={setVideoModal} videoId={orderData?._id} />
            <AddTransportersDataModal openModal={transportersModal} setOpenModal={setTransportersModal} orderData={orderData} setOrderData={setOrderData} />
            <ImageOpenModal openModal={openImageModal} setOpenModal={setOpenImageModal} typeOfImage={typeOfImage} imageData={typeOfImage === "orderImage" ? orderData?.images : typeOfImage === "packingImage" ? orderData?.packingMaterialImages : orderData?.dispatchMaterialImages} selectedImageIndex={selectedImage} />
            <ConfirmCancelOrderModal openModal={confirmCancelModal} setOpenModal={setConfirmCancelModal} setBankDetailsModal={setBankDetailsModal}/>
            <AddBankDetailsModal openModal={bankDetailsModal} setOpenModal={setBankDetailsModal} orderData={orderData}/>
            <Header />
            {!loading ?
                <>
                    <div className="flex border-b pb-2.5 mt-4 mx-4">
                        <Text content={"Order"} className="w-4/5 text-4xl" style={{ fontFamily: key.fontFamily, fontWeight: "bold", color: key.blue }} />
                        {orderData?.status != "cancelled" || orderData?.status != "refund" ? <div className="w-1/5 flex justify-end">
                            <Button style={{ backgroundColor: "red", border: "none", fontFamily: key.fontFamily }} onClick={() => {
                                setConfirmCancelModal(true);
                            }}>
                                Cancel Order
                            </Button>
                        </div> : <></>}
                    </div>
                    <div className="flex mb-4 max-[791px]:flex-col-reverse">
                        <div className="px-4 w-3/4 border-r pt-4 max-[1145px]:w-2/3 max-[791px]:border-0 max-[791px]:w-11/12">
                            <div className="flex gap-2.5" style={{}}>
                                <OrderDetail orderData={orderData} path={"proforma-invoices"} title={"Proforma Invoices (PI)"} field={"purchaseInvoiceUpload"} />
                                <OrderDetail orderData={orderData} path={"tax-invoices"} title={"Tax Invoices"} field={"taxInvoiceUpload"} />
                            </div>
                            <div className="mt-4 flex gap-2.5" style={{}}>
                                <OrderDetail orderData={orderData} path={"e-invoices"} title={"E-Invoices"} field={"eInvoiceUpload"} />
                                <OrderDetail orderData={orderData} path={"e-way-bills"} title={"E-Way Bill"} field={"eWayBillUpload"} />
                            </div>
                            <div className="mt-4 flex gap-2.5">
                                <OrderDetail orderData={orderData} path={"lr-copy"} title={"LR Copy"} field={"lrCopyUpload"} />
                                <OrderDetail orderData={orderData} path={"test-certificate"} title={"Test Certificate"} field={"testCertificateUpload"} />
                            </div>
                            <div className="mt-4 flex gap-2.5">
                                <div className="w-full border rounded">
                                    <Text content={"Video Attachment"} className={"py-2 pl-2 rounded-t"} style={{ fontFamily: key.fontFamily, fontWeight: "bold", color: key.secondaryColor, backgroundColor: key.blue }} />
                                    <div className="py-4 flex justify-center">
                                        {orderData['videoAttachmentUpload'] ? <Button style={{ backgroundColor: key.blue, color: key.secondaryColor, borderRadius: 0, border: "none", fontFamily: key.fontFamily }} className="px-4 py-2 rounded text-sm d-flex gap-3 align-items-center justify-content-center" onClick={() => {
                                            setVideoModal(true);
                                        }}>Play Video</Button> : <Text content={"Not Uploaded Yet"} style={{ fontFamily: key.fontFamily, color: key.blue, fontWeight: "bold" }} />}
                                    </div>
                                </div>
                            </div>
                            <div className="mt-4">
                                <div className="w-full flex items-center justify-center rounded-t" style={{ backgroundColor: key.blue }} onClick={() => { setShowImages(!showImages) }}>
                                    <Text content={"Matrial Images"} className={"py-2.5 pl-2 rounded-t w-full"} style={{ fontFamily: key.fontFamily, fontWeight: "bold", color: key.secondaryColor }} />
                                    <IoIosArrowDroprightCircle size={23} color={key.secondaryColor} style={{ rotate: showImages ? "90deg" : "" }} className="mr-4" />
                                </div>
                                <Collapse isOpen={showImages} className="border">
                                    <CardBody>
                                        {!orderData?.imagesUpload ? <div className="flex justify-center">
                                            <Text content={"Not Uploaded Yet!"} className={"py-6"} style={{ fontFamily: key.fontFamily, color: key.blue, fontWeight: "bold" }} />
                                        </div> :
                                            <div className="flex items-center" style={{ overflowY: "auto" }}>
                                                {orderData?.images?.map((item, index) => {
                                                    return (
                                                        <button className="w-60" onClick={() => {
                                                            setSelectedImage(index);
                                                            setTypeOfImage("orderImage");
                                                            setOpenImageModal(true);
                                                        }}>
                                                            <img key={index} src={ORDER_IMAGE_URL + item} className="border-r px-3 py-3" />
                                                        </button>
                                                    )
                                                })}
                                            </div>
                                        }
                                    </CardBody>
                                </Collapse>
                            </div>
                            <div className="mt-4">
                                <div className="w-full flex items-center justify-center rounded-t" style={{ backgroundColor: key.blue }} onClick={() => { setShowPackingImages(!showPackingImages) }}>
                                    <Text content={"Packing Matrial Images"} className={"py-2.5 pl-2 rounded-t w-full"} style={{ fontFamily: key.fontFamily, fontWeight: "bold", color: key.secondaryColor }} />
                                    <IoIosArrowDroprightCircle size={23} color={key.secondaryColor} style={{ rotate: showPackingImages ? "90deg" : "" }} className="mr-4" />
                                </div>
                                <Collapse isOpen={showPackingImages} className="border">
                                    <CardBody>
                                        {!orderData?.packingMaterialImagesUpload ? <div className="flex justify-center">
                                            <Text content={"Not Uploaded Yet!"} className={"py-6"} style={{ fontFamily: key.fontFamily, color: key.blue, fontWeight: "bold" }} />
                                        </div> :
                                            <div className="flex items-center" style={{ overflowY: "auto" }}>
                                                {orderData?.packingMaterialImages?.map((item, index) => {
                                                    return (
                                                        <button className="w-60" onClick={() => {
                                                            setSelectedImage(index);
                                                            setTypeOfImage("packingImage");
                                                            setOpenImageModal(true);
                                                        }}>
                                                            <img key={index} src={ORDER_MATRIAL_IMAGE_URL + item} className="border-r px-3 py-3" />
                                                        </button>
                                                    )
                                                })}
                                            </div>
                                        }
                                    </CardBody>
                                </Collapse>
                            </div>
                            <div className="mt-4">
                                <div className="w-full flex items-center justify-center rounded-t" style={{ backgroundColor: key.blue }} onClick={() => { setShowMaterialImages(!showMaterialImages) }}>
                                    <Text content={"Dispatch Material Images"} className={"py-2.5 pl-2 rounded-t w-full"} style={{ fontFamily: key.fontFamily, fontWeight: "bold", color: key.secondaryColor }} />
                                    <IoIosArrowDroprightCircle size={23} color={key.secondaryColor} style={{ rotate: showMaterialImages ? "90deg" : "" }} className="mr-4" />
                                </div>
                                <Collapse isOpen={showMaterialImages} className="border">
                                    <CardBody>
                                        {!orderData?.dispatchMaterialImagesUpload ? <div className="flex justify-center">
                                            <Text content={"Not Uploaded Yet!"} className={"py-6"} style={{ fontFamily: key.fontFamily, color: key.blue, fontWeight: "bold" }} />
                                        </div> :
                                            <div className="flex items-center" style={{ overflowY: "auto" }}>
                                                {orderData?.dispatchMaterialImages?.map((item, index) => {
                                                    return (
                                                        <button className="w-60" onClick={() => {
                                                            setSelectedImage(index);
                                                            setTypeOfImage("dispatchMaterialImage");
                                                            setOpenImageModal(true);
                                                        }}>
                                                            <img key={index} src={ORDER_MATRIAL_IMAGE_URL + item} className="border-r px-3 py-3" />
                                                        </button>
                                                    )
                                                })}
                                            </div>
                                        }
                                    </CardBody>
                                </Collapse>
                            </div>
                        </div>
                        <div className="rounded w-1/4 mx-3 h-fit max-[1145px]:w-1/3 max-[791px]:flex max-[791px]:w-11/12 max-[791px]:gap-2 max-[791px]:px-4 max-[791px]:mx-0">
                            {orderData?.purchaseOrderUpload ?
                                <>
                                    <div className="max-[791px]:w-1/2">
                                        <Text content={"Purchase Order"} className={"mt-4 py-2 pl-2 rounded-t"} style={{ fontFamily: key.fontFamily, fontWeight: "bold", color: key.secondaryColor, backgroundColor: key.blue }} />
                                        <div className="py-4 flex justify-center border rounded-b">
                                            <Button style={{ backgroundColor: key.blue, color: key.secondaryColor, borderRadius: 0, border: "none", fontFamily: key.fontFamily }} className="px-4 py-2 rounded text-sm d-flex gap-3 align-items-center justify-content-center" onClick={() => {
                                                fetch(ORDER_PDF_URL + `purchase-orders/` + orderData?._id + ".pdf").then((response) => {
                                                    response.blob().then((blob) => {
                                                        const fileURL = window.URL.createObjectURL(blob);
                                                        let alink = document.createElement("a");
                                                        alink.href = fileURL;
                                                        alink.download = `purchase-orders_${orderData?._id}.pdf`;
                                                        alink.click();
                                                    });
                                                });
                                            }}>Download
                                                <FaDownload size={17} color={key.secondaryColor} />
                                            </Button>
                                        </div>
                                    </div>
                                </>
                                : <></>
                            }
                            <div className="max-[791px]:w-1/2">
                                <Text content={"Transporter Details"} className={"py-2 pl-2 rounded-t mt-4"} style={{ fontFamily: key.fontFamily, fontWeight: "bold", color: key.secondaryColor, backgroundColor: key.blue }} />
                                {orderData?.transportersDetails?.name != "" && orderData?.transportersDetails?.gstNumber != "" && orderData?.transportersDetails?.vehicleNumber != "" && orderData?.transportersDetails?.mobile != "" ?
                                    <>
                                        <div className="py-3 px-2.5 rounded-b border">
                                            <div className="flex justify-end">
                                                <Button style={{ backgroundColor: key.blue, color: key.secondaryColor, borderRadius: 0, border: "none", fontFamily: key.fontFamily }} className="px-4 py-1 rounded text-sm d-flex gap-3 align-items-center justify-content-center" onClick={() => {
                                                    setConfirmModal(true);
                                                }}>Change
                                                </Button>
                                            </div>
                                            <div className="mt-1">
                                                <Text content={"Name : "} className={"text-sm"} style={{ fontFamily: key.fontFamily, fontWeight: "bold", color: key.blue }} />
                                                <Input disabled defaultValue={orderData?.transportersDetails?.name} style={{ fontFamily: key.fontFamily, fontSize: "0.8rem" }} className="mt-1.5" />
                                            </div>
                                            <div className="my-3">
                                                <Text content={"GSTIN : "} className={"text-sm"} style={{ fontFamily: key.fontFamily, fontWeight: "bold", color: key.blue }} />
                                                <Input disabled defaultValue={orderData?.transportersDetails?.gstNumber} style={{ fontFamily: key.fontFamily, fontSize: "0.8rem" }} className="mt-1.5" />
                                            </div>
                                            <div className="my-3">
                                                <Text content={"Vehicle Number : "} className={"text-sm"} style={{ fontFamily: key.fontFamily, fontWeight: "bold", color: key.blue }} />
                                                <Input disabled defaultValue={orderData?.transportersDetails?.vehicleNumber} style={{ fontFamily: key.fontFamily, fontSize: "0.8rem" }} className="mt-1.5" />
                                            </div>
                                            <div className="mb-2">
                                                <Text content={"Contact No. : "} className={"text-sm"} style={{ fontFamily: key.fontFamily, fontWeight: "bold", color: key.blue }} />
                                                <Input disabled defaultValue={orderData?.transportersDetails?.mobile} style={{ fontFamily: key.fontFamily, fontSize: "0.8rem" }} className="mt-1.5" />
                                            </div>
                                        </div>
                                    </>
                                    :
                                    <div className="py-4 flex justify-center border rounded-b">
                                        <Button style={{ backgroundColor: key.blue, color: key.secondaryColor, borderRadius: 0, border: "none", fontFamily: key.fontFamily }} className="px-4 py-2 rounded text-sm d-flex gap-3 align-items-center justify-content-center" onClick={() => {
                                            setTransportersModal(true);
                                        }}>Add Transporter Data
                                        </Button>
                                    </div>}
                            </div>
                        </div>
                    </div>
                </>
                : <Loader />}
            <Footer />
        </>
    )
}
export default Order