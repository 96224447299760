export const priceFormat = (price) => {
    const priceConverted = "Rs. " + parseInt(price).toLocaleString("en-IN") + "/-";
    return priceConverted;
}
export const calculateGst = (price) => {
    const gstAmount = parseInt(price) * 18 / 100;
    return gstAmount;
}
export const addDays = (date, days) => {
    var result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
}