import { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FaEye, FaEyeSlash } from "react-icons/fa";
// import {SlSocialInstagram} from 'react-icons/sl'
import { userLogin } from "../../Services/DataServices";
import BackgroundImage from "../../atom/BackgroundImage";
import Button from "../../atom/Button";
import Description from "../../atom/Description";
import Image from "../../atom/Image";
import Label from "../../atom/Label";
import TextInput from "../../atom/TextInput";
import Title from "../../atom/Title";
import Field from "../../molecules/Field";
import { key } from "../../utils/consts";
import { HOME, SIGNUP } from "../../utils/pathDetails";
import { validatePhone } from '../../utils/validationRegex';
import { IconContext } from "react-icons";




const Login = () => {
    const [userData, setUserData] = useState({});
    const [passwordVisible, setPasswordVisible] = useState(false);
    const history = useHistory();


    const onsubmit = async () => {
        if (userData.mobile && userData.password) {
            const validMobile = validatePhone(userData.mobile);
            if (validMobile) {
                const { result } = await userLogin(userData)
                if (result?.data) {
                    if (result?.data?.statusCode) {
                        toast.error(result?.data?.response)
                    } else {
                        localStorage.setItem("user", JSON.stringify(result?.data?.user));
                        localStorage.setItem("userRole", result?.data?.user?.role);
                        localStorage.setItem("token", result?.data?.token);
                        localStorage.setItem('isLoggedin', "true");
                        toast.success("Loggedin successfully")
                        setTimeout(() => {
                            history.push(HOME);
                        }, 1000);
                    }

                } else {
                    toast.error("Something went wrong")
                }
            } else {
                toast.error("Invalid Phone Number!")
            }
        } else {
            toast.error("Please enter all the details")
        }

    }

    return (
        <div className="flex" style={{ backgroundColor: "#000" }}>
            <ToastContainer />
            <BackgroundImage src={"./images/Login Photo.png"} />
            <div className="w-10/12 pl-10 pr-10 flex flex-col items-center justify-center max-[952px]:w-full pt-20 " style={{ background: key.secondaryColor }}>
                <div className="flex items-center justify-center self-center w-fit max-[1032px]:flex-col">
                    <Image src={'./images/logo-light.png'} className={"w-1/3"}/>
                    <div className="flex flex-col ml-6 max-[1032px]:mt-6 ml-0">
                        <Title text={"Clean Water Starts Here"} color={key.blue} />
                        <Description text={"Login to Your Account"} color={key.blue} />
                    </div>
                </div>
                <div className="h-full w-full mt-16 mr-10 ml-10 rounded-t-2xl p-10" style={{ backgroundColor: key.formBackground, boxShadow: key.formShadow }}>
                    <Label text={"Phone No."} />
                    <div className="flex mb-4">
                        <div className="flex items-center justify-center max-w-md mr-5 rounded max-[414px]:hidden" style={{ backgroundColor: key.secondaryColor }}>
                            <img src={"./images/india.png"} className="w-10 mx-5" draggable={false} onMouseDown={() => { return false }} style={{ userDrag: "none" }} />
                        </div>
                        <TextInput
                            inputType={"text"}
                            placeholder={"1234567890"}
                            onChange={(e) => { userData['mobile'] = e.target.value }}
                            onKeyPress={(target) => {
                                if (target.charCode == 13) {
                                    onsubmit();
                                }
                            }}
                        />
                    </div>
                    <div className="w-full flex items-end">
                        <div className="w-full">
                            <Field
                                text={"Password"}
                                placeholder={"********"}
                                onChange={(e) => { userData['password'] = e.target.value }}
                                inputType={passwordVisible ? "text" : "password"}
                                onKeyPress={(target) => {
                                    if (target.charCode == 13) {
                                        onsubmit();
                                    }
                                }}
                            />
                        </div>
                        {passwordVisible ?
                            <FaEye className="ml-5 h-12" size={20} onClick={() => { setPasswordVisible(!passwordVisible) }} /> :
                            <FaEyeSlash className="ml-5 h-12" size={20} onClick={() => { setPasswordVisible(!passwordVisible) }} />
                        }
                        {/* <IconContext.Provider
                            value={{ color: "blue", className: "global-class-name" }}
                        >
                            <a href="">
                                <SlSocialInstagram id="instagram-icon" />
                            </a>
                        </IconContext.Provider> */}
                    </div>
                    <div className="flex mt-12">
                        <div className="flex items-center justify-start w-6/12">

                        </div>
                        <div className="w-full flex items-center justify-end" style={{ color: key.blue }}>
                            <Link to="/forgot-password" style={{ textDecoration: "none" }}><Label text={"Forgot Password ?"} /></Link>
                        </div>
                    </div>
                    <div className="w-full flex items-center justify-center my-8">
                        <Button
                            onClick={onsubmit}
                            buttonText={"Login"}
                            style={{
                                background: key.primaryColor,
                                color: "#fff",
                                fontFamily: key.fontFamily,
                                fontWeight: "bold"
                            }}
                            className="w-72 h-12 flex items-center justify-center rounded"
                        />
                    </div>
                    <div className="flex justify-center space-x-3">
                        <Label text={"Don't have an account?"} color={"#7E8A97"} />
                        <button onClick={() => { history.push(SIGNUP) }}><Label text={"SIGN UP"} color={"#184295"} /></button>
                    </div>
                </div>
            </div>
        </div>
    )
}
const styles = {
    backgroundLight: {

        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center"
    }
}
export default Login;