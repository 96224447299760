import { ToastContainer, toast } from "react-toastify";
import { Form, FormGroup, Input, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { key } from "../../utils/consts";
import Text from "../../atom/Text";
import Button from "../../atom/Button";
import { validateGst, validatePhone, validateVehicleNumber } from "../../utils/validationRegex";
import { callPutAddTransportersDetails } from "../../Services/OrderServices";

const EditTransportersDataModal = ({ openModal, setOpenModal, orderData, setOrderData, refreshData }) => {
    const onSubmit = async () => {
        if (orderData?.transportersDetails?.name && orderData?.transportersDetails?.gstNumber && orderData?.transportersDetails?.vehicleNumber && orderData?.transportersDetails?.mobile) {
            const validateGstNumber = validateGst(orderData?.transportersDetails?.gstNumber)
            if (validateGstNumber) {
                const validateMobile = validatePhone(orderData?.transportersDetails?.mobile);
                if (validateMobile) {
                    const validateVehicleNumbers = validateVehicleNumber(orderData?.transportersDetails?.vehicleNumber);
                    if (validateVehicleNumbers) {
                        const response = await callPutAddTransportersDetails(orderData?._id, orderData);
                        if (response?.result) {
                            setOrderData(response?.result);
                            setOpenModal(false);
                            refreshData();
                        } else {
                            toast.error("Something Went Wrong!")
                        }
                    } else {
                        toast.error("Invalid Vehicle Number!")
                    }
                } else {
                    toast.error("Invalid Contact Number!")
                }
            } else {
                toast.error("Invalid GSTIN!")
            }
        } else {
            toast.error("Please Enter Required Fields!")
        }
    }
    return (
        <>
            <ToastContainer />
            <Modal isOpen={openModal} toggle={() => { setOpenModal(false) }} size="lg">
                <ModalHeader style={{ fontFamily: key.fontFamily, backgroundColor: key.formBackground, fontWeight: "bold", color: key.blue }}>Edit Transporters Data</ModalHeader>
                <ModalBody style={{ backgroundColor: key.formBackground }}>
                    <Form>
                        <FormGroup className="w-2/3">
                            <Text content={"Transporter Name : "} className={""} style={{ fontFamily: key.fontFamily, color: key.blue, fontWeight: "bold" }} />
                            <Input className="mt-2 text-sm" defaultValue={orderData?.transportersDetails?.name} style={{ fontFamily: key.fontFamily, color: key.blue, border: 0 }} placeholder="Transporter Name" onChange={(e) => {
                                orderData['transportersDetails']['name'] = e.target.value;
                            }} />
                        </FormGroup>
                        <FormGroup className="w-2/3 mt-3">
                            <Text content={"GSTIN : "} className={""} style={{ fontFamily: key.fontFamily, color: key.blue, fontWeight: "bold" }} />
                            <Input className="mt-2 text-sm" defaultValue={orderData?.transportersDetails?.gstNumber} style={{ fontFamily: key.fontFamily, color: key.blue, border: 0 }} placeholder="GSTIN" onChange={(e) => {
                                orderData['transportersDetails']['gstNumber'] = e.target.value;
                            }} />
                        </FormGroup>
                        <FormGroup className="w-2/3 mt-3">
                            <Text content={"Vehicle Number : "} className={""} style={{ fontFamily: key.fontFamily, color: key.blue, fontWeight: "bold" }} />
                            <Input className="mt-2 text-sm" defaultValue={orderData?.transportersDetails?.vehicleNumber} style={{ fontFamily: key.fontFamily, color: key.blue, border: 0 }} placeholder="Vehicle Number" onChange={(e) => {
                                orderData['transportersDetails']['vehicleNumber'] = e.target.value?.toUpperCase();
                            }} />
                        </FormGroup>
                        <FormGroup className="w-2/3 mt-3">
                            <Text content={"Contact No. : "} className={""} style={{ fontFamily: key.fontFamily, color: key.blue, fontWeight: "bold" }} />
                            <Input className="mt-2 text-sm" defaultValue={orderData?.transportersDetails?.mobile} style={{ fontFamily: key.fontFamily, color: key.blue, border: 0 }} placeholder="Contact No." onChange={(e) => {
                                orderData['transportersDetails']['mobile'] = e.target.value;
                            }} />
                        </FormGroup>
                    </Form>
                </ModalBody>
                <ModalFooter style={{ backgroundColor: key.formBackground }}>
                    <Button buttonText={"Close"} className={"w-24 py-1.5 rounded"} onClick={() => { setOpenModal(false) }} />
                    <Button buttonText={"Submit"} className={"w-24 py-1.5 rounded"} onClick={() => { onSubmit() }} />
                </ModalFooter>
            </Modal>
        </>
    )
}
export default EditTransportersDataModal;