import { PDFDownloadLink } from "@react-pdf/renderer";
import Button from "../../atom/Button";
import Text from "../../atom/Text";
import { key } from "../../utils/consts";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer";
import { getManualServices } from "../../Services/ResourcesServices";
import { MANUAL_URL, QUOTATION_URL } from "../../Services/config";
import { useEffect, useState } from "react";
import Loader from "../../atom/Loader";
import { FaDownload } from "react-icons/fa";
import { Input, Spinner } from "reactstrap";
import { CiSearch } from "react-icons/ci";

const Manuals = () => {
    const [loading, setLoading] = useState(false);
    const [selectedIndex, setSelectedIndex] = useState();
    const [manualData, setManualData] = useState([]);
    const [unfilteredManualData, setUnfilteredManualData] = useState([]);
    const [searchData, setSearchData] = useState("");
    const getManualsData = async () => {
        setLoading(true)
        const response = await getManualServices();
        if (response?.result) {
            response?.result?.sort((a,b) => {
                return a.productCategory - b.productCategory || a.name - b.name
            })
            setManualData(response?.result);
            setUnfilteredManualData(response?.result);
            setLoading(false)
        }
    }
    const onDownload = (url) => {
        fetch(url).then((response) => {
            response.blob().then((blob) => {
                const fileURL = window.URL.createObjectURL(blob);
                let alink = document.createElement("a");
                alink.href = fileURL;
                alink.download = "manual.pdf";
                alink.click();
            });
        });
    };
    const filterdata = async (text) => {
        text && text.length > 1 ?
            setManualData(unfilteredManualData.filter((item) => String(item.name).toLowerCase().includes(String(text).toLowerCase()) || String(item.productCategory).toLowerCase().includes(String(text).toLowerCase()))) : setManualData(unfilteredManualData)
    }
    useEffect(() => {
        getManualsData();
    }, [])
    return (
        <>
            <Header />
            <Text content={"Manuals"} className="text-4xl my-4 ml-6" style={{ fontFamily: key.fontFamily, fontWeight: "bold", background: key.primaryColor, WebkitBackgroundClip: "text", WebkitTextFillColor: "transparent" }} />
            <div className="w-1/2 ml-7 mt-4 flex max-[634px]:w-4/5">
                <Input placeholder="Search.." style={{ borderImage: key.primaryColor + 1, fontFamily: key.fontFamily }} onChange={setSearchData}
                    onKeyPress={(target) => {
                        if (target.charCode == 13) {
                            filterdata(searchData.target.value)
                        }
                    }} />
                <button
                    className="w-fit flex items-center px-3" style={{ background: key.primaryColor }}
                    onClick={() => {
                        filterdata(searchData.target.value)
                    }}>
                    <CiSearch size={20} color="#fff" />
                </button>
            </div>
            {loading ? <Loader /> :
                <div className="p-4 rounded" style={{ overflowX: "auto", width: "100%" }}>
                    {manualData ? manualData.length > 0 ?
                        <>
                            <table className="border-1 rounded" style={{ width: "100%" }}>
                                <thead className="w-full" style={{ overflowX: "auto" }}>
                                    <div className="flex max-[893px]:text-xs" style={{ background: key.blue }}>
                                        <Text content={"Product Name"} className={"w-3/12 h-10 text-center flex items-center justify-center border-r"} style={{ fontFamily: key.fontFamily, color: "#fff" }} />
                                        <Text content={"Category"} className={"w-3/12 h-10 text-center flex items-center justify-center border-r"} style={{ fontFamily: key.fontFamily, color: "#fff" }} />
                                        <Text content={"Document"} className={"w-3/12 h-10 text-center flex items-center justify-center border-r"} style={{ fontFamily: key.fontFamily, color: "#fff" }} />
                                        <Text content={"Download"} className={"w-3/12 h-10 text-center flex items-center justify-center border-r"} style={{ fontFamily: key.fontFamily, color: "#fff" }} />
                                    </div>
                                </thead>
                                <tbody className="w-full" style={{ overflowX: "auto" }}>
                                    <div style={{ maxHeight: window.innerHeight / 1.8, overflowY: "auto" }}>
                                        {manualData ? manualData.map((item, index) => {
                                            return (
                                                <>
                                                    <div
                                                        style={{ width: "100%" }}
                                                        key={index}>
                                                        <div className="w-full flex text-sm max-[893px]:text-xs">
                                                            <Text content={item?.name || "-"} className={"w-3/12 text-left flex items-center justify-center border"} style={{ background: key.primaryColor, WebkitBackgroundClip: "text", WebkitTextFillColor: "transparent", fontFamily: key.fontFamily }} />
                                                            <Text content={item?.productCategory[0]?.toUpperCase() + item?.productCategory?.slice(1) || "-"} className={"w-3/12 text-left flex items-center justify-center border"} style={{ background: key.primaryColor, WebkitBackgroundClip: "text", WebkitTextFillColor: "transparent", fontFamily: key.fontFamily }} />
                                                            <Text content={item?.document[0]?.toUpperCase() + item?.document?.slice(1) || "-"} className={"w-3/12 text-center flex items-center justify-center border h-12"} style={{ background: key.primaryColor, WebkitBackgroundClip: "text", WebkitTextFillColor: "transparent", fontFamily: key.fontFamily }} />
                                                            <div className="flex items-center justify-center w-3/12 border h-12">
                                                                {selectedIndex == index ?
                                                                    <Spinner size={"sm"} style={{ color: key.blue }} />
                                                                    : <FaDownload size={20} color={key.blue} onClick={() => {
                                                                        setSelectedIndex(index);
                                                                        onDownload(`${MANUAL_URL}/${item?.filename}`)
                                                                        setSelectedIndex();
                                                                    }} />
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </>
                                            )
                                        }
                                        ) : <></>}
                                    </div>
                                </tbody>
                            </table>
                        </>
                        : <>
                        </> : <></>}
                </div>}
            <Footer />
        </>
    )
}
export default Manuals;