import { ToastContainer, toast } from "react-toastify"
import { FormGroup, Input, Label, Modal, ModalBody, ModalFooter } from "reactstrap"
import Text from "../../atom/Text"
import { key } from "../../utils/consts"
import Button from "../../atom/Button"
import { useState } from "react"

const SelectProductTypeModal = ({ openModal, setOpenModal, couponData, couponProductTypes, setSelectedProductType, couponIndex, setSelectedCouponIndex, getDiscountData }) => {
    const [selectedType, setSelectedType] = useState("");
    const onSubmit = async () => {
        if(selectedType !== ""){
            await setSelectedProductType(selectedType)
            await setSelectedCouponIndex(couponIndex);
            await getDiscountData(couponData?._id, selectedType);
            setOpenModal(false);
        } else {
            toast.error("Please select a product type!");
        }
    }
    return (
        <>
            <ToastContainer />
            <Modal isOpen={openModal}>
                <ModalBody>
                    <Text content={"Select a product to which you want to apply this coupon."} style={{ fontFamily: key.fontFamily, opacity: "0.7" }} className={"text-sm"} />
                    <div className="my-4">
                        {couponProductTypes?.map((item) => {
                            return (
                                <FormGroup check>
                                    <Input
                                        onChange={(e) => {
                                            setSelectedType(e.target.value)
                                        }}
                                        value={item}
                                        name="radio1"
                                        type="radio"
                                    />
                                    {' '}
                                    <Label check style={{ fontFamily: key.fontFamily, color: key.blue }}>
                                        {item}
                                    </Label>
                                </FormGroup>
                            )
                        })}
                    </div>
                </ModalBody>
                <ModalFooter>
                    <Button buttonText={"Close"} className={"px-3 py-2.5 rounded"} style={{ fontFamily: key.fontFamily, backgroundColor: key.blue, color: key.secondaryColor }} onClick={() => {
                        setOpenModal(false)
                    }} />
                    <Button buttonText={"Submit"} className={"px-3 py-2.5 rounded"} style={{ fontFamily: key.fontFamily, backgroundColor: key.blue, color: key.secondaryColor }} onClick={() => {
                        if (selectedType !== "") {
                            onSubmit();
                        } else {
                            toast.error("Please Select A Type!")
                        }
                    }} />
                </ModalFooter>
            </Modal>
        </>
    )
}
export default SelectProductTypeModal;