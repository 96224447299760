import { useEffect, useState } from "react";
import { useHistory, useLocation } from 'react-router-dom';
import Text from "../../atom/Text";
import Header from "../../components/Header/Header";
import Field from "../../molecules/Field";
import { key } from "../../utils/consts";
import { editUserDataService, getUserDataService } from "../../Services/ProfileServices";
import Button from "../../atom/Button";
import Footer from "../../components/Footer";
import { HOME } from "../../utils/pathDetails";
import { FaPen } from "react-icons/fa";
import NewMobileEnterModal from "./NewMobileEnterModal";
import { validateEmail, validateGst, validatePhone } from "../../utils/validationRegex";
import { ToastContainer, toast } from "react-toastify";
import NewMobileVerifyOtpModal from "./NewMobileVerifyOtpModal";

const EditProfile = () => {
    const history = useHistory();
    const location = useLocation()
    const [userData, setUserData] = useState({});
    const [newMobileModal, setNewMobileModal] = useState(false);
    const [newMobileNumber, setNewMobileNumber] = useState("");
    const [newMobileVerifyOtpModal, setNewMobileVerifyOtpModal] = useState(false);

    const getUserData = async () => {
        const response = await getUserDataService();
        console.log("RESPONSE -->", response)
        if (response.result) {
            setUserData(response.result);
        }
    }
    const onSave = async () => {
        const validEmail = validateEmail(userData.email);
        if (validEmail) {
            const validGst = validateGst(userData.GSTNumber);
            if (validGst) {
                const response = await editUserDataService(userData);
                history.push("/")
            } else {
                toast.error("Invalid GST Number!")
            }
        } else {
            toast.error("Invalid Email!")
        }
    }
    useEffect(() => {
        if (location?.state?.otpValid == undefined) {
            history.push(HOME)
        }
        getUserData()
    }, [])
    return (
        <>
            <ToastContainer />
            <NewMobileEnterModal openModal={newMobileModal} setOpenModal={setNewMobileModal} setNewMobileNumber={setNewMobileNumber} validateMobileNumber={validatePhone} newMobileNumber={newMobileNumber} setVerifyOtpModal={setNewMobileVerifyOtpModal}/>
            <NewMobileVerifyOtpModal openModal={newMobileVerifyOtpModal} setOpenModal={setNewMobileVerifyOtpModal} newMobileNumber={newMobileNumber} refreshData={getUserData}/>
            <Header />
            <Text content={"Edit Profile"} className="text-4xl m-4" style={{ fontFamily: key.fontFamily, fontWeight: "bold", background: key.primaryColor, WebkitBackgroundClip: "text", WebkitTextFillColor: "transparent" }} />
            <div className="w-full flex justify-center py-8 mb-10" style={{ background: key.secondaryColor, borderColor:"#000" }}>
                <div className="w-1/2 p-4 rounded max-[567px]:w-4/5" style={{ backgroundColor: key.formBackground }}>
                    {userData.role === "admin" || userData.role === "b2b" ?
                        <>
                            <div className="mb-4"><Field text={"Name"} placeholder={userData.name} defaultValue={userData.name} onChange={(e) => { userData['name'] = e.target.value }} /></div>
                            <div className="mb-4"><Field text={"GST Number"} placeholder={userData.GSTNumber} defaultValue={userData.GSTNumber} onChange={(e) => { userData['GSTNumber'] = e.target.value }} /></div>
                            <div className="mb-4"><Field text={"House No. / Plot No."} placeholder={userData.houseNo} defaultValue={userData.houseNo} onChange={(e) => { userData['houseNo'] = e.target.value }} /></div>
                            <div className="mb-4"><Field text={"Area"} placeholder={userData.area} defaultValue={userData.area} onChange={(e) => { userData['area'] = e.target.value }} /></div>
                            <div className="mb-4"><Field text={"City"} placeholder={userData.city} defaultValue={userData.city} onChange={(e) => { userData['city'] = e.target.value }} /></div>
                            <div className="mb-4"><Field text={"State"} placeholder={userData.state} defaultValue={userData.state} onChange={(e) => { userData['state'] = e.target.value }} /></div>
                            <div className="mb-4"><Field text={"PIN Code"} placeholder={userData.pin} defaultValue={userData.pin} onChange={(e) => { userData['pin'] = e.target.value }} /></div>
                            <div className="mb-4"><Field text={"Email"} placeholder={userData.email} defaultValue={userData.email} onChange={(e) => { userData['email'] = e.target.value }} /></div>
                            <div className="mb-4"><Field text={"Company Name"} placeholder={userData.companyName} defaultValue={userData.companyName} onChange={(e) => { userData['companyName'] = e.target.value }} /></div>
                            <div className="mb-4"><Field text={"Company Link"} placeholder={userData.companyLink} defaultValue={userData.companyLink} onChange={(e) => { userData['companyLink'] = e.target.value }} /></div>
                            <div className="mb-4 flex w-full items-end">
                                <div className="w-full">
                                    <Field text={"Phone No."} placeholder={userData?.mobile} onChange={(e) => { userData['mobile'] = e.target.value }} disabled={true} />
                                </div>
                                {/* <Button buttonText={"Change"} className={"px-3 h-12 py-2 ml-2 rounded text-base"} style={{ background: key.primaryColor, fontFamily: key.fontFamily, color: "#fff" }} /> */}
                                <button onClick={() => { setNewMobileModal(true) }}><FaPen className="mx-3 h-12" style={{ color: "#3A7AB7" }} /></button>
                            </div>
                        </> :
                        <>
                            <div className="mb-4"><Field text={"Name"} placeholder={userData.name} defaultValue={userData.name} onChange={(e) => { userData['name'] = e.target.value }} /></div>
                            <div className="mb-4"><Field text={"GST Number"} placeholder={userData.GSTNumber} defaultValue={userData.GSTNumber} onChange={(e) => { userData['GSTNumber'] = e.target.value }} /></div>
                            <div className="mb-4"><Field text={"House No. / Plot No."} placeholder={userData.houseNo} defaultValue={userData.houseNo} onChange={(e) => { userData['houseNo'] = e.target.value }} /></div>
                            <div className="mb-4"><Field text={"Area"} placeholder={userData.area} defaultValue={userData.area} onChange={(e) => { userData['area'] = e.target.value }} /></div>
                            <div className="mb-4"><Field text={"City"} placeholder={userData.city} defaultValue={userData.city} onChange={(e) => { userData['city'] = e.target.value }} /></div>
                            <div className="mb-4"><Field text={"State"} placeholder={userData.state} defaultValue={userData.state} onChange={(e) => { userData['state'] = e.target.value }} /></div>
                            <div className="mb-4"><Field text={"PIN Code"} placeholder={userData.pin} defaultValue={userData.pin} onChange={(e) => { userData['pin'] = e.target.value }} /></div>
                            <div className="mb-4"><Field text={"Company Name"} placeholder={userData.companyName} defaultValue={userData.companyName} onChange={(e) => { userData['companyName'] = e.target.value }} /></div>
                            <div className="mb-4"><Field text={"Company Link"} placeholder={userData.companyLink} defaultValue={userData.companyLink} onChange={(e) => { userData['companyLink'] = e.target.value }} /></div>
                            <div className="mb-4"><Field text={"Email"} placeholder={userData.email} defaultValue={userData.email} onChange={(e) => { userData['email'] = e.target.value }} /></div>
                            <div className="mb-4 flex w-full items-end">
                                <div className="w-full">
                                    <Field text={"Phone No."} placeholder={userData?.mobile} onChange={(e) => { userData['mobile'] = e.target.value }} disabled={true} />
                                </div>
                                {/* <Button buttonText={"Change"} className={"px-3 h-12 py-2 ml-2 rounded text-base"} style={{ background: key.primaryColor, fontFamily: key.fontFamily, color: "#fff" }} /> */}
                                <button onClick={() => { setNewMobileModal(true) }}><FaPen className="mx-3 h-12" style={{ color: "#3A7AB7" }} /></button>
                            </div>
                        </>}
                    <div className="flex items-center justify-center max-[849px]:flex-col">
                        <Button
                            onClick={() => { onSave() }}
                            buttonText={"Save Changes"}
                            style={{
                                background: key.primaryColor,
                                color: "#fff",
                                fontFamily: key.fontFamily,
                                fontWeight: "bold"
                            }}
                            className="m-4 px-4 h-12 flex items-center justify-center rounded max-[321px]:text-sm"
                        />
                        <Button
                            onClick={() => { history.push("/") }}
                            buttonText={"Cancel"}
                            style={{
                                background: key.primaryColor,
                                color: "#fff",
                                fontFamily: key.fontFamily,
                                fontWeight: "bold"
                            }}
                            className="m-4 px-4 h-12 flex items-center justify-center rounded max-[321px]:text-sm"
                        />
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}
export default EditProfile;