import React, { useState } from "react";
import Slider from "react-slick";
import { FaGreaterThan, FaLessThan } from "react-icons/fa";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import Text from "../../atom/Text";
import { IMAGE_URL } from "../../Services/config";
import { key as utilsKey } from "../../utils/consts";
import PlantModal from "../../components/Plant/PlantModal";
import { PLANT } from "../../utils/pathDetails";
import Button from "../../atom/Button";

const PlantSlider = ({ plantData }) => {
  const [plantModalShow, setPlantModalShow] = useState(false);
  const [selectedRelatedProduct, setSelectedRelatedProduct] = useState([]);
  const [selectedProductData, setSelectedProductData] = useState(plantData[0]);
  const [selectedPlantImage, setSelectedPlantImage] = useState();
  const CustomPrevArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{
          ...style,
          backgroundColor: utilsKey.blue,
          zIndex: 2,
          left: "50px",
          padding: "1px",
          borderRadius: 200
          // height: "23px",
          // width: "24px",
        }} // Customize styles here
        onClick={onClick}
      />
    );
  };

  const CustomNextArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{
          ...style,
          background: utilsKey.blue,
          zIndex: 2,
          right: "50px",
          padding: "1px",
                    borderRadius: 200

          // height: "23px",
          // width: "24px",
        }} // Customize styles here
        onClick={onClick}
      />
    );
  };

  const settings = {
    dots: false,
    infinite: true,
    arrows: true,
    speed: 200,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    prevArrow: <CustomPrevArrow />,
    nextArrow: <CustomNextArrow />,
  };

  return (
    <>
      <PlantModal
        openModal={plantModalShow}
        setOpenModal={setPlantModalShow}
        plantData={selectedProductData}
        selectedRelatedProduct={selectedRelatedProduct}
        setSelectedRelatedProduct={setSelectedRelatedProduct}
        displayPlantImage={selectedPlantImage}
        setDisplayPlantImage={setSelectedPlantImage}
      />

      <Slider {...settings}>
        {plantData.map((item, key) => (
          <div key={item?._id}>
            <div
              className=" flex mt-2 ml-20"
              style={{
                background: utilsKey.secondaryColor,
                borderColor: "#000",
              }}
            >
              <div
                style={{
                  width: "40%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "baseline",
                    marginLeft: 17,
                    padding: "10px 0px",
                  }}
                >
                  <Text
                    content={item.name.toUpperCase()}
                    className="text-4xl leading-normal max-[1300px]:text-4xl max-[972px]:text-3xl max-[814px]:text-2xl max-[768px]:text-base"
                    style={{
                      fontFamily: utilsKey.fontFamily,
                      color: utilsKey.blue,
                      fontWeight: "bold",
                    }}
                  />
                  <Text
                    content={`Brand : ${item?.brand}`}
                    style={{
                      fontFamily: utilsKey.fontFamily,
                      color: utilsKey.blue,
                    }}
                    className={"mt-3"}
                  />
                  <Text
                    content={`Model No. : ${item?.modelNumber}`}
                    style={{
                      fontFamily: utilsKey.fontFamily,
                      color: utilsKey.blue,
                    }}
                    className={"mt-1"}
                  />
                  <Text
                    content={`Capacity : ${item?.capacity} KLD`}
                    style={{
                      fontFamily: utilsKey.fontFamily,
                      color: utilsKey.blue,
                    }}
                    className={"mt-1"}
                  />
                  <Button
                    className="text-lg max-[972px]:text-sm max-[768px]:text-xs w-5/12 py-2 my-7 rounded-lg"
                    style={{
                      fontFamily: utilsKey.fontFamily,
                      backgroundColor: utilsKey.blue,
                      color: utilsKey.secondaryColor,
                    }}
                    onClick={() => {
                      window.open(PLANT + item?._id);
                    }}
                    buttonText={"Get Quote"}
                  />
                </div>
              </div>
              <div style={{ width: "40%", alignSelf: "center" }}>
                <img
                  src={`${IMAGE_URL}/${item.images[0]}`}
                  draggable={false}
                  onMouseDown={() => {
                    return false;
                  }}
                  style={{ userDrag: "none" }}
                  alt={item.name} // Don't forget alt attribute
                />
              </div>
            </div>
          </div>
        ))}
      </Slider>
    </>
  );
};

export default PlantSlider;
