import Image from "../../atom/Image";
import Label from "../../atom/Label";
import Text from "../../atom/Text";
import { key } from "../../utils/consts";

const VerificationPending = ({ }) => {
    return (
        <>
            <div className="flex h-screen max-[810px]:flex-col max-[810px]:px-0" style={{ backgroundColor: "#000" }}>
                <div className="w-1/2 flex items-start py-12 mx-16 max-[1250px]:flex-col max-[1250px]:items-baseline max-[810px]:hidden">
                </div>
                <div className="w-1/2 flex flex-col px-8 max-[810px]:w-full " style={{ background: key.primaryColor }}>
                    <div className="w-full flex items-center justify-center py-12 px-8 max-[1250px]:flex-col max-[1250px]:items-baseline max-[810px]:w-full max-[810px]:py-0 max-[810px]:mx-0 max-[810px]:items-center max-[810px]:mt-8">
                        <Image src={'../images/logo-light.png'} className={"w-1/3"}/>
                        <div className="w-1/2 flex flex-col mx-8 max-[1250px]:w-full max-[1250px]:my-8 max-[810px]:mx-0 max-[810px]:items-center max-[810px]:">
                            <Text content={"Use Plant Perfect Life"} className="text-3xl max-[810px]:text-center" style={{ color: "#fff", fontFamily: key.fontFamily, fontWeight: "bold" }} />
                            <Text content={"Create An Account"} className="text-lg max-[810px]:text-center" style={{ color: "#fff", fontFamily: key.fontFamily }} />
                        </div>
                    </div>
                    <div className="h-full w-full mt-8 rounded-t-2xl p-10 max-[810px]:w-full max-[810px]:mr-0 max-[810px]:ml-0 max-[810px]:mt-8" style={{ backgroundColor: key.secondaryColor, boxShadow: `5px 8px 23px 0px rgba(0, 0, 0, 0.25), 0px 0px 23px 0px rgba(0, 0, 0, 0.25)` }}>
                        <div className="w-full flex flex-col items-center justify-center" style={{ background: key.primaryColor, WebkitBackgroundClip: "text", WebkitTextFillColor: "transparent" }}>
                            <Text content={"Account Verification Pending !"} className="text-3xl" style={{ fontFamily: key.fontFamily, textAlign: "center", fontWeight: "bold" }} />
                            <img src="./gif/verification-pending.gif" className="w-1/4 my-12" draggable={false} onMouseDown={()=>{return false}} style={{userDrag:"none"}}/>
                        </div>
                            <Text content={"The account ( abc@gmail.com ) is not verified by the admin yet. Once the account is verified, You will be notified to your registered mail account."} className="text-lg" style={{color:"#7E8A97", fontFamily:key.fontFamily, fontWeight:"700", textAlign:"center"}} />
                    </div>
                </div>
            </div>
        </>
    )
}
export default VerificationPending;