import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import Button from "../../atom/Button";
import { key } from "../../utils/consts";

const ConfirmCancelOrderModal = ({ openModal, setOpenModal, setBankDetailsModal }) => {
    return (
        <Modal isOpen={openModal}>
            <ModalBody style={{fontFamily: key.fontFamily}}>Do you really want to cancel the order ?</ModalBody>
            <ModalFooter>
                <Button buttonText={"No"} className={"px-4 py-1.5 border-1 rounded"} style={{fontFamily: key.fontFamily, color: key.blue, borderColor: key.blue, backgroundColor: key.secondaryColor}} onClick={()=>{
                    setOpenModal(false);
                }}/>
                <Button buttonText={"Yes"} className={"px-6 py-1.5 rounded"} onClick={()=>{
                    setOpenModal(false);
                    setBankDetailsModal(true);
                }}/>
            </ModalFooter>
        </Modal>
    )
}
export default ConfirmCancelOrderModal;