import { useEffect, useState } from "react";
import Text from "../../atom/Text";
import Header from "../../components/Header/Header";
import { key } from "../../utils/consts";
import Footer from "../../components/Footer";
import { getUserDataService } from "../../Services/ProfileServices";
import AccountInfo from "./AcccountInfo";

const Profile = ({ }) => {
    const [userData, setUserData] = useState({});
    const getUserData = async () => {
        const response = await getUserDataService();
        if (response.result) {
            setUserData(response.result);
        }
    }
    useEffect(() => {
        getUserData();
    }, [])
    return (
        <>
            <Header />
            <Text content={"Profile"} className="text-4xl m-4" style={{ fontFamily: key.fontFamily, fontWeight: "bold", background: key.primaryColor, WebkitBackgroundClip: "text", WebkitTextFillColor: "transparent" }} />
            <div className="w-full flex justify-center py-8 mb-10" style={{ background: key.secondaryColor }}>
                <AccountInfo userData={userData}/>
            </div>
            <Footer />
        </>
    )
}
export default Profile