import { useState } from "react";

import Image from "../../atom/Image";
import Text from "../../atom/Text";
import { key } from "../../utils/consts";
import OtpForm from "../../molecules/OtpForm";
import { callPostServiceData } from "../../Services/DataServices";
import { CHECKOTP } from "../../utils/urlDetails";
import { ToastContainer, toast } from "react-toastify";
import { useLocation, useHistory } from "react-router-dom";
import { RESET_PASSWORD } from "../../utils/pathDetails";
import BackgroundImage from "../../atom/BackgroundImage";



const OtpVerification = () => {
  const [otp, setOtp] = useState();
  const isMobile = window.innerWidth <= 415;
  const history = useHistory();

  const location = useLocation()

  const onClickButton = async () => {
    const data = { otp, mobile: location.state?.user?.mobile }
    const { result } = await callPostServiceData(CHECKOTP, data)
    if (result?.data) {
      if (result?.data?.statusCode) {
        toast.error(result?.data?.response)
      } else {
        toast.success("Matched successfully")
        setTimeout(() => {
          history.push(RESET_PASSWORD, { user: location.state.user });
        }, 1000);
      }

    } else {
      toast.error("Something went wrong")
    }
  }

  return (
    <div className={`${isMobile ? "" : "flex"} w-full h-screen`}>
      <ToastContainer />
      <BackgroundImage src={"./images/OTP Photo.png"}/>
      <div
        className="items-center w-10/12"
        style={{
          background: key.secondaryColor,
          fontFamily: key.fontFamily,
        }}
      >
        <div className={`tablet:flex justify-center gap-8 mt-12 pl-14 items-center`}>
          <Image
            {...{ src: "../images/logo-light.png", className: "w-60 h-20" }}
          />
          <div className={`${isMobile ? "" : "w-1/2"} flex flex-col self-end`}>
            <Text
              {...{
                content: `Your Trusted Partner in Water Treatment Excellence`,
                style: {
                  color: key.blue,
                  fontFamily: key.fontFamily,
                  fontWeight: "bold",
                  margin: 20,
                },
              }}
              className={"text-2xl"}
            />
          </div>
        </div>
        <div className="pr-12 pl-12">
          <OtpForm {...{ mobileNumber: "+91" + location.state?.user?.mobile, otp, setOtp, isMobile, onClick: onClickButton }} />
        </div>
      </div>
    </div>
  );
};

export default OtpVerification;
