import { useParams } from "react-router-dom";
import Header from "../../components/Header/Header"
import { useContext, useEffect, useState } from "react";
import { getCouponsByType, getServiceDataById } from "../../Services/OrderServices";
import Loader from "../../components/Loader";
import { IoDocumentTextOutline } from "react-icons/io5";
import Button from "../../atom/Button";
import { Spinner } from "reactstrap";
import Text from "../../atom/Text";
import { key } from "../../utils/consts";
import { priceFormat } from "../../utils/priceFunctions";
import { CartContext } from "../ShoppingCart/CartContext";
import { CiShoppingCart } from "react-icons/ci";
import DisplayInput from "../../components/DisplayInput";
import Footer from "../../components/Footer";
import { ToastContainer, toast } from "react-toastify";
import { sendQuotationFromCart } from "../../Services/CartServices";
import Coupons from "../../components/Coupons/Coupons";

const Service = () => {
    const { serviceId } = useParams();
    const { cartData, updateCart } = useContext(CartContext)
    const [serviceData, setServiceData] = useState({});
    const [showQuantity, setShowQuantity] = useState(true);
    const [loadingQuotation, setLoadingQuotation] = useState(false);
    const [loading, setLoading] = useState(false);
    const [productType, setProductType] = useState("services")
    const [couponData, setCouponData] = useState([]);

    const getServiceData = async () => {
        setLoading(true);
        const response = await getServiceDataById(serviceId);
        if (response?.result) {
            setServiceData(response?.result)
            setLoading(false);
        }
    }
    const sendQuotation = async () => {
        setLoadingQuotation(true);
        console.log(">serviceData",serviceData)
        const apiBody = {
            products: [{
                name: serviceData?.name,
                b2bPrice: serviceData?.b2bPrice,
                b2cPrice: serviceData?.b2cPrice,
                unit: serviceData?.unit,
                capacity: "0000",
                type: "services",
                quantity: 1,
                gst: serviceData?.gstPercentage,
                details: serviceData?.details,
                category: serviceData?.category,
                productId: serviceData?._id
                
            }],
            type: "serviceQuotation"
        };
        const response = await sendQuotationFromCart(apiBody)
        console.log("RES --->", response?.result)
        if (response?.result?.type == "success") {
            setLoadingQuotation(false)
            toast.success(response?.result?.message)
        } else {
            setLoadingQuotation(false)
            toast.error(response?.result?.message)
        }
    }
    const getCoupons = async () => {
        const response = await getCouponsByType("services");
        if (response?.result) {
            setCouponData(response?.result)
        }
    }
    useEffect(() => {
        getServiceData();
        getCoupons();
    }, [])
    return (
        <>
            <ToastContainer />
            <Header />
            {!loading ? <>
                <div className="w-full flex mb-3">
                    <div className="w-3/4 pl-4 pt-3">
                        <Text content={serviceData?.name} className={"text-3xl border-b pb-3 w-4/5"} style={{ fontFamily: key.fontFamily, fontWeight: "bold" }} />
                        <div className="flex gap-2">
                            <Text content={"Unit : "} className={"mt-3"} style={{ fontFamily: key.fontFamily, fontWeight: "bold" }} />
                            <Text content={serviceData?.unit} className={"mt-3"} style={{ fontFamily: key.fontFamily }} />
                        </div>
                        <Text content={`${window.localStorage.getItem("userRole") == "b2c" ? priceFormat(serviceData?.b2cPrice + serviceData?.b2cPrice * serviceData?.gstPercentage / 100) : priceFormat(serviceData?.b2bPrice + serviceData?.b2bPrice * serviceData?.gstPercentage / 100)} (Incl. of all taxes)`} className={"text-sm mt-8"} style={{ fontFamily: key.fontFamily, color: "gray" }} />
                        <div className="mt-2.5 mb-3 pb-3 flex items-center max-[848px]:flex-col max-[848px]:items-start">
                            <Text content={window.localStorage.getItem("userRole") == "b2c" ? priceFormat(serviceData?.b2cPrice) : priceFormat(serviceData?.b2bPrice)} className={"text-3xl"} style={{ fontFamily: key.fontFamily, fontWeight: "bolder" }} />
                            <Text content={`+ ${priceFormat(window.localStorage.getItem("userRole") == "b2c" ? serviceData?.b2cPrice * serviceData?.gstPercentage / 100 : serviceData?.b2bPrice * serviceData?.gstPercentage / 100)} GST`} className={"text-sm ml-1 max-[848px]:mt-1"} style={{ fontFamily: key.fontFamily, color: "gray" }} />
                        </div>
                        <Text content={"Details"} className={"mt-3"} style={{ fontFamily: key.fontFamily, fontWeight: "bold" }} />
                        <div className="w-11/12">
                            <DisplayInput inputText={serviceData?.details} />
                        </div>
                        <Text content={"Terms & Conditions"} className={"mt-6"} style={{ fontFamily: key.fontFamily, fontWeight: "bold" }} />
                        <div className="w-11/12">
                            <DisplayInput inputText={serviceData?.termsandconditions} />
                        </div>
                    </div>
                    <div className="w-1/4 px-3 max-[848px]:w-full" style={{ backgroundColor: key.secondaryColor, overflowY:"scroll" }}>
                        <Text content={`${window.localStorage.getItem("userRole") == "b2c" ? priceFormat(serviceData?.b2cPrice + serviceData?.b2cPrice * serviceData?.gstPercentage / 100) : priceFormat(serviceData?.b2bPrice + serviceData?.b2bPrice * serviceData?.gstPercentage / 100)} (Incl. of all taxes)`} className={"text-sm mt-8 max-[848px]:hidden"} style={{ fontFamily: key.fontFamily, color: "gray" }} />
                        <div className="my-3 flex flex-col items-start max-[848px]:hidden">
                            <Text content={window.localStorage.getItem("userRole") == "b2c" ? priceFormat(serviceData?.b2cPrice) : priceFormat(serviceData?.b2bPrice)} className={"text-3xl"} style={{ fontFamily: key.fontFamily, fontWeight: "bolder" }} />
                            <Text content={`+ ${priceFormat(window.localStorage.getItem("userRole") == "b2c" ? serviceData?.b2cPrice * serviceData?.gstPercentage / 100 : serviceData?.b2bPrice * serviceData?.gstPercentage / 100)} GST`} className={"text-sm mt-2"} style={{ fontFamily: key.fontFamily, color: "gray" }} />
                        </div>
                        <div className="mt-14 flex items-center flex-col justify-center gap-6 max-[848px]:mt-0 max-[848px]:my-7">
                            {showQuantity && cartData?.products && cartData?.products?.services.filter((item) => item?.productId?._id == serviceData?._id)?.length < 1 ? <button
                                className="flex w-52 h-12 rounded items-center justify-center"
                                style={{ background: key.blue }}
                                onClick={async () => {
                                    await setShowQuantity(false)
                                    await updateCart(productType, 1, serviceData?._id)
                                    await setShowQuantity(true)
                                }}
                            >
                                <CiShoppingCart
                                    className={"text-sm"}
                                    size={30}
                                    style={{ fontFamily: key.fontFamily, fontWeight: "700", color: "#fff" }}
                                />
                                <Text
                                    className={"text-sm ml-4"}
                                    content={"Add to Cart"}
                                    style={{ fontFamily: key.fontFamily, fontWeight: "700", color: "#fff" }}
                                />
                            </button> :
                                <div className="w-52 h-12 flex rounded items-center justify-center" style={{ background: key.blue }}>
                                    <Button buttonText={"-"} className={"text-base w-1/4 h-12 rounded-l flex items-center justify-center"} style={{ fontFamily: key.fontFamily, backgroundColor: key.blue, color: key.secondaryColor }} onClick={async () => {
                                        await setShowQuantity(false)
                                        await updateCart(productType, cartData?.products?.services?.filter((item) => item?.productId?._id == serviceData?._id)[0]?.quantity - 1, serviceData?._id)
                                        await setShowQuantity(true)
                                    }} />
                                    <Text content={cartData?.products ? cartData?.products?.services?.filter((item) => item?.productId?._id == serviceData?._id)[0]?.quantity : 0} className={"text-base w-2/4 h-12 flex items-center justify-center border-y"} style={{ fontFamily: key.fontFamily, backgroundColor: key.secondaryColor, color: key.blue, fontWeight: 'bold' }} />
                                    <Button buttonText={"+"} className={"text-base w-1/4 h-12 flex items-center justify-center rounded-r"} style={{ fontFamily: key.fontFamily, backgroundColor: key.blue, color: key.secondaryColor }} onClick={async () => {
                                        await setShowQuantity(false)
                                        await updateCart(productType, cartData?.products?.services?.filter((item) => item?.productId?._id == serviceData?._id)[0]?.quantity + 1, serviceData?._id)
                                        await setShowQuantity(true)
                                    }} />
                                </div>
                            }
                            <button
                                className="flex w-52 h-12 rounded items-center justify-center"
                                style={{ background: key.blue }}
                                disabled={loading ? true : false}
                                onClick={() => { sendQuotation() }}
                            >
                                {!loadingQuotation ?
                                    <>
                                        <IoDocumentTextOutline
                                            className={"text-sm"}
                                            size={23}
                                            style={{ fontFamily: key.fontFamily, fontWeight: "700", color: "#fff" }}
                                        />
                                        <Text
                                            className={"text-sm ml-4"}
                                            content={"Get Quote"}
                                            style={{ fontFamily: key.fontFamily, fontWeight: "700", color: "#fff" }}
                                        />
                                    </> : <Spinner size={"sm"} style={{ color: key.secondaryColor }} />}
                            </button>
                            <Text content={"(Quotation will be sent to your registered email ID.)"}
                                className={"text-xs italic"} style={{ color: "gray", fontFamily: key.fontFamily }} />
                        </div>
                        <Coupons couponData={couponData} />
                    </div>
                </div>
            </> : <Loader />}
            <Footer />
        </>
    )
}
export default Service;