import { Label, Modal, ModalBody, ModalFooter, ModalHeader, Spinner, Button as SpinnerButton } from "reactstrap";
import { key } from "../../utils/consts";
import Text from "../../atom/Text";
import Button from "../../atom/Button";
import { LiaTimesSolid } from "react-icons/lia";
import { priceFormat } from "../../utils/priceFunctions";
import { sendQuotationToUser } from "../../Services/OrderServices";
import { Parser } from "html-to-react";
import { useContext, useState } from "react";
import DisplayInput from "../DisplayInput";
import { CartContext } from "../../pages/ShoppingCart/CartContext";
import { sendQuotationFromCart } from "../../Services/CartServices";
import { ToastContainer, toast } from "react-toastify";

const ServiceModal = ({ openModal, setOpenModal, serviceData }) => {
    const { cartData, updateCart } = useContext(CartContext)
    const [showQuantity, setShowQuantity] = useState(true);
    const [loading, setLoading] = useState(false);
    const sendQuotation = async () => {
        setLoading(true);
        console.log(">serviceData",serviceData)
        const apiBody = {
            products: [{
                name: serviceData?.name,
                b2bPrice: serviceData?.b2bPrice,
                b2cPrice: serviceData?.b2cPrice,
                unit: serviceData?.unit,
                capacity: undefined,
                type: "services",
                quantity: 1,
                gst: serviceData?.gstPercentage,
                details: serviceData?.details,
                productId:serviceData?._id
            }],
            type: "serviceQuotation"
        };
        const response = await sendQuotationFromCart(apiBody)
        if (response?.result?.type == "success") {
            setLoading(false)
            toast.success(response?.result?.message)
            setTimeout(() => {
                setOpenModal(false)
            }, 3000)
        } else {
            setLoading(false)
            toast.error(response?.result?.message)
        }
    }
    return (
        <>
            <Modal isOpen={openModal} toggle={() => { setOpenModal(!openModal) }}>
                <ToastContainer />
                <ModalBody>
                    <div className="w-full flex justify-end">
                        <button><LiaTimesSolid onClick={() => { setOpenModal(false) }} /></button>
                    </div>
                    <Text className="text-lg" style={{ fontFamily: key.fontFamily, fontWeight: "bold" }} content={serviceData.name} />
                    <DisplayInput inputText={serviceData?.details} />
                    <Text className="text-base mt-3" style={{ fontFamily: key.fontFamily, fontWeight: "bold" }} content={priceFormat(localStorage.getItem("role") == 'b2c' ? serviceData.b2cPrice : serviceData.b2bPrice)} />
                    <DisplayInput inputText={serviceData.termsandconditions} />
                </ModalBody>
                <ModalFooter style={{ display: "flex", justifyContent: "center", border: "none" }}>
                    {showQuantity && cartData?.products && cartData?.products?.services.filter((item) => item?.productId?._id == serviceData?._id)?.length < 1 ? <Button
                        className="text-sm w-36 h-9 rounded"
                        style={{ fontFamily: key.fontFamily, background: key.blue, border: "none", color: "#fff", fontWeight: "bold" }}
                        buttonText={"Add to Cart"}
                        onClick={async () => {
                            await setShowQuantity(false)
                            await updateCart("services", 1, serviceData?._id)
                            await setShowQuantity(true)
                        }} /> :
                        <div className="w-36 h-9 rounded flex items-center justify-center" style={{ backgroundColor: key.blue }}>
                            <Button className={"w-1/4 h-9 text-sm flex items-center justify-center rounded-l"} style={{ backgroundColor: key.blue, color: key.secondaryColor, fontFamily: key.fontFamily }} onClick={async () => {
                                await setShowQuantity(false)
                                await updateCart("services", cartData?.products?.services.filter((item) => item?.productId?._id == serviceData?._id)[0]?.quantity - 1, serviceData?._id)
                                await setShowQuantity(true)
                            }} buttonText={"-"} />
                            <Text content={cartData?.products && cartData?.products?.services.filter((item) => item?.productId?._id == serviceData?._id)[0]?.quantity} className={"h-9 w-2/4 text-sm flex items-center justify-center border-y"} style={{ fontFamily: key.fontFamily, color: key.blue, backgroundColor: key.secondaryColor }} />
                            <Button className={"w-1/4 h-9 text-sm flex items-center justify-center rounded-r"} style={{ backgroundColor: key.blue, color: key.secondaryColor, fontFamily: key.fontFamily }} onClick={async () => {
                                await setShowQuantity(false)
                                await updateCart("services", cartData?.products?.services.filter((item) => item?.productId?._id == serviceData?._id)[0]?.quantity + 1, serviceData?._id)
                                await setShowQuantity(true)
                            }} buttonText={"+"} />
                        </div>}
                    {!loading ? <Button
                        className="text-sm w-36 h-9 rounded"
                        style={{ fontFamily: key.fontFamily, background: key.blue, border: "none", color: "#fff", fontWeight: "bold" }}
                        buttonText={"Get Quote"}
                        onClick={() => {
                            sendQuotation()
                        }} /> : <SpinnerButton style={{ backgroundColor: key.blue, border:"none"}} className="w-36 h-9 rounded ">
                        <Spinner size={"sm"} style={{ color: key.secondaryColor }} />
                    </SpinnerButton>}
                </ModalFooter>
            </Modal>
        </>
    )
}
export default ServiceModal;