export const getOrderStatus = (status) => {
    if (status == "ordered") {
        return "Ordered";
    } else if (status == "advancePayment") {
        return "Advance Payment"
    } else if (status == "orderConfirm") {
        return "Order Confirm"
    } else if (status == "rawMaterialProcurement") {
        return "Process 1 (Raw Material Procurement)"
    } else if (status == "underManufacture") {
        return "Process 2 (Under Manufacture)"
    } else if (status == "secondPayment") {
        return "2nd Payment"
    } else if (status == "finalTouch") {
        return "Process 3 (Under Final Touch)"
    } else if (status == "thirdPayment") {
        return "3rd Payment"
    } else if (status == "underPackaging") {
        return "Under Packaging"
    } else if (status == "availableForPickup") {
        return "Available For Pick-Up"
    } else if (status == "awaitingDocuments") {
        return "Awaiting Documents"
    } else if (status == "delayCharges") {
        return "Delay Charges"
    } else if (status == "materialDispatch") {
        return "Material Dispatch"
    } else if (status == "hold") {
        return "Order On Hold"
    } else if (status == "notApplicable") {
        return "Not Applicable"
    } else if (status == "refund") {
        return "Refund"
    } else if (status == "cancelled") {
        return "Cancelled"
    }
}