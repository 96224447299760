import { useHistory } from "react-router-dom";
import { key } from "../../../utils/consts"
import { USE_QUOTATION_NUMBER } from "../../../utils/pathDetails";
import { CardBody, Collapse, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from "reactstrap";
import { useEffect, useState } from "react";
import CategoryDropdown from "./CategoryDropdown";
import { callGetCategoriesData } from "../../../Services/OrderServices";

const Order = ({ orderSelected, setOrderSelected, theme }) => {
    const [generateNewQuotationSelected, setGenerateNewQuotationSelected] = useState(false);
    const [waterTreatmentPlantSelected, setWaterTreatmentPlantSelected] = useState(false);
    const [accessoriesSelected, setAccessoriesSelected] = useState(false);
    const [sparePartsSelected, setSparePartsSelected] = useState(false);
    const [servicesSelected, setServicesSelected] = useState(false);
    const [sparePartsCategories, setSparePartsCategories] = useState([]);
    const [accessoriesCategories, setAccessoriesCategories] = useState([]);
    const [servicesCategories, setServicesCategories] = useState([]);
    const [plantCategories, setPlantCategories] = useState([]);
    const [categories, setCategories] = useState([]);
    const history = useHistory();
    const getCategoriesData = async () => {
        const responseSpareParts = await callGetCategoriesData("spareParts");
        setSparePartsCategories(responseSpareParts?.result);
        const responseAccessories = await callGetCategoriesData("accessories");
        setAccessoriesCategories(responseAccessories?.result);
        const responseServices = await callGetCategoriesData("services");
        setServicesCategories(responseServices?.result);
        const responsPlants = await callGetCategoriesData("plants");
        setPlantCategories(responsPlants?.result);
    }
    useEffect(() => {
        getCategoriesData();
    }, [])
    return (
        <Dropdown isOpen={orderSelected} toggle={() => { setOrderSelected(!orderSelected) }} direction={"down"} onMouseEnter={()=>{
            setOrderSelected(true)
        }}
        onMouseLeave={()=>{
            setOrderSelected(false)
        }}>
            <DropdownToggle className="text-base py-2 mr-3" style={{ fontFamily: key.fontFamily, fontWeight: "700", color: theme == "light" ? "#000" : "#fff", backgroundColor: theme == "light" ? "#fff" : "transparent", border: "none", width:"fit-content" }}
            >Order</DropdownToggle>
            <DropdownMenu style={{ background: key.primaryColor, border: "none" }}>
                <DropdownItem
                    className="text-sm bg-transparent"
                    style={{ fontFamily: key.fontFamily, fontWeight: "bold", color: "#fff", paddingLeft:0, paddingRight:0, paddingBottom:0 }}
                    onClick={() => {
                        setGenerateNewQuotationSelected(true)
                    }}
                    onMouseEnter={() => { setGenerateNewQuotationSelected(true) }}
                    onMouseLeave={() => { setGenerateNewQuotationSelected(false) }}
                >
                    {/* <text className="mx-3 mt-12 text-center">Generate New Quotation</text>
                    <Collapse className="mt-3" isOpen={generateNewQuotationSelected} style={{ visibility: "visible" }}>
                        <CardBody style={{
                            background: key.secondaryColor,
                            color: key.blue,
                            // border: "none",
                            fontFamily: key.fontFamily,
                            textAlign: "center",
                            // borderColor: key.blue,
                            fontWeight: "bold",
                        }}
                            className="text-xs py-3 border-x"
                            onClick={() => { }}>
                            Water Treatment Plants
                        </CardBody>
                        <CardBody style={{
                            background: key.secondaryColor,
                            color: key.blue,
                            fontFamily: key.fontFamily,
                            // borderColor: key.blue,
                            textAlign: "center",
                            fontWeight: "bold",
                        }}
                            className="text-xs py-3 border-1"
                            onClick={() => { }}>
                            Spare Parts
                        </CardBody>
                        <CardBody style={{
                            background: key.secondaryColor,
                            color: key.blue,
                            fontFamily: key.fontFamily,
                            // borderColor: key.blue,
                            textAlign: "center",
                            fontWeight: "bold",
                        }}
                            className="text-xs py-3 border-x"
                            onClick={() => { }}>
                            Accessories
                        </CardBody>
                        <CardBody style={{
                            background: key.secondaryColor,
                            color: key.blue,
                            fontFamily: key.fontFamily,
                            // borderColor: key.blue,
                            textAlign: "center",
                            fontWeight: "bold",
                        }}
                            className="text-xs py-3 border-1"
                            onClick={() => { }}>
                            Services
                        </CardBody>
                    </Collapse> */}
                    <Dropdown
                        isOpen={generateNewQuotationSelected}
                        toggle={() => { setGenerateNewQuotationSelected(!generateNewQuotationSelected) }}
                        direction="end">
                        <DropdownToggle style={{ background: "transparent", border: "none" }} onClick={() => { setGenerateNewQuotationSelected(true) }}>
                            <DropdownItem className="text-sm bg-transparent" style={{ fontFamily: key.fontFamily, fontWeight: "bold", color: "#fff" }} onClick={() => {  }}>
                            <text className="text-sm" style={{fontWeight:"bold"}}>Generate New Quotation</text>
                            </DropdownItem>
                        </DropdownToggle>
                        <DropdownMenu className="border-1" style={{ background: key.secondaryColor, borderColor: key.blue }}>
                            <CategoryDropdown isOpen={waterTreatmentPlantSelected} field={"Water Treatment Plants"} setOpen={setWaterTreatmentPlantSelected} categoryData={plantCategories} />
                            <DropdownItem divider />
                            <CategoryDropdown isOpen={accessoriesSelected} field={"Accessories"} setOpen={setAccessoriesSelected} categoryData={accessoriesCategories} />
                            <DropdownItem divider />
                            <CategoryDropdown isOpen={sparePartsSelected} field={"Spare Parts"} setOpen={setSparePartsSelected} categoryData={sparePartsCategories} />
                            <DropdownItem divider />
                            <CategoryDropdown isOpen={servicesSelected} field={"Services"} setOpen={setServicesSelected} categoryData={servicesCategories} />
                        </DropdownMenu>
                    </Dropdown>
                </DropdownItem>
                <DropdownItem divider />
                <DropdownItem
                    onClick={()=>{history.push(USE_QUOTATION_NUMBER)}}
                    className="text-sm bg-transparent text-center"
                    style={{ fontFamily: key.fontFamily, fontWeight: "bold", color: "#fff" }}>
                    <text className="mx-3">Order With Quotation</text>
                </DropdownItem>
            </DropdownMenu>
        </Dropdown>
    )
}
export default Order;