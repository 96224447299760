export const key = {
    fontFamily: "Montserrat, sans-serif",
    primaryColor: `linear-gradient(to right, #184295, #3A7AB7)`,
    homeBackgroundColor: `linear-gradient(to right, #184295, #3A7AB7, #3A7AB7)`,
    buttonBackgroundColor: `linear-gradient(to right, #348DE0, #99B5EB)`,
    buttonBackgroundColorLeft: `linear-gradient(to left, #348DE0, #99B5EB)`,
    secondaryColor: "#fff",
    blue: "#3A7AB7",
    hoverColor: "#2263a1",
    formBackground: "rgb(211 211 211 / 36%)",
    formShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
    primaryColorOne:"#184295"
}