import { useParams } from "react-router-dom";
import Footer from "../../components/Footer";
import Header from "../../components/Header/Header";
import { callGetSearchResult } from "../../Services/DataServices";
import { useEffect, useState } from "react";
import Text from "../../atom/Text";
import { key } from "../../utils/consts";
import Product from "../../molecules/Product";
import PlayVideoModal from "../Resources/Videos/PlayVideoModal";
import Video from "../../molecules/Video";
import Service from "../../molecules/Service";
import Plant from "../../molecules/Plant";

const SearchResult = () => {
    const { searchText } = useParams();
    const [searchData, setSearchData] = useState({});
    const [videoStart, setVideoStart] = useState(false);
    const [selectedVideoData, setSelectedVideoData] = useState({});
    const getSearchResultData = async () => {
        const apiBody = { searchText: searchText }
        const response = await callGetSearchResult(apiBody);
        console.log("RES- -->", response)
        setSearchData(response?.result);
    }
    useEffect(() => {
        getSearchResultData()
    }, [])
    return (
        <>
            <Header />
            <PlayVideoModal openModal={videoStart} setOpenModal={setVideoStart} videoData={selectedVideoData} />
            <div className="p-4">
                <Text content={`Search Results for ${searchText}`} className={"text-xl"} style={{ fontFamily: key.fontFamily, color: key.blue, fontWeight: "bold" }} />
                {searchData?.Plant && searchData?.Plant?.length > 0 ?
                    <>
                        <hr className="my-3 w-1/3" />
                        <Text content={"Plants"} className={"text-xl mt-10 italic"} style={{ fontFamily: key.fontFamily, color: key.blue }} />
                        {searchData?.Plant.map((item) => {
                            return (
                                <Plant plantData={item} />
                            )
                        })}
                    </>
                    : <></>}
                {searchData?.SpareParts && searchData?.SpareParts?.length > 0 ?
                    <>
                        <hr className="my-3 w-1/3" />
                        <Text content={"Spare Parts"} className={"text-xl mt-10 italic"} style={{ fontFamily: key.fontFamily, color: key.blue }} />
                        <div className="w-full flex justify-start">
                            <div className="w-4/5 mb-20 grid grid-cols-4 gap-x-10 max-[1045px]:grid-cols-3 max-[757px]:grid-cols-2 max-[550px]:grid-cols-1">
                                {searchData?.SpareParts.map((item) => {
                                    return (
                                        <Product productData={item} productType={"spareParts"} />
                                    )
                                })}
                            </div>
                        </div>
                    </>
                    : <></>}
                {searchData?.Accessories && searchData?.Accessories?.length > 0 ?
                    <>
                        <hr className="my-3 w-1/3" />
                        <Text content={"Accessories"} className={"text-xl mt-10 italic"} style={{ fontFamily: key.fontFamily, color: key.blue }} />
                        <div className="w-full flex justify-start">
                            <div className="w-4/5 mb-20 grid grid-cols-4 gap-x-10 max-[1045px]:grid-cols-3 max-[757px]:grid-cols-2 max-[550px]:grid-cols-1">
                                {searchData?.Accessories.map((item) => {
                                    return (
                                        <Product productData={item} productType={"accessories"} />
                                    )
                                })}
                            </div>
                        </div>
                    </>
                    : <></>}
                {searchData?.ResourceVideos && searchData?.ResourceVideos?.length > 0 ?
                    <>
                        <hr className="my-3 w-1/3" />
                        <Text content={"Videos"} className={"text-xl mt-10 my-3 italic"} style={{ fontFamily: key.fontFamily, color: key.blue }} />
                        <div className="w-full flex justify-start">
                            <div className="w-4/5 mb-20 grid grid-cols-4 gap-x-10 max-[1045px]:grid-cols-3 max-[757px]:grid-cols-2 max-[550px]:grid-cols-1">
                                {searchData?.ResourceVideos.map((item) => {
                                    return (
                                        <Video videoData={item} onClick={() => {
                                            setSelectedVideoData(item);
                                            setVideoStart(true);
                                        }} />
                                    )
                                })}
                            </div>
                        </div>
                    </>
                    : <></>}
                {searchData?.Services && searchData?.Services?.length > 0 ?
                    <>
                        <hr className="my-3 w-1/3" />
                        <Text content={"Services"} className={"text-xl mt-10  italic"} style={{ fontFamily: key.fontFamily, color: key.blue }} />
                        <div className="w-full flex justify-start">
                            <div className="w-4/5 mb-20 grid grid-cols-4 gap-x-10 max-[1045px]:grid-cols-3 max-[757px]:grid-cols-2 max-[550px]:grid-cols-1">
                                {searchData?.Services.map((item) => {
                                    return (
                                        <Service serviceData={item} />
                                    )
                                })}
                            </div>
                        </div>
                    </>
                    : <></>}
            </div>
            <Footer />
        </>
    )
}
export default SearchResult;