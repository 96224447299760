import { useState } from "react";
import Text from "../../atom/Text";
import { key } from "../../utils/consts";
import { priceFormat } from "../../utils/priceFunctions";
import BomDescription from "./BomDescription";
import ExtraAccessories from "./ExtraAccessories";
import DisplayInput from '../../components/DisplayInput';

const PlantDescription = ({ plantData, addPrice, setExtraAccssoriesPrice, extraAccessoriesPrice }) => {
    const [bomShow, setBomShow] = useState(true)
    return (
        <>
            <div className="w-3/5 px-7 pt-4 pb-12 border-r max-[848px]:w-full" style={{ overflowY: "auto" }}>
                <Text content={plantData?.name ? plantData?.name : null} className={"text-3xl"} style={{ fontFamily: key.fontFamily, fontWeight: "bolder" }} />
                <Text content={`Brand : ${plantData?.brand}`} className={"mt-8"} style={{ fontFamily: key.fontFamily, }} />
                <Text content={`Model Number : ${plantData?.modelNumber}`} className={"mt-2"} style={{ fontFamily: key.fontFamily, }} />
                <Text content={`Capacity : ${plantData?.capacity} KLD`} className={"mt-2"} style={{ fontFamily: key.fontFamily, }} />
                <Text content={`${window.localStorage.getItem("userRole") == "b2c" ? priceFormat(plantData?.b2cPrice + plantData?.b2cPrice * plantData?.gstPercentage / 100 + extraAccessoriesPrice?.totalPrice) : priceFormat(plantData?.b2bPrice + plantData?.b2bPrice * plantData?.gstPercentage / 100 + extraAccessoriesPrice?.totalPrice)} (Incl. of all taxes)`} className={"text-sm mt-8"} style={{ fontFamily: key.fontFamily, color: "gray" }} />
                <div className="my-2.5 flex items-center max-[848px]:flex-col max-[848px]:items-start">
                    <Text content={window.localStorage.getItem("userRole") == "b2c" ? priceFormat(plantData?.b2cPrice + extraAccessoriesPrice?.priceWithoutGst) : priceFormat(plantData?.b2bPrice + extraAccessoriesPrice?.priceWithoutGst)} className={"text-3xl"} style={{ fontFamily: key.fontFamily, fontWeight: "bolder" }} />
                    <Text content={`+ ${priceFormat(window.localStorage.getItem("userRole") == "b2c" ? plantData?.b2cPrice * plantData?.gstPercentage / 100 + extraAccessoriesPrice?.gstPrice : plantData?.b2bPrice * plantData?.gstPercentage / 100 + extraAccessoriesPrice?.gstPrice)} GST`} className={"text-sm ml-1 max-[848px]:mt-1"} style={{ fontFamily: key.fontFamily, color: "gray" }} />
                </div>
                <div className="">
                    <Text content={"Parameters"} className={"text-base mt-7 border-b pb-2"} style={{ fontFamily: key.fontFamily, fontWeight: "bold" }} />
                    <DisplayInput inputText={plantData?.parameters} />
                </div>
                <div className="flex items-center mt-12 mb-1 max-[848px]:flex-col max-[848px]:items-start">
                    <Text content={"Bill Of Materials (BOM)"} className={"text-lg"} style={{ fontFamily: key.fontFamily, fontWeight: "bold" }} />
                </div>
                <hr className="w-11/12 my-3.5" />
                <BomDescription plantData={plantData} showBom={bomShow} setShowBom={setBomShow} />
                <ExtraAccessories plantData={plantData} setBomShow={setBomShow} addPrice={addPrice} setExtraAccssoriesPrice={setExtraAccssoriesPrice}/>
                {(plantData?.additionalInformation && plantData?.additionalInformation?.length > 0) || (plantData?.technicalDetails && plantData?.technicalDetails?.length > 0) ?
                    <div className="py-4 px-3 border mt-6">
                        <Text content={"About This Product"} className={"text-lg"} style={{ fontFamily: key.fontFamily, fontWeight: "bolder" }} />
                        <hr className="w-5/6" />
                        {plantData?.technicalDetails && plantData?.technicalDetails?.length > 0 ?
                            <>
                                <Text content={"Technical Details"} className={"text-base mt-7"} style={{ fontFamily: key.fontFamily, fontWeight: "bold" }} />
                                <DisplayInput inputText={plantData?.technicalDetails} /> </> : <></>}

                        {plantData?.additionalInformation && plantData?.additionalInformation?.length > 0 ? <><Text content={"Additional Information"} className={"text-base mt-4"} style={{ fontFamily: key.fontFamily, fontWeight: "bold" }} />
                            <DisplayInput inputText={plantData?.additionalInformation} /> </> : <></>}
                    </div> : <></>}
            </div>
        </>
    )
}
export default PlantDescription;