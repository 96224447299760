import { useEffect, useState } from "react"
import { useParams } from "react-router-dom";
import Footer from "../../components/Footer"
import Header from "../../components/Header/Header"
import { callGetAllCategoriesData, callGetCategoriesById, callGetProductByCategories } from "../../Services/OrderServices";
import Text from "../../atom/Text";
import { key } from "../../utils/consts";
import Product from "../../molecules/Product";
import Plant from '../../molecules/Plant';
import Service from "../../molecules/Service";
import { CiSearch } from "react-icons/ci";
import { Input } from "reactstrap";

const Category = () => {
    const { categoryId, productType } = useParams();
    const [productData, setProductData] = useState([]);
    const [unfilteredData, setUnfilteredData] = useState([]);
    const [searchData, setSearchData] = useState("");
    const [category, setCategory] = useState("");
    const getProductDataByCategory = async () => {
        const response = await callGetProductByCategories(productType, categoryId);
        setProductData(response?.result);
        setUnfilteredData(response?.result)
    }
    const getCategory = async () => {
        const response = await callGetCategoriesById(categoryId);
        setCategory(response?.result?.category)
    }
    const getAllCategoriesData = async () => {
        const response = await callGetAllCategoriesData(productType);
        setProductData(response?.result)
        setUnfilteredData(response?.result)
    }
    useEffect(() => {
        if (categoryId != "get") {
            getProductDataByCategory();
            getCategory();
        } else {
            getAllCategoriesData();
        }
    }, [])
    const filterdata = async (text) => {
        text && text.length > 1 ?
            setProductData(unfilteredData.filter((item) => String(item.name).toLowerCase().includes(String(text).toLowerCase()) || String(item.answer).toLowerCase().includes(String(text).toLowerCase()))) : setProductData(unfilteredData)
    }
    return (
        <>
            <Header />
            <div className="flex mt-14">
                <div className="w-1/2 pl-4">
                    <Text content={productType[0].toUpperCase() + productType.slice(1).replace("-", " ")} className="text-4xl" style={{ background: key.primaryColor, WebkitBackgroundClip: "text", WebkitTextFillColor: "transparent", fontFamily: key.fontFamily, fontWeight: "bold", textAlign: "left" }} />
                    <div className="w-full flex justify-start ">
                        <hr className="w-2/5" />
                    </div>
                    {categoryId !== "get" ? <Text content={`Search Results for Category : ${category}`} style={{ fontFamily: key.fontFamily, color: key.blue, textAlign: "left" }} className={"text-base italic"} /> : <></>}
                </div>
                <div className="w-1/2 flex justify-end items-center">
                    <div className="w-3/4 h-9 flex justify-end max-[634px]:w-4/5 pr-4">
                        <Input placeholder={`Search ${productType[0].toUpperCase() + productType.slice(1).replace("-", " ")}`} style={{ borderImage: key.primaryColor + 1, fontFamily: key.fontFamily }} onChange={setSearchData}
                            className="w-2/3"
                            onKeyPress={(target) => {
                                if (target.charCode == 13) {
                                    filterdata(searchData.target.value)
                                }
                            }} />
                        <button
                            className="w-fit flex items-center px-3" style={{ background: key.primaryColor }}
                            onClick={() => {
                                filterdata(searchData.target.value)
                            }}>
                            <CiSearch size={20} color="#fff" />
                        </button>
                    </div>
                </div>
            </div>
            {productData.length > 0 ?
                productType === "accessories" || productType === "spare-parts" ?
                    <div className="flex justify-start ml-4">
                        <div className="w-4/5 mb-20 grid grid-cols-4 gap-x-10 max-[1045px]:grid-cols-3 max-[757px]:grid-cols-2 max-[550px]:grid-cols-1">
                            {productData.map((item, key) => {
                                return (
                                    <Product productData={item} key={item?._id} productType={productType != "spare-parts" ? productType : "spareParts"} />
                                )
                            })}
                        </div>
                    </div>
                    : productType === "services" ?
                        <div className="w-full flex justify-center">
                            <div className="w-4/5 mb-20 items-start grid grid-cols-4 gap-x-10 max-[1045px]:grid-cols-3 max-[757px]:grid-cols-2 max-[550px]:grid-cols-1">
                                {productData.map((item, key) => {
                                    return (
                                        <Service serviceData={item} key={item?._id} productType={productType} />
                                    )
                                })}
                            </div>
                        </div> : productType === "plants" ?
                            // <div className="w-full flex justify-center">
                            //     <div className="w-4/5 mb-20 grid grid-cols-4 gap-x-10 max-[1045px]:grid-cols-3 max-[757px]:grid-cols-2 max-[550px]:grid-cols-1">
                            productData.map((item, key) => {
                                return (
                                    <Plant plantData={item} key={item?._id} />
                                )
                            })
                            //     </div>
                            // </div>
                            : <></>
                : <></>}
            <Footer />
        </>
    )
}
export default Category