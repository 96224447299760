import React, { Fragment } from "react";
import { Route, Switch } from "react-router-dom";
import { AccessRoute, AuthProtected } from "./AuthProtected";
import {
  adminAuthProtectedRoutes,
  publicRoutes,
} from "./allRoutes";

const availableRoute = [
  ...adminAuthProtectedRoutes,
]
const availablePublicRoutesPaths = publicRoutes.map((r) => r.path);

const Index = () => {

  return (
    <Fragment>
      <Switch>
        <Route path={availablePublicRoutesPaths}>
          <Switch>
            {publicRoutes.map((route, idx) => (
              <Route
                path={route.path}
                component={route.component}
                key={idx}
                exact={true}
              />
            ))}
          </Switch>
        </Route>
        <Route path={availableRoute.map((r) => r.path)}>
          <AuthProtected>
            <Switch>
              {availableRoute.map((route, idx) => (
                <AccessRoute
                  path={route.path}
                  component={route.component}
                  key={idx}
                  exact={true}
                />
              ))}
            </Switch>
          </AuthProtected>
        </Route>
      </Switch>
    </Fragment>
  );
};

export default Index;
