import { CardBody, Collapse } from "reactstrap"
import TableContainer from "../../../../components/TableContainer"
import { useMemo } from "react";
import { priceFormat } from "../../../../utils/priceFunctions";
import { IMAGE_URL } from "../../../../Services/config";
import DisplayInput from "../../../../components/DisplayInput";
import Text from "../../../../atom/Text";
import { key } from "../../../../utils/consts";

const OrderDetails = ({ orderData, isOpen, setIsOpen, selectedIndex, index }) => {
    const ProductDetails = (data) => {
        if (data?.productType === "plants") {
            return (
                <>
                    <Text content={`Brand : ${data?.brand}`} style={{ fontFamily: key.fontFamily, fontWeight: 300, color:"#000" }} className={"mt-3 text-sm"} />
                    <Text content={`Model No. : ${data?.modelNumber}`} style={{ fontFamily: key.fontFamily, fontWeight: 300, color:"#000" }} className={"mt-1 text-sm"} />
                </>
            )
        } else if (data?.productType === "spareParts" || data?.productType === "accessories") {
            return (
                <>
                    <Text content={`Brand : ${data?.brand}`} style={{ fontFamily: key.fontFamily, fontWeight: 300, color:"#000" }} className={"mt-3 text-sm"} />
                    <Text content={`Model No. : ${data?.modelNumber}`} style={{ fontFamily: key.fontFamily, fontWeight: 300, color:"#000" }} className={"mt-1 text-sm"} />
                </>
            )
        }
    }
    const columns = useMemo(
        () => [
            {
                Header: "No.",
                accessor: "id",
                width: "5%",
                Cell: (cellProps) => (
                    <>
                        <div style={{ color: "#000", fontWeight: "bold", display: "flex", alignItems: "center", justifyContent: "center" }}>
                            {cellProps.row.index + 1}
                        </div>
                    </>
                ),
            },
            {
                Header: "Product",
                accessor: "product",
                width: "20%",
                Cell: (cellProps) => (
                    <>
                        <div style={{ color: "#000", fontWeight: "bold", display: "flex", alignItems: "center", justifyContent: "center" }}>
                            {cellProps.row.original?.name}
                        </div>
                            {ProductDetails(cellProps?.row?.original)}
                    </>
                ),
            },
            {
                Header: "Capacity",
                accessor: "capacity",
                width: "10%",
                Cell: (cellProps) => (
                    <>
                        <div style={{ color: "#000", fontWeight: "bold", display: "flex", alignItems: "center", justifyContent: "center" }}>
                            {cellProps.row.original?.capacity || "-"}
                        </div>
                    </>
                ),
            },
            {
                Header: "Quantity",
                accessor: "quantity",
                width: "11%",
                Cell: (cellProps) => (
                    <>
                        <div style={{ color: "#000", fontWeight: "bold", display: "flex", alignItems: "center", justifyContent: "center" }}>
                            {cellProps.row.original?.quantity}
                        </div>
                    </>
                ),
            },
            {
                Header: "Unit Value",
                accessor: "unitValue",
                width: "11%",
                Cell: (cellProps) => (
                    <>
                        <div style={{ color: "#000", fontWeight: "bold", display: "flex", alignItems: "center", justifyContent: "center" }}>
                            {priceFormat(cellProps.row.original?.unitValue)}
                        </div>
                    </>
                ),
            },
            {
                Header: "Value",
                accessor: "value",
                width: "11%",
                Cell: (cellProps) => (
                    <>
                        <div style={{ color: "#000", fontWeight: "bold", display: "flex", alignItems: "center", justifyContent: "center" }}>
                            {priceFormat(cellProps.row.original?.value)}
                        </div>
                    </>
                ),
            },
            {
                Header: "GST Value",
                accessor: "gstValue",
                width: "11%",
                Cell: (cellProps) => (
                    <>
                        <div style={{ color: "#000", fontWeight: "bold", display: "flex", alignItems: "center", justifyContent: "center" }}>
                            {priceFormat(cellProps.row.original?.gst)}
                        </div>
                    </>
                ),
            },
            {
                Header: "Total Value",
                accessor: "totalValue",
                width: "11%",
                Cell: (cellProps) => (
                    <>
                        <div style={{ color: "#000", fontWeight: "bold", display: "flex", alignItems: "center", justifyContent: "center" }}>
                            {priceFormat(cellProps.row.original?.totalValue)}
                        </div>
                    </>
                ),
            },
        ],
        []
    );
    return (
        <div className="m-4">
            <Collapse isOpen={selectedIndex == index ? true : false}>
                <CardBody>
                    <div className="w-full">
                        <TableContainer
                            columns={columns}
                            data={orderData?.products || []}
                            customPageSize={10}
                            className="custom-header-css"
                            trClass={"h-12 rounded-tr text-md"}
                            paginationDisplay={false}
                            backgroundColor={"#d6d6d680"}
                        />
                    </div>
                </CardBody>
            </Collapse>
        </div>
    )
}
export default OrderDetails;