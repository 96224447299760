import { key } from "../utils/consts";

const DisplayInput = ({ inputText }) => {
    return (
        inputText ? <div style={{ fontFamily: key.fontFamily }} className="mt-4 text-sm" dangerouslySetInnerHTML={{
            __html: `<style>
       table, td, tr{border: 1px solid black;border-collapse: collapse;padding:6px;margin-bottom:0px; margin:15px 0px}
       tbody > tr:first-child > td {
        vertical-align: top;
        background-color: #3A7AB7;
        color: #fff;
        font-weight: bold;
    }
        </style>${inputText.join(' ')}`
        }} /> : <></>
    )
}
export default DisplayInput;