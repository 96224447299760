import { useContext, useMemo, useState } from "react";
import TableContainer from "../../components/TableContainer";
import Button from '../../atom/Button';
import { key } from "../../utils/consts";
import Text from "../../atom/Text";
import { IMAGE_URL } from "../../Services/config";
import { priceFormat } from "../../utils/priceFunctions";
import { addToCartService } from "../../Services/CartServices";
import { CartContext } from "./CartContext";
import { CardBody, Collapse } from "reactstrap";
import RightDropdownArrow from "../../atom/RightDropdownArrow";

const ItemsTable = () => {
    const { getCartCount, updateCart, showCart, cartData, cartCount } = useContext(CartContext);
    const [showPlants, setShowPlants] = useState(true);
    const [showServices, setShowServices] = useState(true);
    const [showAccessories, setShowAccessories] = useState(true);
    const [showSpareParts, setShowSpareParts] = useState(true);
    const [showData, setShowData] = useState(true);

    const plantColumns = useMemo(
        () => [
            {
                Header: "No.",
                accessor: "id",
                width: "3%",
                Cell: (cellProps) => (
                    <>
                        <div style={{ color: "#000", fontWeight: "bold", display: "flex", alignItems: "center", justifyContent: "center" }}>
                            {cellProps.row.index + 1}
                        </div>
                    </>
                ),
            },
            {
                Header: "Image",
                accessor: "image",
                width: "17%",
                Cell: (cellProps) => (
                    <>
                        <div className="flex items-center justify-center" style={{ color: "#000", fontWeight: "bold" }}>
                            {cellProps.row.original.productId != null ? <img src={`${IMAGE_URL}/${cellProps.row.original.productId.images[0]}`} style={{ width: "90%", minWidth: 170 }} /> : <></>}
                        </div>
                    </>
                ),
            },
            {
                Header: "Product Name",
                accessor: "productName",
                width: "20%",
                Cell: (cellProps) => (
                    <>
                        <div className="flex items-center justify-center" style={{ color: "#000", fontWeight: "bold" }}>
                            {cellProps.row.original.productId != null ? cellProps.row.original.productId.name : null}
                        </div>
                        <div className="flex items-center justify-center mt-3 text-sm" style={{ color: "#000", fontWeight: 300 }}>
                            {cellProps.row.original.productId != null ? "Brand : " + cellProps.row.original.productId.brand : null}
                        </div>
                        <div className="flex items-center justify-center mt-1 text-sm" style={{ color: "#000", fontWeight: 300 }}>
                            {cellProps.row.original.productId != null ? "Model No. : " + cellProps.row.original.productId.modelNumber : null}
                        </div>
                    </>
                ),
            },
            {
                Header: "Capacity",
                accessor: "capacity",
                width: "10%",
                Cell: (cellProps) => (
                    <>
                        <div className="flex items-center justify-center" style={{ color: "#000", fontWeight: "bold" }}>
                            {cellProps.row.original.productId != null ? cellProps.row.original.productId.capacity + " KLD" : null}
                        </div>
                    </>
                ),
            },
            {
                Header: "Unit Price",
                accessor: "unitPrice",
                width: "19%",
                Cell: (cellProps) => (
                    <>
                        <div style={{ textAlign: "left" }}>
                            <div style={{ color: "#000", fontWeight: "bold" }}>
                                {cellProps.row.original?.productId?.withoutDiscountPrice ?
                                    <Text
                                        className={"text-xs"}
                                        style={{ fontFamily: key.fontFamily, opacity: "0.6" }}
                                        content={`${cellProps.row.original.productId != null ? cellProps.row.original.productId.withoutDiscountPrice != undefined ? priceFormat(cellProps.row.original.productId.withoutDiscountPrice + (cellProps.row.original.productId.withoutDiscountPrice * cellProps.row.original.productId.gstPercentage / 100)) : priceFormat(cellProps.row.original.productId.withoutDiscountPrice + (cellProps.row.original.productId.withoutDiscountPrice * cellProps.row.original.productId.gstPercentage / 100)) : null} (Incl. of all taxes)`}
                                    /> : <Text
                                        className={"text-xs"}
                                        style={{ fontFamily: key.fontFamily, opacity: "0.6" }}
                                        content={`${cellProps.row.original.productId != null ? cellProps.row.original.productId.b2bPrice != undefined ? priceFormat(cellProps.row.original.productId.b2bPrice + (cellProps.row.original.productId.b2bPrice * cellProps.row.original.productId.gstPercentage / 100)) : priceFormat(cellProps.row.original.productId.b2cPrice + (cellProps.row.original.productId.b2cPrice * cellProps.row.original.productId.gstPercentage / 100)) : null} (Incl. of all taxes)`}
                                    />}
                            </div>
                            <div className="my-3" style={{ color: "#000", fontWeight: "bolder" }}>
                                {cellProps.row.original?.productId?.withoutDiscountPrice ?
                                    <Text
                                        className={"text-base"}
                                        style={{ fontFamily: key.fontFamily, }}
                                        content={cellProps.row.original.productId != null ? cellProps.row.original.productId.withoutDiscountPrice != undefined ? priceFormat(cellProps.row.original.productId.withoutDiscountPrice) : priceFormat(cellProps.row.original.productId.withoutDiscountPrice) : null}
                                    /> :
                                    <Text
                                        className={"text-base"}
                                        style={{ fontFamily: key.fontFamily, }}
                                        content={cellProps.row.original.productId != null ? cellProps.row.original.productId.b2bPrice != undefined ? priceFormat(cellProps.row.original.productId.b2bPrice) : priceFormat(cellProps.row.original.productId.b2cPrice) : null}
                                    />}
                            </div>
                            <div style={{ color: "#000", fontWeight: "bold" }}>
                                {cellProps.row.original?.productId?.withoutDiscountPrice ?
                                    <Text
                                        className={"text-xs"}
                                        style={{ fontFamily: key.fontFamily, opacity: "0.6" }}
                                        content={`+ ${cellProps.row.original.productId != null ? cellProps.row.original.productId.withoutDiscountPrice != undefined ? priceFormat(cellProps.row.original.productId.withoutDiscountPrice * cellProps.row.original.productId.gstPercentage / 100) : priceFormat(cellProps.row.original.productId.withoutDiscountPrice * cellProps.row.original.productId.gstPercentage / 100) : null} GST`}
                                    /> :
                                    <Text
                                        className={"text-xs"}
                                        style={{ fontFamily: key.fontFamily, opacity: "0.6" }}
                                        content={`+ ${cellProps.row.original.productId != null ? cellProps.row.original.productId.b2bPrice != undefined ? priceFormat(cellProps.row.original.productId.b2bPrice * cellProps.row.original.productId.gstPercentage / 100) : priceFormat(cellProps.row.original.productId.b2cPrice * cellProps.row.original.productId.gstPercentage / 100) : null} GST`}
                                    />}
                            </div>
                        </div>
                    </>
                ),
            },
            {
                Header: "Quantity",
                accessor: "quantity",
                width: "13%",
                Cell: (cellProps) => (
                    <>
                        <div className="flex items-center justify-center" style={{ color: "#000", fontWeight: "bold" }}>
                            <Button buttonText={"-"} className={"w-8 py-1 rounded"} style={{ background: key.blue, fontFamily: key.fontFamily, fontWeight: "bold", color: "#fff" }}
                                onClick={async () => {
                                    await setShowData(false)
                                    await updateCart("plants", cellProps.row.original.quantity - 1, cellProps.row.original.productId?._id)
                                    await setShowData(true)
                                }}
                            />
                            <Text content={cellProps.row.original.quantity} className={"mx-3 w-12 text-center"} style={{ fontFamily: key.fontFamily, fontWeight: "bold", color: "#000" }} />
                            <Button buttonText={"+"} className={"w-8 py-1 rounded"} style={{ background: key.blue, fontFamily: key.fontFamily, fontWeight: "bold", color: "#fff" }}
                                onClick={async () => {
                                    await setShowData(false)
                                    await updateCart("plants", cellProps.row.original.quantity + 1, cellProps.row.original.productId?._id)
                                    await setShowData(true)
                                }}
                            />
                        </div>
                    </>
                ),
            },
            {
                Header: "Total Value",
                accessor: "totalValue",
                width: "18%",
                Cell: (cellProps) => (
                    <>
                        <div style={{ textAlign: "left" }}>
                            <div style={{ color: "#000", fontWeight: "bold" }}>
                                <Text
                                    className={"text-xs"}
                                    style={{ fontFamily: key.fontFamily, opacity: "0.6" }}
                                    content={`${cellProps.row.original.productId != null ? cellProps.row.original.productId.b2bPrice != undefined ? priceFormat(cellProps.row.original?.quantity * (cellProps.row.original.productId.b2bPrice + (cellProps.row.original.productId.b2bPrice * cellProps.row.original.productId.gstPercentage / 100))) : priceFormat(cellProps?.row?.original?.quantity * (cellProps.row.original.productId.b2cPrice + (cellProps.row.original.productId.b2cPrice * cellProps.row.original.productId.gstPercentage / 100))) : null} (Incl. of all taxes)`}
                                />
                            </div>
                            <div className="my-3" style={{ color: "#000", fontWeight: "bolder" }}>
                                {cellProps.row.original?.productId?.withoutDiscountPrice ?
                                    <>
                                        <Text
                                            className={"text-xs"}
                                            style={{ fontFamily: key.fontFamily, opacity: "0.5", textDecoration: "line-through" }}
                                            content={cellProps.row.original.productId != null ? cellProps.row.original.productId.withoutDiscountPrice != undefined ? priceFormat(cellProps.row.original?.quantity * cellProps.row.original.productId.withoutDiscountPrice) : priceFormat(cellProps.row.original?.quantity * cellProps.row.original.productId.withoutDiscountPrice) : null}
                                        />
                                        <Text
                                            className={"text-base"}
                                            style={{ fontFamily: key.fontFamily, }}
                                            content={cellProps.row.original.productId != null ? cellProps.row.original.productId.b2bPrice != undefined ? priceFormat(cellProps.row.original?.quantity * cellProps.row.original.productId.b2bPrice) : priceFormat(cellProps.row.original?.quantity * cellProps.row.original.productId.b2cPrice) : null}
                                        />
                                    </> :
                                    <Text
                                        className={"text-base"}
                                        style={{ fontFamily: key.fontFamily, }}
                                        content={cellProps.row.original.productId != null ? cellProps.row.original.productId.b2bPrice != undefined ? priceFormat(cellProps.row.original?.quantity * cellProps.row.original.productId.b2bPrice) : priceFormat(cellProps.row.original?.quantity * cellProps.row.original.productId.b2cPrice) : null}
                                    />}
                            </div>
                            <div style={{ color: "#000", fontWeight: "bold" }}>
                                <Text
                                    className={"text-xs"}
                                    style={{ fontFamily: key.fontFamily, opacity: "0.6" }}
                                    content={`+ ${cellProps.row.original.productId != null ? cellProps.row.original.productId.b2bPrice != undefined ? priceFormat(cellProps.row.original?.quantity * cellProps.row.original.productId.b2bPrice * cellProps.row.original.productId.gstPercentage / 100) : priceFormat(cellProps.row.original?.quantity * cellProps.row.original.productId.b2cPrice * cellProps.row.original.productId.gstPercentage / 100) : null} GST`}
                                />
                            </div>
                        </div>
                    </>
                ),
            },
        ],
        []
    );
    const serviceColumns = useMemo(
        () => [
            {
                Header: "No.",
                accessor: "id",
                width: "5%",
                Cell: (cellProps) => (
                    <>
                        <div style={{ color: "#000", fontWeight: "bold", display: "flex", alignItems: "center", justifyContent: "center" }}>
                            {cellProps.row.index + 1}
                        </div>
                    </>
                ),
            },
            {
                Header: "Service",
                accessor: "items",
                width: "25%",
                Cell: (cellProps) => (
                    <>
                        <div className="flex items-center justify-center" style={{ color: "#000", fontWeight: "bold" }}>
                            {cellProps.row.original.productId != null ? cellProps.row.original.productId.name : null}
                        </div>
                    </>
                ),
            },
            {
                Header: "Unit Price",
                accessor: "price",
                width: "25%",
                Cell: (cellProps) => (
                    <>
                        <div style={{ textAlign: "left" }}>
                            <div style={{ color: "#000", fontWeight: "bold" }}>
                                {cellProps.row.original?.productId?.withoutDiscountPrice ?
                                    <Text
                                        className={"text-xs"}
                                        style={{ fontFamily: key.fontFamily, opacity: "0.6" }}
                                        content={`${cellProps.row.original.productId != null ? cellProps.row.original.productId.withoutDiscountPrice != undefined ? priceFormat(cellProps.row.original.productId.withoutDiscountPrice + (cellProps.row.original.productId.withoutDiscountPrice * cellProps.row.original.productId.gstPercentage / 100)) : priceFormat(cellProps.row.original.productId.withoutDiscountPrice + (cellProps.row.original.productId.withoutDiscountPrice * cellProps.row.original.productId.gstPercentage / 100)) : null} (Incl. of all taxes)`}
                                    /> : <Text
                                        className={"text-xs"}
                                        style={{ fontFamily: key.fontFamily, opacity: "0.6" }}
                                        content={`${cellProps.row.original.productId != null ? cellProps.row.original.productId.b2bPrice != undefined ? priceFormat(cellProps.row.original.productId.b2bPrice + (cellProps.row.original.productId.b2bPrice * cellProps.row.original.productId.gstPercentage / 100)) : priceFormat(cellProps.row.original.productId.b2cPrice + (cellProps.row.original.productId.b2cPrice * cellProps.row.original.productId.gstPercentage / 100)) : null} (Incl. of all taxes)`}
                                    />}
                            </div>
                            <div className="my-3" style={{ color: "#000", fontWeight: "bolder" }}>
                                {cellProps.row.original?.productId?.withoutDiscountPrice ?
                                    <Text
                                        className={"text-base"}
                                        style={{ fontFamily: key.fontFamily, }}
                                        content={cellProps.row.original.productId != null ? cellProps.row.original.productId.withoutDiscountPrice != undefined ? priceFormat(cellProps.row.original.productId.withoutDiscountPrice) : priceFormat(cellProps.row.original.productId.withoutDiscountPrice) : null}
                                    /> :
                                    <Text
                                        className={"text-base"}
                                        style={{ fontFamily: key.fontFamily, }}
                                        content={cellProps.row.original.productId != null ? cellProps.row.original.productId.b2bPrice != undefined ? priceFormat(cellProps.row.original.productId.b2bPrice) : priceFormat(cellProps.row.original.productId.b2cPrice) : null}
                                    />}
                            </div>
                            <div style={{ color: "#000", fontWeight: "bold" }}>
                                {cellProps.row.original?.productId?.withoutDiscountPrice ?
                                    <Text
                                        className={"text-xs"}
                                        style={{ fontFamily: key.fontFamily, opacity: "0.6" }}
                                        content={`+ ${cellProps.row.original.productId != null ? cellProps.row.original.productId.withoutDiscountPrice != undefined ? priceFormat(cellProps.row.original.productId.withoutDiscountPrice * cellProps.row.original.productId.gstPercentage / 100) : priceFormat(cellProps.row.original.productId.withoutDiscountPrice * cellProps.row.original.productId.gstPercentage / 100) : null} GST`}
                                    /> :
                                    <Text
                                        className={"text-xs"}
                                        style={{ fontFamily: key.fontFamily, opacity: "0.6" }}
                                        content={`+ ${cellProps.row.original.productId != null ? cellProps.row.original.productId.b2bPrice != undefined ? priceFormat(cellProps.row.original.productId.b2bPrice * cellProps.row.original.productId.gstPercentage / 100) : priceFormat(cellProps.row.original.productId.b2cPrice * cellProps.row.original.productId.gstPercentage / 100) : null} GST`}
                                    />}
                            </div>
                        </div>
                    </>
                ),
            },
            {
                Header: "Quantity",
                accessor: "quantity",
                width: "20%",
                Cell: (cellProps) => (
                    <>
                        <div className="flex items-center justify-center" style={{ color: "#000", fontWeight: "bold" }}>
                            <Button buttonText={"-"} className={"w-8 py-1 rounded"} style={{ background: key.blue, fontFamily: key.fontFamily, fontWeight: "bold", color: "#fff" }}
                                onClick={async () => {
                                    await setShowData(false)
                                    await updateCart("services", cellProps.row.original.quantity - 1, cellProps.row.original.productId?._id)
                                    await setShowData(true)
                                }}
                            />
                            <Text content={cellProps.row.original.quantity} className={"mx-3 w-12 text-center"} style={{ fontFamily: key.fontFamily, fontWeight: "bold", color: "#000" }} />
                            <Button buttonText={"+"} className={"w-8 py-1 rounded"} style={{ background: key.blue, fontFamily: key.fontFamily, fontWeight: "bold", color: "#fff" }}
                                onClick={async () => {
                                    await setShowData(false)
                                    await updateCart("services", cellProps.row.original.quantity + 1, cellProps.row.original.productId?._id)
                                    await setShowData(true)
                                }}
                            />
                        </div>
                    </>
                ),
            }, {
                Header: "Total Price",
                accessor: "totalPrice",
                width: "25%",
                Cell: (cellProps) => (
                    <div style={{ textAlign: "left" }}>
                        <div style={{ color: "#000", fontWeight: "bold" }}>
                            <Text
                                className={"text-xs"}
                                style={{ fontFamily: key.fontFamily, opacity: "0.6" }}
                                content={`${cellProps.row.original.productId != null ? cellProps.row.original.productId.b2bPrice != undefined ? priceFormat(cellProps.row.original?.quantity * (cellProps.row.original.productId.b2bPrice + (cellProps.row.original.productId.b2bPrice * cellProps.row.original.productId.gstPercentage / 100))) : priceFormat(cellProps?.row?.original?.quantity * (cellProps.row.original.productId.b2cPrice + (cellProps.row.original.productId.b2cPrice * cellProps.row.original.productId.gstPercentage / 100))) : null} (Incl. of all taxes)`}
                            />
                        </div>
                        <div className="my-3" style={{ color: "#000", fontWeight: "bolder" }}>
                            {cellProps.row.original?.productId?.withoutDiscountPrice ?
                                <>
                                    <Text
                                        className={"text-xs"}
                                        style={{ fontFamily: key.fontFamily, opacity: "0.5", textDecoration: "line-through" }}
                                        content={cellProps.row.original.productId != null ? cellProps.row.original.productId.withoutDiscountPrice != undefined ? priceFormat(cellProps.row.original?.quantity * cellProps.row.original.productId.withoutDiscountPrice) : priceFormat(cellProps.row.original?.quantity * cellProps.row.original.productId.withoutDiscountPrice) : null}
                                    />
                                    <Text
                                        className={"text-base"}
                                        style={{ fontFamily: key.fontFamily, }}
                                        content={cellProps.row.original.productId != null ? cellProps.row.original.productId.b2bPrice != undefined ? priceFormat(cellProps.row.original?.quantity * cellProps.row.original.productId.b2bPrice) : priceFormat(cellProps.row.original?.quantity * cellProps.row.original.productId.b2cPrice) : null}
                                    />
                                </> :
                                <Text
                                    className={"text-base"}
                                    style={{ fontFamily: key.fontFamily, }}
                                    content={cellProps.row.original.productId != null ? cellProps.row.original.productId.b2bPrice != undefined ? priceFormat(cellProps.row.original?.quantity * cellProps.row.original.productId.b2bPrice) : priceFormat(cellProps.row.original?.quantity * cellProps.row.original.productId.b2cPrice) : null}
                                />}
                        </div>
                        <div style={{ color: "#000", fontWeight: "bold" }}>
                            <Text
                                className={"text-xs"}
                                style={{ fontFamily: key.fontFamily, opacity: "0.6" }}
                                content={`+ ${cellProps.row.original.productId != null ? cellProps.row.original.productId.b2bPrice != undefined ? priceFormat(cellProps.row.original?.quantity * cellProps.row.original.productId.b2bPrice * cellProps.row.original.productId.gstPercentage / 100) : priceFormat(cellProps.row.original?.quantity * cellProps.row.original.productId.b2cPrice * cellProps.row.original.productId.gstPercentage / 100) : null} GST`}
                            />
                        </div>
                    </div>
                ),
            },
        ],
        []
    );
    const sparePartsColumns = useMemo(
        () => [
            {
                Header: "No.",
                accessor: "id",
                width: "3%",
                Cell: (cellProps) => (
                    <>
                        <div style={{ color: "#000", fontWeight: "bold", display: "flex", alignItems: "center", justifyContent: "center" }}>
                            {cellProps.row.index + 1}
                        </div>
                    </>
                ),
            },
            {
                Header: "Images",
                accessor: "images",
                width: "18%",
                Cell: (cellProps) => (
                    <>
                        <div className="flex items-center justify-center" style={{ color: "#000", fontWeight: "bold" }}>
                            {cellProps?.row?.original?.productId?.images ? <img className="w-11/12 flex items-center justify-center" src={`${IMAGE_URL}/${cellProps?.row?.original?.productId?.images[0]}`} /> : <></>}
                        </div>
                    </>
                ),
            },
            {
                Header: "Product Name",
                accessor: "items",
                width: "15%",
                Cell: (cellProps) => (
                    <>
                        <div className="flex items-center justify-center" style={{ color: "#000", fontWeight: "bold" }}>
                            {cellProps.row.original.productId != null ? cellProps.row.original.productId.name : null}
                        </div>
                        <div className="flex items-center justify-center mt-3 text-sm" style={{ color: "#000", fontWeight: 300 }}>
                            {cellProps.row.original.productId != null ? "Brand : " + cellProps.row.original.productId.brand : null}
                        </div>
                        <div className="flex items-center justify-center mt-1 text-sm" style={{ color: "#000", fontWeight: 300 }}>
                            {cellProps.row.original.productId != null ? "Model No. : " + cellProps.row.original.productId.modelNumber : null}
                        </div>
                    </>
                ),
            },
            {
                Header: "Capacity",
                accessor: "capacity",
                width: "10%",
                Cell: (cellProps) => (
                    <>
                        <div className="flex items-center justify-center" style={{ color: "#000", fontWeight: "bold" }}>
                            {cellProps.row.original.productId != null ? cellProps.row.original.productId?.variants.filter((item) => item?._id == cellProps.row.original?.variantId)[0]?.capacity : null}
                        </div>
                    </>
                ),
            },
            {
                Header: "Unit Price",
                accessor: "unitPrice",
                width: "15%",
                Cell: (cellProps) => (
                    <>
                        <div style={{ textAlign: "left" }}>
                            <div style={{ color: "#000", fontWeight: "bold" }}>
                                {cellProps?.row?.original?.productId?.variants?.filter((variant) => variant?._id === cellProps?.row?.original?.variantId)[0]?.withoutDiscountPrice ? <Text
                                    className={"text-xs"}
                                    style={{ fontFamily: key.fontFamily, opacity: "0.6" }}
                                    content={`${cellProps.row.original.productId != null ? localStorage.getItem("role") == "b2c" ? priceFormat(((cellProps?.row?.original?.productId?.variants?.filter((variant) => variant?._id === cellProps?.row?.original?.variantId)[0]?.withoutDiscountPrice * cellProps?.row?.original?.productId?.variants?.filter((variant) => variant?._id === cellProps?.row?.original?.variantId)[0]?.gstPercentage) / 100) + cellProps?.row?.original?.productId?.variants?.filter((variant) => variant?._id === cellProps?.row?.original?.variantId)[0]?.withoutDiscountPrice) : priceFormat(((cellProps?.row?.original?.productId?.variants?.filter((variant) => variant?._id === cellProps?.row?.original?.variantId)[0]?.withoutDiscountPrice * cellProps?.row?.original?.productId?.variants?.filter((variant) => variant?._id === cellProps?.row?.original?.variantId)[0]?.gstPercentage) / 100) + cellProps?.row?.original?.productId?.variants?.filter((variant) => variant?._id === cellProps?.row?.original?.variantId)[0]?.withoutDiscountPrice) : null} (Incl. of all taxes)`}
                                /> : <Text
                                    className={"text-xs"}
                                    style={{ fontFamily: key.fontFamily, opacity: "0.6" }}
                                    content={`${cellProps.row.original.productId != null ? localStorage.getItem("role") == "b2c" ? priceFormat(((cellProps?.row?.original?.productId?.variants?.filter((variant) => variant?._id === cellProps?.row?.original?.variantId)[0]?.b2cPrice * cellProps?.row?.original?.productId?.variants?.filter((variant) => variant?._id === cellProps?.row?.original?.variantId)[0]?.gstPercentage) / 100) + cellProps?.row?.original?.productId?.variants?.filter((variant) => variant?._id === cellProps?.row?.original?.variantId)[0]?.b2cPrice) : priceFormat(((cellProps?.row?.original?.productId?.variants?.filter((variant) => variant?._id === cellProps?.row?.original?.variantId)[0]?.b2bPrice * cellProps?.row?.original?.productId?.variants?.filter((variant) => variant?._id === cellProps?.row?.original?.variantId)[0]?.gstPercentage) / 100) + cellProps?.row?.original?.productId?.variants?.filter((variant) => variant?._id === cellProps?.row?.original?.variantId)[0]?.b2bPrice) : null} (Incl. of all taxes)`}
                                />}
                            </div>
                            <div className="my-3" style={{ color: "#000", fontWeight: "bolder" }}>
                                {cellProps?.row?.original?.productId?.variants?.filter((variant) => variant?._id === cellProps?.row?.original?.variantId)[0]?.withoutDiscountPrice ? <Text
                                    className={"text-base"}
                                    style={{ fontFamily: key.fontFamily, }}
                                    content={cellProps.row.original.productId != null ? localStorage.getItem("role") == "b2c" ? priceFormat(cellProps?.row?.original?.productId?.variants?.filter((variant) => variant?._id === cellProps?.row?.original?.variantId)[0]?.withoutDiscountPrice) : priceFormat(cellProps?.row?.original?.productId?.variants?.filter((variant) => variant?._id === cellProps?.row?.original?.variantId)[0]?.withoutDiscountPrice) : null}
                                /> :
                                    <Text
                                        className={"text-base"}
                                        style={{ fontFamily: key.fontFamily, }}
                                        content={cellProps.row.original.productId != null ? localStorage.getItem("role") == "b2c" ? priceFormat(cellProps?.row?.original?.productId?.variants?.filter((variant) => variant?._id === cellProps?.row?.original?.variantId)[0]?.b2cPrice) : priceFormat(cellProps?.row?.original?.productId?.variants?.filter((variant) => variant?._id === cellProps?.row?.original?.variantId)[0]?.b2bPrice) : null}
                                    />
                                }
                            </div>
                            <div style={{ color: "#000", fontWeight: "bold" }}>
                                {cellProps?.row?.original?.productId?.variants?.filter((variant) => variant?._id === cellProps?.row?.original?.variantId)[0]?.withoutDiscountPrice ? <Text
                                    className={"text-xs"}
                                    style={{ fontFamily: key.fontFamily, opacity: "0.6" }}
                                    content={`+ ${cellProps.row.original.productId != null ? localStorage.getItem("role") == "b2c" ? priceFormat((cellProps?.row?.original?.productId?.variants?.filter((variant) => variant?._id === cellProps?.row?.original?.variantId)[0]?.withoutDiscountPrice * cellProps?.row?.original?.productId?.variants?.filter((variant) => variant?._id === cellProps?.row?.original?.variantId)[0]?.gstPercentage) / 100) : priceFormat((cellProps?.row?.original?.productId?.variants?.filter((variant) => variant?._id === cellProps?.row?.original?.variantId)[0]?.withoutDiscountPrice * cellProps?.row?.original?.productId?.variants?.filter((variant) => variant?._id === cellProps?.row?.original?.variantId)[0]?.gstPercentage) / 100) : null} GST`}
                                /> :
                                    <Text
                                        className={"text-xs"}
                                        style={{ fontFamily: key.fontFamily, opacity: "0.6" }}
                                        content={`+ ${cellProps.row.original.productId != null ? localStorage.getItem("role") == "b2c" ? priceFormat((cellProps?.row?.original?.productId?.variants?.filter((variant) => variant?._id === cellProps?.row?.original?.variantId)[0]?.b2cPrice * cellProps?.row?.original?.productId?.variants?.filter((variant) => variant?._id === cellProps?.row?.original?.variantId)[0]?.gstPercentage) / 100) : priceFormat((cellProps?.row?.original?.productId?.variants?.filter((variant) => variant?._id === cellProps?.row?.original?.variantId)[0]?.b2bPrice * cellProps?.row?.original?.productId?.variants?.filter((variant) => variant?._id === cellProps?.row?.original?.variantId)[0]?.gstPercentage) / 100) : null} GST`}
                                    />
                                }
                            </div>
                        </div>
                    </>
                ),
            },
            {
                Header: "Quantity",
                accessor: "quantity",
                width: "7%",
                Cell: (cellProps) => (
                    <>
                        <div className="flex items-center justify-center" style={{ color: "#000", fontWeight: "bold" }}>
                            <Button buttonText={"-"} className={"w-8 py-1 rounded"} style={{ background: key.blue, fontFamily: key.fontFamily, fontWeight: "bold", color: "#fff" }}
                                onClick={async () => { await updateCart("spareParts", cellProps.row.original.quantity - 1, cellProps.row.original.productId?._id, cellProps.row.original.variantId) }}
                            />
                            <Text content={cellProps.row.original.quantity} className={"mx-3 w-12 text-center"} style={{ fontFamily: key.fontFamily, fontWeight: "bold", color: "#000" }} />
                            <Button buttonText={"+"} className={"w-8 py-1 rounded"} style={{ background: key.blue, fontFamily: key.fontFamily, fontWeight: "bold", color: "#fff" }}
                                onClick={async () => { await updateCart("spareParts", cellProps.row.original.quantity + 1, cellProps.row.original.productId?._id, cellProps.row.original.variantId) }}
                            />
                        </div>
                    </>
                ),
            },
            {
                Header: "Total Price",
                accessor: "totalPrice",
                width: "15%",
                Cell: (cellProps) => (
                    <>
                        <div style={{ textAlign: "left" }}>
                            <div style={{ color: "#000", fontWeight: "bold" }}>
                                <Text
                                    className={"text-xs"}
                                    style={{ fontFamily: key.fontFamily, opacity: "0.6" }}
                                    content={`${cellProps.row.original.productId != null ? localStorage.getItem("role") == "b2c" ? priceFormat((cellProps?.row?.original?.quantity * cellProps?.row?.original?.productId?.variants?.filter((variant) => variant?._id === cellProps?.row?.original?.variantId)[0]?.b2cPrice) + (cellProps?.row?.original?.quantity * (cellProps?.row?.original?.productId?.variants?.filter((variant) => variant?._id === cellProps?.row?.original?.variantId)[0]?.b2cPrice * cellProps?.row?.original?.productId?.variants?.filter((variant) => variant?._id === cellProps?.row?.original?.variantId)[0]?.gstPercentage)) / 100) : priceFormat((cellProps?.row?.original?.quantity * cellProps?.row?.original?.productId?.variants?.filter((variant) => variant?._id === cellProps?.row?.original?.variantId)[0]?.b2bPrice) + (cellProps?.row?.original?.quantity * (cellProps?.row?.original?.productId?.variants?.filter((variant) => variant?._id === cellProps?.row?.original?.variantId)[0]?.b2bPrice * cellProps?.row?.original?.productId?.variants?.filter((variant) => variant?._id === cellProps?.row?.original?.variantId)[0]?.gstPercentage)) / 100) : null} (Incl. of all taxes)`}
                                />
                            </div>
                            <div className="my-3" style={{ color: "#000", fontWeight: "bolder" }}>
                                {cellProps?.row?.original?.productId?.variants?.filter((variant) => variant?._id === cellProps?.row?.original?.variantId)[0]?.withoutDiscountPrice ?
                                    <>
                                        <Text
                                            className={"text-xs"}
                                            style={{ fontFamily: key.fontFamily, textDecoration: "line-through", opacity: "0.5" }}
                                            content={cellProps.row.original.productId != null ?
                                                localStorage.getItem("role") == "b2c" ?
                                                    priceFormat(cellProps?.row?.original?.quantity * cellProps?.row?.original?.productId?.variants?.filter((variant) => variant?._id === cellProps?.row?.original?.variantId)[0]?.withoutDiscountPrice)
                                                    : priceFormat(cellProps?.row?.original?.quantity * cellProps?.row?.original?.productId?.variants?.filter((variant) => variant?._id === cellProps?.row?.original?.variantId)[0]?.withoutDiscountPrice)
                                                : null}
                                        />
                                        <Text
                                            className={"text-base"}
                                            style={{ fontFamily: key.fontFamily }}
                                            content={cellProps.row.original.productId != null ?
                                                localStorage.getItem("role") == "b2c" ?
                                                    priceFormat(cellProps?.row?.original?.quantity * cellProps?.row?.original?.productId?.variants?.filter((variant) => variant?._id === cellProps?.row?.original?.variantId)[0]?.b2cPrice)
                                                    : priceFormat(cellProps?.row?.original?.quantity * cellProps?.row?.original?.productId?.variants?.filter((variant) => variant?._id === cellProps?.row?.original?.variantId)[0]?.b2bPrice)
                                                : null}
                                        />
                                    </>
                                    :
                                    <Text
                                        className={"text-base"}
                                        style={{ fontFamily: key.fontFamily, }}
                                        content={cellProps.row.original.productId != null ?
                                            localStorage.getItem("role") == "b2c" ?
                                                priceFormat(cellProps?.row?.original?.quantity * cellProps?.row?.original?.productId?.variants?.filter((variant) => variant?._id === cellProps?.row?.original?.variantId)[0]?.b2cPrice)
                                                : priceFormat(cellProps?.row?.original?.quantity * cellProps?.row?.original?.productId?.variants?.filter((variant) => variant?._id === cellProps?.row?.original?.variantId)[0]?.b2bPrice)
                                            : null}
                                    />
                                }
                            </div>
                            <div style={{ color: "#000", fontWeight: "bold" }}>
                                <Text
                                    className={"text-xs"}
                                    style={{ fontFamily: key.fontFamily, opacity: "0.6" }}
                                    content={`+ ${cellProps.row.original.productId != null ? localStorage.getItem("role") == "b2c" ? priceFormat(cellProps?.row?.original?.quantity * (cellProps?.row?.original?.productId?.variants?.filter((variant) => variant?._id === cellProps?.row?.original?.variantId)[0]?.b2cPrice * cellProps?.row?.original?.productId?.variants?.filter((variant) => variant?._id === cellProps?.row?.original?.variantId)[0]?.gstPercentage) / 100) : priceFormat(cellProps?.row?.original?.quantity * (cellProps?.row?.original?.productId?.variants?.filter((variant) => variant?._id === cellProps?.row?.original?.variantId)[0]?.b2bPrice * cellProps?.row?.original?.productId?.variants?.filter((variant) => variant?._id === cellProps?.row?.original?.variantId)[0]?.gstPercentage) / 100) : null} GST`}
                                />
                            </div>
                        </div>
                    </>
                ),
            },
        ],
        []
    );
    const accessoriesColumns = useMemo(
        () => [
            {
                Header: "No.",
                accessor: "id",
                width: "3%",
                Cell: (cellProps) => (
                    <>
                        <div style={{ color: "#000", fontWeight: "bold", display: "flex", alignItems: "center", justifyContent: "center" }}>
                            {cellProps.row.index + 1}
                        </div>
                    </>
                ),
            },
            {
                Header: "Images",
                accessor: "images",
                width: "23%",
                Cell: (cellProps) => (
                    <>
                        <div className="flex items-center justify-center" style={{ color: "#000", fontWeight: "bold" }}>
                            {cellProps?.row?.original?.productId?.images ? <img className="w-11/12 flex items-center justify-center" src={`${IMAGE_URL}/${cellProps?.row?.original?.productId?.images[0]}`} /> : <></>}
                        </div>
                    </>
                ),
            },
            {
                Header: "Product Name",
                accessor: "items",
                width: "15%",
                Cell: (cellProps) => (
                    <>
                        <div className="flex items-center justify-center" style={{ color: "#000", fontWeight: "bold" }}>
                            {cellProps.row.original.productId != null ? cellProps.row.original.productId.name : null}
                        </div>
                        <div className="flex items-center justify-center mt-3 text-sm" style={{ color: "#000", fontWeight: 300 }}>
                            {cellProps.row.original.productId != null ? "Brand : " + cellProps.row.original.productId.brand : null}
                        </div>
                        <div className="flex items-center justify-center mt-1 text-sm" style={{ color: "#000", fontWeight: 300 }}>
                            {cellProps.row.original.productId != null ? "Model No. : " + cellProps?.row?.original?.productId?.variants?.filter((variant) => variant?._id === cellProps?.row?.original?.variantId)[0]?.modelNumber : null}
                        </div>
                    </>
                ),
            },
            {
                Header: "Unit Price",
                accessor: "unitPrice",
                width: "19%",
                Cell: (cellProps) => (
                    <>
                        <div style={{ textAlign: "left" }}>
                            <div style={{ color: "#000", fontWeight: "bold" }}>
                                {cellProps?.row?.original?.productId?.variants?.filter((variant) => variant?._id === cellProps?.row?.original?.variantId)[0]?.withoutDiscountPrice ? <Text
                                    className={"text-xs"}
                                    style={{ fontFamily: key.fontFamily, opacity: "0.6" }}
                                    content={`${cellProps.row.original.productId != null ? localStorage.getItem("role") == "b2c" ? priceFormat(((cellProps?.row?.original?.productId?.variants?.filter((variant) => variant?._id === cellProps?.row?.original?.variantId)[0]?.withoutDiscountPrice * cellProps?.row?.original?.productId?.variants?.filter((variant) => variant?._id === cellProps?.row?.original?.variantId)[0]?.gstPercentage) / 100) + cellProps?.row?.original?.productId?.variants?.filter((variant) => variant?._id === cellProps?.row?.original?.variantId)[0]?.withoutDiscountPrice) : priceFormat(((cellProps?.row?.original?.productId?.variants?.filter((variant) => variant?._id === cellProps?.row?.original?.variantId)[0]?.withoutDiscountPrice * cellProps?.row?.original?.productId?.variants?.filter((variant) => variant?._id === cellProps?.row?.original?.variantId)[0]?.gstPercentage) / 100) + cellProps?.row?.original?.productId?.variants?.filter((variant) => variant?._id === cellProps?.row?.original?.variantId)[0]?.withoutDiscountPrice) : null} (Incl. of all taxes)`}
                                /> : <Text
                                    className={"text-xs"}
                                    style={{ fontFamily: key.fontFamily, opacity: "0.6" }}
                                    content={`${cellProps.row.original.productId != null ? localStorage.getItem("role") == "b2c" ? priceFormat(((cellProps?.row?.original?.productId?.variants?.filter((variant) => variant?._id === cellProps?.row?.original?.variantId)[0]?.b2cPrice * cellProps?.row?.original?.productId?.variants?.filter((variant) => variant?._id === cellProps?.row?.original?.variantId)[0]?.gstPercentage) / 100) + cellProps?.row?.original?.productId?.variants?.filter((variant) => variant?._id === cellProps?.row?.original?.variantId)[0]?.b2cPrice) : priceFormat(((cellProps?.row?.original?.productId?.variants?.filter((variant) => variant?._id === cellProps?.row?.original?.variantId)[0]?.b2bPrice * cellProps?.row?.original?.productId?.variants?.filter((variant) => variant?._id === cellProps?.row?.original?.variantId)[0]?.gstPercentage) / 100) + cellProps?.row?.original?.productId?.variants?.filter((variant) => variant?._id === cellProps?.row?.original?.variantId)[0]?.b2bPrice) : null} (Incl. of all taxes)`}
                                />}
                            </div>
                            <div className="my-3" style={{ color: "#000", fontWeight: "bolder" }}>
                                {cellProps?.row?.original?.productId?.variants?.filter((variant) => variant?._id === cellProps?.row?.original?.variantId)[0]?.withoutDiscountPrice ? <Text
                                    className={"text-base"}
                                    style={{ fontFamily: key.fontFamily, }}
                                    content={cellProps.row.original.productId != null ? localStorage.getItem("role") == "b2c" ? priceFormat(cellProps?.row?.original?.productId?.variants?.filter((variant) => variant?._id === cellProps?.row?.original?.variantId)[0]?.withoutDiscountPrice) : priceFormat(cellProps?.row?.original?.productId?.variants?.filter((variant) => variant?._id === cellProps?.row?.original?.variantId)[0]?.withoutDiscountPrice) : null}
                                /> :
                                    <Text
                                        className={"text-base"}
                                        style={{ fontFamily: key.fontFamily, }}
                                        content={cellProps.row.original.productId != null ? localStorage.getItem("role") == "b2c" ? priceFormat(cellProps?.row?.original?.productId?.variants?.filter((variant) => variant?._id === cellProps?.row?.original?.variantId)[0]?.b2cPrice) : priceFormat(cellProps?.row?.original?.productId?.variants?.filter((variant) => variant?._id === cellProps?.row?.original?.variantId)[0]?.b2bPrice) : null}
                                    />
                                }
                            </div>
                            <div style={{ color: "#000", fontWeight: "bold" }}>
                                {cellProps?.row?.original?.productId?.variants?.filter((variant) => variant?._id === cellProps?.row?.original?.variantId)[0]?.withoutDiscountPrice ? <Text
                                    className={"text-xs"}
                                    style={{ fontFamily: key.fontFamily, opacity: "0.6" }}
                                    content={`+ ${cellProps.row.original.productId != null ? localStorage.getItem("role") == "b2c" ? priceFormat((cellProps?.row?.original?.productId?.variants?.filter((variant) => variant?._id === cellProps?.row?.original?.variantId)[0]?.withoutDiscountPrice * cellProps?.row?.original?.productId?.variants?.filter((variant) => variant?._id === cellProps?.row?.original?.variantId)[0]?.gstPercentage) / 100) : priceFormat((cellProps?.row?.original?.productId?.variants?.filter((variant) => variant?._id === cellProps?.row?.original?.variantId)[0]?.withoutDiscountPrice * cellProps?.row?.original?.productId?.variants?.filter((variant) => variant?._id === cellProps?.row?.original?.variantId)[0]?.gstPercentage) / 100) : null} GST`}
                                /> :
                                    <Text
                                        className={"text-xs"}
                                        style={{ fontFamily: key.fontFamily, opacity: "0.6" }}
                                        content={`+ ${cellProps.row.original.productId != null ? localStorage.getItem("role") == "b2c" ? priceFormat((cellProps?.row?.original?.productId?.variants?.filter((variant) => variant?._id === cellProps?.row?.original?.variantId)[0]?.b2cPrice * cellProps?.row?.original?.productId?.variants?.filter((variant) => variant?._id === cellProps?.row?.original?.variantId)[0]?.gstPercentage) / 100) : priceFormat((cellProps?.row?.original?.productId?.variants?.filter((variant) => variant?._id === cellProps?.row?.original?.variantId)[0]?.b2bPrice * cellProps?.row?.original?.productId?.variants?.filter((variant) => variant?._id === cellProps?.row?.original?.variantId)[0]?.gstPercentage) / 100) : null} GST`}
                                    />
                                }
                            </div>
                        </div>
                    </>
                ),
            },
            {
                Header: "Quantity",
                accessor: "quantity",
                width: "20%",
                Cell: (cellProps) => (
                    <>
                        <div className="flex items-center justify-center" style={{ color: "#000", fontWeight: "bold" }}>
                            <Button buttonText={"-"} className={"w-8 py-1 rounded"} style={{ background: key.blue, fontFamily: key.fontFamily, fontWeight: "bold", color: "#fff" }}
                                onClick={async () => { await updateCart("accessories", cellProps.row.original.quantity - 1, cellProps.row.original.productId?._id, cellProps.row.original.variantId) }}
                            />
                            <Text content={cellProps.row.original.quantity} className={"mx-3 w-12 text-center"} style={{ fontFamily: key.fontFamily, fontWeight: "bold", color: "#000" }} />
                            <Button buttonText={"+"} className={"w-8 py-1 rounded"} style={{ background: key.blue, fontFamily: key.fontFamily, fontWeight: "bold", color: "#fff" }}
                                onClick={async () => { await updateCart("accessories", cellProps.row.original.quantity + 1, cellProps.row.original.productId?._id, cellProps.row.original.variantId) }}
                            />
                        </div>
                    </>
                ),
            },
            {
                Header: "Total Price",
                accessor: "totalPrice",
                width: "20%",
                Cell: (cellProps) => (
                    <>
                        <div style={{ textAlign: "left" }}>
                            <div style={{ color: "#000", fontWeight: "bold" }}>
                                <Text
                                    className={"text-xs"}
                                    style={{ fontFamily: key.fontFamily, opacity: "0.6" }}
                                    content={`${cellProps.row.original.productId != null ? localStorage.getItem("role") == "b2c" ? priceFormat((cellProps?.row?.original?.quantity * cellProps?.row?.original?.productId?.variants?.filter((variant) => variant?._id === cellProps?.row?.original?.variantId)[0]?.b2cPrice) + (cellProps?.row?.original?.quantity * (cellProps?.row?.original?.productId?.variants?.filter((variant) => variant?._id === cellProps?.row?.original?.variantId)[0]?.b2cPrice * cellProps?.row?.original?.productId?.variants?.filter((variant) => variant?._id === cellProps?.row?.original?.variantId)[0]?.gstPercentage)) / 100) : priceFormat((cellProps?.row?.original?.quantity * cellProps?.row?.original?.productId?.variants?.filter((variant) => variant?._id === cellProps?.row?.original?.variantId)[0]?.b2bPrice) + (cellProps?.row?.original?.quantity * (cellProps?.row?.original?.productId?.variants?.filter((variant) => variant?._id === cellProps?.row?.original?.variantId)[0]?.b2bPrice * cellProps?.row?.original?.productId?.variants?.filter((variant) => variant?._id === cellProps?.row?.original?.variantId)[0]?.gstPercentage)) / 100) : null} (Incl. of all taxes)`}
                                />
                            </div>
                            <div className="my-3" style={{ color: "#000", fontWeight: "bolder" }}>
                                {cellProps?.row?.original?.productId?.variants?.filter((variant) => variant?._id === cellProps?.row?.original?.variantId)[0]?.withoutDiscountPrice ?
                                    <>
                                        <Text
                                            className={"text-xs"}
                                            style={{ fontFamily: key.fontFamily, textDecoration: "line-through", opacity: "0.5" }}
                                            content={cellProps.row.original.productId != null ?
                                                localStorage.getItem("role") == "b2c" ?
                                                    priceFormat(cellProps?.row?.original?.quantity * cellProps?.row?.original?.productId?.variants?.filter((variant) => variant?._id === cellProps?.row?.original?.variantId)[0]?.withoutDiscountPrice)
                                                    : priceFormat(cellProps?.row?.original?.quantity * cellProps?.row?.original?.productId?.variants?.filter((variant) => variant?._id === cellProps?.row?.original?.variantId)[0]?.withoutDiscountPrice)
                                                : null}
                                        />
                                        <Text
                                            className={"text-base"}
                                            style={{ fontFamily: key.fontFamily }}
                                            content={cellProps.row.original.productId != null ?
                                                localStorage.getItem("role") == "b2c" ?
                                                    priceFormat(cellProps?.row?.original?.quantity * cellProps?.row?.original?.productId?.variants?.filter((variant) => variant?._id === cellProps?.row?.original?.variantId)[0]?.b2cPrice)
                                                    : priceFormat(cellProps?.row?.original?.quantity * cellProps?.row?.original?.productId?.variants?.filter((variant) => variant?._id === cellProps?.row?.original?.variantId)[0]?.b2bPrice)
                                                : null}
                                        />
                                    </>
                                    :
                                    <Text
                                        className={"text-base"}
                                        style={{ fontFamily: key.fontFamily, }}
                                        content={cellProps.row.original.productId != null ?
                                            localStorage.getItem("role") == "b2c" ?
                                                priceFormat(cellProps?.row?.original?.quantity * cellProps?.row?.original?.productId?.variants?.filter((variant) => variant?._id === cellProps?.row?.original?.variantId)[0]?.b2cPrice)
                                                : priceFormat(cellProps?.row?.original?.quantity * cellProps?.row?.original?.productId?.variants?.filter((variant) => variant?._id === cellProps?.row?.original?.variantId)[0]?.b2bPrice)
                                            : null}
                                    />
                                }
                            </div>
                            <div style={{ color: "#000", fontWeight: "bold" }}>
                                <Text
                                    className={"text-xs"}
                                    style={{ fontFamily: key.fontFamily, opacity: "0.6" }}
                                    content={`+ ${cellProps.row.original.productId != null ? localStorage.getItem("role") == "b2c" ? priceFormat(cellProps?.row?.original?.quantity * (cellProps?.row?.original?.productId?.variants?.filter((variant) => variant?._id === cellProps?.row?.original?.variantId)[0]?.b2cPrice * cellProps?.row?.original?.productId?.variants?.filter((variant) => variant?._id === cellProps?.row?.original?.variantId)[0]?.gstPercentage) / 100) : priceFormat(cellProps?.row?.original?.quantity * (cellProps?.row?.original?.productId?.variants?.filter((variant) => variant?._id === cellProps?.row?.original?.variantId)[0]?.b2bPrice * cellProps?.row?.original?.productId?.variants?.filter((variant) => variant?._id === cellProps?.row?.original?.variantId)[0]?.gstPercentage) / 100) : null} GST`}
                                />
                            </div>
                        </div>
                    </>
                ),
            },
        ],
        []
    );
    return (
        <>
            {showCart && cartData && showData && cartData?.products?.plants?.length > 0 ? <div>
                <div className="flex border w-full"
                    onClick={() => { setShowPlants(!showPlants) }} >
                    <Text className={"h-12 w-full flex items-center pl-4 text-lg"} content={"Plants"} style={{ fontFamily: key.fontFamily, color: key.blue, backgroundColor: key.secondaryColor, fontWeight: "bold" }} />
                    <RightDropdownArrow show={showPlants} setShow={setShowPlants} />
                </div>
                <Collapse isOpen={showPlants} style={{ visibility: "visible" }}>
                    <CardBody>
                        <TableContainer
                            columns={plantColumns}
                            data={cartData.products.plants || []}
                            customPageSize={10}
                            className="custom-header-css"
                            trClass={"h-12 rounded-tr text-md"}
                            paginationDisplay={false}
                            backgroundColor={"#d6d6d680"}
                        />
                    </CardBody>
                </Collapse>
            </div> : <></>
            }
            {showCart && cartData && showData && cartData?.products?.services?.length > 0 ?
                <div>
                    <div className="flex border" onClick={() => { setShowServices(!showServices) }}>
                        <Text className={"h-12 w-full flex items-center pl-4 text-lg"} content={"Services"} style={{ fontFamily: key.fontFamily, color: key.blue, backgroundColor: key.secondaryColor, fontWeight: "bold" }} />
                        <RightDropdownArrow show={showServices} setShow={setShowServices} />
                    </div>
                    <Collapse isOpen={showServices} style={{ visibility: "visible" }}>
                        <CardBody>
                            <TableContainer
                                columns={serviceColumns}
                                data={cartData.products.services || []}
                                customPageSize={10}
                                className="custom-header-css"
                                trClass={"h-12 rounded-tr text-md"}
                                paginationDisplay={false}
                                backgroundColor={"#d6d6d680"}
                            />
                        </CardBody>
                    </Collapse>
                </div> : <></>
            }
            {showCart && cartData && showData && cartData?.products?.accessories?.length > 0 ?
                <div>
                    <div className="flex border" onClick={() => { setShowAccessories(!showAccessories) }}>
                        <Text className={"h-12 w-full flex items-center pl-4 text-lg"} content={"Accessories"} style={{ fontFamily: key.fontFamily, color: key.blue, backgroundColor: key.secondaryColor, fontWeight: "bold" }} />
                        <RightDropdownArrow show={showAccessories} setShow={setShowAccessories} />
                    </div>
                    <Collapse isOpen={showAccessories} style={{ visibility: "visible" }}>
                        <CardBody>
                            <TableContainer
                                columns={accessoriesColumns}
                                data={cartData.products.accessories || []}
                                customPageSize={10}
                                className="custom-header-css"
                                trClass={"h-12 rounded-tr text-md"}
                                paginationDisplay={false}
                                backgroundColor={"#d6d6d680"}
                            />
                        </CardBody>
                    </Collapse>
                </div> : <></>
            }
            {showCart && cartData && showData && cartData?.products?.spareParts?.length > 0 ?
                <>
                    <div className="flex border" onClick={() => { setShowSpareParts(!showSpareParts) }}>
                        <Text className={"h-12 w-full flex items-center pl-4 text-lg"} content={"Spare Parts"} style={{ fontFamily: key.fontFamily, color: key.blue, backgroundColor: key.secondaryColor, fontWeight: "bold" }} />
                        <RightDropdownArrow show={showSpareParts} setShow={setShowSpareParts} />
                    </div>
                    <Collapse isOpen={showSpareParts} style={{ visibility: "visible" }}>
                        <CardBody>
                            <TableContainer
                                columns={sparePartsColumns}
                                data={cartData.products.spareParts || []}
                                customPageSize={10}
                                className="custom-header-css"
                                trClass={"h-12 rounded-tr text-md"}
                                paginationDisplay={false}
                                backgroundColor={"#d6d6d680"}
                            />
                        </CardBody>
                    </Collapse>
                </> : <></>
            }
            {cartData?.products?.plants?.length < 1 && cartData?.products?.accessories?.length < 1 && cartData?.products?.spareParts?.length < 1 && cartData?.products?.services?.length < 1 ?
                <Text content={"No Items Added!"} className={"w-full text-2xl flex items-center justify-center"} style={{ color: "#000", opacity: "0.7", fontFamily: key.fontFamily }} />
                : <></>}
        </>
    )
}
export default ItemsTable;