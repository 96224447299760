import { FormGroup, Input, Label, Modal, ModalBody, ModalFooter } from "reactstrap"
import Text from "../../atom/Text"
import Button from "../../atom/Button"
import { key } from "../../utils/consts"
import { useContext, useState } from "react"
import { ToastContainer, toast } from "react-toastify"
import { CartContext } from "./CartContext"

const SelectProductModal = ({ openModal, setOpenModal, couponData, couponProductTypes, setSelectedProductType, couponIndex, setSelectedCouponIndex }) => {
    const [selectedType, setSelectedType] = useState("");
    const { applyCoupon } = useContext(CartContext);
    return (
        <>
            <ToastContainer />
            <Modal isOpen={openModal}>
                <ModalBody>
                    <Text content={"Select a product to which you want to apply this coupon."} style={{ fontFamily: key.fontFamily, opacity: "0.7" }} className={"text-sm"} />
                    <div className="my-4">
                        {couponProductTypes?.map((item) => {
                            return (
                                <FormGroup check>
                                    <Input
                                        onChange={(e) => {
                                            setSelectedType(e.target.value)
                                        }}
                                        value={item}
                                        name="radio1"
                                        type="radio"
                                    />
                                    {' '}
                                    <Label check style={{ fontFamily: key.fontFamily, color: key.blue }}>
                                        {item}
                                    </Label>
                                </FormGroup>
                            )
                        })}
                    </div>
                </ModalBody>
                <ModalFooter>
                    <Button buttonText={"Close"} className={"px-3 py-2.5 rounded"} style={{ fontFamily: key.fontFamily, backgroundColor: key.blue, color: key.secondaryColor }} onClick={() => {
                        setOpenModal(false)
                    }} />
                    <Button buttonText={"Submit"} className={"px-3 py-2.5 rounded"} style={{ fontFamily: key.fontFamily, backgroundColor: key.blue, color: key.secondaryColor }} onClick={() => {
                        if (selectedType !== "") {
                            setSelectedProductType(selectedType);
                            setOpenModal(false);
                            setSelectedCouponIndex(couponIndex);
                            applyCoupon(couponData?._id, selectedType)
                        } else {
                            toast.error("Please Select A Type!")
                        }
                    }} />
                </ModalFooter>
            </Modal>
        </>
    )
}
export default SelectProductModal