// export const BASE_URL = 'http://localhost:9001/api/'
// export const VIDEO_URL = 'http://localhost:9001/public/uploads/videos'
// export const BASE_URL_IMAGE =  'http://localhost:9001/'
// export const IMAGE_URL ='http://localhost:9001/public/uploads/images'
// export const LOGO_URL =  'http://localhost:9001/logo/';
// export const QUOTATION_URL = 'http://localhost:9001/public/uploads/quotations';
// export const MANUAL_URL = 'http://localhost:9001/public/uploads/manuals';
// export const ORDER_PDF_URL = 'http://localhost:9001/public/uploads/';
// export const ORDER_IMAGE_URL = 'http://localhost:9001/public/uploads/order-images/';
// export const ORDER_PACKING_IMAGE_URL = "http://localhost:9001/public/uploads/packing-material-images/"
// export const ORDER_MATRIAL_IMAGE_URL = 'http://localhost:9001/public/uploads/dispatch-material-images/';
// export const ORDER_VIDEO_URL = 'http://localhost:9001/public/uploads/video-attachment/'
export const BASE_URL = 'https://api-rtpl.stpmart.com/api/'
export const VIDEO_URL = 'https://api-rtpl.stpmart.com/public/uploads/videos'
export const BASE_URL_IMAGE =  'https://api-rtpl.stpmart.com/'
export const IMAGE_URL ='https://api-rtpl.stpmart.com/public/uploads/images'
export const LOGO_URL =  'https://api-rtpl.stpmart.com/logo/'
export const QUOTATION_URL = 'https://api-rtpl.stpmart.com/public/uploads/quotations';
export const MANUAL_URL = 'https://api-rtpl.stpmart.com/public/uploads/manuals'
export const ORDER_PDF_URL = 'https://api-rtpl.stpmart.com/public/uploads/';
export const ORDER_IMAGE_URL = 'https://api-rtpl.stpmart.com/public/uploads/order-images/';
export const ORDER_PACKING_IMAGE_URL = "https://api-rtpl.stpmart.com/public/uploads/packing-material-images/"
export const ORDER_MATRIAL_IMAGE_URL = 'https://api-rtpl.stpmart.com/public/uploads/dispatch-material-images/';
export const ORDER_VIDEO_URL = 'https://api-rtpl.stpmart.com/public/uploads/video-attachment/'



export const getTokenData = () => {
  let tokenDetails = "Bearer " + localStorage.getItem("token");
  return tokenDetails;
};
