import { ToastContainer, toast } from "react-toastify";
import BackgroundImage from "../../atom/BackgroundImage";
import Title from "../../atom/Title";
import Description from "../../atom/Description";
import { key } from "../../utils/consts";
import Image from "../../atom/Image";
import Field from "../../molecules/Field";
import { useState } from "react";
import { useHistory } from 'react-router-dom';
import Button from "../../atom/Button";
import Text from "../../atom/Text";
import { callPostServiceData } from "../../Services/DataServices";
import { BASE_URL } from "../../Services/config";
import { OTP_VERIFICATION } from "../../utils/pathDetails";

const ForgotPassword = ({ }) => {
    const [phoneNumber, setPhoneNumber] = useState("");
    const history = useHistory();
    const onSubmit = async () => {
        if (phoneNumber == "") {
            toast.error("Please Enter Phone Number ")
        } else {
            const apiBody = { mobile: phoneNumber };
            const response = await callPostServiceData(`${BASE_URL}auth/forgot-password`, apiBody);
            if (response.result.data.type == "success") {
                toast.success("OTP Sent")
                setTimeout(() => {
                    history.push(OTP_VERIFICATION, { user: response.result.data.userData });
                }, 1000);
            } else {
                toast.error("Phone Number Not Registered")
            }
        }
    }
    return (
        <>
            <ToastContainer />
            <div className="flex" style={{ backgroundColor: "#fff" }}>
                <BackgroundImage src={"./images/Signup Photo.png"} />
                <div className="w-10/12 pl-10 pr-10 flex flex-col items-center justify-center max-[952px]:w-full pt-20 " style={{ background: key.secondaryColor }}>
                    <div className="flex items-center justify-center self-center w-fit max-[1032px]:flex-col">
                        <Image src={'./images/logo-light.png'} className={"w-1/3"} />
                        <div className="flex flex-col ml-6 max-[1032px]:mt-6 ml-0">
                            <Title text={"Let's Get Something"} color={key.blue} />
                            <Description text={"Login to Your Account"} color={key.blue} />
                        </div>
                    </div>
                    <div
                        className="h-full w-full mt-8 rounded-t-2xl p-10 max-[810px]:w-full max-[810px]:mr-0 max-[810px]:ml-0 max-[810px]:mt-8"
                        style={{ backgroundColor: key.formBackground, boxShadow: key.formShadow }}>
                        <Text
                            content={"Please enter your registered phone number. The One Time Password (OTP) will be sent to this phone number to reset your password. "}
                            className="text-lg mb-10"
                            style={{ color: "#7E8A97", fontFamily: key.fontFamily, fontWeight: "400", textAlign: "center" }} />
                        <Field text={"Phone No."} placeholder={"Phone No."} required={true} onChange={(e) => { setPhoneNumber(e.target.value) }} />
                        <div className="flex justify-center mt-12">
                            <Button
                                onClick={() => { onSubmit() }}
                                buttonText={"Submit"}
                                style={{
                                    background: key.primaryColor,
                                    color: "#fff",
                                    fontFamily: key.fontFamily,
                                    fontWeight: "bold"
                                }}
                                className="w-52 h-12 flex items-center justify-center rounded"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default ForgotPassword;