import { useHistory } from "react-router-dom";
import { LOGIN, ORDER_HISTORY, PROFILE, QUOTATION_HISTORY } from "../../utils/pathDetails";
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from "reactstrap";
import { FaUser } from "react-icons/fa";
import { IoIosLogOut } from "react-icons/io";
import { key } from "../../utils/consts";
import { useState } from "react";

const Profile = ({ profileSelected, setProfileSelected, theme }) => {
    const history = useHistory();
    const [mouseEntered, setMouseEntered] = useState(false);
    return (
        <Dropdown isOpen={profileSelected} toggle={() => { setProfileSelected(!profileSelected) }} direction={"down"}>
            <DropdownToggle className={`text-base mr-6 ${mouseEntered ? "border-1 rounded" : ""}`} onMouseEnter={() => { setMouseEntered(true) }} onMouseLeave={() => { setMouseEntered(false) }} style={{ fontFamily: key.fontFamily, fontWeight: "700", color: theme == "light" ? "#000" : "#fff", backgroundColor: theme == "light" ? "#fff" : "transparent", border:`${!mouseEntered ? "none" : ""}`, borderColor: `${mouseEntered ? key.blue : ""}` }} >
                <FaUser style={{ width: "1.5em", height: "1.5em" }} />
            </DropdownToggle>
            <DropdownMenu style={{ background: key.primaryColor, border: "none" }}>
                <DropdownItem className="text-xs bg-transparent" style={{ fontFamily: key.fontFamily, fontWeight: "bold", color: "#fff" }} onClick={() => { history.push(PROFILE) }}>Profile</DropdownItem>
                <DropdownItem divider />
                <DropdownItem className="text-xs bg-transparent" style={{ fontFamily: key.fontFamily, fontWeight: "bold", color: "#fff" }} onClick={() => { history.push(ORDER_HISTORY) }}>My Orders</DropdownItem>
                <DropdownItem divider />
                <DropdownItem className="text-xs bg-transparent" style={{ fontFamily: key.fontFamily, fontWeight: "bold", color: "#fff" }} onClick={() => { history.push(QUOTATION_HISTORY) }}>Quotations</DropdownItem>
                <DropdownItem divider />
                <DropdownItem className="text-xs bg-transparent flex flex-col" style={{ fontFamily: key.fontFamily, fontWeight: "bold", color: "#fff" }} onClick={async () => {
                    await window.localStorage.clear();
                    history.push(LOGIN)
                }}>
                    <div className="text-xs bg-transparent flex">
                        <IoIosLogOut size={20} className="mr-2" style={{ color: "#fff" }} />
                        Logout
                    </div>
                </DropdownItem>
            </DropdownMenu>
        </Dropdown>
    )
}
export default Profile;