import { useContext, useEffect, useState } from "react"
import Button from "../../atom/Button"
import Text from "../../atom/Text"
import { key } from "../../utils/consts"
import { calculateGst, priceFormat } from "../../utils/priceFunctions"
import { CartContext } from "./CartContext"
import { useHistory } from "react-router-dom";
import { ORDER_CONFIRM } from "../../utils/pathDetails";
import { Button as Loader, Spinner } from 'reactstrap';
import { ToastContainer, toast } from "react-toastify"

const BillPayment = ({ sendQuotation, data, quotationLoader, setQuotationLoader }) => {
    const { gstPrice, totalPrice, productPrice, discountPrice, totalDiscountPrice, gstDiscountPrice, productDiscountPrice, cartData } = useContext(CartContext);
    const history = useHistory();
    return (
        <>
            <ToastContainer />
            <div className={"h-full w-full"} style={{ backgroundColor: "#EAEAEA" }}>
                <div className="border-b flex items-center border-x-2 py-2" style={{ borderColor: "#00000050" }}>
                    <Text content={"Bill Payment"} className={"ml-4 my-2"} style={{ fontFamily: key.fontFamily, color: "#000", fontWeight: "bold" }} />
                </div>
                <div className="border-b border-x-2" style={{ borderColor: "#00000050" }}>
                    <div className="flex">
                        <Text content={"Total Price"} className={"w-3/5 pl-4 text-sm mt-4"} style={{ fontFamily: key.fontFamily, color: "#000" }} />
                        <Text content={priceFormat(productDiscountPrice ? productDiscountPrice : productPrice)} className={"w-2/5 pr-2 my-4 text-sm text-right"} style={{ fontFamily: key.fontFamily, color: "#000" }} />
                    </div>
                    <div className="flex">
                        <Text content={"GST"} className={"w-3/5 pl-4 mb-4 text-sm"} style={{ fontFamily: key.fontFamily, color: "#000" }} />
                        <Text content={priceFormat(gstDiscountPrice ? gstDiscountPrice : gstPrice)} className={"w-2/5 mb-4 pr-2 text-sm text-right"} style={{ fontFamily: key.fontFamily, color: "#000" }} />
                    </div>
                    {discountPrice ? <div className="flex">
                        <Text content={"Discount"} className={"w-3/5 pl-4 mb-4 text-sm"} style={{ fontFamily: key.fontFamily, color: "#000" }} />
                        <Text content={priceFormat(discountPrice)} className={"w-2/5 mb-4 pr-2 text-sm text-right"} style={{ fontFamily: key.fontFamily, color: "#000" }} />
                    </div> : <></>}
                </div>
                <div className="border-b border-x-2" style={{ borderColor: "#00000050" }}>
                    <div className="flex">
                        <Text content={"Total Amount"} className={"w-2/5 pl-4 text-sm my-3"} style={{ fontFamily: key.fontFamily, color: "#000", fontWeight: "bold" }} />
                        <Text content={priceFormat(totalDiscountPrice ? totalDiscountPrice : totalPrice)} className={"w-3/5 pr-2 text-sm text-right my-3"} style={{ fontFamily: key.fontFamily, color: "#000", fontWeight: "bold" }} />
                    </div>
                </div>
                <div className="flex">
                    {!quotationLoader ?
                        <Button buttonText={"Get Quote"} className={"h-1/6 w-1/2 py-2 border-r"} style={{ fontFamily: key.fontFamily, color: "#fff", background: key.blue, fontWeight: "bold", borderColor: key.secondaryColor }} onClick={() => {
                            if (cartData?.products?.plants?.length < 1 && cartData?.products?.accessories?.length < 1 && cartData?.products?.spareParts?.length < 1 && cartData?.products?.services?.length < 1) {
                                toast.error("No Items in cart!")
                            } else {
                                setQuotationLoader(true);
                                sendQuotation()
                                setQuotationLoader(false);
                            }
                        }}
                        />
                        : <Loader className="w-1/2 h-1/6 py-2 border-r" style={{ fontFamily: key.fontFamily, color: "#fff", background: key.blue, fontWeight: "bold", borderColor: key.secondaryColor }}>
                            <Spinner size={"sm"} style={{ color: key.secondaryColor }}></Spinner>
                        </Loader>
                    }
                    <Button buttonText={"Order Now"} className={"h-1/6 w-1/2 py-2"} style={{ fontFamily: key.fontFamily, color: "#fff", background: key.blue, fontWeight: "bold" }} onClick={() => {
                        if (cartData?.products?.plants?.length < 1 && cartData?.products?.accessories?.length < 1 && cartData?.products?.spareParts?.length < 1 && cartData?.products?.services?.length < 1) {
                            toast.error("No Items in cart!")
                        } else {
                            sendQuotation()
                            setTimeout(() => {
                                data['discountPrice'] = discountPrice;
                                history.push(ORDER_CONFIRM + "get", { data: data })
                            }, 2000)
                        }
                    }
                    } />
                </div>
            </div>
        </>
    )
}
export default BillPayment