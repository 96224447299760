import { useEffect, useState } from "react";
import Text from "../../atom/Text";
import { key } from "../../utils/consts";
import { BiSolidDiscount } from "react-icons/bi";
import { callGetCouponsData } from "../../Services/DataServices";
import { priceFormat } from "../../utils/priceFunctions";
import { MdDiscount } from "react-icons/md";
import CouponKnowMoreModal from "../ShoppingCart/CouponKnowMoreModal";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";


const BestDeals = () => {
    const [couponsData, setCouponsData] = useState([]);
    const [selectedCoupon, setSelectedCoupon] = useState();
    const [knowMoreModal, setKnowMoreModal] = useState(false);
    const getCouponsData = async () => {
        const response = await callGetCouponsData();
        if (response?.result) {
            setCouponsData(response?.result)
        }
    }

    const couponText = (coupon) => {
        if (coupon?.type === "both") {
            return `Use code ${coupon?.code?.toUpperCase()} and get ${coupon?.discountPercentage}% Offer on orders above ${priceFormat(coupon?.lowerLimit)}. Maximum discount ${priceFormat(coupon?.discountAmount)}.`
        } else if (coupon?.type === "flat") {
            return `Use code ${coupon?.code?.toUpperCase()} and get flat discount of ${priceFormat(coupon?.discountAmount)} on orders above ${priceFormat(coupon?.lowerLimit)}.`
        } else if (coupon?.type === "percentage") {
            return `Use code ${coupon?.code?.toUpperCase()} and get ${coupon?.discountPercentage}% discount on orders above ${priceFormat(coupon?.lowerLimit)}.`
        }
    }
    useEffect(() => {
        getCouponsData();
    }, [])
    return (
        <>
            <CouponKnowMoreModal openModal={knowMoreModal} setOpenModal={setKnowMoreModal} couponData={selectedCoupon} />
            <div className="pb-3" style={{ backgroundColor: key.blue }}>
                <div className="flex items-center justify-center pt-6 gap-2">
                    <Text content={"Best Deals"} className={"text-3xl text-center"} style={{ fontFamily: key.fontFamily, color: key.secondaryColor, fontWeight: "bold" }} />
                    <MdDiscount size={26} style={{ color: key.secondaryColor }} />
                </div>
                <Slider
                    dots={false}
                    slidesToShow={window.innerWidth > 1213 ? 3 : window.innerWidth > 844 ? 2 : 1}
                    slidesToScroll={1}
                    autoplay={true}
                    arrows={false}
                    autoplaySpeed={5000}
                >
                    {couponsData ? couponsData?.map((item) => {
                        return (
                            <>
                                <div style={{ backgroundColor: key.secondaryColor, borderColor: "gray" }} className="m-4 flex w-96 rounded border-dashed border-2 max-[419px]:w-72">
                                    <div className="w-24 p-2 border-r flex items-center justify-center">
                                        <BiSolidDiscount size={50} style={{ color: key.blue }} />
                                    </div>
                                    <div className="w-72 py-2 px-3">
                                        <Text content={item?.code?.toUpperCase()} style={{ fontFamily: key.fontFamily, fontWeight: "bold"}} className={"text-sm"} />
                                        <Text content={couponText(item)} style={{ opacity: "0.6", fontFamily: key.fontFamily }} className={"text-xs my-2 h-12"} />
                                        <Text HtmlTag="button" content={"Know More"} style={{ color: key.blue, fontFamily: key.fontFamily }} className={"text-xs my-1 font-bold"} onClick={() => {
                                            setSelectedCoupon(item)
                                            setKnowMoreModal(true);
                                        }} />
                                    </div>
                                </div>
                            </>
                        )
                    }) : <></>}
                </Slider>
            </div>
        </>
    )
}
export default BestDeals;