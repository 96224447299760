import Label from "../atom/Label"
import TextInput from "../atom/TextInput"

const Field = ({ text, placeholder, onChange, inputType, defaultValue, required, disabled, onKeyPress }) => {
    return (
        <>
            <Label text={text} required={required} />
            <TextInput placeholder={placeholder} onChange={onChange} inputType={inputType} defaultValue={defaultValue} disabled={disabled} onKeyPress={onKeyPress}/>
        </>
    )
}
export default Field