import { useState } from "react"
import { IMAGE_URL } from "../../Services/config"
import { ButtonBack, ButtonNext, CarouselProvider, Slide, Slider } from "pure-react-carousel"
import { FaGreaterThan, FaLessThan } from "react-icons/fa6"
import { key } from "../../utils/consts"

const ImageDisplay = ({ productData }) => {
    const [displayedImage, setDisplayedImage] = useState(productData?.images ? productData?.images[0] : null)
    return (
        <div className="w-2/5 max-[848px]:w-full" style={{ overflowY: "auto" }}>
            {/* <div className="flex items-center justify-center h-5/6 border-b border-r ">
                <img src={`${IMAGE_URL}/${productData?.images ? displayedImage : null}`} className="w-11/12 max-[848px]:w-3/5" />
            </div> */}
            <CarouselProvider
                naturalSlideWidth={window.innerWidth}
                isIntrinsicHeight={"100"}
                className="flex items-center justify-center h-5/6 border-b border-r "
                totalSlides={productData?.images?.length}
                infinite={true}
                isPlaying={true}
                interval={10000}
            >
                <Slider style={{ height: "fit-content" }}>
                    {productData?.images && productData?.images.map((item, index) => {
                        return (
                            <Slide key={index}>
                                <div className="w-full flex mt-2" style={{ background: key.secondaryColor }}>
                                    <div style={{ width: "10%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                                        <ButtonBack><FaLessThan className="text-3xl max-[768px]:text-base" style={{ color: "gray", opacity: 0.6 }} /></ButtonBack>
                                    </div>
                                    <div style={{ width: "80%", alignSelf: "center" }} >
                                        <img src={`${IMAGE_URL}/${item}`}
                                            draggable={false} onMouseDown={() => { return false }} style={{ userDrag: "none" }}
                                        />
                                    </div>
                                    <div style={{ width: "10%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                                        <ButtonNext><FaGreaterThan className="text-3xl max-[768px]:text-base" style={{ color: "gray", opacity: 0.6 }} /></ButtonNext>
                                    </div>
                                </div>
                            </Slide>
                        )
                    })}
                </Slider>
            </CarouselProvider>
            <div className="h-1/6 flex border items-center gap-2" style={{ overflowX: "auto" }}>
                {productData?.images ? productData?.images.map((item) => {
                    return (
                        <img src={`${IMAGE_URL}/${item}`} className="h-2/3 border-r max-[848px]:w-1/4 max-[848px]:h-auto" onClick={() => { setDisplayedImage(item) }} />
                    )
                }) : <></>}
            </div>
        </div>
    )
}
export default ImageDisplay