import { useEffect, useMemo, useState } from "react";
import Text from "../../../../atom/Text";
import Header from "../../../../components/Header/Header";
import { key } from "../../../../utils/consts";
import { callGetOrderData } from "../../../../Services/OrderServices";
import "react-step-progress-bar/styles.css";
import { ProgressBar, Step } from "react-step-progress-bar";
import { IoIosArrowDroprightCircle } from "react-icons/io";
import { priceFormat } from "../../../../utils/priceFunctions";
import Footer from "../../../../components/Footer";
import { CardBody, Collapse } from "reactstrap";
import TableContainer from "../../../../components/TableContainer";
import { IMAGE_URL } from "../../../../Services/config";
import OrderTrack from "./OrderTrack";
import OrderDetails from "./OrderDetails";
import Loader from "../../../../components/Loader";


const OrderHistory = () => {
    const [orderData, setOrderData] = useState([])
    const [selectedIndex, setSelectedIndex] = useState();
    const [isOpen, setIsOpen] = useState(false);
    const getOrdersData = async () => {
        const response = await callGetOrderData();
        if (response?.result) {
            setOrderData(response?.result)
        }
    }

    useEffect(() => {
        getOrdersData();
    }, [])
    return (
        <>
            <Header />
            <Text content={"Order History"} className="text-4xl m-4" style={{ fontFamily: key.fontFamily, fontWeight: "bold", background: key.primaryColor, WebkitBackgroundClip: "text", WebkitTextFillColor: "transparent" }} />
            {orderData && orderData?.length > 0 ?
                orderData?.map((item, index) => {
                    return (
                        <>
                            <OrderTrack orderData={item} isOpen={isOpen} setIsOpen={setIsOpen} setSelectedIndex={setSelectedIndex} selectedIndex={selectedIndex} index={index} />
                            <OrderDetails orderData={item} isOpen={selectedIndex === index ? true : false} setIsOpen={setIsOpen} index={index} selectedIndex={selectedIndex} />
                        </>
                    )
                })
                : <><Loader /></>}
            <Footer />
        </>
    )
}
export default OrderHistory;