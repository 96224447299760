import axios from "axios";

const setAuthorization = (token) => {
  axios.defaults.headers.common["Authorization"] = "Bearer " + token;
};

const getLoggedinUser = () => {
  const user = localStorage.getItem("user");
  if (!user) {
    return null;
  } else {
    return JSON.parse(user);
  }
};

const getIsLoggedinUser = () => {
  const user = localStorage.getItem("isLoggedin");
  if (!user) {
    return null;
  } else {
    return user;
  }
};


const loggedOutUser = () => {
  return localStorage.removeItem("user");
};

export {  getLoggedinUser, setAuthorization, loggedOutUser ,getIsLoggedinUser};
