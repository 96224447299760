import { useEffect, useState } from "react";
import Header from "../../../../../components/Header/Header"
import { getPlantDataService } from "../../../../../Services/OrderServices";
import Plant from "../../../../../molecules/Plant";
import Text from "../../../../../atom/Text";
import { key } from "../../../../../utils/consts";
import { Input } from "reactstrap";
import { CiSearch } from "react-icons/ci";
import Footer from "../../../../../components/Footer";

const WaterTreatmentPlant = () => {
    const [plantData, setPlantData] = useState([]);
    const [unfilteredPlantData, setUnfilteredPlantData] = useState([]);
    const [searchData, setSearchData] = useState("");

    const getPlantData = async () => {
        const { result } = await getPlantDataService();
        setPlantData(result);
        setUnfilteredPlantData(result);
    }
    const filterdata = async (text) => {
        text && text.length > 1 ?
            setPlantData(unfilteredPlantData.filter((item) => String(item.name).toLowerCase().includes(String(text).toLowerCase()))) : setPlantData(unfilteredPlantData)
    }
    useEffect(() => {
        getPlantData();
    }, [])
    return (
        <>
            <Header />
            <Text content={"Water Treatment Plant"} className="text-4xl m-4" style={{ fontFamily: key.fontFamily, fontWeight: "bold", background: key.primaryColor, WebkitBackgroundClip: "text", WebkitTextFillColor: "transparent" }} />
            <div className="w-1/2 m-4 flex max-[634px]:w-4/5">
                <Input placeholder="Search a Plant" style={{ borderImage: key.primaryColor + 1, fontFamily: key.fontFamily }} onChange={setSearchData}
                    onKeyPress={(target) => {
                        if (target.charCode == 13) {
                            filterdata(searchData.target.value)
                        }
                    }} />
                <button
                    className="w-fit flex items-center px-3" style={{ background: key.primaryColor }}
                    onClick={() => {
                        filterdata(searchData.target.value)
                    }}>
                    <CiSearch size={20} color="#fff" />
                </button>
            </div>
            {plantData.length > 0 ?
                plantData.map((item, index) => {
                    return (
                        <Plant key={index} plantData={item} />
                    )
                })
                : <></>
            }
            <Footer />
        </>
    )
}
export default WaterTreatmentPlant;