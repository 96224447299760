import { BiSolidOffer } from "react-icons/bi";
import Text from "../../atom/Text";
import { key } from "../../utils/consts";
import { useState } from "react";
import CouponKnowMoreModal from "../../pages/ShoppingCart/CouponKnowMoreModal";
import { priceFormat } from "../../utils/priceFunctions";

const Coupons = ({ couponData }) => {
    const [knowMoreModal, setKnowMoreModal] = useState(false);
    const [selectedData, setSelectedData] = useState({});
    const couponText = (coupon) => {
        if (coupon?.type === "both") {
            return `Use code ${coupon?.code?.toUpperCase()} and get ${coupon?.discountPercentage}% Offer on orders above ${priceFormat(coupon?.lowerLimit)}. Maximum discount ${priceFormat(coupon?.discountAmount)}.`
        } else if (coupon?.type === "flat") {
            return `Use code ${coupon?.code?.toUpperCase()} and get flat discount of ${priceFormat(coupon?.discountAmount)} on orders above ${priceFormat(coupon?.lowerLimit)}.`
        } else if (coupon?.type === "percentage") {
            return `Use code ${coupon?.code?.toUpperCase()} and get ${coupon?.discountPercentage}% discount on orders above ${priceFormat(coupon?.lowerLimit)}.`
        }
    }
    return (
        <>
            <CouponKnowMoreModal openModal={knowMoreModal} setOpenModal={setKnowMoreModal} couponData={selectedData} />
            <Text content={"Offers & Coupons"} className={"py-2 pl-2 mt-4 rounded-t"} style={{ fontFamily: key.fontFamily, color: key.secondaryColor, backgroundColor: key.blue, fontWeight: "bold" }} />
            <div className='border-x border-b' style={{ overflowY: "auto", height: "290px", backgroundColor: key.formBackground }}>
                {couponData ? couponData?.map((item, index) => {
                    return (
                        <div key={index} className='pl-3 mx-3 my-3 shadow-md rounded' style={{ backgroundColor: key.secondaryColor }}>
                            <div className='py-1.5 flex w-full items-center'>
                                <BiSolidOffer size={26} style={{ color: key.blue }} />
                                <Text content={item?.code?.toUpperCase()} className={"ml-1 text-sm w-full"} style={{ fontFamily: key.fontFamily, fontWeight: "bold" }} />
                            </div>
                            <Text content={couponText(item)} className={"text-xs w-full mt-2 pr-2"} style={{ fontFamily: key.fontFamily, opacity: "0.7" }} />
                            <button onClick={() => {
                                setSelectedData(item);
                                setKnowMoreModal(true);
                            }}>
                                <Text content={"Know More"} className={"text-xs pb-2.5"} style={{ fontFamily: key.fontFamily, color: key.blue }} />
                            </button>
                        </div>
                    )
                }) : <></>}
            </div>
        </>
    )
}
export default Coupons;