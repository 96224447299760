import { useEffect, useState } from "react";
import { getSparePartsDataService } from "../../../Services/ResourcesServices";
import Header from "../../../components/Header/Header";
import Footer from "../../../components/Footer";
import Text from "../../../atom/Text";
import CatelogueDoc from "./DownloadCatelogue";
import Button from "../../../atom/Button";
import { key } from "../../../utils/consts";
import { PDFDownloadLink } from "@react-pdf/renderer";

const Catalogue = () => {
    const [sparePartsData, setSparePartsData] = useState([]);
    const getSparePartsData = async () => {
        const response = await getSparePartsDataService();
        setSparePartsData(response?.result);
    }
    useEffect(() => {
        getSparePartsData();
    }, [])
    return (
        <>
            <Header />
            <Text content={"Catalogue"} className="text-4xl m-4" style={{ fontFamily: key.fontFamily, fontWeight: "bold", background: key.primaryColor, WebkitBackgroundClip: "text", WebkitTextFillColor: "transparent" }} />
            <Text content={"The catalogue, a PDF file contains the detailed information about the spare parts of the plants."} className="w-1/2 text-lg m-4 max-[550px]:w-3/4" style={{ fontFamily: key.fontFamily, fontWeight: "400", color: "rgba(0,0,0,0.5)" }} />
            <PDFDownloadLink document={<CatelogueDoc sparePartsData={sparePartsData} />} fileName="catalogue.pdf">
                {({ blob, url, loading, error }) => (
                    !loading ? <Button
                        buttonText={"Download"}
                        style={{
                            background: key.primaryColor,
                            color: "#fff",
                            fontFamily: key.fontFamily,
                            fontWeight: "bold",
                        }}
                        onClick={async () => {
                            await getSparePartsData()
                        }}
                        className="mx-4 w-52 h-12 flex items-center justify-center rounded no-underline mb-28"
                    /> : <></>
                )}
            </PDFDownloadLink>
            <Footer />
        </>
    )
}
export default Catalogue;