import { useContext, useEffect, useState } from 'react';
import Button from '../../atom/Button';
import Text from '../../atom/Text';
import Footer from '../../components/Footer';
import Header from '../../components/Header/Header';
import { key } from '../../utils/consts';
import ItemsTable from './ItemsTable';
import Coupon from '../../molecules/Coupon';
import BillPayment from './BillPayment';
import { getCartDataService, sendQuotationFromCart } from '../../Services/CartServices';
import Loader from '../../components/Loader';
import { CartContext } from './CartContext';
import { ToastContainer, toast } from 'react-toastify';
import Coupons from './Coupons';
import { MdDiscount } from "react-icons/md";
import { BiSolidOffer } from "react-icons/bi";

const ShoppingCart = () => {
    const { cartData, couponData, cartDataWithoutCoupon } = useContext(CartContext);
    const [loading, setLoading] = useState(false);
    const [quotationLoader, setQuotationLoader] = useState(false);
    const sendQuotation = async () => {
        let products = [];
        await cartDataWithoutCoupon?.products?.plants?.map(async (item) => {
            const productObj = {
                name: item?.productId?.name,
                productsId: item?.productId?._id,
                b2bPrice: item?.productId?.b2bPrice,
                b2cPrice: item?.productId?.b2cPrice,
                unit: "NOS",
                category: item?.productId?.category,
                capacity: item?.productId?.capacity + " KLD",
                brand: item?.productId?.brand,
                modelNumber: item?.productId?.modelNumber,
                type: "plants",
                quantity: item?.quantity,
                images: item?.productId?.images,
                gst: item?.productId?.gstPercentage,
                productId: item?.productId?._id,
                parameters: item?.productId?.parameters,
                bom: item?.productId?.bom
            }
            await products.push(productObj);
        })
        await cartDataWithoutCoupon?.products?.accessories?.map(async (item) => {
            const productObj = {
                name: item?.productId?.name,
                productsId: item?.productId?._id,
                category: item?.productId?.category,
                b2bPrice: item?.productId?.variants.filter((value) => value?._id == item?.variantId)[0]?.b2bPrice,
                b2cPrice: item?.productId?.variants.filter((value) => value?._id == item?.variantId)[0]?.b2cPrice,
                capacity: item?.productId?.variants.filter((value) => value?._id == item?.variantId)[0]?.capacity,
                unit: item?.productId?.variants.filter((value) => value?._id == item?.variantId)[0]?.unit,
                quantity: item?.quantity,
                modelNumber: item?.productId?.modelNumber,
                brand: item?.productId?.brand,
                type: "accessories",
                images: item?.productId?.images,
                gst: item?.productId?.variants.filter((value) => value?._id == item?.variantId)[0]?.gstPercentage,
                productId: item?.productId?._id,
                variantId: item?.productId?.variants.filter((value) => value?._id == item?.variantId)[0]?._id,
                parameters: item?.productId?.variants.filter((value) => value?._id == item?.variantId)[0]?.parameters
            }
            await products.push(productObj);
        })
        await cartDataWithoutCoupon?.products?.spareParts?.map(async (item) => {
            const productObj = {
                name: item?.productId?.name,
                productsId: item?.productId?._id,
                category: item?.productId?.category,
                b2bPrice: item?.productId?.variants.filter((value) => value?._id == item?.variantId)[0]?.b2bPrice,
                b2cPrice: item?.productId?.variants.filter((value) => value?._id == item?.variantId)[0]?.b2cPrice,
                capacity: item?.productId?.variants.filter((value) => value?._id == item?.variantId)[0]?.capacity,
                brand: item?.productId?.brand,
                unit: item?.productId?.variants.filter((value) => value?._id == item?.variantId)[0]?.unit,
                modelNumber: item?.productId?.modelNumber,
                images: item?.productId?.images,
                type: "spareParts",
                quantity: item?.quantity,
                gst: item?.productId?.variants.filter((value) => value?._id == item?.variantId)[0]?.gstPercentage,
                productId: item?.productId?._id,
                variantId: item?.productId?.variants.filter((value) => value?._id == item?.variantId)[0]?._id,
                parameters: item?.productId?.variants.filter((value) => value?._id == item?.variantId)[0]?.parameters
            }
            await products.push(productObj);
        })
        await Promise.all(cartDataWithoutCoupon?.products?.services?.map(async (item) => {
            console.log("productObj",productObj)
            const productObj = {
                name: item?.productId?.name,
                category: item?.productId?.category,
                productsId: item?.productId?._id,
                b2bPrice: item?.productId?.b2bPrice,
                b2cPrice: item?.productId?.b2cPrice,
                unit: item?.productId?.unit,
                details: item?.productId?.details,
                capacity: undefined,
                type: "services",
                quantity: item?.quantity,
                gst: item?.productId?.gstPercentage,
                productId: item?.productId?._id,
            }
            await products.push(productObj);
        })).then(async () => {
            const apiBody = {
                products: products,
                type: "cartQuotation"
            }
            const response = await sendQuotationFromCart(apiBody)
            if (response?.result?.type == "success") {
                toast.success(response?.result?.message)
            } else {
                toast.error(response?.result?.message)
            }
        })
    }
    return (
        <>
            <ToastContainer />
            <Header />
            <Text content={"My Cart"} className="text-4xl m-4" style={{ fontFamily: key.fontFamily, fontWeight: "bold", background: key.primaryColor, WebkitBackgroundClip: "text", WebkitTextFillColor: "transparent" }} />
            <div className='flex mb-4'>
                <div className={`mx-4 w-2/3 mb-4 border-1 ${cartData?.products?.plants?.length < 1 && cartData?.products?.accessories?.length < 1 && cartData?.products?.spareParts?.length < 1 && cartData?.products?.services?.length < 1 ? "flex items-center justify-content" : ""}`} style={{ overflowY: "scroll", maxHeight: window.innerHeight / 1.30 }}>
                    <ItemsTable cartData={cartData} loading={loading} />
                </div>
                <div className='w-1/3 mr-3'>
                    <Coupons couponData={couponData} cartData={cartData} loading={loading} setLoading={setLoading} />
                    <div className='flex justify-end mt-3'>
                        <BillPayment sendQuotation={sendQuotation} data={cartData} quotationLoader={quotationLoader} setQuotationLoader={setQuotationLoader} />
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}
export default ShoppingCart;