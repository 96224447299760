import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from "reactstrap";
import { useHistory } from "react-router-dom";
import { key } from "../../utils/consts"
import { CATALOGUE, MANUALS, TRANSPORTERS, VIDEOS } from "../../utils/pathDetails";

const Resource = ({ resourceSelected, setResourceSelected, theme }) => {
    const history = useHistory();
    return (
        <Dropdown isOpen={resourceSelected} toggle={() => { setResourceSelected(!resourceSelected) }} direction={"down"} onMouseEnter={()=>{setResourceSelected(true)}} onMouseLeave={()=>{setResourceSelected(false)}}>
            <DropdownToggle className="text-base py-2 mr-3" style={{ fontFamily: key.fontFamily, fontWeight: "700", color: theme == "light" ? "#000" : "#fff", backgroundColor: theme == "light" ? "#fff" : "transparent", border: "none", width:"fit-content" }}
            >Resources</DropdownToggle>
            <DropdownMenu style={{ background: key.primaryColor, border: "none" }}>
                <DropdownItem className="text-xs bg-transparent" style={{ fontFamily: key.fontFamily, fontWeight: "bold", color: "#fff" }} onClick={() => { history.push(VIDEOS) }}>Videos</DropdownItem>
                <DropdownItem divider />
                <DropdownItem className="text-xs bg-transparent" style={{ fontFamily: key.fontFamily, fontWeight: "bold", color: "#fff" }} onClick={() => { history.push(MANUALS) }}>Manuals</DropdownItem>
                <DropdownItem divider />
                <DropdownItem className="text-xs bg-transparent" style={{ fontFamily: key.fontFamily, fontWeight: "bold", color: "#fff" }} onClick={() => { history.push(CATALOGUE) }}>Catalogue</DropdownItem>
                <DropdownItem divider />
                <DropdownItem className="text-xs bg-transparent" style={{ fontFamily: key.fontFamily, fontWeight: "bold", color: "#fff" }} onClick={() => { history.push(TRANSPORTERS) }}>Transporters</DropdownItem>
            </DropdownMenu>
        </Dropdown>
    )
}
export default Resource;