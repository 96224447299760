import { useEffect, useState } from "react";
import Header from "../../../../../components/Header/Header";
import Text from "../../../../../atom/Text";
import { key } from "../../../../../utils/consts";
import { getAccessoriesDataService } from "../../../../../Services/OrderServices";
import Product from "../../../../../molecules/Product";
import { Input } from "reactstrap";
import { CiSearch } from "react-icons/ci";
import Footer from "../../../../../components/Footer";

const Accessories = () => {
    const [accessoriesData, setAccessoriesData] = useState([]);
    const [unfilteredAccessoriesData, setUnfilteredAccessoriesData] = useState([]);
    const [searchData, setSearchData] = useState("");

    const getAccessoriesData = async () => {
        const { result } = await getAccessoriesDataService()
        setAccessoriesData(result);
        setUnfilteredAccessoriesData(result);
    }
    const filterdata = async (text) => {
        text && text.length > 1 ?
            setAccessoriesData(unfilteredAccessoriesData.filter((item) => String(item.name).toLowerCase().includes(String(text).toLowerCase()))) : setAccessoriesData(unfilteredAccessoriesData)
    }
    useEffect(() => {
        getAccessoriesData();
    }, [])
    return (
        <>
            <Header />
            <Text content={"Accessories"} className="text-4xl mt-6" style={{ background: key.primaryColor, WebkitBackgroundClip: "text", WebkitTextFillColor: "transparent", fontFamily: key.fontFamily, fontWeight: "bold", textAlign: "center" }} />
            <div className="w-full flex justify-center ">
                <hr className="w-1/12" />
            </div>
            <div className="w-full flex justify-center mt-8">
                <div className="w-4/5">
                    <div className="w-1/2 flex max-[634px]:w-4/5 max-[491px]:w-full">
                        <Input placeholder="Search an Accessories" style={{ borderImage: key.primaryColor + 1, fontFamily: key.fontFamily }}
                            onChange={setSearchData}
                            onKeyPress={(target) => {
                                if (target.charCode == 13) {
                                    filterdata(searchData.target.value)
                                }
                            }}
                        />
                        <button
                            className="w-fit flex items-center px-3" style={{ background: key.primaryColor }}
                            onClick={() => {
                                filterdata(searchData.target.value)
                            }}>
                            <CiSearch size={20} color="#fff" />
                        </button>
                    </div>
                </div>
            </div>
            <div className="w-full flex justify-center mt-2 mb-10">
                <div className="w-4/5 grid grid-cols-4 gap-x-10 max-[1000px]:grid-cols-3 max-[735px]:grid-cols-2 max-[425px]:grid-cols-1">
                    {accessoriesData.length > 0 ?
                        accessoriesData.map((item, index) => {
                            return (
                                <Product productData={item} productType={'accessories'}/>
                            )
                        })
                        : <></>}
                </div>
            </div>
            <Footer />
        </>
    )
}
export default Accessories;