import { BASE_URL, getTokenData } from "./config";

export const callConvertQuotationData = async (data) => {
    try {
        const response = await fetch(BASE_URL + "quotations/convert/data", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: getTokenData(),
            },
            body: JSON.stringify(data)
        })
        const result = await response.json();
        return { result };
    } catch (error) {
        console.log("DELETE ERROR ----> ", error)
    }
}
export const getProductTypeTotal = async (apiBody) => {
    try {
        const response = await fetch(BASE_URL + "quotations/get/total", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: getTokenData(),
            },
            body: JSON.stringify(apiBody)
        })
        const result = await response.json();
        return { result };
    } catch (error) {
        console.log("DELETE ERROR ----> ", error)
    }
}