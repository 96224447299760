import { useHistory } from 'react-router-dom';
import Text from "../atom/Text";
import { key } from "../utils/consts";
import { RiInstagramFill } from "react-icons/ri";
import { SiGooglestreetview } from "react-icons/si";
import { IoLogoInstagram } from "react-icons/io5";
import { FaLinkedin, FaInstagramSquare } from "react-icons/fa";
import { FaLocationDot } from "react-icons/fa6";
import { BiLogoGmail } from "react-icons/bi";
import { MdPhone } from "react-icons/md";
import Image from "../atom/Image";
import Logo from '../utils/images/logo-light.png'
import { CONTACT, FAQs, REFUND_POLICY, TERMS_AND_CONDITIONS } from '../utils/pathDetails';
import { useEffect, useState } from 'react';
import { getDetails } from '../Services/FooterServices';
import HelpDesk from './images/helpDesk.png'
import BuyerProtection from './images/buyerProtection.png'
import SecurePayment from './images/securePayment.png'
import GreatValue from './images/greatValue.png'
import Call from './images/call.png'
import Facebook from './images/facebook.png'
import Gmail from './images/gmail.png'
import GoogleMaps from './images/google-maps.png'
import Instagram from './images/instagram.png'
import Linkedin from './images/linkedin.png'
import Map from './images/map.png'
import Youtube from './images/youtube.png'

const Footer = () => {
    const [details, setDetails] = useState([]);
    const getFooterDetails = async () => {
        const response = await getDetails();
        if (response?.result) {
            setDetails(response?.result)
        }
    }
    useEffect(() => {
        getFooterDetails();
    }, [])
    const history = useHistory();
    return (
        <>
            <div className="w-full h-full flex flex-col items-center border-t" style={{ background: key.secondaryColor, borderColor: "#000", boxShadow: "0px 4px 4px 7px rgba(0, 0, 0, 0.25)" }}>
                <div className=" ml-14 grid grid-cols-4  max-[900px]:pl-1 max-[496px]:px-2 max-[632px]:grid-cols-1">
                    <div className="flex flex-col my-6 max-[420px]:my-3 max-[420px]:ml-3 max-[632px]:my-0" >
                        <img src={GreatValue} className="w-11 h-11  mb-2" />
                        <Text content={"Great Value"} className="text-lg max-[900px]:text-base" style={{ fontFamily: key.fontFamily, fontWeight: "bold" }} />
                        <div className="mt-2 flex items-start max-[420px]:mt-2">
                            <Text content={"Most popular brands with widest range of selection at best prices."} className="text-sm w-2/4 max-[900px]:text-sm" style={{ fontFamily: key.fontFamily, fontWeight: "300" }} />
                        </div>
                    </div>
                    <div className="flex flex-col my-6 max-[420px]:my-3 max-[420px]:ml-3 max-[632px]:my-0" >
                        <img src={SecurePayment} className="w-11 h-11  mb-2" />
                        <Text content={"Secure Payment"} className="text-lg max-[900px]:text-base" style={{ fontFamily: key.fontFamily, fontWeight: "bold" }} />
                        <div className="mt-2 flex items-start max-[420px]:mt-2">
                            <Text content={"Partnered with India's most popular and secure payment solutions."} className="text-sm w-2/4 max-[900px]:text-sm" style={{ fontFamily: key.fontFamily, fontWeight: "300" }} />
                        </div>
                    </div>
                    <div className="flex flex-col my-6 max-[420px]:my-3 max-[420px]:ml-3 max-[632px]:my-0" >
                        <img src={BuyerProtection} className="w-11 h-11  mb-2" />
                        <Text content={"Buyer Protection"} className="text-lg max-[900px]:text-base" style={{ fontFamily: key.fontFamily, fontWeight: "bold" }} />
                        <div className="mt-2 flex items-start max-[420px]:mt-2">
                            <Text content={"Committed to buyer interests to provide a smooth shopping experience."} className="text-sm w-2/4 max-[900px]:text-sm" style={{ fontFamily: key.fontFamily, fontWeight: "300" }} />
                        </div>
                    </div>
                    <div className="flex flex-col  my-6 max-[420px]:my-3 max-[420px]:ml-3 max-[632px]:my-0 ">
                        <img src={HelpDesk} className="w-10 h-9  mb-2" />
                        <Text content={"Help Desk"} className="text-lg max-[900px]:text-base" style={{ fontFamily: key.fontFamily, fontWeight: "bold" }} />
                        <div className="mt-2 flex items-start max-[420px]:mt-2">
                            <Text content={details?.length > 0 ? details.filter((item) => item?.field == 'mobile')[0].content : null} className="text-sm w-2/4 max-[900px]:text-sm" style={{ fontFamily: key.fontFamily, fontWeight: "300" }} />
                        </div>
                    </div>

                </div>
            </div>

            <div className="w-full h-full flex flex-col items-center border-t" style={{ background: key.secondaryColor, borderColor: "rgb(234 234 234)", borderWidth: 2 }}>
                <div className="grid grid-cols-3 px-10 max-[900px]:pl-1 max-[496px]:px-2 max-[632px]:grid-cols-1">
                    <div className="flex flex-col ml-10 my-10 max-[752px]:mb-0 max-[420px]:my-4 max-[420px]:mt-5 max-[420px]:ml-3">
                        <Text content={"Company"} className="text-lg max-[900px]:text-base" style={{ fontFamily: key.fontFamily, fontWeight: "bold" }} />
                        <Text HtmlTag={'button'} content={"Contact Us"} onClick={() => { history.push(CONTACT) }} className="w-fit text-sm mt-6 max-[900px]:text-sm max-[420px]:mt-2" style={{ fontFamily: key.fontFamily, fontWeight: "300" }} />
                        <Text HtmlTag={'button'} content={"Terms & Conditions"} className="w-fit text-sm mt-1 max-[900px]:text-sm" style={{ fontFamily: key.fontFamily, fontWeight: "300" }} onClick={() => {
                            history.push(TERMS_AND_CONDITIONS)
                        }} />
                        <Text HtmlTag={'button'} content={"Refund Policy"} className="w-fit text-sm mt-1 max-[900px]:text-sm" style={{ fontFamily: key.fontFamily, fontWeight: "300" }} onClick={() => {
                            history.push(REFUND_POLICY)
                        }} />
                        <Text HtmlTag={'button'} content={"FAQ"} onClick={() => { history.push(FAQs) }} className="w-fit text-sm mt-1 max-[900px]:text-sm" style={{ fontFamily: key.fontFamily, fontWeight: "300" }} />
                    </div>
                    <div className="flex flex-col ml-10 my-10 max-[420px]:my-3 max-[420px]:ml-3">
                        <Text content={"Contact Us"} className="text-lg max-[900px]:text-base" style={{ fontFamily: key.fontFamily, fontWeight: "bold" }} />
                        <div className="mt-6 flex items-center max-[420px]:mt-2">
                            <img src={Gmail} className="w-5 h-4  " />
                            &nbsp;<Text content={details?.length > 0 ? details.filter((item) => item?.field == 'email')[0].content : null} className="text-sm ml-1 max-[900px]:text-sm" style={{ fontFamily: key.fontFamily, fontWeight: "300" }} />
                        </div>
                        <div className="mt-3 flex items-center">
                            <img src={Call} className="w-6 h-6  " />
                            <Text content={details?.length > 0 ? details.filter((item) => item?.field == 'mobile')[0].content : null} className="text-sm ml-1 max-[900px]:text-sm" style={{ fontFamily: key.fontFamily, fontWeight: "300" }} />
                        </div>
                    </div>
                    <div className="flex flex-col ml-10 my-10 max-[420px]:my-3 max-[420px]:ml-3 max-[632px]:my-0">
                        <Text content={"Location"} className="text-lg max-[900px]:text-base" style={{ fontFamily: key.fontFamily, fontWeight: "bold" }} />
                        <div className="mt-6 flex items-start max-[420px]:mt-2">
                            <img src={Map} className="w-8 h-6  " />
                            <Text content={details?.length > 0 ? details.filter((item) => item?.field == 'address')[0].content : null} className="text-sm w-2/4 ml-2 max-[900px]:text-sm" style={{ fontFamily: key.fontFamily, fontWeight: "300" }} />
                        </div>
                    </div>
                </div>
                <div className="w-fit" style={{ backgroundColor: "#fff" }}>
                    <Image className={"w-60"} src={Logo} />
                    <div className='flex items-center justify-center my-6 gap-2'>
                        <img className="w-7 h-7" src={Instagram} onClick={() => {
                            window.open(details.filter((item) => item?.field == 'instagram')[0].content)
                        }} />
                        <img className="w-7 h-7" src={Linkedin} onClick={() => {
                            window.open(details.filter((item) => item?.field == 'linkedin')[0].content)
                        }} />
                        <img className="w-7 h-7" src={Facebook} onClick={() => {
                            window.open(details.filter((item) => item?.field == 'facebook')[0].content)
                        }} />
                        <img className="w-7 h-7" src={Youtube} onClick={() => {
                            window.open(details.filter((item) => item?.field == 'youtube')[0].content)
                        }} />
                        <img className="w-7 h-7" style={{ borderRadius: 20 }} src={GoogleMaps} onClick={() => {
                            window.open(details.filter((item) => item?.field == 'googleMapLink')[0].content)
                        }} />
                    </div>
                </div>
            </div>
        </>
    )
}
export default Footer;