import Button from "../atom/Button";
import Text from "../atom/Text";
import { key } from "../utils/consts";
import { IMAGE_URL } from "../Services/config";
import { useState } from "react";
import PlantModal from "../components/Plant/PlantModal";
import { PLANT } from "../utils/pathDetails";

const Plant = ({ plantData }) => {
    const [plantModal, setPlantModal] = useState(false);
    const [selectedRelatedProduct, setSelectedRelatedProduct] = useState([]);
    const [selectedPlantImage, setSelectedPlantImage] = useState();
    return (
        <>
            <PlantModal openModal={plantModal} setOpenModal={setPlantModal} plantData={plantData} selectedRelatedProduct={selectedRelatedProduct} setSelectedRelatedProduct={setSelectedRelatedProduct} displayPlantImage={selectedPlantImage} setDisplayPlantImage={setSelectedPlantImage} />
            <div className="w-full flex py-4 max-[780px]:flex-col-reverse">
                <div className="w-2/3 p-4 max-[780px]:w-11/12">
                    <Text
                        content={plantData?.name}
                        className="text-4xl uppercase"
                        style={{ color: key.blue, fontFamily: key.fontFamily, fontWeight: "bold" }}
                    />
                    <Text content={`Brand : ${plantData?.brand}`} style={{ fontFamily: key.fontFamily, color: key.blue }} className={"mt-3"} />
                    <Text content={`Model No. : ${plantData?.modelNumber}`} style={{ fontFamily: key.fontFamily, color: key.blue }} className={"mt-1"} />
                    <Text content={`Capacity : ${plantData?.capacity} KLD`} style={{ fontFamily: key.fontFamily, color: key.blue }} className={"mt-1"} />
                    {/* <Text
                        content={plantData?.technicalDetails}
                        className="text-lg w-4/5 mt-6 max-[780px]:w-full line-clamp-4"
                        style={{ fontFamily: key.fontFamily, fontWeight: "500", color: "#000", opacity: "0.6" }}
                    /> */}
                    <Button
                        buttonText={"Generate Quote"}
                        className={"w-52 h-10 rounded mt-6 "}
                        style={{ color: key.secondaryColor, backgroundColor: key.blue, fontFamily: key.fontFamily, }}
                        onClick={() => {
                            // plantData?.relatedProducts.map((item)=>{selectedRelatedProduct.push({productId : item?._id, quantity:0})})
                            // setPlantModal(true)
                            window.open(PLANT + plantData?._id)
                        }}
                    />
                </div>
                <div className="w-1/3 max-[780px]:self-center max-[526px]:w-11/12">
                    <img src={`${IMAGE_URL}/${plantData?.images[0]}`} className="w-full" draggable={false} onMouseDown={() => { return false }} style={{ userDrag: "none" }} />
                </div>
            </div>
            <hr />
        </>
    )
}
export default Plant;