import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { FaTimesCircle } from "react-icons/fa";
import { VIDEO_URL } from "../../../Services/config";
import ReactPlayer from "react-player";
import Text from "../../../atom/Text";
import { key } from "../../../utils/consts";


const PlayVideoModal = ({ openModal, setOpenModal, videoData }) => {
    return (
        <Modal
            isOpen={openModal}
            toggle={() => { setOpenModal(!openModal) }}
            size={"xl"}
        >
            <ModalHeader style={{ display: "flex", justifyContent: "flex-end" }}>
                <button onClick={() => { setOpenModal(false) }}><FaTimesCircle size={26} /> </button>
            </ModalHeader>
            <ModalBody>
                <ReactPlayer
                    style={{ backgroundColor: "#000" }}
                    width={"100%"}
                    volume={1}
                    playing
                    url={`${VIDEO_URL}/${videoData.filename}`}
                    controls
                />
            </ModalBody>
            <ModalFooter style={{ display: "flex", flexDirection: "column", alignItems: "flex-start" }}>
                <Text content={videoData.title} className="text-lg m-2 mt-3" style={{ fontFamily: key.fontFamily, fontWeight: "bold" }} />
                <Text content={videoData.description} className="text-sm m-2 mt-2 line-clamp-4 w-11/12" style={{ fontFamily: key.fontFamily }} />
            </ModalFooter>
        </Modal >
    )
}
export default PlayVideoModal;