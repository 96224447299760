import { LiaTimesSolid } from "react-icons/lia";
import Text from "../../atom/Text";
import { key } from "../../utils/consts";
import Button from "../../atom/Button";
import { sendQuotationToUser } from "../../Services/OrderServices";
import { ToastContainer, toast } from "react-toastify";
import { useState } from "react";
import { FormGroup, Input, Label, Spinner } from "reactstrap";
import RelatedProduct from "./RelatedProducts";

const PlantDescription = ({ plantData, setOpenModal, selectedRelatedProduct, setSelectedRelatedProduct }) => {
    const [loading, setLoading] = useState(false);
    const [quotationType, setQuotationType] = useState("Standard");

    const onSend = async () => {
        setLoading(true);
        if (quotationType != "Standard") {
            const addedProducts = await selectedRelatedProduct.filter((item) => item?.quantity != 0);
            const apiBody = { type: "plant", productId: plantData?._id, additionalProducts: selectedRelatedProduct, additionalProducts: addedProducts };
            const { result } = await sendQuotationToUser(apiBody);
            if (result.type == "success") {
                if (result.userVerification == true) {
                    setLoading(false)
                    toast.success(result.message);
                    setTimeout(() => {
                        setOpenModal(false)
                        setSelectedRelatedProduct([])
                    }, 3000)
                } else {
                    setLoading(false)
                    toast.error(result.message);
                }
            } else {
                setLoading(false)
                toast.error("Something went wrong!");
            }
        } else {
            const addedProducts = [];
            await plantData?.relatedProducts.map(async (item) => {
                await addedProducts.push({ productId: item?._id, quantity: 1 })
            })
            const apiBody = { type: "plant", productId: plantData?._id, additionalProducts: addedProducts };
            const { result } = await sendQuotationToUser(apiBody);
            if (result.type == "success") {
                if (result.userVerification == true) {
                    setLoading(false)
                    toast.success(result.message);
                    setTimeout(() => {
                        setOpenModal(false)
                        setSelectedRelatedProduct([])
                    }, 3000)
                } else {
                    setLoading(false)
                    toast.error(result.message);
                }
            } else {
                setLoading(false)
                toast.error("Something went wrong!");
            }
        }
    }
    return (
        <>
            <ToastContainer />
            <div className="w-1/2 pb-10 max-[992px]:w-full max-[992px]:pt-6 rounded-r-xl" style={{ backgroundColor: "#E6E6E6", overflowY: "auto" }}>
                <div className=" flex justify-end w-full my-2 max-[992px]:hidden">
                    <LiaTimesSolid size={18} className={"mr-3"} style={{ border: "none", opacity: "0.7" }} onClick={() => {
                        setOpenModal(false)
                        setSelectedRelatedProduct([])
                    }} />
                </div>
                <div className="mx-6">
                    <Text
                        className={"text-3xl"}
                        content={plantData?.name}
                        style={{ fontFamily: key.fontFamily, fontWeight: "bold" }} />
                    <Text
                        className={"text-sm mt-4"}
                        content={plantData?.additionalInformation}
                        style={{ fontFamily: key.fontFamily, fontWeight: "500", opacity: "0.5" }}
                    />
                    <Text
                        className={"text-lg mt-8"}
                        content={"Specifications :"}
                        style={{ fontFamily: key.fontFamily, fontWeight: "bold" }}
                    />
                    <Text
                        className={"text-sm mt-3 ml-6"}
                        content={`Model - ${plantData?.modelNumber}`}
                        style={{ display: "list-item", fontFamily: key.fontFamily, fontWeight: "300", opacity: "0.5" }}
                    />
                    <Text
                        className={"text-sm mt-1 ml-6"}
                        content={`Information - ${plantData?.information}`}
                        style={{ display: "list-item", fontFamily: key.fontFamily, fontWeight: "300", opacity: "0.5" }}
                    />
                    <Text
                        className={"text-lg mt-8"}
                        content={"Technical Details :"}
                        style={{ fontFamily: key.fontFamily, fontWeight: "bold" }}
                    />
                    <Text
                        className={"text-sm mt-3 ml-6"}
                        content={`${plantData?.details}`}
                        style={{ display: "list-item", fontFamily: key.fontFamily, fontWeight: "300", opacity: "0.5" }}
                    />
                    {plantData?.relatedProducts.length > 0 ? <>
                        <Text
                            className={"text-lg mt-8"}
                            content={"Additional Accessories"}
                            style={{ fontFamily: key.fontFamily, fontWeight: "bold" }}
                        />
                        <div className="my-3 flex gap-6">
                            <FormGroup check>
                                <Input
                                    name="radio1"
                                    type="radio"
                                    defaultChecked={quotationType == "Standard" ? true : false}
                                    onChange={() => {
                                        setQuotationType("Standard")
                                    }} ƒ
                                />
                                {' '}
                                <Label check style={{ fontFamily: key.fontFamily, color: "#000", opacity: "0.6" }}>
                                    Standard
                                </Label>
                            </FormGroup>
                            <FormGroup check>
                                <Input
                                    name="radio1"
                                    type="radio"
                                    defaultChecked={quotationType == "Standard" ? false : true}
                                    onChange={() => {
                                        setQuotationType("Manual")
                                    }}
                                />
                                {' '}
                                <Label check style={{ fontFamily: key.fontFamily, color: "#000", opacity: "0.6" }}>
                                    Manual
                                </Label>
                            </FormGroup>
                        </div>
                        <div className="flex w-full mt-2 gap-1" style={{ overflowX: "auto" }}>
                            {plantData?.relatedProducts.map((item, index) => {
                                return (
                                    <RelatedProduct
                                        key={index}
                                        productData={item}
                                        setSelectedRelatedProduct={setSelectedRelatedProduct}
                                        selectedRelatedProduct={selectedRelatedProduct}
                                        quantityData={selectedRelatedProduct.filter((value) => value?.productId == item?._id)}
                                        quotationType={quotationType}
                                    />
                                )
                            })}
                        </div> </> : <></>}
                </div>
                <div className="flex items-center flex-col mx-6 ">
                    {!loading ?
                        <Button
                            disabled={loading ? true : false}
                            className={"w-52 h-12 rounded mt-10 text-sm"}
                            buttonText={"Get Quote"}
                            style={{ fontFamily: key.fontFamily, background: key.primaryColor, fontWeight: "700", color: "#fff" }}
                            onClick={() => { onSend() }}
                        />
                        :
                        <div className="w-52 h-12 rounded mt-10 flex justify-center items-center" style={{ background: key.primaryColor, }}>
                            <Spinner color="light" style={{
                                height: '1.5rem',
                                width: '1.5rem'
                            }}>Loading</Spinner>
                        </div>}
                </div>
            </div>
        </>
    )
}
export default PlantDescription;