export const validateEmail = (text) => {
    let reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w\w+)+$/;
    let emailText = text?.replace(/ /g, '')
    if (reg.test(emailText) === false) {
        return false
    } else {
        return true
    }
}
export const validatePhone = (text) => {
    let reg = /^(\+\d{1,3}[- ]?)?\d{10}$/
    let phoneText = text?.replace(/ /g, '')
    if (reg.test(phoneText) === false) {
        return false
    } else {
        return true
    }
}
export const validateGst = (text) => {
    let reg = /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/
    let gstText = text?.replace(/ /g, '')
    if (reg.test(gstText) === false) {
        return false
    } else {
        return true
    }
}
export const validatePincode = (text) => {
    let reg = /^[1-9][0-9]{5}$/
    if(reg.test(text) === false){
        return false
    } else {
        return true
    }
}
export const validateVehicleNumber = (text) => {
    let reg = /^[A-Z]{2}[0-9]{2}[A-Z]{2}[0-9]{4}$/
    if(reg.test(text) === false){
        return false
    } else {
        return true
    }
}