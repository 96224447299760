import { LiaTimesSolid } from "react-icons/lia";
import { Modal } from "reactstrap";
import { IMAGE_URL } from "../../Services/config";
import PlantDescription from "./PlantDescription";
import ImageSlider from "../ImageSlider";
import { useState } from "react";

const PlantModal = ({ openModal, setOpenModal, plantData, selectedRelatedProduct, setSelectedRelatedProduct, displayPlantImage, setDisplayPlantImage }) => {
    // const [displayPlantImage, setDisplayPlantImage] = useState(plantData?.images[0])
    return (
        <Modal isOpen={openModal} toggle={() => { setOpenModal(!openModal) }} size="xl" className="rounded-xl">
            <div className="flex max-[992px]:flex-col rounded-l-xl" style={{ height: window.innerWidth > 993 ? window.innerHeight / 1.1 : "100%" }}>
                <div className="w-1/2 max-[992px]:w-full rounded-l-xl" style={{ backgroundColor: "#D9D9D9" }}>
                    <div className="flex justify-end w-full mt-3 min-[992px]:hidden">
                        <LiaTimesSolid size={18} className={"mr-3"} style={{ border: "none", opacity: "0.7" }} onClick={() => {
                            setOpenModal(false)
                            setSelectedRelatedProduct([])
                        }} />
                    </div>
                    <div className="h-4/5 w-full flex items-center max-[992px]:justify-center max-[992px]:mb-6">
                        <img src={`${IMAGE_URL}/${displayPlantImage}`} style={{ width: window.innerWidth <= 992 ? "70%" : "auto", userDrag: "none" }} draggable={false} onMouseDown={() => { return false }} />
                    </div>
                    <ImageSlider images={plantData?.images} selectedImage={displayPlantImage} setSelectedImage={setDisplayPlantImage} />
                </div>
                <PlantDescription plantData={plantData} setOpenModal={setOpenModal} selectedRelatedProduct={selectedRelatedProduct} setSelectedRelatedProduct={setSelectedRelatedProduct} />
            </div>
        </Modal>
    )
}
export default PlantModal;