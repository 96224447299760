import { useEffect, useState } from "react";
import Text from "../../../atom/Text";
import Footer from "../../../components/Footer";
import Header from "../../../components/Header/Header";
import TransportersTable from "./TransportersTable";
import { getTransportersDataService } from "../../../Services/ResourcesServices";
import { key } from "../../../utils/consts";

const Transporters = () => {
    const [transportersData, setTransportersData] = useState([]);
    const getTransportersData = async () => {
        const response = await getTransportersDataService();
        if (response.result.length > 0) {
            setTransportersData(response.result);
        }
    }
    useEffect(() => {
        getTransportersData()
    }, [])
    return (
        <>
            <Header />
            <Text content={"Transporters"} className="text-4xl m-4" style={{ fontFamily: key.fontFamily, fontWeight: "bold", background: key.primaryColor, WebkitBackgroundClip: "text", WebkitTextFillColor: "transparent" }} />
            <Text content={"The transporters are the ones who ship the products from the our place to your destinations. They are different for every state and city. See below details for transporters’ information."}
                className="w-3/4 text-lg m-4"
                style={{ fontFamily: key.fontFamily, fontWeight: "400", color: "rgba(0,0,0,0.5)" }} />
            <TransportersTable transportersData={transportersData} />
            <Footer />
        </>
    )
}
export default Transporters;