import { IoIosArrowDroprightCircle } from "react-icons/io";
import { key } from "../utils/consts";

const RightDropdownArrow = ({ show, setShow }) => {
    return (
        <>
            <div className="w-1/4 flex items-center justify-end">
                <IoIosArrowDroprightCircle size={27} style={{ color: key.blue, rotate: show ? "90deg" : "none" }} className="mr-6" />
            </div>
        </>
    )
}
export default RightDropdownArrow;