import Image from "../../atom/Image";
import Text from "../../atom/Text";
import { key } from "../../utils/consts";
import Logo from '../../utils/images/logo-light.png'

const UnavailableRoute = () => {
    return(
        <div className="h-screen w-full flex flex-col items-center py-40" style={{background:key.primaryColor}}>
            <Image src={Logo} className={"w-1/5"}/>
            <Text content={"Error 404! Page Not Found"} className="text-4xl mt-10 italic" style={{fontFamily:key.fontFamily, fontWeight:"500", color:"#fff"}}/>
        </div>
    )
}
export default UnavailableRoute;