import { useEffect, useState } from "react"
import { callGetTandCData } from "../../Services/DataServices";
import Text from "../../atom/Text";
import { key } from "../../utils/consts";
import DisplayInput from "../../components/DisplayInput";
import Footer from "../../components/Footer";
import Header from "../../components/Header/Header";

const TermsAndConditions = () => {
    const [contentData, setContentData] = useState({});
    const getContentData = async () => {
        const response = await callGetTandCData();
        const tandCData = response?.result?.filter((item) => item?.field === "Terms And Conditions")
        setContentData(tandCData[0])
    }
    useEffect(() => {
        getContentData();
    }, [])
    return (
        <>
            <Header />
            <Text content={"Terms And Conditions"} className="text-4xl mt-10 mb-4 flex items-center justify-center" style={{ fontFamily: key.fontFamily, fontWeight: "bold", background: key.primaryColor, WebkitBackgroundClip: "text", WebkitTextFillColor: "transparent" }} />
            <div className="mx-16 mt-12 mb-10">
                <DisplayInput inputText={[contentData?.content]} />
            </div>
            <Footer />
        </>
    )
}
export default TermsAndConditions