import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { LiaTimesSolid } from "react-icons/lia";
import { IMAGE_URL } from "../../Services/config";
import Text from "../../atom/Text";
import { key } from "../../utils/consts";
import Button from "../../atom/Button";
import ImageSlider from "../ImageSlider";
import ProductDescription from "./ProductDescription";
import { useState } from "react";

const ProductModal = ({ openModal, setOpenModal, productData }) => {
    const [displayProductImage, setDisplayProductImage] = useState(productData?.images[0])
    return (
        <Modal
            isOpen={openModal}
            toggle={() => { setOpenModal(!openModal) }}
            size={"xl"}
            className="rounded-xl"
            centered>
            <div className="flex max-[992px]:flex-col rounded-xl">
                <div className="w-1/2 max-[992px]:w-full rounded-l-xl" style={{ backgroundColor: "#D9D9D9", height: window.innerWidth > 993 ? window.innerHeight / 1.1 : "100%" }}>
                    <div className="flex justify-end w-full mt-3 min-[992px]:hidden">
                        <LiaTimesSolid size={18} className={"mr-3"} style={{ border: "none", opacity: "0.7" }} onClick={() => { (setOpenModal(false)) }} />
                    </div>
                    <div className="h-4/5 w-full flex items-center justify-center max-[992px]:justify-center max-[992px]:mb-6 rounded-l-xl">
                        <img src={`${IMAGE_URL}/${displayProductImage}`} style={{ width: window.innerWidth <= 992 ? "70%" : "auto", maxWidth: "90%", userDrag: "none" }} draggable={false} onMouseDown={() => { return false }} />
                    </div>
                    <ImageSlider images={productData?.images} selectedImage={displayProductImage} setSelectedImage={setDisplayProductImage} />
                </div>
                <ProductDescription productData={productData} setOpenModal={setOpenModal} />
            </div>
        </Modal>
    )
}
export default ProductModal;