import { IMAGE_URL } from "../Services/config";

const ImageSlider = ({ images, selectedImage, setSelectedImage }) => {
    return (
        <div className="h-1/5 rounded-bl-xl" style={{ overflowX: "scroll", overflowY: "hidden", whiteSpace: "nowrap" }}>
            <div className="w-full h-full flex">
                {images.map((image, index) => {
                    return (
                        <img 
                        key={index} 
                        src={`${IMAGE_URL}/${image}`} className="border-r-2" style={{backgroundColor:"#fff", width: window.innerWidth <= 992 ? "25%" : ""}}
                        onClick={()=>{setSelectedImage(image)}}
                        />
                    )
                })}
            </div>
        </div>
    )
}
export default ImageSlider;