import { useState } from "react";
import { UploadPurchaseOrderPdf } from "../../Services/DataServices";
import Button from "../../atom/Button";
import Text from "../../atom/Text";
import { key } from "../../utils/consts";
import { Input } from "reactstrap";
import { ToastContainer, toast } from "react-toastify";

const UploadPurchaseOrder = ({ fileData, setFileData, fileChoose, setFileChoose }) => {
    const [confirmUpload, setConfirmUpload] = useState(false);
    
    return (
        <>
            <ToastContainer />
            <Text content={"Purchase Order (PO)"} className={"py-2 pl-2 border-b rounded-t"} style={{ fontFamily: key.fontFamily, color: key.secondaryColor, backgroundColor: key.blue, fontWeight: "bold" }} />
            <Text content={"Kindly upload a purchase report, if you want to"} style={{ fontFamily: key.fontFamily }} className={"text-sm pl-3 mt-4 mb-3"} />
            <div className="mt-3 pl-2 w-4/5" >
                <Input
                    type="file"
                    onChange={async (e) => {
                        setFileData(e.target.files[0])
                        setFileChoose(true)
                    }}
                    style={{ fontFamily: key.fontFamily, backgroundColor: "transparent" }}
                    className="text-sm"
                />
            </div>
            {!confirmUpload ? <Button buttonText={"Upload"} onClick={() => {
                if (fileChoose) {
                    setConfirmUpload(true);
                } else {
                    toast.error("Please Choose a File!")
                }
            }} className={"w-full py-2 rounded-b mt-4"} style={{ fontFamily: key.fontFamily, color: key.secondaryColor, backgroundColor: key.blue, fontWeight: "bold" }} />
                : <>
                    <Button buttonText={"Uploaded!"} className={"w-full py-2 rounded-b mt-4"} style={{ fontFamily: key.fontFamily, color: key.secondaryColor, backgroundColor: 'green', fontWeight: "bold" }} />
                </>}
        </>
    )
}
export default UploadPurchaseOrder;