import React, { useEffect, useState } from "react";
import { Redirect, Route } from "react-router-dom";
import { setAuthorization } from "../helpers/api_helper";

import { useProfile } from "../Hooks/UserHooks";
import { loggedOutUser } from "../helpers/api_helper";
import { getUserDataService } from "../Services/ProfileServices";

const AuthProtected = (props) => {
  const { userProfile, loading, token } = useProfile();
  const [loginUserData, setLoginUserData] = useState()
  useEffect(() => {
    if (userProfile && !loading && token) {
      setAuthorization(token);
    } else if (!userProfile && loading && !token) {
      loggedOutUser();
    }
    // if(userProfile?.verify === false && userProfile?.role === "b2b"){
    //   return (
    //   <Redirect to={{ pathname: "/verification-pending", state: { from: props.location } }} />
    //   )
    // }
    getUserbyId()

  }, [token, userProfile, loading]);

  const getUserbyId = async () => {
    const { result } = await getUserDataService()
    setLoginUserData(result)
  }
  if (!userProfile && loading && !token) {
    return (
      <Redirect to={{ pathname: "/login", state: { from: props.location } }} />
    );
  }
  // if (loginUserData?.verify === false && loginUserData?.role === "b2b") {
  //   return (
  //     <Redirect to={{ pathname: "/verification-pending", state: { from: props.location } }} />
  //   )
  // }

  return <>{props.children}</>;
};

const AccessRoute = ({ component: Component, ...rest }) => {
  const { userProfile } = useProfile();
  return (
    <Route
      {...rest}
      render={props => {
        if (userProfile.type === "Admin" && (rest.path.startsWith("/customer") || rest.path.startsWith("/partner"))) {
          return <Redirect to="/admin/dashboard" />;
        } else {
          return <Component {...props} />;
        }
      }}
    />
  );
};

export { AccessRoute, AuthProtected };
