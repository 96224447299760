import BackgroundImage from "../../atom/BackgroundImage";
import TitleWithLogo from "../../molecules/TitleWithLogo"
import SignUpForm from "../../molecules/SignUpForm";

const SignUp = () => {
  return (
    <div className="flex">
      <BackgroundImage src={"./images/Signup Photo.png"} />
      <div
        className={`w-10/12 pl-10 pr-10 flex flex-col items-center justify-center max-[700px]:w-full pt-20 bg-white`}
      >
        <TitleWithLogo title={"From Source to Tap, We Make Pure Water"} description={"Create an Account"}/>
        <SignUpForm />
      </div>
    </div>
  );
};

export default SignUp;


