import React from "react";
import { Redirect } from "react-router-dom";
import HomePage from "../pages/Home";
import Login from "../pages/Login";
import OtpVerification from "../pages/otp";
import ResetPassword from "../pages/ResetPassword";
import SignUp from "../pages/SignUp";
import BusinessSignUp from "../pages/SignUp/Business";
import IndividualSignUp from "../pages/SignUp/Individual";
import VerificationPending from "../pages/VerificationPending";
import About from "../pages/About";
import FAQ from "../pages/Faq";
import ContactUs from "../pages/Contact";
import EditProfile from "../pages/Profile/EditProfile";
import ForgotPassword from "../pages/ForgotPassword";
import Profile from "../pages/Profile";
import UnavailableRoute from "../pages/404";
import { getUserDataService } from "../Services/ProfileServices";
import GenerateNewQuotation from "../pages/Order/Quotation/GenerateNewQuotation";
import OrderQuotationNumber from "../pages/Order/Quotation/OrderQuotationNumber";
import OrderHistory from "../pages/Profile/History/OrderHistory";
import WaterTreatmentPlant from "../pages/Order/Quotation/GenerateNewQuotation/WaterTreatmentPlant";
import { ABOUT, BUSINESS_SIGNUP, FORGOT_PASSWORD, HOME, INDIVIDUAL_SIGNUP, LOGIN, OTP_VERIFICATION, RESET_PASSWORD, SIGNUP, VERIFICATION_PENDING, FAQs, CONTACT, PROFILE, EDIT_PROFILE, ORDER, GENERATE_NEW_QUOTATION, WATER_TREATMENT_PLANT, USE_QUOTATION_NUMBER, QUOTATION_HISTORY, ACCESSORIES, VIDEOS, CATALOGUE, TRANSPORTERS, SERVICES, SHOPPING_CART, PRODUCT, PRODUCT_NAVIGATION, CATEGORY_NAVIGATION, SEARCH_NAVIGATION, PLANT_NAVIGATION, MANUALS, QUOTATION_SUMMARY_NAVIGATION, ORDER_CONFIRM_NAVIGATION, ORDER_SUMMARY, ORDER_SUCCESS, ORDER_HISTORY, TERMS_AND_CONDITIONS, REFUND_POLICY, SERVICE, SERVICE_NAVIGATION, ORDER_NAVIGATION, BANK_DETAILS } from "../utils/pathDetails";
import Accessories from "../pages/Order/Quotation/GenerateNewQuotation/Accessories";
import Videos from "../pages/Resources/Videos";
import Catalogue from "../pages/Resources/Catalogue";
import Transporters from "../pages/Resources/Transporters";
import Services from "../pages/Order/Services";
import ShoppingCart from "../pages/ShoppingCart";
import Product from "../pages/Product";
import Category from "../pages/Category";
import SearchResult from "../pages/SearchResult";
import QuotationHistory from "../pages/Profile/History/QuotationHistory";
import Plant from "../pages/Plant";
import Manuals from "../pages/Manuals";
import QuotationSummary from "../pages/QuotationSummary";
import OrderConfirm from "../pages/OrderConfirm";
import OrderSummary from "../pages/OrderSummary";
import OrderSuccess from "../pages/OrderSuccess";
import TermsAndConditions from "../pages/TermsAndConditions";
import RefundPolicy from "../pages/RefundPolicy";
import Service from "../pages/Service";
import Order from "../pages/Order";
import BankDetails from "../pages/BankDetails";

// PublicRoutes
const publicRoutes = [
  { path: LOGIN, component: Login },
  { path: OTP_VERIFICATION, component: OtpVerification },
  { path: FORGOT_PASSWORD, component: ForgotPassword },
  { path: RESET_PASSWORD, component: ResetPassword },
  { path: SIGNUP, component: SignUp },
  { path: BUSINESS_SIGNUP, component: BusinessSignUp },
  { path: INDIVIDUAL_SIGNUP, component: IndividualSignUp },
  { path: VERIFICATION_PENDING, component: VerificationPending },
  // { path: "*", component: UnavailableRoute },
];
const isAuthenticated = localStorage.getItem("token") !== null;

// AuthProtected Routes
const adminAuthProtectedRoutes = [
  { path: HOME, component: HomePage },
  { path: ABOUT, component: About },
  { path: FAQs, component: FAQ },
  { path: CONTACT, component: ContactUs },
  { path: PROFILE, component: Profile },
  { path: EDIT_PROFILE, component: EditProfile },
  { path: GENERATE_NEW_QUOTATION, component: GenerateNewQuotation },
  { path: WATER_TREATMENT_PLANT, component: WaterTreatmentPlant },
  { path: ACCESSORIES, component: Accessories },
  { path: SERVICES, component: Services },
  { path: USE_QUOTATION_NUMBER, component: OrderQuotationNumber },
  { path: QUOTATION_HISTORY, component: QuotationHistory },
  { path: ORDER_HISTORY, component: OrderHistory },
  { path: VIDEOS, component: Videos },
  { path: CATALOGUE, component: Catalogue },
  { path: TRANSPORTERS, component: Transporters },
  { path: SHOPPING_CART, component: ShoppingCart },
  { path: PRODUCT_NAVIGATION, component: Product },
  { path: CATEGORY_NAVIGATION, component: Category },
  { path: SEARCH_NAVIGATION, component: SearchResult },
  { path: PLANT_NAVIGATION, component: Plant },
  { path: MANUALS, component: Manuals },
  { path: QUOTATION_SUMMARY_NAVIGATION, component: QuotationSummary },
  { path: ORDER_CONFIRM_NAVIGATION, component: OrderConfirm },
  { path: ORDER_SUMMARY, component: OrderSummary },
  { path: ORDER_SUCCESS, component: OrderSuccess },
  { path: TERMS_AND_CONDITIONS, component: TermsAndConditions },
  { path: REFUND_POLICY, component: RefundPolicy },
  { path: SERVICE_NAVIGATION, component: Service },
  { path: ORDER_NAVIGATION, component: Order },
  { path: BANK_DETAILS, component: BankDetails },
  {
    path: "/",
    component: () => {
      return <Redirect to={"/home"} />;
    },
  },
  { path: "*", component: UnavailableRoute },
];

export { adminAuthProtectedRoutes, publicRoutes };
