import { useContext } from "react"
import Button from "../../atom/Button"
import Text from "../../atom/Text"
import { key } from "../../utils/consts"
import { priceFormat } from "../../utils/priceFunctions"

const BillAmount = ({ quotationData }) => {
    return (
        <div className="rounded-t" style={{ backgroundColor: key.formBackground }}>
            <Text className={"text-lg pl-3 py-2 rounded-t"} content={"Bill Amount"} style={{ fontFamily: key.fontFamily, color: key.secondaryColor, backgroundColor: key.blue, fontWeight: "bold" }} />
            <div className="px-2 pt-3">
                <div className="flex my-2 items-center justify-center">
                    <Text content={"Total Value (without GST Taxes)"} className={"w-1/2 text-sm"} style={{ fontFamily: key.fontFamily, color: "#000000" }} />
                    <Text content={priceFormat(quotationData?.priceWithoutGst + quotationData?.spareAndServicePriceWithoutGst)} className={"w-1/2 flex items-center justify-end mr-4 text-sm"} style={{ fontFamily: key.fontFamily, color: "#000000" }} />
                </div>
                <div className="flex my-3 border-b" style={{ borderColor: "gray" }}>
                    <Text content={"Total GST"} className={" w-1/2 text-sm"} style={{ fontFamily: key.fontFamily, color: "#000000", opacity: "0.6" }} />
                    <Text content={"+ " + priceFormat(quotationData?.totalGstPrice + quotationData?.totalSpareAndServiceGst)} className={"w-1/2 flex items-center justify-end mr-4 mb-3 text-sm"} style={{ fontFamily: key.fontFamily, color: "#000000", opacity: "0.6" }} />
                </div>
                <div className="flex mt-3">
                    <Text content={"Total Amount"} className={" w-1/2"} style={{ fontFamily: key.fontFamily, color: "#000000", fontWeight: "bold" }} />
                    <Text content={priceFormat(quotationData?.price + quotationData?.totalSpareAndServicePrice)} className={"w-1/2 flex items-center justify-end mr-4 mb-3 "} style={{ fontFamily: key.fontFamily, color: "#000000", fontWeight: "bold" }} />
                </div>
            </div>
        </div>
    )
}
export default BillAmount;