import { useEffect, useMemo, useState } from "react";
import Text from "../../../../atom/Text";
import Header from "../../../../components/Header/Header"
import { key } from "../../../../utils/consts";
import { getQuotationHistory } from "../../../../Services/ProfileServices";
import { IMAGE_URL, QUOTATION_URL } from "../../../../Services/config";
import moment from 'moment';
import Button from '../../../../atom/Button';
import { CardBody, Collapse, Table } from "reactstrap";
import Footer from "../../../../components/Footer";
import Loader from "../../../../atom/Loader";
import { priceFormat } from "../../../../utils/priceFunctions";
import { FaDownload } from "react-icons/fa6";

const QuotationHistory = () => {
    const [quotationData, setQuotationData] = useState([]);
    const [selectedIndex, setSelectedIndex] = useState("");
    const [loading, setLoading] = useState(false);

    const getQuotationData = async () => {
        setLoading(true);
        const { result } = await getQuotationHistory();
        setQuotationData(result);
        setLoading(false)
    }
    useEffect(() => {
        getQuotationData();
    }, [])
    return (
        <>
            <Header />
            <Text content={"Quotations"} className="text-4xl m-4" style={{ fontFamily: key.fontFamily, fontWeight: "bold", background: key.primaryColor, WebkitBackgroundClip: "text", WebkitTextFillColor: "transparent" }} />
            {loading ? <Loader /> :
                <div className="p-4 rounded" style={{ overflowX: "auto", width: "100%" }}>
                    {quotationData ? quotationData.length > 0 ?
                        <>
                            <table className="border-1 rounded" style={{ width: "100%" }}>
                                <thead className="w-full" style={{ overflowX: "auto" }}>
                                    <div className="flex max-[893px]:text-xs" style={{ background: key.homeBackgroundColor }}>
                                        <Text content={"Quotation No."} className={"w-3/12 h-10 text-center flex items-center justify-center border-r"} style={{ fontFamily: key.fontFamily, color: "#fff" }} />
                                        <Text content={"Price"} className={"w-3/12 h-10 text-center flex items-center justify-center border-r"} style={{ fontFamily: key.fontFamily, color: "#fff" }} />
                                        <Text content={"Date"} className={"w-3/12 h-10 text-center flex items-center justify-center border-r"} style={{ fontFamily: key.fontFamily, color: "#fff" }} />
                                        <Text content={"Download"} className={"w-3/12 h-10 text-center flex items-center justify-center border-r"} style={{ fontFamily: key.fontFamily, color: "#fff" }} />
                                    </div>
                                </thead>
                                <tbody className="w-full" style={{ overflowX: "auto" }}>
                                    <div style={{ maxHeight: window.innerHeight / 1.8, overflowY: "auto" }}>
                                        {quotationData ? quotationData.map((item, index) => {
                                            return (
                                                <>
                                                    <div
                                                        style={{ width: "100%" }}
                                                        key={index}>
                                                        <div className="w-full flex text-sm max-[893px]:text-xs">
                                                            <Text content={item?._id || "-"} className={"w-3/12 text-left flex items-center justify-center border"} style={{ background: key.primaryColor, WebkitBackgroundClip: "text", WebkitTextFillColor: "transparent", fontFamily: key.fontFamily }} />
                                                            <Text content={priceFormat(item?.price) || "-"} className={"w-3/12 text-left flex items-center justify-center border"} style={{ background: key.primaryColor, WebkitBackgroundClip: "text", WebkitTextFillColor: "transparent", fontFamily: key.fontFamily }} />
                                                            <Text content={moment(item?.createdAt).format("DD/MM/YYYY HH:MM:SS") || "-"} className={"w-3/12 text-center flex items-center justify-center border h-12"} style={{ background: key.primaryColor, WebkitBackgroundClip: "text", WebkitTextFillColor: "transparent", fontFamily: key.fontFamily }} />
                                                            <div className="flex items-center justify-center w-3/12 border h-12">
                                                                <FaDownload size={20} color = { key.blue } onClick={() => {
                                                                    window.open(`${QUOTATION_URL}/${item?._id}.pdf`)
                                                                }} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </>
                                            )
                                        }
                                        ) : <></>}
                                    </div>
                                </tbody>
                            </table>
                        </>
                        : <>
                            <div className="w-full flex items-center justify-center my-32">
                                <h1 className="text-4xl" style={{ fontFamily: key.fontFamily, color: "#000", opacity: "0.5" }}>You have not generated any quotation yet</h1>
                            </div>
                        </> : <></>}
                </div>}
            <Footer />
        </>
    )
}
export default QuotationHistory;