import { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import Header from "../../components/Header/Header";
import { getPlantDataService, getPlantSliderDataService, getSparePartsDataService } from "../../Services/HomePageServices";
import PlantSlider from "./PlantSlider";
import Footer from "../../components/Footer";
import AccessoriesList from "../../components/AccessoriesList";
import Loader from "../../components/Loader";
import { getAccessoriesDataService } from "../../Services/OrderServices";
import { CartContext } from "../ShoppingCart/CartContext";
import BestDeals from "./BestDeals";
import Text from "../../atom/Text";
import { key } from "../../utils/consts";
import { callGetNoteStatusData } from "../../Services/DataServices";

const HomePage = () => {
    const { getCartCount } = useContext(CartContext)
    const [userData, setUserData] = useState({});
    const [loading, setLoading] = useState(false);
    const [plantData, setPlantData] = useState([]);
    const [accessoriesData, setAccessoriesData] = useState([]);
    const history = useHistory();
    const getPlantData = async () => {
        const response = await getPlantSliderDataService();
        if (response.result.length > 0) {
            setPlantData(response.result)
        }
    }
    const getSparePartsData = async () => {
        const response = await getAccessoriesDataService();
        if (response.result.length > 0) {
            setAccessoriesData(response.result.slice(0, 8));
        }
    }
    
    useEffect(() => {
        getCartCount()
        setLoading(true);
        getPlantData();
        getSparePartsData();
        setLoading(false);
    }, [])
    return (
        <>
            <Header theme={"light"} />
            {!loading ?
                <>
                    <PlantSlider plantData={plantData} />
                    <BestDeals />
                    <AccessoriesList accessoriesData={accessoriesData} />
                </>
                : <><Loader /></>}
            <Footer />
        </>
    )
}
export default HomePage;