import { Modal, ModalBody, ModalFooter } from "reactstrap"
import { key } from "../../utils/consts";
import Button from "../../atom/Button";

const EditTransporterConfirmModal = ({ openModal, setOpenModal, setEditTransportersModal }) => {
    return (
        <Modal isOpen={openModal} >
            <ModalBody style={{ fontFamily: key.fontFamily, color: key.blue }}>
                Are you sure you want to change the transporters' details?
            </ModalBody>
            <ModalFooter>
                <Button buttonText={"No"} className={"w-24 py-1.5 rounded"} onClick={() => { setOpenModal(false) }} />
                <Button buttonText={"Yes"} className={"w-24 py-1.5 rounded"} onClick={() => {
                    setOpenModal(false)
                    setEditTransportersModal(true)
                }} />
            </ModalFooter>
        </Modal>
    )
}
export default EditTransporterConfirmModal;