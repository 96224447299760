import { BASE_URL, getTokenData } from "./config";

export const getPlantDataService = async () => {
  try {
    const response = await fetch(BASE_URL + "plants", {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: getTokenData(),
      },
    });
    const result = await response.json();
    return { result };
  } catch (error) {
    return { status: false, message: "Something went wrong" };
  }
};

export const getSparePartsDataService = async (type) => {
  try {
    const response = await fetch(BASE_URL + `products/type/${type}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: getTokenData(),
      },
    });
    const result = await response.json();
    return { result };
  } catch (error) {
    return { status: false, message: "Something went wrong" };
  }
};

export const getPlantSliderDataService = async () => {
  try {
    const response = await fetch(BASE_URL + "plants/get/slider", {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: getTokenData(),
      },
    });
    const result = await response.json();
    return { result };
  } catch (error) {
    return { status: false, message: "Something went wrong" };
  }
};