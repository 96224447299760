import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from "reactstrap";
import { key } from "../../../utils/consts";
import { CATEGORY } from "../../../utils/pathDetails";

const CategoryDropdown = ({ isOpen, field, setOpen, categoryData }) => {
    return (
        <DropdownItem className="text-xs bg-transparent" style={{ fontFamily: key.fontFamily, fontWeight: "bold", color: key.blue }} onClick={() => { setOpen(!isOpen) }} onMouseEnter={() => { setOpen(true) }} onMouseLeave={() => { setOpen(false) }}>
            <Dropdown
                isOpen={isOpen}
                direction="end"
            >
                <DropdownToggle style={{ background: "#fff", border: "none", color: key.blue, fontFamily: key.fontFamily }} onClick={() => {
                }}>
                    <DropdownItem className="text-xs bg-transparent" style={{ fontFamily: key.fontFamily, fontWeight: "bold", color: key.blue }} onClick={() => {
                        if (field === 'Water Treatment Plants') {
                            window.location.replace(CATEGORY + 'plants' + `/get`)
                        } else {
                            window.location.replace(CATEGORY + field.toLowerCase().replace(" ", "-") + `/get`)
                        }
                    }}>{field}</DropdownItem>
                </DropdownToggle>
                {categoryData && categoryData?.length > 0 ? <DropdownMenu className="border-1" style={{ background: "#fff", borderColor: key.blue }}>
                    <DropdownItem className="text-sm bg-transparent italic" style={{ fontFamily: key.fontFamily, fontWeight: "bold", color: key.blue }} header onClick={() => {
                        if (field === 'Water Treatment Plants') {
                            window.location.replace(CATEGORY + 'plants' + `/get`)
                        } else {
                            window.location.replace(CATEGORY + field.toLowerCase().replace(" ", "-") + `/get`)
                        }
                    }}>Category</DropdownItem>
                    {categoryData ? categoryData.map((item, index) => {
                        return (
                            <>
                                <DropdownItem key={index} divider />
                                <DropdownItem className="text-xs bg-transparent" style={{ fontFamily: key.fontFamily, color: key.blue }} onClick={() => {
                                    if (field === 'Water Treatment Plants') {
                                        window.location.replace(CATEGORY + 'plants' + `/${item?._id}`)
                                    } else {
                                        window.location.replace(CATEGORY + field.toLowerCase().replace(" ", "-") + `/${item?._id}`)
                                    }
                                }}>{item?.category}</DropdownItem>
                            </>
                        )
                    }) : <></>}
                </DropdownMenu> : <></>}
            </Dropdown>
        </DropdownItem>
    )
}
export default CategoryDropdown;