import { BASE_URL, getTokenData } from "./config";
let userData = null;

export const getUserData = () => {
  let userDetails = localStorage.getItem("user");
  return JSON.parse(userDetails);
};

export const login = async (userData) => {
  try {
    const response = await fetch(BASE_URL + "auth/admin-login", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(userData),
    });
    const result = await response.json();
    return { result };
  } catch (error) {
    return { status: false, message: "Something went wrong" };
  }
};

export const userLogin = async (userData) => {
  try {
    const response = await fetch(BASE_URL + "auth/login", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(userData),
    });
    const result = await response.json();
    return { result };
  } catch (error) {
    return { status: false, message: "Something went wrong" };
  }
};


export const getByUserId = async (id) => {
  try {
    const response = await fetch(`${BASE_URL}users/${id}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: getTokenData(),
      }
    });
    const result = await response.json();
    return { status: true, result };
  } catch (error) {
    return { status: false, message: "Something went wrong" };
  }
};

export const forgotPassword = async (data) => {
  try {
    const response = await fetch(`${BASE_URL}login/forgotPassword`, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        // Authorization: getTokenData(),
      },
      body: JSON.stringify(data),
    });
    const result = await response.json();
    return { status: true, result };
  } catch (error) {
    return { status: false, message: "Something went wrong" };
  }
};

export const getAllCustomer = async () => {
  try {
    const response = await fetch(BASE_URL + "users", {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: getTokenData(),
      },
    });
    const result = await response.json();
    return { status: true, result };
  } catch (error) {
    return { status: false, message: "Something went wrong" };
  }
};


export const deleteCustomer = async (customerId) => {

  try {
    const response = await fetch(`${BASE_URL}customers/${customerId._id}`, {
      method: "DELETE",
      body: JSON.stringify(customerId),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: getTokenData(),
      },
    });
    if (response.ok) {
      return { status: true, message: "Customer deleted successfully!" };
    } else {
      const errorData = await response.json();
      return {
        status: false,
        message: errorData.message || "Error deleting customer!",
      };
    }
  } catch (error) {
    return { status: false, message: "Something went wrong" };
  }
};

export const getCustomerById = async (id) => {
  try {
    const response = await fetch(`${BASE_URL}customers/${id}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: getTokenData(),
      }
    });
    const result = await response.json();
    return { status: true, result };
  } catch (error) {
    return { status: false, message: "Something went wrong" };
  }
};

export const editCustomer = async (customer) => {
  try {
    const response = await fetch(`${BASE_URL}customers/${customer._id}`, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: getTokenData(),
      },
      body: JSON.stringify(customer),
    });
    const result = await response.json();
    return { status: true, result };
  } catch (error) {
    return { status: false, message: "Something went wrong" };
  }
};


export const addCustomers = async (data) => {
  try {
    const response = await fetch(BASE_URL + "customers", {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: getTokenData(),
      },
    });
    const result = await response.json();
    return { status: true, result };
  } catch (error) {
    return { status: false, message: "Something went wrong" };
  }
};

export const callGetServiceData = async (url) => {
  try {
    const response = await fetch(url, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: getTokenData(),
      },
    })
    const result = await response.json();
    return { result };
  } catch (error) {
    console.log("GET ERROR ----> ", error)
  }
}
export const callPostServiceData = async (url, data) => {
  try {
    const response = await fetch(url, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: getTokenData(),
      },
      body: JSON.stringify(data)
    })
    const result = await response.json();
    return { result };
  } catch (error) {
    console.log("DELETE ERROR ----> ", error)
  }
}
export const callPutServiceData = async (url, data) => {
  try {
    const response = await fetch(url, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: getTokenData(),
      },
      body: JSON.stringify(data)
    })
    const result = await response.json();
    return { result };
  } catch (error) {
    console.log("PUT ERROR ----> ", error)
  }
}
export const callDeleteServiceData = async (url) => {
  try {
    const response = await fetch(url, {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: getTokenData(),
      }
    })
    const result = await response.json();
    return { result };
  } catch (error) {
    console.log("DELETE ERROR ----> ", error)
  }
}
export const VerifyOtpProfile = async (apiBody) => {
  try {
    const response = await fetch(BASE_URL + 'auth/verify-otp', {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: getTokenData(),
      },
      body: JSON.stringify(apiBody)
    })
    const result = await response.json();
    return { result };
  } catch (e) {
    console.log("OTP ERROR ----> ", e)
  }
}
export const callGetSearchResult = async (apiBody) => {
  try {
    const response = await fetch(BASE_URL + 'users/search/items', {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: getTokenData(),
      },
      body: JSON.stringify(apiBody)
    })
    const result = await response.json();
    return { result };
  } catch (e) {
    console.log("SEARCH ERROR ----> ", e)
  }
}
export const callGetTandCData = async () => {
  try {
    const response = await fetch(BASE_URL + 'policies', {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: getTokenData(),
      },
    })
    const result = await response.json();
    return { result };
  } catch (e) {
    console.log("T&C ERROR ----> ", e)
  }
}
export const UploadPurchaseOrderPdf = async (data) => {
  try {
    const response = await fetch(BASE_URL + "uploads/purchase-order", {
      method: "POST",
      body: data
    })
    const result = await response.json();
    return { result };
  } catch (error) {
    console.log("DELETE ERROR ----> ", error)
  }
}
export const callGetCouponsData = async () => {
  try {
    const response = await fetch(BASE_URL + "coupons/get/slider/data", {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: getTokenData(),
      },
    })
    const result = await response.json();
    return { result };
  } catch (error) {
    console.log("DELETE ERROR ----> ", error)
  }
}
export const callGetNoteStatusData = async () => {
  try {
    const response = await fetch(BASE_URL + "details", {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: getTokenData(),
      },
    })
    const result = await response.json();
    return { result };
  } catch (error) {
    console.log("DELETE ERROR ----> ", error)
  }
}