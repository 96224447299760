import { useEffect, useState } from "react";
import Header from "../../components/Header/Header";
import { getFaqDataService } from "../../Services/FaqServices";
import Text from "../../atom/Text";
import { key } from "../../utils/consts";
import { CardBody, Collapse, Input } from "reactstrap";
import Footer from "../../components/Footer";
import Loader from "../../components/Loader";
import { CiSearch } from "react-icons/ci";

const FAQ = ({ }) => {
    const [loading, setLoading] = useState(false);
    const [faqData, setFaqData] = useState([]);
    const [unfilteredFaqData, setUnfilteredFaqData] = useState([]);
    const [selectedFaqIndex, setSelectedFaqIndex] = useState();
    const [searchData, setSearchData] = useState("");
    const getFaqData = async () => {
        const response = await getFaqDataService();
        if (response.result.length > 0) {
            setFaqData(response.result);
            setUnfilteredFaqData(response.result)
        }
    }
    const filterdata = async (text) => {
        text && text.length > 1 ?
            setFaqData(unfilteredFaqData.filter((item) => String(item.question).toLowerCase().includes(String(text).toLowerCase()) || String(item.answer).toLowerCase().includes(String(text).toLowerCase()))) : setFaqData(unfilteredFaqData)
    }
    useEffect(() => {
        setLoading(true)
        getFaqData()
        setLoading(false)
    }, [])
    return (
        <>
            <Header />
            {!loading ? <>
                <div className="m-4 flex justify-center">
                    <Text content={"FAQ"} className="text-7xl " style={{ fontFamily: key.fontFamily, fontWeight: "bold", background: key.primaryColor, WebkitBackgroundClip: "text", WebkitTextFillColor: "transparent" }} />
                </div>
                <div className="mx-4 my-8 flex flex-col items-start">
                    <div className="w-1/2 my-4 flex max-[634px]:w-4/5">
                        <Input placeholder="Search.." style={{ borderImage: key.primaryColor + 1, fontFamily: key.fontFamily }} onChange={setSearchData}
                            onKeyPress={(target) => {
                                if (target.charCode == 13) {
                                    filterdata(searchData.target.value)
                                }
                            }} />
                        <button
                            className="w-fit flex items-center px-3" style={{ background: key.primaryColor }}
                            onClick={() => {
                                filterdata(searchData.target.value)
                            }}>
                            <CiSearch size={20} color="#fff" />
                        </button>
                    </div>
                    {faqData.length > 0 ?
                        faqData.map((item, index) => {
                            return (
                                <>
                                    <div className="border-t py-2 pl-4 w-full" style={{ backgroundColor: "#e3f2ff", borderColor: "#fff" }}>
                                        <button className="w-full flex items-start" onClick={() => {
                                            selectedFaqIndex == index ? setSelectedFaqIndex() : setSelectedFaqIndex(index)
                                        }}>
                                            <Text className="text-lg" content={`${index + 1}. ${item.question}`} style={{ background: key.primaryColor, textAlign: "left", WebkitBackgroundClip: "text", WebkitTextFillColor: "transparent", fontFamily: key.fontFamily, fontWeight: "500" }} />
                                        </button>
                                    </div>
                                    <Collapse className="w-full" isOpen={selectedFaqIndex == index ? true : false} style={{ visibility: "visible" }}>
                                        <CardBody
                                            style={{
                                                background: "rgb(245,245,245)",
                                                color: "#000",
                                                border: "none",
                                                fontFamily: key.fontFamily,
                                                textAlign: "left",
                                            }}
                                            className="w-full text-md p-4 mb-1 flex">
                                            {item.answer}
                                        </CardBody>
                                    </Collapse>
                                </>
                            )
                        })
                        : <></>}
                </div> </> : <Loader />}
            <Footer />
        </>
    )
}
export default FAQ;