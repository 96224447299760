import { IMAGE_URL } from "../Services/config";
import Text from "../atom/Text";
import { key } from "../utils/consts";
import { IoPlayCircleOutline } from "react-icons/io5";

const Video = ({ videoData, onClick }) => {
    return (
        <div
            className="w-full rounded-lg min-h-full"
            style={{ backgroundColor: "#D3D3D3", boxShadow: "0px 0px 25px 0px rgba(0, 0, 0, 0.25), 4px 8px 25px 0px rgba(0, 0, 0, 0.25)" }}
            onClick={() => { onClick() }}
        >
            <div
                className="rounded-t-lg h-52 max-h-52"
                style={{
                    backgroundImage: `url(${IMAGE_URL}/${videoData.thumbnail})`,
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: "cover",
                    boxShadow: "0px 0px 10px 200px rgba(0,0,0,0.5) inset",
                }}>
                <div className="w-full h-full flex items-center justify-center">
                    <IoPlayCircleOutline style={{ width: 60, height: 60, color: "#fff" }} />
                </div>
            </div>
            <Text content={videoData.title} className="text-sm m-2 mt-3" style={{ fontFamily: key.fontFamily, fontWeight: "bold" }} />
            <Text content={videoData.description} className="text-xs m-2 mt-2 line-clamp-4" style={{ fontFamily: key.fontFamily }} />
        </div>
    )
}
export default Video;