import { FaDownload } from "react-icons/fa6";
import { ORDER_PDF_URL } from "../../Services/config";
import Text from "../../atom/Text";
import { key } from "../../utils/consts";
import { Button } from "reactstrap";

const OrderDetail = ({ orderData, path, title, field }) => {
    return (
        <div className="w-1/2 border rounded">
            <Text content={title} className={"py-2 pl-2 rounded-t"} style={{ fontFamily: key.fontFamily, fontWeight: "bold", color: key.secondaryColor, backgroundColor: key.blue }} />
            <div className="py-4 flex justify-center">
                {!orderData[field?.replace("Upload", "Applicable")] && field != 'purchaseInvoiceUpload' ?
                    <Text content={"Not Applicable"} style={{ fontFamily: key.fontFamily, color: key.blue, fontWeight: "bold" }} />
                    :
                    orderData[field] ?
                        <Button style={{ backgroundColor: key.blue, color: key.secondaryColor, borderRadius: 0, border: "none", fontFamily: key.fontFamily }} className="px-4 py-2 rounded text-sm d-flex gap-3 align-items-center justify-content-center" onClick={() => {
                            fetch(ORDER_PDF_URL + `${path}/` + orderData?._id + ".pdf").then((response) => {
                                response.blob().then((blob) => {
                                    const fileURL = window.URL.createObjectURL(blob);
                                    let alink = document.createElement("a");
                                    alink.href = fileURL;
                                    alink.download = `${path}_${orderData?._id}.pdf`;
                                    alink.click();
                                });
                            });
                        }}>Download
                            <FaDownload size={17} color={key.secondaryColor} />
                        </Button>
                        :
                        <Text content={"Not Uploaded Yet"} style={{ fontFamily: key.fontFamily, color: key.blue, fontWeight: "bold" }} />

                }
            </div>
        </div>
    )
}
export default OrderDetail;