import { Button, Input, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap"
import { key } from "../../utils/consts";
import { FaCheckCircle } from "react-icons/fa";
import { IoSearchOutline } from "react-icons/io5";
import Text from "../../atom/Text";
import { IMAGE_URL } from "../../Services/config";
import { useState } from "react";
import { RxOpenInNewWindow } from "react-icons/rx";

import { ToastContainer, toast } from "react-toastify";
import { ACCESSORIES, PRODUCT } from "../../utils/pathDetails";
import Accessories from "./Accessories";

const ExtraAccessoriesModal = ({ openModal, setOpenModal, accessoriesData, setAccessoriesData, unfilteredAccessoriesData, plantData, setBomShow, addPrice, setExtraAccssoriesPrice }) => {
    const [searchText, setSearchText] = useState("");
    const filterdata = async (text) => {
        text && text.length > 1 ?
            setAccessoriesData(unfilteredAccessoriesData.filter((item) => String(item.name).toLowerCase().includes(String(text).toLowerCase()) || String(item.answer).toLowerCase().includes(String(text).toLowerCase()))) : setAccessoriesData(unfilteredAccessoriesData)
    }
    return (
        <>
            <ToastContainer />
            <Modal isOpen={openModal} centered size="xl">
                <ModalHeader style={{ fontFamily: key.fontFamily }}>Add Extra Accessories</ModalHeader>
                <ModalBody>
                    <div className="w-1/2 flex">
                        <Input
                            placeholder="Search Accessories"
                            className="w-1/3 my-2 rounded-0"
                            onChange={setSearchText}
                            onKeyPress={(target) => {
                                if (target.charCode == 13) {
                                    filterdata(searchText.target.value)
                                }
                            }}
                            style={{ fontFamily: key.fontFamily }}
                        />
                        <div className="px-2.5 my-2 flex items-center justify-center" style={{ backgroundColor: key.blue }}>
                            <IoSearchOutline color={key.secondaryColor} onClick={() => { filterdata(searchText.target.value) }} />
                        </div>
                    </div>
                    <div className="flex mt-3">
                        <div className={"w-2/6 h-12 px-2 rounded-tl flex items-center justify-center border-r"} style={{ fontFamily: key.fontFamily, backgroundColor: key.blue }}>
                            <Text content={"Accessories Name"} className={'text-sm'} style={{ fontFamily: key.fontFamily, color: key.secondaryColor, fontWeight: 'bold' }} />
                        </div>
                        <div className={"w-2/6 h-12 px-2 flex items-center justify-center border-r"} style={{ fontFamily: key.fontFamily, backgroundColor: key.blue }}>
                            <Text content={"Images"} className={'text-sm'} style={{ fontFamily: key.fontFamily, color: key.secondaryColor, fontWeight: 'bold' }} />
                        </div>
                        <div className={"w-1/6 h-12 px-2 flex items-center justify-center border-r"} style={{ fontFamily: key.fontFamily, backgroundColor: key.blue }}>
                            <Text content={"Select Variant"} className={'text-sm'} style={{ fontFamily: key.fontFamily, color: key.secondaryColor, fontWeight: 'bold' }} />
                        </div>
                        <div className={"w-1/6 h-12 px-2 flex rounded-tr items-center justify-center"} style={{ fontFamily: key.fontFamily, backgroundColor: key.blue }}>
                            <Text content={"Add"} className={'text-sm'} style={{ fontFamily: key.fontFamily, color: key.secondaryColor, fontWeight: 'bold' }} />
                        </div>
                    </div>
                    <div className="border" style={{ maxHeight: window.innerHeight / 1.9, overflowY: 'auto' }}>
                        {accessoriesData ? accessoriesData.map((item, index) => {
                            return (
                                <Accessories key={index} accessoriesData={item} plantData={plantData} />
                            )
                        }) : <></>}
                    </div>
                </ModalBody>
                <ModalFooter>
                    <Button className="w-28" outline style={{ color: key.blue, backgroundColor: key.secondaryColor, borderColor: key.blue, fontFamily: key.fontFamily }}
                        onClick={async () => {
                            await setBomShow(false)
                            let priceWithoutGst = 0;
                            let totalPrice = 0;
                            let gstPrice = 0;
                            await Promise.all(plantData?.bom?.map((item) => {
                                if(item?.variantId){
                                    if (localStorage?.getItem("role") === "b2c") {
                                        priceWithoutGst = priceWithoutGst + item?.b2cPrice * item?.quantity;
                                        gstPrice = gstPrice + item?.b2cPrice * item?.quantity * item?.gst / 100;
                                        totalPrice = priceWithoutGst + gstPrice;
                                    } else {
                                        priceWithoutGst = priceWithoutGst + item?.b2bPrice * item?.quantity;
                                        gstPrice = gstPrice + item?.b2bPrice * item?.quantity * item?.gst / 100;
                                        totalPrice = priceWithoutGst + gstPrice;
                                    }
                                }
                            })).then(async () => {
                                await setExtraAccssoriesPrice({ priceWithoutGst: priceWithoutGst, totalPrice: totalPrice, gstPrice: gstPrice });
                                await setOpenModal(false)
                                await setBomShow(true)
                            })
                        }}
                    >Close</Button>
                </ModalFooter>
            </Modal >
        </>
    )
}
export default ExtraAccessoriesModal;