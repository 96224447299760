import { Input, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { key } from "../../utils/consts";
import Text from "../../atom/Text";
import { useState } from "react";
import Button from "../../atom/Button";
import { useHistory } from "react-router-dom";
import { HOME, REFUND_POLICY } from "../../utils/pathDetails";
import { ToastContainer, toast } from "react-toastify";
import { callPostCancelOrderMail, callPutCancelOrder } from "../../Services/OrderServices";

const AddBankDetailsModal = ({ openModal, setOpenModal, orderData }) => {
    const [bankDetails, setBankDetails] = useState({});
    const history = useHistory();
    const onSubmit = async () => {
        if (bankDetails?.bankName && bankDetails?.accountName && bankDetails?.accountNumber && bankDetails?.ifscCode && bankDetails?.branchName && bankDetails?.reason) {
            orderData['refundBankDetails'] = bankDetails;
            orderData['status'] = "refund";
            orderData['progress'] = 0;
            const response = await callPutCancelOrder(orderData?._id, orderData);
            const mailApiBody = {id: orderData?._id}
            const sentMail = await callPostCancelOrderMail(mailApiBody);
            if(response?.result){
                history.push(HOME);
            }
        } else {
            toast.error("Please Enter All Values!")
        }
    }
    return (
        <>
            <ToastContainer />
            <Modal isOpen={openModal}>
                <ModalHeader style={{ fontFamily: key.fontFamily, color: key.blue }}>Add Bank Details</ModalHeader>
                <ModalBody>
                    <div className="mt-1">
                        <div className="flex">
                            <Text content={"Bank Name"} className={"text-sm"} style={{ fontFamily: key.fontFamily, fontWeight: "bold", color: key.blue }} />
                            <Text content={"*"} className={"text-xs"} style={{ fontFamily: key.fontFamily, color: "red" }} />
                        </div>
                        <Input placeholder="Bank Name" style={{ fontFamily: key.fontFamily, fontSize: "0.8rem" }} className="mt-1.5" onChange={(e) => {
                            bankDetails['bankName'] = e.target.value;
                        }} />
                    </div>
                    <div className="my-3">
                        <div className="flex">
                            <Text content={"Account Number"} className={"text-sm"} style={{ fontFamily: key.fontFamily, fontWeight: "bold", color: key.blue }} />
                            <Text content={"*"} className={"text-xs"} style={{ fontFamily: key.fontFamily, color: "red" }} />
                        </div>
                        <Input placeholder="Account Number" style={{ fontFamily: key.fontFamily, fontSize: "0.8rem" }} className="mt-1.5" onChange={(e) => {
                            bankDetails['accountNumber'] = e.target.value;
                        }} />
                    </div>
                    <div className="my-3">
                        <div className="flex">
                            <Text content={"Account Name"} className={"text-sm"} style={{ fontFamily: key.fontFamily, fontWeight: "bold", color: key.blue }} />
                            <Text content={"*"} className={"text-xs"} style={{ fontFamily: key.fontFamily, color: "red" }} />
                        </div>
                        <Input placeholder="Account Name" style={{ fontFamily: key.fontFamily, fontSize: "0.8rem" }} className="mt-1.5" onChange={(e) => {
                            bankDetails['accountName'] = e.target.value;
                        }} />
                    </div>
                    <div className="my-3">
                        <div className="flex">
                            <Text content={"IFSC Code"} className={"text-sm"} style={{ fontFamily: key.fontFamily, fontWeight: "bold", color: key.blue }} />
                            <Text content={"*"} className={"text-xs"} style={{ fontFamily: key.fontFamily, color: "red" }} />
                        </div>
                        <Input placeholder="IFSC Code" style={{ fontFamily: key.fontFamily, fontSize: "0.8rem" }} className="mt-1.5" onChange={(e) => {
                            bankDetails['ifscCode'] = e.target.value;
                        }} />
                    </div>
                    <div className="mb-2">
                        <div className="flex">
                            <Text content={"Branch Name"} className={"text-sm"} style={{ fontFamily: key.fontFamily, fontWeight: "bold", color: key.blue }} />
                            <Text content={"*"} className={"text-xs"} style={{ fontFamily: key.fontFamily, color: "red" }} />
                        </div>
                        <Input placeholder="Branch Name" style={{ fontFamily: key.fontFamily, fontSize: "0.8rem" }} className="mt-1.5" onChange={(e) => {
                            bankDetails['branchName'] = e.target.value;
                        }} />
                    </div>
                    <div className="mb-2">
                        <div className="flex">
                            <Text content={"Reason to Cancel"} className={"text-sm"} style={{ fontFamily: key.fontFamily, fontWeight: "bold", color: key.blue }} />
                            <Text content={"*"} className={"text-xs"} style={{ fontFamily: key.fontFamily, color: "red" }} />
                        </div>
                        <Input placeholder="Reason" type="textarea" style={{ fontFamily: key.fontFamily, fontSize: "0.8rem" }} className="mt-1.5" onChange={(e) => {
                            bankDetails['reason'] = e.target.value;
                        }} />
                    </div>
                    <div>
                        <Text HtmlTag="text" content={"Click here"} className={"underline text-sm"} style={{ fontFamily: key.fontFamily, color: key.blue }} onClick={() => {
                            history.push(REFUND_POLICY)
                        }} />
                        <Text HtmlTag="text" content={", to see the refund policy of RTPL."} className={"text-sm"} style={{ fontFamily: key.fontFamily, color: key.blue }} />
                    </div>
                </ModalBody>
                <ModalFooter>
                    <Button buttonText={"Close"} className={"px-4 py-1.5 border-1 rounded"} style={{ fontFamily: key.fontFamily, color: key.blue, borderColor: key.blue, backgroundColor: key.secondaryColor }} onClick={() => {
                        setOpenModal(false);
                    }} />
                    <Button buttonText={"Submit"} className={"px-6 py-1.5 rounded"} onClick={() => {
                        onSubmit();
                    }} />
                </ModalFooter>
            </Modal>
        </>
    )
}
export default AddBankDetailsModal;