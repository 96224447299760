import { key } from "../utils/consts"

const Label = ({ text, color, required }) => {
    return (
        required ? <div className="flex">
            <p className="text-base font-semibold max-[1005px]:text-sm" style={{ fontFamily: key.fontFamily, color: color ? color : "#646982" }}>{text}</p>
            <p style={{ color: "red" }}>*</p>
        </div>
            : <><p className="text-base font-semibold max-[1005px]:text-sm" style={{ fontFamily: key.fontFamily, color: color ? color : "#646982" }}>{text}</p></>
    )
}
export default Label;