import { useMemo, useState } from "react"
import Text from "../../atom/Text";
import { CardBody, Collapse } from "reactstrap";
import TableContainer from "../../components/TableContainer";
import { IoIosArrowDroprightCircle } from "react-icons/io";
import { key } from "../../utils/consts";
import { IMAGE_URL } from "../../Services/config";
import { priceFormat } from "../../utils/priceFunctions";
import DisplayInput from "../../components/DisplayInput";

const Products = ({ quotationData }) => {
    const [isOpen, setIsOpen] = useState(true);
    const ProductDetails = ({ data }) => {
        if (data?.productType === "plants") {
            return (
                <>
                    <Text content={`Brand : ${data?.brand}`} style={{ fontFamily: key.fontFamily, fontWeight: 300 }} className={"mt-3 text-sm"} />
                    <Text content={`Model No. : ${data?.modelNumber}`} style={{ fontFamily: key.fontFamily, fontWeight: 300 }} className={"mt-1 text-sm"} />
                    <Text content={`Capacity : ${data?.capacity} KLD`} style={{ fontFamily: key.fontFamily, fontWeight: 300 }} className={"mt-1 text-sm"} />
                </>
            )
        } else if (data?.productType === "spareParts" || data?.productType === "accessories") {
            return (
                <>
                    <Text content={`Brand : ${data?.brand}`} style={{ fontFamily: key.fontFamily, fontWeight: 300 }} className={"mt-3 text-sm"} />
                    <Text content={`Model No. : ${data?.modelNumber}`} style={{ fontFamily: key.fontFamily, fontWeight: 300 }} className={"mt-1 text-sm"} />
                    <Text content={`Capacity : ${data?.capacity}`} style={{ fontFamily: key.fontFamily, fontWeight: 300 }} className={"mt-1 text-sm"} />
                </>
            )
        } else if (data?.productType === "services") {
            return (
                <>
                    <div style={{ fontWeight: 300 }} className='text-sm line-clamp-3'>
                        <DisplayInput inputText={data?.details} />
                    </div>
                </>
            )
        }
    }
    const columns = useMemo(
        () => [
            {
                Header: "No.",
                accessor: "id",
                width: "7%",
                Cell: (cellProps) => (
                    <>
                        <div style={{ color: "#000", fontWeight: "bold", display: "flex", alignItems: "center", justifyContent: "center" }}>
                            {cellProps.row.index + 1}
                        </div>
                    </>
                ),
            },
            {
                Header: "Image",
                accessor: "image",
                width: "20%",
                minWidth: "10.5rem",
                Cell: (cellProps) => (
                    <>
                        <div className="flex items-center justify-center w-full" style={{ color: "#000", fontWeight: "bold" }}>
                            {cellProps.row.original.images?.length > 0 ? <img src={`${IMAGE_URL}/${cellProps.row.original.images[0]}`} /> : "-"}
                        </div>
                    </>
                ),
            },
            {
                Header: "Product Details",
                accessor: "items",
                width: "28%",
                Cell: (cellProps) => (
                    <>
                        <div className="flex items-center justify-center" style={{ color: "#000", fontWeight: "bold", display: "flex", flexDirection: "column" }}>
                            {cellProps.row.original?.name}
                            <ProductDetails data={cellProps?.row?.original} />
                        </div>
                    </>
                ),
            },
            {
                Header: "Quantity",
                accessor: "quantity",
                width: "20%",
                Cell: (cellProps) => (
                    <>
                        <div className="flex items-center justify-center" style={{ color: "#000", fontWeight: "bold" }}>
                            {cellProps.row.original?.quantity ? `${cellProps.row.original?.quantity} ${cellProps?.row?.original?.unit ? cellProps?.row?.original?.productType === "plants" ? "NOS" : cellProps?.row?.original?.unit : ""}` : "-"}
                        </div>
                    </>
                ),
            },
            {
                Header: "Total Price",
                accessor: "totalPrice",
                width: "25%",
                Cell: (cellProps) => (
                    <div style={{ textAlign: "left" }}>
                        <div style={{ color: "#000", fontWeight: "bold" }}>
                            <Text
                                className={"text-xs"}
                                style={{ fontFamily: key.fontFamily, opacity: "0.6" }}
                                content={cellProps.row.original?.totalValue ? `${priceFormat(cellProps.row.original?.totalValue)} (Incl. of all taxes)` : "-"}
                            />
                        </div>
                        <div className="my-3" style={{ color: "#000", fontWeight: "bolder" }}>
                            <Text
                                className={"text-base"}
                                style={{ fontFamily: key.fontFamily, }}
                                content={cellProps.row.original?.value ? priceFormat(cellProps.row.original?.value) : "-"}
                            />
                        </div>
                        <div style={{ color: "#000", fontWeight: "bold" }}>
                            <Text
                                className={"text-xs"}
                                style={{ fontFamily: key.fontFamily, opacity: "0.6" }}
                                content={cellProps.row.original?.gst ? `+ ${priceFormat(cellProps?.row?.original?.gst)} GST` : "-"}
                            />
                        </div>
                    </div>
                ),
            },
        ],
        []
    );
    return (
        <div className='w-2/3 max-[800px]:w-full'>
            <div className="flex" onClick={() => { setIsOpen(!isOpen) }}>
                <Text className={"text-lg pl-3 py-2 rounded-tl w-3/4"} content={"Products"} style={{ fontFamily: key.fontFamily, color: key.secondaryColor, backgroundColor: key.blue, fontWeight: "bold" }} onClick={() => {
                    setIsOpen(!isOpen)
                }} />
                <div className="w-1/4 flex justify-end items-center pr-4 rounded-tr" style={{ backgroundColor: key.blue }}>
                    <IoIosArrowDroprightCircle size={25} color={key.secondaryColor} style={{ rotate: isOpen ? "90deg" : null }} />
                </div>
            </div>
            <Collapse isOpen={isOpen}>
                <CardBody>
                    <div className='border rounded-b'>
                        <TableContainer
                            columns={columns}
                            data={quotationData?.products || []}
                            customPageSize={10}
                            className="custom-header-css"
                            trClass={"h-12 rounded-tr text-md"}
                            paginationDisplay={false}
                            backgroundColor={"#d6d6d680"}
                        />
                    </div>
                </CardBody>
            </Collapse>
        </div>
    )
}
export default Products;