import { FaCircleCheck } from "react-icons/fa6";
import { AiFillInfoCircle } from "react-icons/ai";
import Button from "../atom/Button";
import Label from "../atom/Label";
import Text from "../atom/Text";
import TextInput from "../atom/TextInput";
import { key } from "../utils/consts";

const ResetPasswordForm = ({
  onClick,
  resetPassword,
  setResetPassword,
  confirmPassword,
  setConfirmPassword,
  resetPasswordValid,
  checkResetPasswordValid,
  confirmPasswordValid,
  setConfirmPasswordValid,
  checkConfirmPasswordValid }) => {

  return (
    <>
      <div
        className="h-full w-full mt-8 rounded-t-2xl p-10 max-[810px]:w-full max-[810px]:mr-0 max-[810px]:ml-0 max-[810px]:mt-8"
        style={{ backgroundColor: key.formBackground, boxShadow: key.formShadow }}>
        <Text
          content={"Please enter and confirm the password. Make sure the password must include minimum 8 characters, a capital alphabet (A-Z), a small alphabet  (a-z) and a symbol."}
          className="text-lg"
          style={{ color: "#7E8A97", fontFamily: key.fontFamily, fontWeight: "400", textAlign: "center" }} />
        <div className="mt-8">
          <Label text={"Reset Password"} required={true} />
          <div className="flex items-center justify-center">
            <TextInput placeholder={"Reset Password"} onChange={(e) => {
              setResetPassword(e.target.value);
              checkResetPasswordValid();
            }} />
            {resetPasswordValid === true ? <FaCircleCheck size={30} className="ml-4" style={{ color: "green" }} /> : resetPasswordValid === false ?
              <AiFillInfoCircle size={30} className="ml-4" style={{ color: "red" }} /> : <></>}
          </div>
        </div>
        <div className="mt-8">
          <Label text={"Confirm Password"} required={true} />
          <div className="flex items-center justify-center">
            <TextInput placeholder={"Confirm Password"} onChange={async (e) => {
              setConfirmPassword(e.target.value);
              const valid = await checkConfirmPasswordValid();
              if (valid) {
                if (resetPassword == e.target.value) setConfirmPasswordValid(true);
              } else {
                setConfirmPasswordValid(false)
              }
            }} />
            {confirmPasswordValid === true ? <FaCircleCheck size={30} className="ml-4" style={{ color: "green" }} /> : confirmPasswordValid === false ?
              <AiFillInfoCircle size={30} className="ml-4" style={{ color: "red" }} /> : <></>}
          </div>
        </div>
        <div className="flex justify-center mt-12">
          <Button
            onClick={() => { onClick() }}
            buttonText={"Submit"}
            style={{
              background: key.primaryColor,
              color: "#fff",
              fontFamily: key.fontFamily,
              fontWeight: "bold"
            }}
            className="w-52 h-12 flex items-center justify-center rounded"
          />
        </div>
      </div>
    </>
  )
};

export default ResetPasswordForm;
