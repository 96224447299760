import { useHistory } from "react-router-dom";
import Button from "../atom/Button";
import Text from "../atom/Text";
import Product from "../molecules/Product";
import { key } from "../utils/consts";

const AccessoriesList = ({ accessoriesData }) => {
    const history = useHistory();
    return (
        <>
            <Text content={"Accessories"} className="text-4xl mt-20" style={{ background: key.primaryColor, WebkitBackgroundClip: "text", WebkitTextFillColor: "transparent", fontFamily: key.fontFamily, fontWeight: "bold", textAlign: "center" }} />
            <div className="w-full flex justify-center ">
                <hr className="w-1/5" />
            </div>
            {accessoriesData?.length > 0 ?
                <>
                    <div className="w-full flex justify-center">
                        <div className="w-4/5 mb-20 grid grid-cols-4 gap-x-10 max-[1045px]:grid-cols-3 max-[757px]:grid-cols-2 max-[550px]:grid-cols-1">
                            {accessoriesData?.map((item, key) => {
                                return (
                                    <Product productData={item} key={item?._id} productType={"accessories"} />
                                )
                            })}
                        </div>
                    </div>
                </> : <></>}
        </>
    )
}
export default AccessoriesList;