import { PDFDownloadLink, Document, Page, Image, View, Text, StyleSheet, Font } from '@react-pdf/renderer';
import { IMAGE_URL } from '../../../Services/config';

const CatelogueDoc = ({ sparePartsData }) => {
    return (
        <Document>
            <Page>
                <Image src={`${IMAGE_URL}/logo-light.png`} style={{ width: "25%", margin: 15 }} fixed />
                <View style={{ height: 50, display: "flex", alignItems: "flex-start", marginTop: 20, marginLeft: 10, marginBottom: 20 }}>
                    <Text style={{ fontSize: 22, textDecoration: "underline" }}>SPARE PARTS CATALOGUE</Text>
                </View>
                {sparePartsData.map((item) => {
                    return (
                        <View style={{ display: "flex", flexDirection: "row", marginLeft: 10, marginRight: 10, border: "1px solid gray" }}>
                            <View style={{ width: "40%", borderRight: "1px solid gray", display: "flex", alignItems: "center", justifyContent: "center" }}>
                                <Image src={`${IMAGE_URL}/${item.images[0]}`}
                                    style={{ width: "80%" }} />
                            </View>
                            <View style={{ display: "flex", flexDirection: "column", padding: 15 }} >
                                <Text style={{ fontSize: 13, fontWeight: "bold" }}>{item.name}</Text>
                                <Text style={{ fontSize: 10, marginTop: 13, width: "50%" }}>{item.details}</Text>
                                <Text style={{ fontSize: 10, marginTop: 13, width: "50%" }}>{item.information}</Text>
                                <Text style={{ fontSize: 10, marginTop: 13, width: "50%" }}>{`Model No. - ${item.modelNumber}`}</Text>
                            </View>
                        </View>
                    )
                })
                }
            </Page>
        </Document>

    )
}
export default CatelogueDoc;