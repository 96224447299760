import { BASE_URL, getTokenData } from "./config";

export const getUserDataService = async () => {
    try {
        const response = await fetch(BASE_URL + `users/get/profile`, {
            method: "GET",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: getTokenData(),
            },
        });
        const result = await response.json();
        return { result };
    } catch (error) {
        return { status: false, message: "Something went wrong" };
    }
};

export const editUserDataService = async (apiBody) => {
    try {
        const response = await fetch(BASE_URL + `users/save/profile`, {
            method: "PUT",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: getTokenData(),
            },
            body: JSON.stringify(apiBody)
        });
        const result = await response.json();
        return { result };
    } catch (error) {
        return { status: false, message: "Something went wrong" };
    }
};

export const getQuotationHistory = async () => {
    try {
        const response = await fetch(BASE_URL + `quotations/history/getdetails`, {
            method: "GET",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: getTokenData(),
            }
        });
        const result = await response.json();
        return { result };
    } catch (error) {
        return { status: false, message: "Something went wrong" };
    }
}

export const editMobileNumberService = async (apiBody) => {
    try {
        const response = await fetch(BASE_URL + `auth/edit/mobile`, {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: getTokenData(),
            },
            body: JSON.stringify(apiBody)
        });
        const result = await response.json();
        return { result };
    } catch (error) {
        return { status: false, message: "Something went wrong" };
    }
}
