import { Button, Modal, ModalBody, ModalFooter } from "reactstrap"
import { key } from "../../utils/consts"
import { getUserDataService } from "../../Services/ProfileServices"
import { callPostServiceData } from "../../Services/DataServices"
import { BASE_URL } from "../../Services/config"

const ConfirmModal = ({ openModal, setOpenModal, setVerificationModal, setMobile }) => {
    const onSend = async () => {
        const response = await getUserDataService()
        const user = localStorage.getItem("user");
        console.log(user)
        let user1=JSON.parse(user)
        console.log(user1)
        console.log(user1.mobile)
        const apiBody = { mobile: user1.mobile };
        const response12 = await callPostServiceData(`${BASE_URL}auth/forgot-password`, apiBody);

        setMobile(response?.result?.mobile);
    }
    return (
        <Modal isOpen={openModal} toggle={() => { setOpenModal(!openModal) }} centered>
            <ModalBody style={{ fontFamily: key.fontFamily }}>
                A One Time Password (OTP) will be sent to your registered mobile number. Please confirm to get the verification code.
            </ModalBody>
            <ModalFooter style={{ border: "none" }}>
                <Button outline onClick={() => { setOpenModal(false) }} style={{ fontFamily: key.fontFamily, paddingInline: 22 }}>
                    Close
                </Button>
                <Button style={{ fontFamily: key.fontFamily, background: key.primaryColor, color: "#fff", border: "none", paddingInline: 22 }} onClick={() => {
                    onSend();
                    setOpenModal(false)
                    setVerificationModal(true)
                }}>
                    Send
                </Button>
            </ModalFooter>
        </Modal>
    )
}
export default ConfirmModal;