import { useEffect, useMemo, useState } from 'react';
import { checkQuotationByNumber, getQuotationById } from '../../Services/OrderServices';
import Footer from '../../components/Footer';
import Header from '../../components/Header/Header';
import { useParams } from "react-router-dom";
import { key } from '../../utils/consts';
import Text from '../../atom/Text';
import TableContainer from '../../components/TableContainer';
import { IMAGE_URL } from '../../Services/config';
import { priceFormat } from '../../utils/priceFunctions';
import Button from '../../atom/Button';
import { useHistory } from 'react-router-dom';
import { ORDER_CONFIRM } from '../../utils/pathDetails';
import DisplayInput from '../../components/DisplayInput';
import { getCouponService } from '../../Services/CartServices';
import Coupons from './Coupons';
import { callConvertQuotationData } from '../../Services/QuotationServices';
import RightDropdownArrow from '../../atom/RightDropdownArrow';
import { CardBody, Collapse } from 'reactstrap';

const QuotationSummary = () => {
    const history = useHistory()
    const { quotationNumber } = useParams();
    const [quotationData, setQuotationData] = useState([]);
    const [couponsData, setCouponsData] = useState([]);
    const [data, setData] = useState({});
    const [unChangedData, setUnChangedData] = useState({});
    const [showPlants, setShowPlants] = useState(true);
    const [showServices, setShowServices] = useState(true);
    const [showAccessories, setShowAccessories] = useState(true);
    const [showSpareParts, setShowSpareParts] = useState(true);
    const [showData, setShowData] = useState(true);
    const [totalDiscountPrice, setTotalDiscountPrice] = useState();
    const [gstDiscountPrice, setGstDiscountPrice] = useState();
    const [productDiscountPrice, setProductDiscountPrice] = useState();
    const [discount, setDiscount] = useState();
    const getQuotationData = async () => {
        const response = await getQuotationById(quotationNumber);
        console.log(">response",response)
        if (response?.result) {
            setQuotationData(response?.result)
        }
        const convertResponse = await callConvertQuotationData({ quotationId: quotationNumber })
        setData(convertResponse?.result);
        setUnChangedData(convertResponse?.result);
        await getCouponsData(convertResponse?.result)
    }
    const getCouponsData = async (apiBody) => {
        const response = await getCouponService(apiBody);
        if (response?.result) {
            response?.result?.sort((a, b) => b.isApplicable - a.isApplicable)
            setCouponsData(response?.result)
        }
    }
    const ProductDetails = ({ data }) => {
        if (data?.productType === "plants") {
            return (
                <>
                    <Text content={`Brand : ${data?.brand}`} style={{ fontFamily: key.fontFamily, fontWeight: 300 }} className={"mt-3 text-sm"} />
                    <Text content={`Model No. : ${data?.modelNumber}`} style={{ fontFamily: key.fontFamily, fontWeight: 300 }} className={"mt-1 text-sm"} />
                    <Text content={`Capacity : ${data?.capacity}`} style={{ fontFamily: key.fontFamily, fontWeight: 300 }} className={"mt-1 text-sm"} />
                </>
            )
        } else if (data?.productType === "spareParts" || data?.productType === "accessories") {
            return (
                <>
                    <Text content={`Brand : ${data?.brand}`} style={{ fontFamily: key.fontFamily, fontWeight: 300 }} className={"mt-3 text-sm"} />
                    <Text content={`Model No. : ${data?.modelNumber}`} style={{ fontFamily: key.fontFamily, fontWeight: 300 }} className={"mt-1 text-sm"} />
                    <Text content={`Capacity : ${data?.capacity}`} style={{ fontFamily: key.fontFamily, fontWeight: 300 }} className={"mt-1 text-sm"} />
                </>
            )
        } else if (data?.productType === "services") {
            return (
                <>
                    <div style={{ fontWeight: 300 }} className='text-sm line-clamp-3'>
                        <DisplayInput inputText={data?.details} />
                    </div>
                </>
            )
        }
    }
    useEffect(() => {
        getQuotationData();
    }, [])
    // const columns = useMemo(
    //     () => [
    //         {
    //             Header: "No.",
    //             accessor: "id",
    //             width: "5%",
    //             Cell: (cellProps) => (
    //                 <>
    //                     <div style={{ color: "#000", fontWeight: "bold", display: "flex", alignItems: "center", justifyContent: "center" }}>
    //                         {cellProps.row.index + 1}
    //                     </div>
    //                 </>
    //             ),
    //         },
    //         {
    //             Header: "Image",
    //             accessor: "image",
    //             width: "15%",
    //             minWidth: "10.5rem",
    //             Cell: (cellProps) => (
    //                 <>
    //                     <div className="flex items-center justify-center w-full" style={{ color: "#000", fontWeight: "bold" }}>
    //                         {cellProps.row.original.images?.length > 0 ? <img src={`${IMAGE_URL}/${cellProps.row.original.images[0]}`} /> : "-"}
    //                     </div>
    //                 </>
    //             ),
    //         },
    //         {
    //             Header: "Product Details",
    //             accessor: "items",
    //             width: "19.5%",
    //             Cell: (cellProps) => (
    //                 <>
    //                     <div className="flex items-center justify-center" style={{ color: "#000", fontWeight: "bold", display: "flex", flexDirection: "column" }}>
    //                         {cellProps.row.original?.name}
    //                         <ProductDetails data={cellProps?.row?.original} />
    //                     </div>
    //                 </>
    //             ),
    //         },
    //         {
    //             Header: "Unit Price",
    //             accessor: "unitPrice",
    //             width: "15.5%",
    //             Cell: (cellProps) => (
    //                 <>
    //                     <div style={{ textAlign: "left" }}>
    //                         <div style={{ color: "#000", fontWeight: "bold" }}>
    //                             <Text
    //                                 className={"text-xs"}
    //                                 style={{ fontFamily: key.fontFamily, opacity: "0.6" }}
    //                                 content={cellProps.row.original?.unitTotalValue ? `${priceFormat(cellProps.row.original?.unitTotalValue)} (Incl. of all taxes)` : "-"}
    //                             />
    //                         </div>
    //                         <div className="my-3" style={{ color: "#000", fontWeight: "bolder" }}>
    //                             <Text
    //                                 className={"text-base"}
    //                                 style={{ fontFamily: key.fontFamily, }}
    //                                 content={cellProps.row.original?.unitValue ? priceFormat(cellProps.row.original?.unitValue) : "-"}
    //                             />
    //                         </div>
    //                         <div style={{ color: "#000", fontWeight: "bold" }}>
    //                             <Text
    //                                 className={"text-xs"}
    //                                 style={{ fontFamily: key.fontFamily, opacity: "0.6" }}
    //                                 content={cellProps.row.original?.unitGst ? `+ ${priceFormat(cellProps?.row?.original?.unitGst)} GST` : "-"}
    //                             />
    //                         </div>
    //                     </div>
    //                 </>
    //             ),
    //         },
    //         {
    //             Header: "Quantity",
    //             accessor: "quantity",
    //             width: "10%",
    //             Cell: (cellProps) => (
    //                 <>
    //                     <div className="flex items-center justify-center" style={{ color: "#000", fontWeight: "bold" }}>
    //                         {cellProps.row.original?.quantity ? `${cellProps.row.original?.quantity} ${cellProps.row.original?.unit ? cellProps.row.original?.unit : ""}` : "-"}
    //                     </div>
    //                 </>
    //             ),
    //         },
    //         {
    //             Header: "Total Price",
    //             accessor: "totalPrice",
    //             width: "14%",
    //             Cell: (cellProps) => (
    //                 <>
    //                     <div style={{ textAlign: "left" }}>
    //                         <div style={{ color: "#000", fontWeight: "bold" }}>
    //                             <Text
    //                                 className={"text-xs"}
    //                                 style={{ fontFamily: key.fontFamily, opacity: "0.6" }}
    //                                 content={cellProps.row.original?.totalValue ? `${priceFormat(cellProps.row.original?.totalValue)} (Incl. of all taxes)` : "-"}
    //                             />
    //                         </div>
    //                         <div className="my-3" style={{ color: "#000", fontWeight: "bolder" }}>
    //                             <Text
    //                                 className={"text-base"}
    //                                 style={{ fontFamily: key.fontFamily, }}
    //                                 content={cellProps.row.original?.value ? priceFormat(cellProps.row.original?.value) : "-"}
    //                             />
    //                         </div>
    //                         <div style={{ color: "#000", fontWeight: "bold" }}>
    //                             <Text
    //                                 className={"text-xs"}
    //                                 style={{ fontFamily: key.fontFamily, opacity: "0.6" }}
    //                                 content={cellProps.row.original?.gst ? `+ ${priceFormat(cellProps?.row?.original?.gst)} GST` : "-"}
    //                             />
    //                         </div>
    //                     </div>
    //                 </>
    //             ),
    //         },
    //     ],
    //     []
    // );

    const plantColumns = useMemo(
        () => [
            {
                Header: "No.",
                accessor: "id",
                width: "3%",
                Cell: (cellProps) => (
                    <>
                        <div style={{ color: "#000", fontWeight: "bold", display: "flex", alignItems: "center", justifyContent: "center" }}>
                            {cellProps.row.index + 1}
                        </div>
                    </>
                ),
            },
            {
                Header: "Image",
                accessor: "image",
                width: "17%",
                Cell: (cellProps) => (
                    <>
                        <div className="flex items-center justify-center" style={{ color: "#000", fontWeight: "bold" }}>
                            {cellProps.row.original.productId != null ? <img src={`${IMAGE_URL}/${cellProps.row.original.productId.images[0]}`} style={{ width: "90%", minWidth: 170 }} /> : <></>}
                        </div>
                    </>
                ),
            },
            {
                Header: "Product Name",
                accessor: "productName",
                width: "20%",
                Cell: (cellProps) => (
                    <>
                        <div className="flex items-center justify-center" style={{ color: "#000", fontWeight: "bold" }}>
                            {cellProps.row.original.productId != null ? cellProps.row.original.productId.name : null}
                        </div>
                        <div className="flex items-center justify-center mt-3 text-sm" style={{ color: "#000", fontWeight: 300 }}>
                            {cellProps.row.original.productId != null ? "Brand : " + cellProps.row.original.productId.brand : null}
                        </div>
                        <div className="flex items-center justify-center mt-1 text-sm" style={{ color: "#000", fontWeight: 300 }}>
                            {cellProps.row.original.productId != null ? "Model No. : " + cellProps.row.original.productId.modelNumber : null}
                        </div>
                    </>
                ),
            },
            {
                Header: "Capacity",
                accessor: "capacity",
                width: "10%",
                Cell: (cellProps) => (
                    <>
                        <div className="flex items-center justify-center" style={{ color: "#000", fontWeight: "bold" }}>
                            {cellProps.row.original.productId != null ? cellProps.row.original.productId.capacity + " KLD" : null}
                        </div>
                    </>
                ),
            },
            {
                Header: "Unit Price",
                accessor: "unitPrice",
                width: "19%",
                Cell: (cellProps) => (
                    <>
                        <div style={{ textAlign: "left" }}>
                            <div style={{ color: "#000", fontWeight: "bold" }}>
                                {cellProps.row.original?.productId?.withoutDiscountPrice ?
                                    <Text
                                        className={"text-xs"}
                                        style={{ fontFamily: key.fontFamily, opacity: "0.6" }}
                                        content={`${cellProps.row.original.productId != null ? cellProps.row.original.productId.withoutDiscountPrice != undefined ? priceFormat(cellProps.row.original.productId.withoutDiscountPrice + (cellProps.row.original.productId.withoutDiscountPrice * cellProps.row.original.productId.gstPercentage / 100)) : priceFormat(cellProps.row.original.productId.withoutDiscountPrice + (cellProps.row.original.productId.withoutDiscountPrice * cellProps.row.original.productId.gstPercentage / 100)) : null} (Incl. of all taxes)`}
                                    /> : <Text
                                        className={"text-xs"}
                                        style={{ fontFamily: key.fontFamily, opacity: "0.6" }}
                                        content={`${cellProps.row.original.productId != null ? cellProps.row.original.productId.b2bPrice != undefined ? priceFormat(cellProps.row.original.productId.b2bPrice + (cellProps.row.original.productId.b2bPrice * cellProps.row.original.productId.gstPercentage / 100)) : priceFormat(cellProps.row.original.productId.b2cPrice + (cellProps.row.original.productId.b2cPrice * cellProps.row.original.productId.gstPercentage / 100)) : null} (Incl. of all taxes)`}
                                    />}
                            </div>
                            <div className="my-3" style={{ color: "#000", fontWeight: "bolder" }}>
                                {cellProps.row.original?.productId?.withoutDiscountPrice ?
                                    <Text
                                        className={"text-base"}
                                        style={{ fontFamily: key.fontFamily, }}
                                        content={cellProps.row.original.productId != null ? cellProps.row.original.productId.withoutDiscountPrice != undefined ? priceFormat(cellProps.row.original.productId.withoutDiscountPrice) : priceFormat(cellProps.row.original.productId.withoutDiscountPrice) : null}
                                    /> :
                                    <Text
                                        className={"text-base"}
                                        style={{ fontFamily: key.fontFamily, }}
                                        content={cellProps.row.original.productId != null ? cellProps.row.original.productId.b2bPrice != undefined ? priceFormat(cellProps.row.original.productId.b2bPrice) : priceFormat(cellProps.row.original.productId.b2cPrice) : null}
                                    />}
                            </div>
                            <div style={{ color: "#000", fontWeight: "bold" }}>
                                {cellProps.row.original?.productId?.withoutDiscountPrice ?
                                    <Text
                                        className={"text-xs"}
                                        style={{ fontFamily: key.fontFamily, opacity: "0.6" }}
                                        content={`+ ${cellProps.row.original.productId != null ? cellProps.row.original.productId.withoutDiscountPrice != undefined ? priceFormat(cellProps.row.original.productId.withoutDiscountPrice * cellProps.row.original.productId.gstPercentage / 100) : priceFormat(cellProps.row.original.productId.withoutDiscountPrice * cellProps.row.original.productId.gstPercentage / 100) : null} GST`}
                                    /> :
                                    <Text
                                        className={"text-xs"}
                                        style={{ fontFamily: key.fontFamily, opacity: "0.6" }}
                                        content={`+ ${cellProps.row.original.productId != null ? cellProps.row.original.productId.b2bPrice != undefined ? priceFormat(cellProps.row.original.productId.b2bPrice * cellProps.row.original.productId.gstPercentage / 100) : priceFormat(cellProps.row.original.productId.b2cPrice * cellProps.row.original.productId.gstPercentage / 100) : null} GST`}
                                    />}
                            </div>
                        </div>
                    </>
                ),
            },
            {
                Header: "Quantity",
                accessor: "quantity",
                width: "13%",
                Cell: (cellProps) => (
                    <>
                        {console.log("CELL ===>", cellProps?.row?.original)}
                        <div className="flex items-center justify-center" style={{ color: "#000", fontWeight: "bold" }}>
                            {cellProps.row.original.productId != null ? cellProps.row.original.quantity + " KLD" : null}
                        </div>
                    </>
                ),
            },
            {
                Header: "Total Value",
                accessor: "totalValue",
                width: "18%",
                Cell: (cellProps) => (
                    <>
                        <div style={{ textAlign: "left" }}>
                            <div style={{ color: "#000", fontWeight: "bold" }}>
                                <Text
                                    className={"text-xs"}
                                    style={{ fontFamily: key.fontFamily, opacity: "0.6" }}
                                    content={`${cellProps.row.original.productId != null ? cellProps.row.original.productId.b2bPrice != undefined ? priceFormat(cellProps.row.original?.quantity * (cellProps.row.original.productId.b2bPrice + (cellProps.row.original.productId.b2bPrice * cellProps.row.original.productId.gstPercentage / 100))) : priceFormat(cellProps?.row?.original?.quantity * (cellProps.row.original.productId.b2cPrice + (cellProps.row.original.productId.b2cPrice * cellProps.row.original.productId.gstPercentage / 100))) : null} (Incl. of all taxes)`}
                                />
                            </div>
                            <div className="my-3" style={{ color: "#000", fontWeight: "bolder" }}>
                                {cellProps.row.original?.productId?.withoutDiscountPrice ?
                                    <>
                                        <Text
                                            className={"text-xs"}
                                            style={{ fontFamily: key.fontFamily, opacity: "0.5", textDecoration: "line-through" }}
                                            content={cellProps.row.original.productId != null ? cellProps.row.original.productId.withoutDiscountPrice != undefined ? priceFormat(cellProps.row.original?.quantity * cellProps.row.original.productId.withoutDiscountPrice) : priceFormat(cellProps.row.original?.quantity * cellProps.row.original.productId.withoutDiscountPrice) : null}
                                        />
                                        <Text
                                            className={"text-base"}
                                            style={{ fontFamily: key.fontFamily, }}
                                            content={cellProps.row.original.productId != null ? cellProps.row.original.productId.b2bPrice != undefined ? priceFormat(cellProps.row.original?.quantity * cellProps.row.original.productId.b2bPrice) : priceFormat(cellProps.row.original?.quantity * cellProps.row.original.productId.b2cPrice) : null}
                                        />
                                    </> :
                                    <Text
                                        className={"text-base"}
                                        style={{ fontFamily: key.fontFamily, }}
                                        content={cellProps.row.original.productId != null ? cellProps.row.original.productId.b2bPrice != undefined ? priceFormat(cellProps.row.original?.quantity * cellProps.row.original.productId.b2bPrice) : priceFormat(cellProps.row.original?.quantity * cellProps.row.original.productId.b2cPrice) : null}
                                    />}
                            </div>
                            <div style={{ color: "#000", fontWeight: "bold" }}>
                                <Text
                                    className={"text-xs"}
                                    style={{ fontFamily: key.fontFamily, opacity: "0.6" }}
                                    content={`+ ${cellProps.row.original.productId != null ? cellProps.row.original.productId.b2bPrice != undefined ? priceFormat(cellProps.row.original?.quantity * cellProps.row.original.productId.b2bPrice * cellProps.row.original.productId.gstPercentage / 100) : priceFormat(cellProps.row.original?.quantity * cellProps.row.original.productId.b2cPrice * cellProps.row.original.productId.gstPercentage / 100) : null} GST`}
                                />
                            </div>
                        </div>
                    </>
                ),
            },
        ],
        []
    );
    const serviceColumns = useMemo(
        () => [
            {
                Header: "No.",
                accessor: "id",
                width: "5%",
                Cell: (cellProps) => (
                    <>
                        <div style={{ color: "#000", fontWeight: "bold", display: "flex", alignItems: "center", justifyContent: "center" }}>
                            {cellProps.row.index + 1}
                        </div>
                    </>
                ),
            },
            {
                Header: "Service",
                accessor: "items",
                width: "25%",
                Cell: (cellProps) => (
                    <>
                        <div className="flex items-center justify-center" style={{ color: "#000", fontWeight: "bold" }}>
                            {cellProps.row.original.productId != null ? cellProps.row.original.productId.name : null}
                        </div>
                    </>
                ),
            },
            {
                Header: "Unit Price",
                accessor: "price",
                width: "25%",
                Cell: (cellProps) => (
                    <>
                        <div style={{ textAlign: "left" }}>
                            <div style={{ color: "#000", fontWeight: "bold" }}>
                                {cellProps.row.original?.productId?.withoutDiscountPrice ?
                                    <Text
                                        className={"text-xs"}
                                        style={{ fontFamily: key.fontFamily, opacity: "0.6" }}
                                        content={`${cellProps.row.original.productId != null ? cellProps.row.original.productId.withoutDiscountPrice != undefined ? priceFormat(cellProps.row.original.productId.withoutDiscountPrice + (cellProps.row.original.productId.withoutDiscountPrice * cellProps.row.original.productId.gstPercentage / 100)) : priceFormat(cellProps.row.original.productId.withoutDiscountPrice + (cellProps.row.original.productId.withoutDiscountPrice * cellProps.row.original.productId.gstPercentage / 100)) : null} (Incl. of all taxes)`}
                                    /> : <Text
                                        className={"text-xs"}
                                        style={{ fontFamily: key.fontFamily, opacity: "0.6" }}
                                        content={`${cellProps.row.original.productId != null ? cellProps.row.original.productId.b2bPrice != undefined ? priceFormat(cellProps.row.original.productId.b2bPrice + (cellProps.row.original.productId.b2bPrice * cellProps.row.original.productId.gstPercentage / 100)) : priceFormat(cellProps.row.original.productId.b2cPrice + (cellProps.row.original.productId.b2cPrice * cellProps.row.original.productId.gstPercentage / 100)) : null} (Incl. of all taxes)`}
                                    />}
                            </div>
                            <div className="my-3" style={{ color: "#000", fontWeight: "bolder" }}>
                                {cellProps.row.original?.productId?.withoutDiscountPrice ?
                                    <Text
                                        className={"text-base"}
                                        style={{ fontFamily: key.fontFamily, }}
                                        content={cellProps.row.original.productId != null ? cellProps.row.original.productId.withoutDiscountPrice != undefined ? priceFormat(cellProps.row.original.productId.withoutDiscountPrice) : priceFormat(cellProps.row.original.productId.withoutDiscountPrice) : null}
                                    /> :
                                    <Text
                                        className={"text-base"}
                                        style={{ fontFamily: key.fontFamily, }}
                                        content={cellProps.row.original.productId != null ? cellProps.row.original.productId.b2bPrice != undefined ? priceFormat(cellProps.row.original.productId.b2bPrice) : priceFormat(cellProps.row.original.productId.b2cPrice) : null}
                                    />}
                            </div>
                            <div style={{ color: "#000", fontWeight: "bold" }}>
                                {cellProps.row.original?.productId?.withoutDiscountPrice ?
                                    <Text
                                        className={"text-xs"}
                                        style={{ fontFamily: key.fontFamily, opacity: "0.6" }}
                                        content={`+ ${cellProps.row.original.productId != null ? cellProps.row.original.productId.withoutDiscountPrice != undefined ? priceFormat(cellProps.row.original.productId.withoutDiscountPrice * cellProps.row.original.productId.gstPercentage / 100) : priceFormat(cellProps.row.original.productId.withoutDiscountPrice * cellProps.row.original.productId.gstPercentage / 100) : null} GST`}
                                    /> :
                                    <Text
                                        className={"text-xs"}
                                        style={{ fontFamily: key.fontFamily, opacity: "0.6" }}
                                        content={`+ ${cellProps.row.original.productId != null ? cellProps.row.original.productId.b2bPrice != undefined ? priceFormat(cellProps.row.original.productId.b2bPrice * cellProps.row.original.productId.gstPercentage / 100) : priceFormat(cellProps.row.original.productId.b2cPrice * cellProps.row.original.productId.gstPercentage / 100) : null} GST`}
                                    />}
                            </div>
                        </div>
                    </>
                ),
            },
            {
                Header: "Quantity",
                accessor: "quantity",
                width: "20%",
                Cell: (cellProps) => (
                    <>
                        <div className="flex items-center justify-center" style={{ color: "#000", fontWeight: "bold" }}>
                            {cellProps.row.original.productId != null ? cellProps.row.original.quantity + " " + cellProps.row.original.unit : null}
                        </div>
                    </>
                ),
            }, {
                Header: "Total Price",
                accessor: "totalPrice",
                width: "25%",
                Cell: (cellProps) => (
                    <div style={{ textAlign: "left" }}>
                        <div style={{ color: "#000", fontWeight: "bold" }}>
                            <Text
                                className={"text-xs"}
                                style={{ fontFamily: key.fontFamily, opacity: "0.6" }}
                                content={`${cellProps.row.original.productId != null ? cellProps.row.original.productId.b2bPrice != undefined ? priceFormat(cellProps.row.original?.quantity * (cellProps.row.original.productId.b2bPrice + (cellProps.row.original.productId.b2bPrice * cellProps.row.original.productId.gstPercentage / 100))) : priceFormat(cellProps?.row?.original?.quantity * (cellProps.row.original.productId.b2cPrice + (cellProps.row.original.productId.b2cPrice * cellProps.row.original.productId.gstPercentage / 100))) : null} (Incl. of all taxes)`}
                            />
                        </div>
                        <div className="my-3" style={{ color: "#000", fontWeight: "bolder" }}>
                            {cellProps.row.original?.productId?.withoutDiscountPrice ?
                                <>
                                    <Text
                                        className={"text-xs"}
                                        style={{ fontFamily: key.fontFamily, opacity: "0.5", textDecoration: "line-through" }}
                                        content={cellProps.row.original.productId != null ? cellProps.row.original.productId.withoutDiscountPrice != undefined ? priceFormat(cellProps.row.original?.quantity * cellProps.row.original.productId.withoutDiscountPrice) : priceFormat(cellProps.row.original?.quantity * cellProps.row.original.productId.withoutDiscountPrice) : null}
                                    />
                                    <Text
                                        className={"text-base"}
                                        style={{ fontFamily: key.fontFamily, }}
                                        content={cellProps.row.original.productId != null ? cellProps.row.original.productId.b2bPrice != undefined ? priceFormat(cellProps.row.original?.quantity * cellProps.row.original.productId.b2bPrice) : priceFormat(cellProps.row.original?.quantity * cellProps.row.original.productId.b2cPrice) : null}
                                    />
                                </> :
                                <Text
                                    className={"text-base"}
                                    style={{ fontFamily: key.fontFamily, }}
                                    content={cellProps.row.original.productId != null ? cellProps.row.original.productId.b2bPrice != undefined ? priceFormat(cellProps.row.original?.quantity * cellProps.row.original.productId.b2bPrice) : priceFormat(cellProps.row.original?.quantity * cellProps.row.original.productId.b2cPrice) : null}
                                />}
                        </div>
                        <div style={{ color: "#000", fontWeight: "bold" }}>
                            <Text
                                className={"text-xs"}
                                style={{ fontFamily: key.fontFamily, opacity: "0.6" }}
                                content={`+ ${cellProps.row.original.productId != null ? cellProps.row.original.productId.b2bPrice != undefined ? priceFormat(cellProps.row.original?.quantity * cellProps.row.original.productId.b2bPrice * cellProps.row.original.productId.gstPercentage / 100) : priceFormat(cellProps.row.original?.quantity * cellProps.row.original.productId.b2cPrice * cellProps.row.original.productId.gstPercentage / 100) : null} GST`}
                            />
                        </div>
                    </div>
                ),
            },
        ],
        []
    );
    const sparePartsColumns = useMemo(
        () => [
            {
                Header: "No.",
                accessor: "id",
                width: "3%",
                Cell: (cellProps) => (
                    <>
                        <div style={{ color: "#000", fontWeight: "bold", display: "flex", alignItems: "center", justifyContent: "center" }}>
                            {cellProps.row.index + 1}
                        </div>
                    </>
                ),
            },
            {
                Header: "Images",
                accessor: "images",
                width: "18%",
                Cell: (cellProps) => (
                    <>
                        <div className="flex items-center justify-center" style={{ color: "#000", fontWeight: "bold" }}>
                            {cellProps?.row?.original?.productId?.images ? <img className="w-11/12 flex items-center justify-center" src={`${IMAGE_URL}/${cellProps?.row?.original?.productId?.images[0]}`} /> : <></>}
                        </div>
                    </>
                ),
            },
            {
                Header: "Product Name",
                accessor: "items",
                width: "21%",
                Cell: (cellProps) => (
                    <>
                        <div className="flex items-center justify-center" style={{ color: "#000", fontWeight: "bold" }}>
                            {cellProps.row.original.productId != null ? cellProps.row.original.productId.name : null}
                        </div>
                        <div className="flex items-center justify-center mt-3 text-sm" style={{ color: "#000", fontWeight: 300 }}>
                            {cellProps.row.original.productId != null ? "Brand : " + cellProps.row.original.productId.brand : null}
                        </div>
                        <div className="flex items-center justify-center mt-1 text-sm" style={{ color: "#000", fontWeight: 300 }}>
                            {cellProps.row.original.productId != null ? "Model No. : " + cellProps.row.original.productId.modelNumber : null}
                        </div>
                    </>
                ),
            },
            {
                Header: "Capacity",
                accessor: "capacity",
                width: "10%",
                Cell: (cellProps) => (
                    <>
                        <div className="flex items-center justify-center" style={{ color: "#000", fontWeight: "bold" }}>
                            {cellProps.row.original.productId != null ? cellProps.row.original.productId?.variants.filter((item) => item?._id == cellProps.row.original?.variantId)[0]?.capacity : null}
                        </div>
                    </>
                ),
            },
            {
                Header: "Unit Price",
                accessor: "unitPrice",
                width: "15%",
                Cell: (cellProps) => (
                    <>
                        <div style={{ textAlign: "left" }}>
                            <div style={{ color: "#000", fontWeight: "bold" }}>
                                {cellProps?.row?.original?.productId?.variants?.filter((variant) => variant?._id === cellProps?.row?.original?.variantId)[0]?.withoutDiscountPrice ? <Text
                                    className={"text-xs"}
                                    style={{ fontFamily: key.fontFamily, opacity: "0.6" }}
                                    content={`${cellProps.row.original.productId != null ? localStorage.getItem("role") == "b2c" ? priceFormat(((cellProps?.row?.original?.productId?.variants?.filter((variant) => variant?._id === cellProps?.row?.original?.variantId)[0]?.withoutDiscountPrice * cellProps?.row?.original?.productId?.variants?.filter((variant) => variant?._id === cellProps?.row?.original?.variantId)[0]?.gstPercentage) / 100) + cellProps?.row?.original?.productId?.variants?.filter((variant) => variant?._id === cellProps?.row?.original?.variantId)[0]?.withoutDiscountPrice) : priceFormat(((cellProps?.row?.original?.productId?.variants?.filter((variant) => variant?._id === cellProps?.row?.original?.variantId)[0]?.withoutDiscountPrice * cellProps?.row?.original?.productId?.variants?.filter((variant) => variant?._id === cellProps?.row?.original?.variantId)[0]?.gstPercentage) / 100) + cellProps?.row?.original?.productId?.variants?.filter((variant) => variant?._id === cellProps?.row?.original?.variantId)[0]?.withoutDiscountPrice) : null} (Incl. of all taxes)`}
                                /> : <Text
                                    className={"text-xs"}
                                    style={{ fontFamily: key.fontFamily, opacity: "0.6" }}
                                    content={`${cellProps.row.original.productId != null ? localStorage.getItem("role") == "b2c" ? priceFormat(((cellProps?.row?.original?.productId?.variants?.filter((variant) => variant?._id === cellProps?.row?.original?.variantId)[0]?.b2cPrice * cellProps?.row?.original?.productId?.variants?.filter((variant) => variant?._id === cellProps?.row?.original?.variantId)[0]?.gstPercentage) / 100) + cellProps?.row?.original?.productId?.variants?.filter((variant) => variant?._id === cellProps?.row?.original?.variantId)[0]?.b2cPrice) : priceFormat(((cellProps?.row?.original?.productId?.variants?.filter((variant) => variant?._id === cellProps?.row?.original?.variantId)[0]?.b2bPrice * cellProps?.row?.original?.productId?.variants?.filter((variant) => variant?._id === cellProps?.row?.original?.variantId)[0]?.gstPercentage) / 100) + cellProps?.row?.original?.productId?.variants?.filter((variant) => variant?._id === cellProps?.row?.original?.variantId)[0]?.b2bPrice) : null} (Incl. of all taxes)`}
                                />}
                            </div>
                            <div className="my-3" style={{ color: "#000", fontWeight: "bolder" }}>
                                {cellProps?.row?.original?.productId?.variants?.filter((variant) => variant?._id === cellProps?.row?.original?.variantId)[0]?.withoutDiscountPrice ? <Text
                                    className={"text-base"}
                                    style={{ fontFamily: key.fontFamily, }}
                                    content={cellProps.row.original.productId != null ? localStorage.getItem("role") == "b2c" ? priceFormat(cellProps?.row?.original?.productId?.variants?.filter((variant) => variant?._id === cellProps?.row?.original?.variantId)[0]?.withoutDiscountPrice) : priceFormat(cellProps?.row?.original?.productId?.variants?.filter((variant) => variant?._id === cellProps?.row?.original?.variantId)[0]?.withoutDiscountPrice) : null}
                                /> :
                                    <Text
                                        className={"text-base"}
                                        style={{ fontFamily: key.fontFamily, }}
                                        content={cellProps.row.original.productId != null ? localStorage.getItem("role") == "b2c" ? priceFormat(cellProps?.row?.original?.productId?.variants?.filter((variant) => variant?._id === cellProps?.row?.original?.variantId)[0]?.b2cPrice) : priceFormat(cellProps?.row?.original?.productId?.variants?.filter((variant) => variant?._id === cellProps?.row?.original?.variantId)[0]?.b2bPrice) : null}
                                    />
                                }
                            </div>
                            <div style={{ color: "#000", fontWeight: "bold" }}>
                                {cellProps?.row?.original?.productId?.variants?.filter((variant) => variant?._id === cellProps?.row?.original?.variantId)[0]?.withoutDiscountPrice ? <Text
                                    className={"text-xs"}
                                    style={{ fontFamily: key.fontFamily, opacity: "0.6" }}
                                    content={`+ ${cellProps.row.original.productId != null ? localStorage.getItem("role") == "b2c" ? priceFormat((cellProps?.row?.original?.productId?.variants?.filter((variant) => variant?._id === cellProps?.row?.original?.variantId)[0]?.withoutDiscountPrice * cellProps?.row?.original?.productId?.variants?.filter((variant) => variant?._id === cellProps?.row?.original?.variantId)[0]?.gstPercentage) / 100) : priceFormat((cellProps?.row?.original?.productId?.variants?.filter((variant) => variant?._id === cellProps?.row?.original?.variantId)[0]?.withoutDiscountPrice * cellProps?.row?.original?.productId?.variants?.filter((variant) => variant?._id === cellProps?.row?.original?.variantId)[0]?.gstPercentage) / 100) : null} GST`}
                                /> :
                                    <Text
                                        className={"text-xs"}
                                        style={{ fontFamily: key.fontFamily, opacity: "0.6" }}
                                        content={`+ ${cellProps.row.original.productId != null ? localStorage.getItem("role") == "b2c" ? priceFormat((cellProps?.row?.original?.productId?.variants?.filter((variant) => variant?._id === cellProps?.row?.original?.variantId)[0]?.b2cPrice * cellProps?.row?.original?.productId?.variants?.filter((variant) => variant?._id === cellProps?.row?.original?.variantId)[0]?.gstPercentage) / 100) : priceFormat((cellProps?.row?.original?.productId?.variants?.filter((variant) => variant?._id === cellProps?.row?.original?.variantId)[0]?.b2bPrice * cellProps?.row?.original?.productId?.variants?.filter((variant) => variant?._id === cellProps?.row?.original?.variantId)[0]?.gstPercentage) / 100) : null} GST`}
                                    />
                                }
                            </div>
                        </div>
                    </>
                ),
            },
            {
                Header: "Quantity",
                accessor: "quantity",
                width: "14%",
                Cell: (cellProps) => (
                    <>
                        <div className="flex items-center justify-center" style={{ color: "#000", fontWeight: "bold" }}>
                            {cellProps.row.original.productId != null ? cellProps.row.original.quantity + " " + cellProps?.row?.original?.productId?.variants?.filter((variant) => variant?._id === cellProps?.row?.original?.variantId)[0]?.unit : null}
                        </div>
                    </>
                ),
            },
            {
                Header: "Total Price",
                accessor: "totalPrice",
                width: "15%",
                Cell: (cellProps) => (
                    <>
                        <div style={{ textAlign: "left" }}>
                            <div style={{ color: "#000", fontWeight: "bold" }}>
                                <Text
                                    className={"text-xs"}
                                    style={{ fontFamily: key.fontFamily, opacity: "0.6" }}
                                    content={`${cellProps.row.original.productId != null ? localStorage.getItem("role") == "b2c" ? priceFormat((cellProps?.row?.original?.quantity * cellProps?.row?.original?.productId?.variants?.filter((variant) => variant?._id === cellProps?.row?.original?.variantId)[0]?.b2cPrice) + (cellProps?.row?.original?.quantity * (cellProps?.row?.original?.productId?.variants?.filter((variant) => variant?._id === cellProps?.row?.original?.variantId)[0]?.b2cPrice * cellProps?.row?.original?.productId?.variants?.filter((variant) => variant?._id === cellProps?.row?.original?.variantId)[0]?.gstPercentage)) / 100) : priceFormat((cellProps?.row?.original?.quantity * cellProps?.row?.original?.productId?.variants?.filter((variant) => variant?._id === cellProps?.row?.original?.variantId)[0]?.b2bPrice) + (cellProps?.row?.original?.quantity * (cellProps?.row?.original?.productId?.variants?.filter((variant) => variant?._id === cellProps?.row?.original?.variantId)[0]?.b2bPrice * cellProps?.row?.original?.productId?.variants?.filter((variant) => variant?._id === cellProps?.row?.original?.variantId)[0]?.gstPercentage)) / 100) : null} (Incl. of all taxes)`}
                                />
                            </div>
                            <div className="my-3" style={{ color: "#000", fontWeight: "bolder" }}>
                                {cellProps?.row?.original?.productId?.variants?.filter((variant) => variant?._id === cellProps?.row?.original?.variantId)[0]?.withoutDiscountPrice ?
                                    <>
                                        <Text
                                            className={"text-xs"}
                                            style={{ fontFamily: key.fontFamily, textDecoration: "line-through", opacity: "0.5" }}
                                            content={cellProps.row.original.productId != null ?
                                                localStorage.getItem("role") == "b2c" ?
                                                    priceFormat(cellProps?.row?.original?.quantity * cellProps?.row?.original?.productId?.variants?.filter((variant) => variant?._id === cellProps?.row?.original?.variantId)[0]?.withoutDiscountPrice)
                                                    : priceFormat(cellProps?.row?.original?.quantity * cellProps?.row?.original?.productId?.variants?.filter((variant) => variant?._id === cellProps?.row?.original?.variantId)[0]?.withoutDiscountPrice)
                                                : null}
                                        />
                                        <Text
                                            className={"text-base"}
                                            style={{ fontFamily: key.fontFamily }}
                                            content={cellProps.row.original.productId != null ?
                                                localStorage.getItem("role") == "b2c" ?
                                                    priceFormat(cellProps?.row?.original?.quantity * cellProps?.row?.original?.productId?.variants?.filter((variant) => variant?._id === cellProps?.row?.original?.variantId)[0]?.b2cPrice)
                                                    : priceFormat(cellProps?.row?.original?.quantity * cellProps?.row?.original?.productId?.variants?.filter((variant) => variant?._id === cellProps?.row?.original?.variantId)[0]?.b2bPrice)
                                                : null}
                                        />
                                    </>
                                    :
                                    <Text
                                        className={"text-base"}
                                        style={{ fontFamily: key.fontFamily, }}
                                        content={cellProps.row.original.productId != null ?
                                            localStorage.getItem("role") == "b2c" ?
                                                priceFormat(cellProps?.row?.original?.quantity * cellProps?.row?.original?.productId?.variants?.filter((variant) => variant?._id === cellProps?.row?.original?.variantId)[0]?.b2cPrice)
                                                : priceFormat(cellProps?.row?.original?.quantity * cellProps?.row?.original?.productId?.variants?.filter((variant) => variant?._id === cellProps?.row?.original?.variantId)[0]?.b2bPrice)
                                            : null}
                                    />
                                }
                            </div>
                            <div style={{ color: "#000", fontWeight: "bold" }}>
                                <Text
                                    className={"text-xs"}
                                    style={{ fontFamily: key.fontFamily, opacity: "0.6" }}
                                    content={`+ ${cellProps.row.original.productId != null ? localStorage.getItem("role") == "b2c" ? priceFormat(cellProps?.row?.original?.quantity * (cellProps?.row?.original?.productId?.variants?.filter((variant) => variant?._id === cellProps?.row?.original?.variantId)[0]?.b2cPrice * cellProps?.row?.original?.productId?.variants?.filter((variant) => variant?._id === cellProps?.row?.original?.variantId)[0]?.gstPercentage) / 100) : priceFormat(cellProps?.row?.original?.quantity * (cellProps?.row?.original?.productId?.variants?.filter((variant) => variant?._id === cellProps?.row?.original?.variantId)[0]?.b2bPrice * cellProps?.row?.original?.productId?.variants?.filter((variant) => variant?._id === cellProps?.row?.original?.variantId)[0]?.gstPercentage) / 100) : null} GST`}
                                />
                            </div>
                        </div>
                    </>
                ),
            },
        ],
        []
    );
    const accessoriesColumns = useMemo(
        () => [
            {
                Header: "No.",
                accessor: "id",
                width: "3%",
                Cell: (cellProps) => (
                    <>
                        <div style={{ color: "#000", fontWeight: "bold", display: "flex", alignItems: "center", justifyContent: "center" }}>
                            {cellProps.row.index + 1}
                        </div>
                    </>
                ),
            },
            {
                Header: "Images",
                accessor: "images",
                width: "23%",
                Cell: (cellProps) => (
                    <>
                        <div className="flex items-center justify-center" style={{ color: "#000", fontWeight: "bold" }}>
                            {cellProps?.row?.original?.productId?.images ? <img className="w-11/12 flex items-center justify-center" src={`${IMAGE_URL}/${cellProps?.row?.original?.productId?.images[0]}`} /> : <></>}
                        </div>
                    </>
                ),
            },
            {
                Header: "Product Name",
                accessor: "items",
                width: "21%",
                Cell: (cellProps) => (
                    <>
                        <div className="flex items-center justify-center" style={{ color: "#000", fontWeight: "bold" }}>
                            {cellProps.row.original.productId != null ? cellProps.row.original.productId.name : null}
                        </div>
                        <div className="flex items-center justify-center mt-3 text-sm" style={{ color: "#000", fontWeight: 300 }}>
                            {cellProps.row.original.productId != null ? "Brand : " + cellProps.row.original.productId.brand : null}
                        </div>
                        <div className="flex items-center justify-center mt-1 text-sm" style={{ color: "#000", fontWeight: 300 }}>
                            {cellProps.row.original.productId != null ? "Model No. : " + cellProps.row.original.productId.modelNumber : null}
                        </div>
                    </>
                ),
            },
            {
                Header: "Unit Price",
                accessor: "unitPrice",
                width: "19%",
                Cell: (cellProps) => (
                    <>
                        <div style={{ textAlign: "left" }}>
                            <div style={{ color: "#000", fontWeight: "bold" }}>
                                {console.log(">>>", cellProps?.row?.original)}
                                {cellProps?.row?.original?.productId?.variants?.filter((variant) => variant?._id === cellProps?.row?.original?.variantId)[0]?.withoutDiscountPrice ? <Text
                                    className={"text-xs"}
                                    style={{ fontFamily: key.fontFamily, opacity: "0.6" }}
                                    content={`${cellProps.row.original.productId != null ? localStorage.getItem("role") == "b2c" ? priceFormat(((cellProps?.row?.original?.productId?.variants?.filter((variant) => variant?._id === cellProps?.row?.original?.variantId)[0]?.withoutDiscountPrice * cellProps?.row?.original?.productId?.variants?.filter((variant) => variant?._id === cellProps?.row?.original?.variantId)[0]?.gstPercentage) / 100) + cellProps?.row?.original?.productId?.variants?.filter((variant) => variant?._id === cellProps?.row?.original?.variantId)[0]?.withoutDiscountPrice) : priceFormat(((cellProps?.row?.original?.productId?.variants?.filter((variant) => variant?._id === cellProps?.row?.original?.variantId)[0]?.withoutDiscountPrice * cellProps?.row?.original?.productId?.variants?.filter((variant) => variant?._id === cellProps?.row?.original?.variantId)[0]?.gstPercentage) / 100) + cellProps?.row?.original?.productId?.variants?.filter((variant) => variant?._id === cellProps?.row?.original?.variantId)[0]?.withoutDiscountPrice) : null} (Incl. of all taxes)`}
                                /> : <Text
                                    className={"text-xs"}
                                    style={{ fontFamily: key.fontFamily, opacity: "0.6" }}
                                    content={`${cellProps.row.original.productId != null ? localStorage.getItem("role") == "b2c" ? priceFormat(((cellProps?.row?.original?.productId?.variants?.filter((variant) => variant?._id === cellProps?.row?.original?.variantId)[0]?.b2cPrice * cellProps?.row?.original?.productId?.variants?.filter((variant) => variant?._id === cellProps?.row?.original?.variantId)[0]?.gstPercentage) / 100) + cellProps?.row?.original?.productId?.variants?.filter((variant) => variant?._id === cellProps?.row?.original?.variantId)[0]?.b2cPrice) : priceFormat(((cellProps?.row?.original?.productId?.variants?.filter((variant) => variant?._id === cellProps?.row?.original?.variantId)[0]?.b2bPrice * cellProps?.row?.original?.productId?.variants?.filter((variant) => variant?._id === cellProps?.row?.original?.variantId)[0]?.gstPercentage) / 100) + cellProps?.row?.original?.productId?.variants?.filter((variant) => variant?._id === cellProps?.row?.original?.variantId)[0]?.b2bPrice) : null} (Incl. of all taxes)`}
                                />}
                            </div>
                            <div className="my-3" style={{ color: "#000", fontWeight: "bolder" }}>
                                {cellProps?.row?.original?.productId?.variants?.filter((variant) => variant?._id === cellProps?.row?.original?.variantId)[0]?.withoutDiscountPrice ? <Text
                                    className={"text-base"}
                                    style={{ fontFamily: key.fontFamily, }}
                                    content={cellProps.row.original.productId != null ? localStorage.getItem("role") == "b2c" ? priceFormat(cellProps?.row?.original?.productId?.variants?.filter((variant) => variant?._id === cellProps?.row?.original?.variantId)[0]?.withoutDiscountPrice) : priceFormat(cellProps?.row?.original?.productId?.variants?.filter((variant) => variant?._id === cellProps?.row?.original?.variantId)[0]?.withoutDiscountPrice) : null}
                                /> :
                                    <Text
                                        className={"text-base"}
                                        style={{ fontFamily: key.fontFamily, }}
                                        content={cellProps.row.original.productId != null ? localStorage.getItem("role") == "b2c" ? priceFormat(cellProps?.row?.original?.productId?.variants?.filter((variant) => variant?._id === cellProps?.row?.original?.variantId)[0]?.b2cPrice) : priceFormat(cellProps?.row?.original?.productId?.variants?.filter((variant) => variant?._id === cellProps?.row?.original?.variantId)[0]?.b2bPrice) : null}
                                    />
                                }
                            </div>
                            <div style={{ color: "#000", fontWeight: "bold" }}>
                                {cellProps?.row?.original?.productId?.variants?.filter((variant) => variant?._id === cellProps?.row?.original?.variantId)[0]?.withoutDiscountPrice ? <Text
                                    className={"text-xs"}
                                    style={{ fontFamily: key.fontFamily, opacity: "0.6" }}
                                    content={`+ ${cellProps.row.original.productId != null ? localStorage.getItem("role") == "b2c" ? priceFormat((cellProps?.row?.original?.productId?.variants?.filter((variant) => variant?._id === cellProps?.row?.original?.variantId)[0]?.withoutDiscountPrice * cellProps?.row?.original?.productId?.variants?.filter((variant) => variant?._id === cellProps?.row?.original?.variantId)[0]?.gstPercentage) / 100) : priceFormat((cellProps?.row?.original?.productId?.variants?.filter((variant) => variant?._id === cellProps?.row?.original?.variantId)[0]?.withoutDiscountPrice * cellProps?.row?.original?.productId?.variants?.filter((variant) => variant?._id === cellProps?.row?.original?.variantId)[0]?.gstPercentage) / 100) : null} GST`}
                                /> :
                                    <Text
                                        className={"text-xs"}
                                        style={{ fontFamily: key.fontFamily, opacity: "0.6" }}
                                        content={`+ ${cellProps.row.original.productId != null ? localStorage.getItem("role") == "b2c" ? priceFormat((cellProps?.row?.original?.productId?.variants?.filter((variant) => variant?._id === cellProps?.row?.original?.variantId)[0]?.b2cPrice * cellProps?.row?.original?.productId?.variants?.filter((variant) => variant?._id === cellProps?.row?.original?.variantId)[0]?.gstPercentage) / 100) : priceFormat((cellProps?.row?.original?.productId?.variants?.filter((variant) => variant?._id === cellProps?.row?.original?.variantId)[0]?.b2bPrice * cellProps?.row?.original?.productId?.variants?.filter((variant) => variant?._id === cellProps?.row?.original?.variantId)[0]?.gstPercentage) / 100) : null} GST`}
                                    />
                                }
                            </div>
                        </div>
                    </>
                ),
            },
            {
                Header: "Quantity",
                accessor: "quantity",
                width: "14%",
                Cell: (cellProps) => (
                    <>
                        <div className="flex items-center justify-center" style={{ color: "#000", fontWeight: "bold" }}>
                            {cellProps.row.original.productId != null ? cellProps.row.original.quantity + " " + (cellProps?.row?.original?.productId?.variants?.filter((variant) => variant?._id === cellProps?.row?.original?.variantId)[0]?.unit? cellProps?.row?.original?.productId?.variants?.filter((variant) => variant?._id === cellProps?.row?.original?.variantId)[0]?.unit : '') : null}
                        </div>
                    </>
                ),
            },
            {
                Header: "Total Price",
                accessor: "totalPrice",
                width: "20%",
                Cell: (cellProps) => (
                    <>
                        <div style={{ textAlign: "left" }}>
                            <div style={{ color: "#000", fontWeight: "bold" }}>
                                <Text
                                    className={"text-xs"}
                                    style={{ fontFamily: key.fontFamily, opacity: "0.6" }}
                                    content={`${cellProps.row.original.productId != null ? localStorage.getItem("role") == "b2c" ? priceFormat((cellProps?.row?.original?.quantity * cellProps?.row?.original?.productId?.variants?.filter((variant) => variant?._id === cellProps?.row?.original?.variantId)[0]?.b2cPrice) + (cellProps?.row?.original?.quantity * (cellProps?.row?.original?.productId?.variants?.filter((variant) => variant?._id === cellProps?.row?.original?.variantId)[0]?.b2cPrice * cellProps?.row?.original?.productId?.variants?.filter((variant) => variant?._id === cellProps?.row?.original?.variantId)[0]?.gstPercentage)) / 100) : priceFormat((cellProps?.row?.original?.quantity * cellProps?.row?.original?.productId?.variants?.filter((variant) => variant?._id === cellProps?.row?.original?.variantId)[0]?.b2bPrice) + (cellProps?.row?.original?.quantity * (cellProps?.row?.original?.productId?.variants?.filter((variant) => variant?._id === cellProps?.row?.original?.variantId)[0]?.b2bPrice * cellProps?.row?.original?.productId?.variants?.filter((variant) => variant?._id === cellProps?.row?.original?.variantId)[0]?.gstPercentage)) / 100) : null} (Incl. of all taxes)`}
                                />
                            </div>
                            <div className="my-3" style={{ color: "#000", fontWeight: "bolder" }}>
                                {cellProps?.row?.original?.productId?.variants?.filter((variant) => variant?._id === cellProps?.row?.original?.variantId)[0]?.withoutDiscountPrice ?
                                    <>
                                        <Text
                                            className={"text-xs"}
                                            style={{ fontFamily: key.fontFamily, textDecoration: "line-through", opacity: "0.5" }}
                                            content={cellProps.row.original.productId != null ?
                                                localStorage.getItem("role") == "b2c" ?
                                                    priceFormat(cellProps?.row?.original?.quantity * cellProps?.row?.original?.productId?.variants?.filter((variant) => variant?._id === cellProps?.row?.original?.variantId)[0]?.withoutDiscountPrice)
                                                    : priceFormat(cellProps?.row?.original?.quantity * cellProps?.row?.original?.productId?.variants?.filter((variant) => variant?._id === cellProps?.row?.original?.variantId)[0]?.withoutDiscountPrice)
                                                : null}
                                        />
                                        <Text
                                            className={"text-base"}
                                            style={{ fontFamily: key.fontFamily }}
                                            content={cellProps.row.original.productId != null ?
                                                localStorage.getItem("role") == "b2c" ?
                                                    priceFormat(cellProps?.row?.original?.quantity * cellProps?.row?.original?.productId?.variants?.filter((variant) => variant?._id === cellProps?.row?.original?.variantId)[0]?.b2cPrice)
                                                    : priceFormat(cellProps?.row?.original?.quantity * cellProps?.row?.original?.productId?.variants?.filter((variant) => variant?._id === cellProps?.row?.original?.variantId)[0]?.b2bPrice)
                                                : null}
                                        />
                                    </>
                                    :
                                    <Text
                                        className={"text-base"}
                                        style={{ fontFamily: key.fontFamily, }}
                                        content={cellProps.row.original.productId != null ?
                                            localStorage.getItem("role") == "b2c" ?
                                                priceFormat(cellProps?.row?.original?.quantity * cellProps?.row?.original?.productId?.variants?.filter((variant) => variant?._id === cellProps?.row?.original?.variantId)[0]?.b2cPrice)
                                                : priceFormat(cellProps?.row?.original?.quantity * cellProps?.row?.original?.productId?.variants?.filter((variant) => variant?._id === cellProps?.row?.original?.variantId)[0]?.b2bPrice)
                                            : null}
                                    />
                                }
                            </div>
                            <div style={{ color: "#000", fontWeight: "bold" }}>
                                <Text
                                    className={"text-xs"}
                                    style={{ fontFamily: key.fontFamily, opacity: "0.6" }}
                                    content={`+ ${cellProps.row.original.productId != null ? localStorage.getItem("role") == "b2c" ? priceFormat(cellProps?.row?.original?.quantity * (cellProps?.row?.original?.productId?.variants?.filter((variant) => variant?._id === cellProps?.row?.original?.variantId)[0]?.b2cPrice * cellProps?.row?.original?.productId?.variants?.filter((variant) => variant?._id === cellProps?.row?.original?.variantId)[0]?.gstPercentage) / 100) : priceFormat(cellProps?.row?.original?.quantity * (cellProps?.row?.original?.productId?.variants?.filter((variant) => variant?._id === cellProps?.row?.original?.variantId)[0]?.b2bPrice * cellProps?.row?.original?.productId?.variants?.filter((variant) => variant?._id === cellProps?.row?.original?.variantId)[0]?.gstPercentage) / 100) : null} GST`}
                                />
                            </div>
                        </div>
                    </>
                ),
            },
        ],
        []
    );

    return (
        <>
            <Header />
            <Text content={"Quotation Summary"} className="text-4xl m-4" style={{ fontFamily: key.fontFamily, fontWeight: "bold", background: key.primaryColor, WebkitBackgroundClip: "text", WebkitTextFillColor: "transparent" }} />
            <div className='flex m-4 gap-6'>
                <div className="w-2/3" style={{ overflowY: "scroll", maxHeight: window.innerHeight / 1.30 }}>
                    {data && showData && data?.products?.plants?.length > 0 ? <div>
                        <div className="flex border w-full"
                            onClick={() => { setShowPlants(!showPlants) }} >
                            <Text className={"h-12 w-full flex items-center pl-4 text-lg"} content={"Plants"} style={{ fontFamily: key.fontFamily, color: key.blue, backgroundColor: key.secondaryColor, fontWeight: "bold" }} />
                            <RightDropdownArrow show={showPlants} setShow={setShowPlants} />
                        </div>
                        <Collapse isOpen={showPlants} style={{ visibility: "visible" }}>
                            <CardBody>
                                <TableContainer
                                    columns={plantColumns}
                                    data={data.products.plants || []}
                                    customPageSize={10}
                                    className="custom-header-css"
                                    trClass={"h-12 rounded-tr text-md"}
                                    paginationDisplay={false}
                                    backgroundColor={"#d6d6d680"}
                                />
                            </CardBody>
                        </Collapse>
                    </div> : <></>
                    }
                    {data && showData && data?.products?.services?.length > 0 ?
                        <div>
                            <div className="flex border" onClick={() => { setShowServices(!showServices) }}>
                                <Text className={"h-12 w-full flex items-center pl-4 text-lg"} content={"Services"} style={{ fontFamily: key.fontFamily, color: key.blue, backgroundColor: key.secondaryColor, fontWeight: "bold" }} />
                                <RightDropdownArrow show={showServices} setShow={setShowServices} />
                            </div>
                            <Collapse isOpen={showServices} style={{ visibility: "visible" }}>
                                <CardBody>
                                    <TableContainer
                                        columns={serviceColumns}
                                        data={data.products.services || []}
                                        customPageSize={10}
                                        className="custom-header-css"
                                        trClass={"h-12 rounded-tr text-md"}
                                        paginationDisplay={false}
                                        backgroundColor={"#d6d6d680"}
                                    />
                                </CardBody>
                            </Collapse>
                        </div> : <></>
                    }
                    {data && showData && data?.products?.accessories?.length > 0 ?
                        <div>
                            <div className="flex border" onClick={() => { setShowAccessories(!showAccessories) }}>
                                <Text className={"h-12 w-full flex items-center pl-4 text-lg"} content={"Accessories"} style={{ fontFamily: key.fontFamily, color: key.blue, backgroundColor: key.secondaryColor, fontWeight: "bold" }} />
                                <RightDropdownArrow show={showAccessories} setShow={setShowAccessories} />
                            </div>
                            <Collapse isOpen={showAccessories} style={{ visibility: "visible" }}>
                                <CardBody>
                                    {console.log(">data.products.accessories",data.products.accessories[0])}
                                    {console.log(">data.products.accessories",data.products.accessories[0]?.productId?.variants?.filter((variant) => variant?._id === data.products.accessories[0]?.productId)[0]?.unit)}
                                    <TableContainer
                                        columns={accessoriesColumns}
                                        data={data.products.accessories || []}
                                        customPageSize={10}
                                        className="custom-header-css"
                                        trClass={"h-12 rounded-tr text-md"}
                                        paginationDisplay={false}
                                        backgroundColor={"#d6d6d680"}
                                    />
                                </CardBody>
                            </Collapse>
                        </div> : <></>
                    }
                    {data && showData && data?.products?.spareParts?.length > 0 ?
                        <>
                            <div className="flex border" onClick={() => { setShowSpareParts(!showSpareParts) }}>
                                <Text className={"h-12 w-full flex items-center pl-4 text-lg"} content={"Spare Parts"} style={{ fontFamily: key.fontFamily, color: key.blue, backgroundColor: key.secondaryColor, fontWeight: "bold" }} />
                                <RightDropdownArrow show={showSpareParts} setShow={setShowSpareParts} />
                            </div>
                            <Collapse isOpen={showSpareParts} style={{ visibility: "visible" }}>
                                <CardBody>
                                    <TableContainer
                                        columns={sparePartsColumns}
                                        data={data.products.spareParts || []}
                                        customPageSize={10}
                                        className="custom-header-css"
                                        trClass={"h-12 rounded-tr text-md"}
                                        paginationDisplay={false}
                                        backgroundColor={"#d6d6d680"}
                                    />
                                </CardBody>
                            </Collapse>
                        </> : <></>
                    }
                </div>
                {console.log(">quotationData?.products",quotationData.products)}
                {quotationData?.products ?
                    <div className='w-1/3'>
                        <Coupons couponData={couponsData} unChangedData={unChangedData} setShowData={setShowData} setData={setData} data={data} setTotalDiscountPrice={setTotalDiscountPrice} setGstDiscountPrice={setGstDiscountPrice} setProductDiscountPrice={setProductDiscountPrice} setDiscount={setDiscount} />
                        <div className="border-b border-x-2 w-full" style={{ borderColor: "#00000050", backgroundColor: "#EAEAEA" }}>
                            <div className='border-b my-2 py-2' style={{ borderColor: "#000" }}>
                                <Text content={"Bill Amount"} className={"text-xl ml-3"} style={{ fontFamily: key.fontFamily, fontWeight: "bold" }} />
                            </div>
                            <div className='flex'>
                                <Text content={`Total Value (without GST Taxes)`} className={"text-base ml-3 mt-4 mb-2 w-full italic"} style={{ fontFamily: key.fontFamily }} />
                                <Text content={`${"Rs." + parseInt(productDiscountPrice ? productDiscountPrice : quotationData?.priceWithoutGst).toLocaleString("en-IN") + "/-"}`} className={"text-lg ml-3 mt-4 mb-2 w-fit flex justify-end mr-3"} style={{ fontFamily: key.fontFamily, fontWeight: "bold" }} />
                            </div>
                            <div className='flex' style={{ borderColor: "#000" }}>
                                <Text content={`Total GST`} className={"text-base ml-3 my-2 w-full italic"} style={{ fontFamily: key.fontFamily }} />
                                <Text content={`${"Rs." + parseInt(gstDiscountPrice ? gstDiscountPrice : quotationData?.totalGstPrice).toLocaleString("en-IN") + "/-"}`} className={"text-lg ml-3 my-2 w-fit flex justify-end mr-3"} style={{ fontFamily: key.fontFamily, fontWeight: "bold" }} />
                            </div>
                            {discount ? <div className='flex' style={{ borderColor: "#000" }}>
                                <Text content={`Discount`} className={"text-base ml-3 my-2 w-full italic"} style={{ fontFamily: key.fontFamily }} />
                                <Text content={`${"Rs." + parseInt(discount).toLocaleString("en-IN") + "/-"}`} className={"text-lg ml-3 my-2 w-fit flex justify-end mr-3"} style={{ fontFamily: key.fontFamily, fontWeight: "bold" }} />
                            </div> : <></>}
                            <div className='flex border-t' style={{ borderColor: "#000" }}>
                                <Text content={`Total Amount (Incl. of all taxes)`} className={"text-base ml-3 mt-4 mb-2 w-full italic"} style={{ fontFamily: key.fontFamily }} />
                                <Text content={`${"Rs." + parseInt(totalDiscountPrice ? totalDiscountPrice : quotationData?.price).toLocaleString("en-IN") + "/-"}`} className={"text-lg ml-3 mt-4 mb-2 w-fit flex justify-end mr-3"} style={{ fontFamily: key.fontFamily, fontWeight: "bold" }} />
                            </div>
                            <Button className={"w-full h-12 flex items-center justify-center text-lg"} buttonText={"Proceed"} style={{ fontFamily: key.fontFamily, color: key.secondaryColor, backgroundColor: key.blue, fontWeight: "bold" }}
                                onClick={() => {
                                    history.push(ORDER_CONFIRM + quotationNumber, { data: data })
                                }} />
                        </div>

                    </div> : <></>}
            </div>
            <Footer />
        </>
    )
}
export default QuotationSummary;