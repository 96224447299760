const BackgroundImage = ({ src, className = "w-95 max-[820px]:hidden" }) => {
  return (
      <img
        {...{
          src,
          className,
          style: { height: window.innerHeight, top: 0, bottom: 0, userDrag:"none" },
        }}
        draggable={false} onMouseDown={()=>{return false}}
      />
  );
};
export default BackgroundImage;
