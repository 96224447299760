import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap"
import { key } from "../../utils/consts"
import { useEffect, useState } from "react";
import { callGetTandCData } from "../../Services/DataServices";
import DisplayInput from "../../components/DisplayInput";
import Button from "../../atom/Button";

const TermsAndConditionsAgreementModal = ({ openModal, setOpenModal, setReadTandC }) => {
    const [contentData, setContentData] = useState({});
    const getContentData = async () => {
        const response = await callGetTandCData();
        const tandCData = response?.result?.filter((item) => item?.field === "Terms And Conditions")
        setContentData(tandCData[0])
    }
    useEffect(() => {
        getContentData();
    }, [])
    return (
        <Modal isOpen={openModal} size="xl">
            <ModalHeader style={{fontFamily: key.fontFamily, fontWeight:"bolder"}}>
                Terms & Conditions
            </ModalHeader>
            <ModalBody>
                <div className="mx-3 mt-3 mb-3">
                    <DisplayInput inputText={[contentData?.content]} />
                </div>
            </ModalBody>
            <ModalFooter>
                <Button buttonText={"Cancel"} className={"px-4 py-2 rounded border-1"} style={{ backgroundColor: key.secondaryColor, color: key.blue, borderColor: key.blue }} onClick={()=>{
                    setOpenModal(false);
                }}/>
                <Button buttonText={"Accept"} className={"px-7 py-2 rounded"} onClick={()=>{
                    setReadTandC(true);
                    setOpenModal(false);
                }}/>
            </ModalFooter>
        </Modal>
    )
}
export default TermsAndConditionsAgreementModal;