import { useState } from "react";
import Text from "../../atom/Text";
import { key } from "../../utils/consts";
import DisplayInput from "../../components/DisplayInput";

const BomDescription = ({ plantData, showBom, setShowBom }) => {
    const [columns, setColumns] = useState(['Component Name', 'Make', 'Specification', 'Quantity', 'Unit']);
    return (
        <>
            <div className="my-3">
                {plantData?.bom ?
                    <>
                        <div className="flex items-center justify-center rounded-t" style={{ backgroundColor: key.blue }}>
                            {columns.map((item) => {
                                return (
                                    <Text className={"w-1/5 text-sm h-14 text-center flex items-center justify-center border-r"} content={item} style={{ fontFamily: key.fontFamily, color: key.secondaryColor }} />
                                )
                            })}
                        </div>
                        {showBom ? plantData?.bom.map((item) => {
                            return (
                                <>
                                    <div className="flex items-center justify-center border" style={{}}>
                                        <div className="w-1/5 flex items-center justify-center py-2 border-r"><Text className={"text-xs text-center"} content={item?.componentName || "-"} style={{ fontFamily: key.fontFamily, color: key.blue }} /></div>
                                        <div className="w-1/5 flex items-center justify-center py-2 border-r"><Text className={"text-xs text-center"} content={item?.make || "-"} style={{ fontFamily: key.fontFamily, color: key.blue }} /></div>
                                        <div className="w-1/5 flex items-center justify-center py-2 border-r">
                                            {typeof item?.specification !== typeof [] ? <Text className={"text-xs text-center"} content={item?.specification || "-"} style={{ fontFamily: key.fontFamily, color: key.blue }} /> : <div style={{ fontFamily: key.fontFamily, textAlign:"center", color:key.blue }} className="text-xs px-2" dangerouslySetInnerHTML={{
                                                __html: `<style>
       table, td, tr{border: 1px solid black;border-collapse: collapse;padding:6px;margin-bottom:0px; margin:15px 0px}
       tbody > tr:first-child > td {
        vertical-align: top;
        background-color: #3A7AB7;
        color: #3A7AB7;
    }
        </style>${item?.specification?.length > 0 ? item?.specification.join(' ') : "-"}`
                                            }} />}
                                        </div>
                                        <div className="w-1/5 flex items-center justify-center py-2 border-r"><Text className={"text-xs text-center"} content={item?.quantity || "-"} style={{ fontFamily: key.fontFamily, color: key.blue }} /></div>
                                        <div className="w-1/5 flex items-center justify-center py-2 border-r"><Text className={"text-xs text-center"} content={item?.unit || "-"} style={{ fontFamily: key.fontFamily, color: key.blue }} /></div>
                                    </div>
                                </>
                            )
                        }) : <></>}
                    </>
                    : <></>}
            </div>
        </>
    )
}
export default BomDescription;