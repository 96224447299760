import { Link, useHistory } from "react-router-dom";
import { Country, State, City } from 'country-state-city';
import Button from "../../../atom/Button";
import Image from "../../../atom/Image";
import Label from "../../../atom/Label";
import Text from "../../../atom/Text";
import TextInput from "../../../atom/TextInput";
import { key } from "../../../utils/consts";
import { REGISTERUSERS } from "../../../utils/urlDetails";
import { callPostServiceData } from "../../../Services/DataServices";
import { ToastContainer, toast } from "react-toastify";
import { useState } from "react";
import { OTP_VERIFICATION, SIGNUP } from "../../../utils/pathDetails";
import { validateEmail, validateGst, validatePhone, validatePincode } from "../../../utils/validationRegex";
import { Col, FormGroup, Input } from "reactstrap";
import TermsAndConditionsAgreementModal from "../TermsAndConditionsAgreementModal";
import BackgroundImage from "../../../atom/BackgroundImage";
import RefundPolicyAgreementModal from "../RefundPolicyAgreementModal";

const BusinessSignUp = ({ }) => {
    const history = useHistory();
    const [stateData, setStateData] = useState(State.getStatesOfCountry("IN"));
    const [stateSelected, setStateSelected] = useState(false);
    const [citySelected, setCitySelected] = useState(false);
    const [cityData, setCityData] = useState([]);
    const [otherField, setOtherField] = useState(false);
    const [userData, setUserData] = useState({ role: "b2b" });
    const [readTandC, setReadTandC] = useState();
    const [modal, setModal] = useState(false);
    const [refundModal, setRefundModal] = useState(false);
    const [readRefund, setReadRefund] = useState();

    const onsubmit = async () => {
        if (userData.mobile && userData.email && userData.name && userData.GSTNumber && userData.companyName && userData.houseNo && userData.city && userData.pin && userData.state) {
            if (readTandC) {
                if (readRefund) {
                    const validEmail = validateEmail(userData.email);
                    if (validEmail) {
                        const validGst = validateGst(userData.GSTNumber);
                        if (validGst) {
                            const validMobile = validatePhone(userData.mobile);
                            if (validMobile) {

                                const validatePin = validatePincode(userData.pin);
                                if (validatePin) {
                                    const apiBody = { data: userData, platform: "frontend" }
                                    const { result } = await callPostServiceData(REGISTERUSERS, apiBody)
                                    if (result?.data) {
                                        if (result?.data?.statusCode) {
                                            toast.error(result?.data?.response)
                                        } else {
                                            toast.success("Registered Successfully")
                                            setTimeout(() => {
                                                history.push(OTP_VERIFICATION, { user: result?.data });
                                            }, 1000);
                                        }
                                    } else {
                                        toast.error("Something went wrong")
                                    }
                                } else {
                                    toast.error("Invalid PIN Code!")
                                }
                            } else {
                                toast.error("Invalid Mobile Number!")
                            }
                        } else {
                            toast.error("Invalid GST Number!")
                        }
                    } else {
                        toast.error("Invalid Email!")
                    }
                } else {
                    toast.error("Please Accept Refund Policy!")
                }
            } else {
                toast.error("Please Accept Terms & Conditions!")
            }
        } else {
            toast.error("Please enter all the details")
        }
    }

    return (
        <>
            <ToastContainer />
            <TermsAndConditionsAgreementModal openModal={modal} setOpenModal={setModal} setReadTandC={setReadTandC} />
            <RefundPolicyAgreementModal openModal={refundModal} setOpenModal={setRefundModal} setReadTandC={setReadRefund} />
            <div className="flex max-[810px]:flex-col max-[810px]:px-8" style={{ backgroundColor: key.secondaryColor }}>
                <BackgroundImage src={"../images/Individual Photo.png"} />
                <div className="w-10/12 mt-16 mr-10 ml-10 rounded-t-2xl p-10 max-[810px]:w-full max-[810px]:mr-0 max-[810px]:ml-0 max-[810px]:mt-8" style={{ backgroundColor: key.formBackground, boxShadow: key.formShadow, height: window.innerHeight / 1.1, overflowY: "auto" }}>
                    <div className="flex items-start max-[1250px]:flex-col max-[1250px]:items-baseline max-[810px]:w-full max-[810px]:py-0 max-[810px]:mx-0 max-[810px]:items-center max-[810px]:mt-8">
                        <Image src={'../images/logo-light.png'} className={"w-1/3"} />
                        <div className="w-2/3 flex flex-col mx-8 max-[1250px]:w-full max-[1250px]:my-8 max-[810px]:mx-0 max-[810px]:items-center max-[810px]:">
                            <Text content={"Innovative Water Treatment Technologies, Built to Last"} className="text-2xl max-[810px]:text-center" style={{ color: key.blue, fontFamily: key.fontFamily, fontWeight: "bold" }} />
                            <Text content={"Create An Account"} className="text-lg max-[810px]:text-center" style={{ color: key.blue, fontFamily: key.fontFamily }} />
                        </div>
                    </div>
                    <Label text={"Name"} required={true} />
                    <div className="mb-4">
                        <TextInput
                            placeholder={"Name"}
                            onChange={(e) => { userData['name'] = e.target.value }}
                        />
                    </div>
                    <Label text={"Company Name"} required={true} />
                    <div className="mb-4">
                        <TextInput placeholder={"Company Name"}
                            onChange={(e) => { userData['companyName'] = e.target.value }}
                        />
                    </div>
                    <Label text={"Company Link"} required={false} />
                    <div className="mb-4">
                        <TextInput placeholder={"Company Link"}
                            onChange={(e) => { userData['companyLink'] = e.target.value }}
                        />
                    </div>
                    <Label text={"GST Number"} required={true} />
                    <div className="mb-4">
                        <TextInput placeholder={"GST Number"}
                            onChange={(e) => { userData['GSTNumber'] = e.target.value }}
                        />
                    </div>
                    <Label text={"Mobile Number"} required={true} />
                    <div className="mb-4">
                        <TextInput placeholder={"Mobile Number"}
                            onChange={(e) => { userData['mobile'] = e.target.value }}
                        />
                    </div>
                    <Label text={"Alternate Mobile Number"} required={true} />
                    <div className="mb-4">
                        <TextInput placeholder={"Alternate Mobile Number"}
                            onChange={(e) => { userData['alternateMobile'] = e.target.value }}
                        />
                    </div>
                    <Label text={"Email"} required={true} />
                    <div className="mb-4">
                        <TextInput placeholder={"Email"}
                            onChange={(e) => { userData['email'] = e.target.value }}
                        />
                    </div>
                    <Text content={"Address"} className={"text-lg mb-2"} style={{ fontFamily: key.fontFamily, fontWeight: "bold", color: "#646982" }} />
                    <div className="border-1 px-4 pt-3" style={{ borderColor: key.secondaryColor }}>
                        <Label text={"State"} required={true} />
                        <div className="mb-4">
                            <Input
                                type="select"
                                style={{ fontFamily: key.fontFamily, letterSpacing: 1, opacity: "0.8" }}
                                onChange={(e) => {
                                    if (e.target.value != "none") {
                                        const selectedData = stateData.filter((item) => item?.name == e.target.value)
                                        userData['state'] = e.target.value
                                        setCityData(City.getCitiesOfState("IN", selectedData[0]?.isoCode))
                                        setStateSelected(true);
                                    } else {
                                        setOtherField(false)
                                        setStateSelected(false);
                                        setCityData([])
                                    }
                                }}
                            >
                                <option value={"none"}>Select State</option>
                                {stateData.map((item) => {
                                    return (
                                        <option>{item?.name}</option>
                                    )
                                })}
                            </Input>
                        </div>
                        <Label text={"City"} required={true} />
                        <div className="mb-4">
                            <Input
                                disabled={stateSelected ? false : true}
                                type="select"
                                style={{ fontFamily: key.fontFamily, letterSpacing: 1, opacity: "0.8" }}
                                onChange={(e) => {
                                    if (e.target.value == "none") {
                                        setOtherField(false)
                                        setCitySelected(false)
                                    } else if (e.target.value == "other") {
                                        setOtherField(true)
                                    } else {
                                        setOtherField(false)
                                        userData['city'] = e.target.value
                                        setCitySelected(true)
                                    }
                                }}
                            >
                                <option value={"none"}>Select City</option>
                                {cityData.map((item) => {
                                    return (
                                        <option value={item?.name}>{item?.name}</option>
                                    )
                                })}
                                <option value={"other"}>Other</option>
                            </Input>
                        </div>
                        {otherField ?
                            <>
                                <Label text={"Other City"} required={true} />
                                <div className="mb-4">
                                    <TextInput
                                        placeholder={"Other City"}
                                        onChange={(e) => { userData['city'] = e.target.value }}
                                    />
                                </div>
                            </> : <></>}
                        <Label text={"House No. / Plot No."} required={true} />
                        <div className="mb-4">
                            <TextInput
                                placeholder={"House No. / Plot No."}
                                disabled={citySelected ? false : true}
                                onChange={(e) => { userData['houseNo'] = e.target.value }}
                            />
                        </div>
                        <Label text={"Area"} required={true} />
                        <div className="mb-4">
                            <TextInput placeholder={"Area"}
                                disabled={citySelected ? false : true}
                                onChange={(e) => { userData['area'] = e.target.value }}
                            />
                        </div>
                        <Label text={"PIN Code"} required={true} />
                        <div className="mb-4">
                            <TextInput placeholder={"PIN Code"}
                                disabled={citySelected ? false : true}
                                onChange={(e) => { userData['pin'] = e.target.value }}
                            />
                        </div>
                    </div>
                    <FormGroup className="my-4" row style={{ display: "flex", width: "100%" }}>
                        <Col
                            sm={{
                                size: 19
                            }}
                            style={{ width: "100%" }}
                        >
                            <FormGroup check>
                                <Input
                                    disabled={readTandC ? true : false}
                                    onChange={(e) => {
                                        if (e.target.checked === true) {
                                            setModal(true);
                                        }
                                    }}
                                    id="checkbox2"
                                    type="checkbox"
                                />
                                {' '}
                                <Label text={"Check here to indicate that you have read and agreed to the terms & conditions of RTPL."} />
                            </FormGroup>
                        </Col>
                    </FormGroup>
                    <FormGroup className=" mb-4" row style={{ display: "flex", width: "100%" }}>
                        <Col
                            sm={{
                                size: 19
                            }}
                            style={{ width: "100%" }}
                        >
                            <FormGroup check>
                                <Input
                                    disabled={readRefund ? true : false}
                                    onChange={(e) => {
                                        if (e.target.checked === true) {
                                            setRefundModal(true);
                                        }
                                    }}
                                    id="checkbox2"
                                    type="checkbox"
                                />
                                {' '}
                                <Label text={"Check here to indicate that you have read and agreed to the refund policies of RTPL."} />
                            </FormGroup>
                        </Col>
                    </FormGroup>
                    <div className="flex justify-center my-8">
                        <Button
                            {...{
                                disabled: readTandC && readRefund ? false : true,
                                buttonText: "Generate OTP",
                                className:
                                    "p-2.5 text-center text-white phone:w-36 tablet:w-80 rounded-lg",
                                style: { background: key.primaryColor, fontFamily: key.fontFamily, fontWeight: "700", opacity: readTandC ? 1 : 0.5 },
                                onClick: () => onsubmit()
                            }}
                        />
                    </div>
                    <div className="flex justify-center space-x-3">
                        <Label text={"Change the account type ?"} color={"#7E8A97"} />
                        <button onClick={() => { history.push(SIGNUP) }}><Label text={"SIGN UP"} color={"#184295"} /></button>
                    </div>
                </div>
            </div>
        </>
    )
}
export default BusinessSignUp;