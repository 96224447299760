import { useEffect, useState } from "react";
import { getDetails } from "../../Services/FooterServices";
import Text from "../../atom/Text";
import Footer from "../../components/Footer";
import Header from "../../components/Header/Header";
import { key } from "../../utils/consts";
import { MdPhone } from "react-icons/md";
import Loader from "../../atom/Loader";

const ContactUs = () => {
    const [details, setDetails] = useState([]);
    const [loading, setLoading] = useState(false);
    const getDetailsData = async () => {
        setLoading(true)
        const response = await getDetails();
        if (response?.result) {
            setDetails(response?.result)
        }
        setLoading(false)
    }
    useEffect(() => {
        getDetailsData();
    }, [])

    return (
        <>
            <Header />
            <Text content={"Contact Us"} className="text-4xl m-4" style={{ fontFamily: key.fontFamily, fontWeight: "bold", background: key.primaryColor, WebkitBackgroundClip: "text", WebkitTextFillColor: "transparent" }} />
            <div className="w-2/3 mt-4 ml-4 mb-24 rounded max-[623px]:m-0 max-[623px]:w-11/12" style={{ background: key.formBackground, boxShadow: key.formShadow }}>
                {loading ? <Loader/> : 
                <>
                <div className="flex p-10 items-center pb-0">
                    <img src="./images/gmail.png" className="w-8 h-6 mr-8 max-[450px]:w-7 max-[450px]:h-5 max-[450px]:mr-4 " draggable={false} onMouseDown={() => { return false }} style={{ userDrag: "none" }} />
                    <Text content={`${details?.length > 0 ? details.filter((item) => item?.field == 'email')[0].content : null}`} className="text-3xl italic max-[450px]:text-lg" style={{ fontFamily: key.fontFamily, color: key.blue, fontWeight: "400" }} />
                </div>
                <div className="flex mb-10 p-10 items-center">
                <img src="./images/call.png" className="w-10 h-10 mr-8 max-[450px]:w-7 max-[450px]:h-5 max-[450px]:mr-4 " draggable={false} onMouseDown={() => { return false }} style={{ userDrag: "none" }} />
                    <Text content={`${details?.length > 0 ? details.filter((item) => item?.field == 'mobile')[0].content : null}`} className="text-3xl italic max-[450px]:text-lg" style={{ fontFamily: key.fontFamily, color: key.blue, fontWeight: "400" }} />
                </div>
                </>}
            </div>
            <Footer />
        </>
    )
}
export default ContactUs;