import { useHistory } from "react-router-dom";
import Button from "../atom/Button";
import Text from "../atom/Text";
import { key } from "../utils/consts";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { BUSINESS_SIGNUP, INDIVIDUAL_SIGNUP, LOGIN } from "../utils/pathDetails";

const SignUpForm = () => {
  const history = useHistory()
  return(
  <div
    className="h-full w-full mt-16 mr-10 ml-10 rounded-t-2xl"
    style={{
      backgroundColor: key.formBackground,
      boxShadow: key.formShadow,
    }}
  >
    <Text
      {...{
        content: `Select an option to create your account`,
        className: "text-center w-full pt-10 text-lg text-gray-600",
        style: { fontFamily: key.fontFamily },
      }}
    />
    <Text
      {...{
        content: `You can either register for your personal account or business account`,
        className: "text-center w-full pb-10 text-lg text-gray-600",
        style: { fontFamily: key.fontFamily },
      }}
    />
    <div className="flex flex-col gap-4 items-center">
      <Button
        {...{
          buttonText: "End User",
          className:
            "p-2.5 text-center text-white phone:w-36 tablet:w-80 rounded-lg",
          style: { background: key.primaryColor, fontFamily:key.fontFamily },
          onClick:()=>history.push(INDIVIDUAL_SIGNUP)
        }}
      />
      <Button
        {...{
          buttonText: "Reseller",
          className:
            "p-2.5 text-center text-white phone:w-36 tablet:w-80 rounded-lg",
          style: { background: key.primaryColor, fontFamily:key.fontFamily },
          onClick:()=>history.push(BUSINESS_SIGNUP)
        }}
      />
      <div className="flex pt-10 gap-3">
        <Text
          {...{
            content: "Already have an account?",
            className: "text-center text-sm text-slate-500",
            style: { fontFamily: key.fontFamily },
          }}
        />
        <button onClick={()=>{history.push(LOGIN)}}><Text
          {...{
            content: "LOG IN",
            className: "text-center text-sm text-blue-500 font-bold",
          }}
        /></button>
      </div>
    </div>
  </div>
)};

export default SignUpForm;
