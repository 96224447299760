import { BASE_URL, getTokenData } from "./config";

export const getPlantDataService = async () => {
  try {
    const response = await fetch(BASE_URL + "/plants", {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: getTokenData(),
      },
    });
    const result = await response.json();
    return { result };
  } catch (error) {
    return { status: false, message: "Something went wrong" };
  }
}
export const getPlantByIdDataService = async (plantId) => {
  try {
    const response = await fetch(BASE_URL + `/plants/${plantId}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: getTokenData(),
      },
    });
    const result = await response.json();
    return { result };
  } catch (error) {
    return { status: false, message: "Something went wrong" };
  }
}
export const getSparePartsDataService = async () => {
  try {
    const response = await fetch(BASE_URL + `products/type/spareParts`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: getTokenData(),
      },
    });
    const result = await response.json();
    return { result };
  } catch (error) {
    return { status: false, message: "Something went wrong" };
  }
}
export const getAccessoriesDataService = async () => {
  try {
    const response = await fetch(BASE_URL + `accessories`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: getTokenData(),
      },
    });
    const result = await response.json();
    return { result };
  } catch (error) {
    return { status: false, message: "Something went wrong" };
  }
}
export const sendQuotationToUser = async (apiBody) => {
  console.log(">apiBody",apiBody)
  try {
    const response = await fetch(BASE_URL + `quotations`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: getTokenData(),
      },
      body: JSON.stringify(apiBody)
    });
    const result = await response.json();
    return { result };
  } catch (error) {
    return { status: false, message: "Something went wrong" };
  }
}
export const checkQuotationByNumber = async (apiBody) => {
  try {
    const response = await fetch(BASE_URL + `quotations/get/data`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: getTokenData(),
      },
      body: JSON.stringify(apiBody)
    });
    const result = await response.json();
    return { result };
  } catch (e) {
    return { status: false, message: "Invalid Quotation Number!" };
  }
}
export const getQuotationById = async (id, apiBody) => {
  try {
    const response = await fetch(BASE_URL + `quotations/${id}`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: getTokenData(),
      },
      body: JSON.stringify(apiBody)
    });
    const result = await response.json();
    return { result };
  } catch (e) {
    return { status: false, message: "Invalid Quotation Number!" };
  }
}
export const callGetServicesData = async () => {
  try {
    const response = await fetch(BASE_URL + "services", {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: getTokenData(),
      },
    });
    const result = await response.json();
    return { result };

  } catch (e) {
    return { status: false, message: "Something Went Wrong!" };
  }
}
export const callGetCategoriesData = async (type) => {
  try {
    const response = await fetch(BASE_URL + "categories" + `/type/${type}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: getTokenData(),
      },
    });
    const result = await response.json();
    return { result };

  } catch (e) {
    return { status: false, message: "Something Went Wrong!" };
  }
}
export const callGetProductByCategories = async (productType, categoryId) => {
  try {
    const response = await fetch(BASE_URL + `${productType}` + `/category/${categoryId}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: getTokenData(),
      },
    });
    const result = await response.json();
    return { result };

  } catch (e) {
    return { status: false, message: "Something Went Wrong!" };
  }
}
export const callGetSparePartsById = async (id) => {
  try {
    const response = await fetch(BASE_URL + "spare-parts" + `/${id}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: getTokenData(),
      },
    });
    const result = await response.json();
    return { result };

  } catch (e) {
    return { status: false, message: "Something Went Wrong!" };
  }
}
export const callGetAccessoriesById = async (id) => {
  try {
    const response = await fetch(BASE_URL + "accessories" + `/${id}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: getTokenData(),
      },
    });
    const result = await response.json();
    return { result };

  } catch (e) {
    return { status: false, message: "Something Went Wrong!" };
  }
}
export const callGetCategoriesById = async (id) => {
  try {
    const response = await fetch(BASE_URL + "categories" + `/${id}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: getTokenData(),
      },
    });
    const result = await response.json();
    return { result };

  } catch (e) {
    return { status: false, message: "Something Went Wrong!" };
  }
}
export const callGetPlantsById = async (id) => {
  try {
    const response = await fetch(BASE_URL + "plants" + `/${id}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: getTokenData(),
      },
    });
    const result = await response.json();
    return { result };

  } catch (e) {
    return { status: false, message: "Something Went Wrong!" };
  }
}
export const callGetAllCategoriesData = async (productType) => {
  try {
    const response = await fetch(BASE_URL + `${productType}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: getTokenData(),
      },
    });
    const result = await response.json();
    return { result };

  } catch (e) {
    return { status: false, message: "Something Went Wrong!" };
  }
}
export const callPostPlaceOrder = async (apiBody) => {
  try {
    const response = await fetch(BASE_URL + 'orders', {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: getTokenData(),
      },
      body: JSON.stringify(apiBody)
    });
    const result = await response.json();
    return { result };

  } catch (e) {
    return { status: false, message: "Something Went Wrong!" };
  }
}
export const callGetOrderData = async () => {
  try {
    const response = await fetch(BASE_URL + 'orders/get/data', {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: getTokenData(),
      },
    });
    const result = await response.json();
    return { result };

  } catch (e) {
    return { status: false, message: "Something Went Wrong!" };
  }
}
export const callPostConfirmData = async (apiBody) => {
  try {
    const response = await fetch(BASE_URL + 'orders/confirm/data', {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: getTokenData(),
      },
      body: JSON.stringify(apiBody)
    });
    const result = await response.json();
    return { result };

  } catch (e) {
    return { status: false, message: "Something Went Wrong!" };
  }
}
export const callGetPaymentBreakupData = async () => {
  try {
    const response = await fetch(BASE_URL + 'payment-breakups', {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: getTokenData(),
      },
    });
    const result = await response.json();
    return { result };

  } catch (e) {
    return { status: false, message: "Something Went Wrong!" };
  }
}
export const getServiceDataById = async (id) => {
  try {
    const response = await fetch(BASE_URL + `services/${id}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: getTokenData(),
      },
    });
    const result = await response.json();
    return { result };

  } catch (e) {
    return { status: false, message: "Something Went Wrong!" };
  }
}
export const getPaymentValuesData = async (apiBody) => {
  try {
    const response = await fetch(BASE_URL + `quotations/get/payment/data`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: getTokenData(),
      },
      body: JSON.stringify(apiBody)
    });
    const result = await response.json();
    return { result };

  } catch (e) {
    return { status: false, message: "Something Went Wrong!" };
  }
}
export const getOrderById = async (id) => {
  try {
    const response = await fetch(BASE_URL + `orders/${id}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: getTokenData(),
      }
    });
    const result = await response.json();
    return { result };

  } catch (e) {
    return { status: false, message: "Something Went Wrong!" };
  }
}
export const callPutAddTransportersDetails = async (id, data) => {
  try {
    const response = await fetch(BASE_URL + `orders/update/transporter-details/${id}`, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: getTokenData(),
      },
      body: JSON.stringify(data)
    });
    const result = await response.json();
    return { result };

  } catch (e) {
    return { status: false, message: "Something Went Wrong!" };
  }
}
export const getCouponsByType = async (type) => {
  try {
    const response = await fetch(BASE_URL + `coupons/get/${type}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: getTokenData(),
      }
    });
    const result = await response.json();
    return { result };

  } catch (e) {
    return { status: false, message: "Something Went Wrong!" };
  }
}
export const callPutCancelOrder = async (id, apiBody) => {
  try {
    const response = await fetch(BASE_URL + `orders/${id}`, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: getTokenData(),
      },
      body: JSON.stringify(apiBody)
    });
    const result = await response.json();
    return { result };

  } catch (e) {
    return { status: false, message: "Something Went Wrong!" };
  }
}
export const callPostCancelOrderMail = async (apiBody) => {
  try {
    const response = await fetch(BASE_URL + "orders/cancel/data", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: getTokenData(),
      },
      body: JSON.stringify(apiBody)
    });
    const result = await response.json();
    return { result };

  } catch (e) {
    return { status: false, message: "Something Went Wrong!" };
  }
}