import { BASE_URL, getTokenData } from "./config";

export const createCartService = async (apiBody) => {
    try {
        const response = await fetch(BASE_URL + "carts", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: getTokenData(),
            },
            body: JSON.stringify(apiBody)
        });
        const result = await response.json();
        return { result };
    } catch (error) {
        return { status: false, message: "Something went wrong" };
    }
};

export const getCartDataService = async () => {
    if (localStorage.getItem("token") != null) {
        try {
            const response = await fetch(BASE_URL + "carts/get/data", {
                method: "GET",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: getTokenData(),
                },
            });
            const result = await response.json();
            return { result };
        } catch (error) {
            return { status: false, message: "Something went wrong" };
        }
    }
}

export const addToCartService = async (apiBody) => {
    try {
        const response = await fetch(BASE_URL + `carts/edit/data`, {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: getTokenData(),
            },
            body: JSON.stringify(apiBody)
        });
        const result = await response.json();
        return { result };
    } catch (error) {
        return { status: false, message: "Something went wrong" };
    }
}
export const getCouponService = async (apiBody) => {
    try {
        const response = await fetch(BASE_URL + `coupons/get`, {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: getTokenData(),
            },
            body: JSON.stringify(apiBody)
        });
        const result = await response.json();
        return { result };
    } catch (error) {
        return { status: false, message: "Something went wrong" };
    }
}
export const sendQuotationFromCart = async (apiBody) => {
    console.log(">apiBody",apiBody)
    try {
        const response = await fetch(BASE_URL + `quotations/send/pdf`, {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: getTokenData(),
            },
            body: JSON.stringify(apiBody)
        });
        const result = await response.json();
        return { result };
    } catch (e) {
        return { status: false, message: "Something went wrong" };
    }
}
export const calculateBillAmountService = async () => {
    try {
        const response = await fetch(BASE_URL + `carts/get/bill`, {
            method: "GET",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: getTokenData(),
            },
        });
        const result = await response.json();
        return { result };
    } catch (e) {
        return { status: false, message: "Something went wrong" };
    }
}
export const calculatePriceDiscount = async (apiBody) => {
    try {
        const response = await fetch(BASE_URL + `carts/get/bill/discount`, {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: getTokenData(),
            },
            body: JSON.stringify(apiBody)
        });
        const result = await response.json();
        return { result };
    } catch (e) {
        return { status: false, message: "Something went wrong" };
    }
}