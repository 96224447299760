import { useEffect, useState } from "react";
import { getAccessoriesDataService } from "../../Services/OrderServices";
import Text from "../../atom/Text";
import { key } from "../../utils/consts";
import Button from "../../atom/Button";
import ExtraAccessoriesModal from "./ExtraAccessoriesModal";
import { IMAGE_URL } from "../../Services/config";
import { FaCheckCircle } from "react-icons/fa";

const ExtraAccessories = ({ plantData, setBomShow, addPrice, setExtraAccssoriesPrice }) => {
    const [accessoriesData, setAccessoriesData] = useState([]);
    const [unfilteredAccessoriesData, setUnfilteredAccessoriesData] = useState([]);
    const [addModal, setAddModal] = useState(false);
    const getAccessoriesData = async () => {
        const response = await getAccessoriesDataService();
        setAccessoriesData(response?.result)
        setUnfilteredAccessoriesData(response?.result)
    }
    return (
        <>
            <ExtraAccessoriesModal openModal={addModal} setOpenModal={setAddModal} accessoriesData={accessoriesData} setAccessoriesData={setAccessoriesData} unfilteredAccessoriesData={unfilteredAccessoriesData} plantData={plantData} setBomShow={setBomShow} addPrice={addPrice} setExtraAccssoriesPrice={setExtraAccssoriesPrice}/>
            <div className="flex items-center mt-10 mb-1 max-[848px]:flex-col max-[848px]:items-start">
                <Text content={"Extra Accessories"} className={"text-lg"} style={{ fontFamily: key.fontFamily, fontWeight: "bold" }} />
            </div>
            <Button
                buttonText={"Add Extra Accessories"}
                className={"rounded px-3 h-10 mt-2 mb-3"}
                style={{ fontFamily: key.fontFamily, backgroundColor: key.blue, color: key.secondaryColor }}
                onClick={() => {
                    getAccessoriesData()
                    setAddModal(true)
                }} />
        </>
    )
}
export default ExtraAccessories;