import { IMAGE_URL } from "../../Services/config";
import Button from "../../atom/Button";
import Text from "../../atom/Text";
import { key } from "../../utils/consts";

const RelatedProduct = ({ productData, setSelectedRelatedProduct, selectedRelatedProduct, quantityData, quotationType }) => {
    return (
        <div className="flex flex-col items-center w-40 border-1" style={{ backgroundColor: "#fff", borderColor:"#d6d6d6" }}>
            <img src={`${IMAGE_URL}/${productData.images[0]}`} className="w-32 mt-2 mx-2" draggable={false} onMouseDown={() => { return false }} style={{ userDrag: "none" }} />
            <div className="flex flex-col items-start w-40 mx-2">
                <Text content={`${productData.name}`} className={"text-sm mx-2 mt-3"} style={{ fontFamily: key.fontFamily, color: "#000", fontWeight: "600" }} />
                <Text content={`${productData.details}`} className={"text-xs mx-2 mt-2 mb-3 line-clamp-3"} style={{ fontFamily: key.fontFamily, color: "#000", fontWeight: "500" }} />
            </div>
            {quotationType != "Standard" ?
                quantityData.length > 0 ?
                    quantityData[0]['quantity'] < 1 ?
                        <Button
                            buttonText={"Select"}
                            className={"w-full text-sm h-8"}
                            style={{ background: key.primaryColor, fontFamily: key.fontFamily, fontWeight: "500", color: "#fff" }}
                            onClick={() => {
                                let unIncreasedData = selectedRelatedProduct.filter((item) => item?.productId != productData?._id);
                                let increasedData = selectedRelatedProduct.filter((item) => item?.productId == productData?._id);
                                increasedData[0]['quantity'] = increasedData[0]['quantity'] + 1;
                                unIncreasedData.push(increasedData[0])
                                setSelectedRelatedProduct(unIncreasedData)
                            }}
                        /> :
                        <div className="w-full flex h-8">
                            <Button className={"w-1/5 h-full flex items-center justify-center"} buttonText={"-"} style={{ color: "#fff", background: key.primaryColor, fontFamily: key.fontFamily }}
                                onClick={() => {
                                    let unIncreasedData = selectedRelatedProduct.filter((item) => item?.productId != productData?._id);
                                    let increasedData = selectedRelatedProduct.filter((item) => item?.productId == productData?._id);
                                    increasedData[0]['quantity'] = increasedData[0]['quantity'] - 1;
                                    unIncreasedData.push(increasedData[0])
                                    setSelectedRelatedProduct(unIncreasedData)

                                }} />
                            <Text content={selectedRelatedProduct.filter((item) => item?.productId == productData?._id)[0]['quantity']} className={"text-sm w-3/5 flex items-center justify-center border-y-2"} style={{ fontFamily: key.fontFamily, background: key.primaryColor, WebkitBackgroundClip: "text", WebkitTextFillColor: "transparent", fontWeight: "bold" }} />
                            <Button
                                className={"w-1/5 h-full flex items-center justify-center"}
                                buttonText={"+"}
                                style={{ color: "#fff", background: key.primaryColor, fontFamily: key.fontFamily }}
                                onClick={() => {
                                    let unIncreasedData = selectedRelatedProduct.filter((item) => item?.productId != productData?._id);
                                    let increasedData = selectedRelatedProduct.filter((item) => item?.productId == productData?._id);
                                    increasedData[0]['quantity'] = increasedData[0]['quantity'] + 1;
                                    unIncreasedData.push(increasedData[0])
                                    setSelectedRelatedProduct(unIncreasedData)
                                }}
                            />
                        </div>
                    :
                    <></>
                :
                <></>
            }
        </div>
    )
}
export default RelatedProduct;