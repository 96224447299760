import { MdVerifiedUser } from "react-icons/md"
import Text from "../../atom/Text"
import { FaCircleCheck, FaCircleUser, FaPen } from "react-icons/fa6"
import { useHistory } from "react-router-dom"
import moment from 'moment';
import Button from "../../atom/Button"
import { key } from "../../utils/consts"
import { EDIT_PROFILE, FORGOT_PASSWORD, QUOTATION_HISTORY } from "../../utils/pathDetails";
import ConfirmModal from "./ConfirmModal";
import { useState } from "react";
import OtpVerificationModal from "./OtpVerificationModal";

const AccountInfo = ({ userData }) => {
    const history = useHistory();
    const [confirmModal, setConfirmModal] = useState(false);
    const [otpVerificationModal, setOtpVerificationModal] = useState(false);
    const [mobile, setMobile] = useState();
    return (
        <>
            <ConfirmModal openModal={confirmModal} setOpenModal={setConfirmModal} setVerificationModal={setOtpVerificationModal} setMobile={setMobile} />
            <OtpVerificationModal openModal={otpVerificationModal} setOpenModal={setOtpVerificationModal} mobile={mobile} />
            <div className="w-1/2 p-4 rounded max-[647px]:w-4/5" style={{ backgroundColor: key.formBackground, borderColor:"#000", boxShadow:key.formShadow }}>
                <div className="flex items-center">
                    <div className="w-2/3 mb-1">
                        <Text content={"Account"} className={"text-2xl mb-1"} style={{ fontFamily: key.fontFamily, color: "#000", fontWeight: "bold" }} />
                        <Text content={"See your account information."} className={"text-base"} style={{ fontFamily: key.fontFamily, fontWeight: 500, color: "gray" }} />
                        <Text content={`(Last modified on ${moment(userData.updatedAt).format('LLLL')})`} className="text-xs italic mt-2" style={{ fontFamily: key.fontFamily, color: "gray" }} />
                    </div>
                    <div className="w-1/3 flex justify-end">
                        <button onClick={() => { setConfirmModal(true) }}>
                            <FaPen size={24} className="mr-2" onClick={() => {
                                // history.push(EDIT_PROFILE)
                            }} />
                        </button>
                    </div>
                </div>
                <hr className="w-full mb-8" />
                <div className="flex items-start mb-8 max-[425px]:flex-col max-[425px]:items-center max-[425px]:mb-6">
                    <FaCircleUser size={100} />
                    <div className="flex flex-col ml-6 max-[425px]:ml-0 max-[425px]:mt-4">
                        <div className="flex items-center max-[943px]:flex-col max-[943px]:items-start max-[425px]:flex-row max-[425px]:items-center">
                            <Text content={userData.name} className="text-xl mb-1" style={{ fontFamily: key.fontFamily, fontWeight: "bold" }} />
                            {userData.verify == true ? <MdVerifiedUser className="ml-2 max-[943px]:ml-0 max-[425px]:ml-2" size={20} style={{ color: "#184295" }} /> : <></>}
                        </div>
                        <Text content={userData.email} className="text-sm italic" style={{ fontFamily: key.fontFamily, color: "gray" }} />
                        <Text content={`+91 ${userData.mobile}`} className="text-sm italic" style={{ fontFamily: key.fontFamily, color: "gray" }} />
                    </div>
                </div>
                <div className="mb-4">
                    <Text content={"Address"} className={"w-50 mb-2 text-sm mr-2 italic"} style={{ fontFamily: key.fontFamily, color: "gray" }} />
                    <Text content={userData.houseNo + ", " + userData.area + ", " + userData.city + ", " + userData.state + ", " + "India-" + userData.pin} className={"text-base w-1/2 max-[802px]:w-full"} style={{ fontFamily: key.fontFamily }} />
                </div>
                <div className="mb-4">
                    <Text content={"GST Number"} className={"w-50 mb-2 text-sm mr-2 italic"} style={{ fontFamily: key.fontFamily, color: "gray" }} />
                    <Text content={userData.GSTNumber} className={"text-base w-1/2"} style={{ fontFamily: key.fontFamily }} />
                </div>
                {userData.role === "admin" || userData.role === "b2b" ? <div className="mb-4">
                    <Text content={"Company"} className={"w-50 mb-2 text-sm mr-2 italic"} style={{ fontFamily: key.fontFamily, color: "gray" }} />
                    <Text content={userData.companyName} className={"text-base w-1/2"} style={{ fontFamily: key.fontFamily }} />
                </div> : <></>}
                <hr className="w-full mb-4" />
                <div className="flex max-[1222px]:flex-col">
                    <div className="w-1/3 max-[1222px]:w-full">
                        <Text content={"Orders"} className={"w-50 mb-2 text-sm mr-2 italic"} style={{ fontFamily: key.fontFamily, color: "gray" }} />
                        <button><Text content={"See All Orders"} className={" mb-2 text-lg mr-2 text-left"} style={{ fontFamily: key.fontFamily, background: key.primaryColor, WebkitBackgroundClip: "text", WebkitTextFillColor: "transparent", fontWeight: "bold" }} /></button>
                    </div>
                    <div className="w-1/3 ml-6 max-[1222px]:ml-0 max-[1222px]:w-full">
                        <Text content={"Quotations"} className={"w-50 mb-2 text-sm mr-2 italic"} style={{ fontFamily: key.fontFamily, color: "gray" }} />
                        <button onClick={() => { history.push(QUOTATION_HISTORY) }}><Text content={"See All Quotations"} className={" mb-2 text-lg mr-2 text-left"} style={{ fontFamily: key.fontFamily, background: key.primaryColor, WebkitBackgroundClip: "text", WebkitTextFillColor: "transparent", fontWeight: "bold" }} /></button>
                    </div>
                    <div className="w-1/3 flex justify-end max-[1222px]:w-full max-[1222px]:justify-start">
                        <Button
                            onClick={() => { history.push(FORGOT_PASSWORD) }}
                            buttonText={"Change Password"}
                            style={{
                                background: key.primaryColor,
                                color: "#fff",
                                fontFamily: key.fontFamily,
                                fontWeight: "400"
                            }}
                            className="w-48 h-12 ml-4 max-[1222px]:ml-0 flex items-center justify-center rounded max-[1222px]:mt-2"
                        />
                    </div>
                </div>
            </div>
        </>
    )
}
export default AccountInfo